import React, { Component } from "react";
import { Modal, Button, message, Input } from "antd";
import { connect } from "react-redux";
import styles from "../styles/sharemodal.module.css";
import nowstyles from "../styles/nowPlaying.module.css";
import {
  imagePicker,
  imagePickContainer,
  imagePickContainerSmall,
} from "../styles/addTeam.module.css";
import { playlistItem } from "../styles/playlist.module.css";
import apiRequest from "../../api/clientRequest";
import ACTIONS from "../../redux/actions/site";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import moment from "moment";
import GroupSearch from "../GroupSearch";
import TEAMACTIONS from "../../redux/actions/team";
import CollectionItem from "../CollectionItem";
import "react-multi-email/style.css";
import CampaignItem from "./CampaignItem";
import ScrollContainer from "react-indiana-drag-scroll";

const mapStateToProps = (state) => ({
  theme: state.site.theme,
  teams: state.team.teams,
  feedlyUser: state.news.feedlyUser,
  selectedTeam: state.team.selectedTeam,
});
var mapDispatchToProps = (dispatch) => ({
  setMeter: (items) => dispatch(ACTIONS.setMeter(items)),
  setMeterProgress: (items) => dispatch(ACTIONS.setMeterProgress(items)),
  setMeterTitle: (items) => dispatch(ACTIONS.setMeterTitle(items)),
  setTeam: (item) => dispatch(TEAMACTIONS.setTeams(item)),
  setSelectedTeam: (item) => dispatch(TEAMACTIONS.setSelectedTeam(item)),
  setCampaignItems: (item) => dispatch(TEAMACTIONS.setCampaignItems(item)),
});

class CampaignEdit extends Component {
  state = {
    emails: [],
    name: "",
    selectedTemplate: "",
    selectedGroups: [],
    items: [],
    selectedCampaign: null,
    previewLink: false,
  };

  resetState() {
    this.setState({
      name: "",
      emails: [],
      selectedTeam: "",
      selectedGroups: [],
      selectedTemplate: "",
      items: [],
      selectedCampaign: "",
    });
  }

  componentWillReceiveProps(nextProp) {
    if (nextProp.visible !== this.props.visible && nextProp.selectedCampaign) {
      this.setState({
        name: nextProp.selectedCampaign["name"],
        emails: nextProp.selectedCampaign["emails"],
        items: nextProp.selectedCampaign["items"],
        selectedTemplate: nextProp.selectedCampaign["selected_template"],
        selectedCampaign: nextProp.selectedCampaign,
      });
    }
  }

  saveCampaign = () => {
    const config = {
      headers: {
        "Content-Type": `multipart/form-data;`,
      },
    };
    this.props.setMeterTitle("Editing Campaign");
    this.props.setMeter(true);
    this.props.setMeterProgress("25");

    const formData = new FormData();
    formData.append("name", this.state.name);
    if (this.state.emails.length > 0)
      formData.append("emails", this.state.emails);
    else
      formData.append(
        "group_id",
        JSON.stringify(this.state.selectedGroups.map((item) => item.id))
      );

    formData.append("selected_template", this.state.selectedTemplate);
    formData.append("_id", this.state.selectedCampaign._id);
    formData.append("items", JSON.stringify(this.state.items));

    apiRequest({
      url: "/teams/campaign/detail",
      method: "PUT",
      data: formData,
      config,
    }).then((res) => {
      this.props.setMeterTitle("Saved Campaign");
      this.props.setMeterProgress("100");

      setTimeout(() => {
        this.props.setMeter(false);
      }, 1000);

      if (res.data.status == 200) {
        let allTeams = this.props.teams;
        const foundIndex = this.props.teams.findIndex(
          (item) => item.id == this.props.selectedTeam.id
        );

        const campIndex = allTeams[foundIndex].campaigns.findIndex(
          (item) => item._id == this.state.selectedCampaign._id
        );
        allTeams[foundIndex].campaigns[campIndex] = res.data.campaign;
        this.props.setSelectedTeam(allTeams[foundIndex]);
        this.props.setTeam([...allTeams]);
        if (!this.state.previewLink) this.props.setVisibility();
      } else {
        message.error(res.data.msg);
        this.props.setMeter(false);
      }
      this.resetState();
    });
  };

  shareByEmail() {
    this.props.setMeterTitle("Sending Email");
    this.props.setMeter(true);
    this.props.setMeterProgress("50");
    const campaign = this.state.selectedCampaign;
    var related = [];
    let { items } = this.state;

    var { team_id, group_ids } = campaign;

    const selected_template = this.state.selectedTemplate;
    var emails = this.state.emails;
    if (group_ids && group_ids.length > 0) {
      let groups = this.props.teams.map((item) => item.groups);
      groups = groups.flat();
      let foundGroup = groups.find((item) => group_ids.includes(item.id));
      emails = foundGroup.emails[0].split(",");
    }

    related = items.slice(1);
    this.saveCampaign();

    let firstItem = items[0];

    apiRequest({
      url: "/news/share/article/email",
      method: "POST",
      data: {
        previewLink: this.state.previewLink,
        related: related,
        loadedLink: firstItem.link,
        shareEmails: emails,
        type: firstItem.type,
        selectedTemplate: selected_template,
        teamid: team_id,
        date: moment().format("X"),
        feedlyUser: this.props.feedlyUser,
        campaignImage: this.state.selectedCampaign.defaultImage,
        groups: [],
        ...firstItem,
        description:
          firstItem["description"] !== undefined ? firstItem.description : "",
      },
    }).then((res) => {
      if (res.data.success) {
        if (this.state.previewLink) {
          window.open(res.data.link);
        } else {
          this.props.setMeterTitle("Email sent successfully");
          this.props.setMeterProgress("100");
          if (this.state.selectedTemplate !== "personal") {
            const allteam = [...this.props.teams];
            const foundIndex = allteam.findIndex(
              (item) => item.id == campaign["team_id"]
            );
          }
        }
        setTimeout(() => {
          this.props.setMeter(false);
        }, 2000);
      } else {
        this.props.setMeter(false);
        message.error(res.data.error);
      }

      this.setState({ previewLink: false });
    });
  }

  removeItem = (data) => {
    if (this.state.items && this.state.items.length > 0) {
      let tempitems = [...this.state.items];
      tempitems = tempitems.filter((item) => item.id !== data.id);
      this.setState({ items: tempitems });
    }
  };

  handleBackgroundImage(files) {
    if (files.length > 0) {
      message.info("Adding Default Image");
      const formData = new FormData();
      formData.append("campaignid", this.state.selectedCampaign._id);
      formData.append("image", files[0]);

      apiRequest({
        url: `/teams/campaign/addImage`,
        method: "POST",
        data: formData,
      }).then((res) => {
        if (res.data.status == 200) {
          message.success("Default Image added");

          let allTeams = this.props.teams;
          const foundIndex = this.props.teams.findIndex(
            (item) => item.id == this.props.selectedTeam.id
          );
          const campIndex = allTeams[foundIndex].campaigns.findIndex(
            (item) => item._id == this.state.selectedCampaign._id
          );
          allTeams[foundIndex].campaigns[campIndex] = res.data.campaign;
          this.props.setSelectedTeam(allTeams[foundIndex]);
          this.props.setTeam([...allTeams]);
        } else message.error(res.data);
      });
    }
  }

  render() {
    return (
      <Modal
        visible={this.props.visible}
        title="Share this Article"
        onOk={this.handleOk}
        footer={[
          <Button key="back" onClick={this.handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={this.handleOk}>
            Share
          </Button>,
        ]}
        className={`${styles.shareEmail} shareEmail share-modal ${this.props.theme}`}
        onCancel={this.handleCancel}
      >
        <div className="p-3">
          <div className="d-flex justify-content-between">
            <h2>Edit Email Campaign</h2>
            <i
              class={`${styles.crossIcon} far fa-times-circle`}
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.props.setVisibility(false);
              }}
            ></i>
          </div>

          <div className="row">
            <div className="col-md-6">
              <h5 className="" style={{ fontSize: "1.3em", marginTop: "80px" }}>
                Step 1: Select Template
              </h5>

              <div className="d-flex mt-4" style={{ overflow: "auto" }}>
                <div className={styles.templateItem}>
                  <img
                    src="/images/personaltemplate.jpg"
                    className={`${styles.templateThumb} d-block`}
                    alt=""
                    style={{ marginBottom: "10px" }}
                  />
                  <span className={styles.templateThumbspan}>Default</span>
                  <button
                    class={`btn ${styles.selectBtn} ${
                      this.state.selectedTemplate == "personal"
                        ? styles.selectBtnActive
                        : ""
                    }`}
                    onClick={() =>
                      this.setState({ selectedTemplate: "personal" })
                    }
                  >
                    SELECT
                  </button>
                </div>
                {this.props.selectedTeam.template ? (
                  <div className={styles.templateItem}>
                    <img
                      src={`/images/${this.props.selectedTeam.template}.jpg`}
                      className={`${styles.templateThumb} d-block`}
                      alt=""
                      style={{ marginBottom: "10px" }}
                    />
                    <span className={styles.templateThumbspan}>
                      {this.props.selectedTeam.name}
                    </span>
                    <button
                      class={`btn ${styles.selectBtn} ${
                        this.state.teamid == this.props.selectedTeam.id
                          ? styles.selectBtnActive
                          : ""
                      }`}
                      onClick={() =>
                        this.setState({
                          selectedTemplate: this.props.selectedTeam.template,
                          fromName: this.props.selectedTeam.name,
                          teamid: this.props.selectedTeam.id,
                          selectedGroups: [],
                        })
                      }
                    >
                      SELECT
                    </button>
                  </div>
                ) : (
                  <div className={styles.templateItem}>
                    <img
                      src="/images/personaltemplate.jpg"
                      className={`${styles.templateThumb} d-block`}
                      alt=""
                      style={{ marginBottom: "10px" }}
                    />
                    <span className={styles.templateThumbspan}>
                      {this.props.selectedTeam.name}
                    </span>
                    <button
                      class={`btn ${styles.selectBtn} ${
                        this.state.selectedTemplate ==
                        this.props.selectedTeam.name
                          ? styles.selectBtnActive
                          : ""
                      }`}
                      onClick={() =>
                        this.setState({
                          selectedTemplate: this.props.selectedTeam.name,
                          fromName: this.props.selectedTeam.name,
                          teamid: this.props.selectedTeam.id,
                          selectedGroups: [],
                        })
                      }
                    >
                      SELECT
                    </button>
                  </div>
                )}
              </div>

              <div
                className={`${nowstyles.titleContainer} pr-4`}
                style={{ marginTop: "3em" }}
              >
                <h1 className={nowstyles.title} style={{ fontSize: "1.6em" }}>
                  Email Campaign Template
                </h1>
                <div className="d-flex align-items-center">
                  <p>
                    {" "}
                    Upload Default Background Image/Thumbnail for Email
                    Messages.
                  </p>
                  <div
                    style={{ cursor: "pointer" }}
                    // onClick={() => this.handleBackgroundImage()}
                    className={`currency-box  ${imagePickContainerSmall} ${imagePickContainer}`}
                  >
                    <div
                      className={`${playlistItem}  currency-pair   `}
                      style={{
                        background: "#393939",
                        minHeight: "30px",
                        height: "90px",
                      }}
                    >
                      <div
                        className={`cat-content text-center`}
                        style={{ cursor: "pointer" }}
                      >
                        <div>
                          <img
                            src="/images/plusNewIcon.png"
                            style={{
                              width: "55px",
                            }}
                          />
                          <input
                            onChange={(e) =>
                              this.handleBackgroundImage(e.target.files)
                            }
                            type="file"
                            name=""
                            id=""
                            className={imagePicker}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-5 ">
              <div className="mb-5 mt-4">
                <h5 className="mb-4" style={{ fontSize: "1.3em" }}>
                  Step 2: Add Campaign Name
                </h5>
                <Input
                  placeholder="Campaign Name"
                  className="campaignName"
                  value={this.state.name}
                  onChange={(e) => this.setState({ name: e.target.value })}
                />
              </div>
              <div className="">
                <h5 className="mb-4" style={{ fontSize: "1.3em" }}>
                  Step 3: Add Recipients and Send
                </h5>
                <ReactMultiEmail
                  placeholder="Enter email"
                  emails={this.state.emails}
                  onChange={(_emails) => {
                    this.setState({ emails: _emails });
                  }}
                  validateEmail={(email) => {
                    return isEmail(email); // return boolean
                  }}
                  getLabel={(email, index, removeEmail) => {
                    return (
                      <div data-tag key={index}>
                        {email}
                        <span
                          data-tag-handle
                          onClick={() => removeEmail(index)}
                        >
                          ×
                        </span>
                      </div>
                    );
                  }}
                />
                <span
                  style={{ fontSize: "1.2em" }}
                  className="d-block text-center my-5 mx-3"
                >
                  OR
                </span>
                <h5 className="mb-4" style={{ fontSize: "1.3em" }}>
                  Select an Email Group
                </h5>
                <GroupSearch
                  groups={
                    this.props.selectedTeam
                      ? this.props.teams.find(
                          (item) => item.id == this.props.selectedTeam.id
                        ).groups
                      : []
                  }
                  onSelect={(group) =>
                    this.setState((prev) => ({
                      selectedGroups: [...prev.selectedGroups, group],
                    }))
                  }
                />
              </div>
            </div>

            <div className="col-md-12 mt-4 pl-0">
              <h5 className="mb-4" style={{ fontSize: "1.3em" }}>
                Selected Campaign Items
              </h5>

              <ScrollContainer style={{ overflowX: "scroll", display: "flex" }}>
                {this.state.items.map((item) => (
                  <CampaignItem
                    data={item}
                    onRemove={() => this.removeItem(item)}
                    type={item.type}
                  />
                ))}
              </ScrollContainer>
            </div>
          </div>

          <div className="d-flex">
            <button
              class={`btn  mt-3 mb-4 mr-4 ${styles.selectBtn} ${styles.selectBtnActive}`}
              style={{ width: "145px" }}
              onClick={() => {
                this.setState({ previewLink: true }, () => {
                  this.shareByEmail();
                });
              }}
            >
              Preview Link
            </button>
            <button
              class={`btn  mt-3 mb-4 ${styles.selectBtn} ${styles.selectBtnActive}`}
              style={{ width: "145px" }}
              onClick={this.shareByEmail.bind(this)}
            >
              SEND
            </button>

            <button
              class={`btn ml-auto mt-3 mb-4 ${styles.selectBtn} ${styles.selectBtnActive}`}
              style={{ width: "145px" }}
              onClick={() => this.saveCampaign()}
            >
              SAVE
            </button>
          </div>

          <img
            style={{ width: "100px", float: "right" }}
            className="ml-auto"
            src="/images/AudioOneLogo@2x.png"
            alt=""
          />
        </div>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CampaignEdit);
