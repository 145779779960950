import ACTIONS from "../actions/site";
import _ from "lodash";
import player from "../../data/player";

const playerInstance = player();
var defaultState = {
  searchVal: "",
  searchType: "",

  SearchToggled: false,
  instantSearch: false
};

var siteReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ACTIONS.Types.setInstantSearch: {
      let newState = _.cloneDeep(state);
      newState.instantSearch = action.payload;
      return newState;
    }
    case ACTIONS.Types.searchVal: {
      let newState = _.cloneDeep(state);
      newState.searchVal = action.payload;
      return newState;
    }

    case ACTIONS.Types.searchType: {
      let newState = _.cloneDeep(state);
      newState.searchType = action.payload;
      return newState;
    }

    case ACTIONS.Types.setSearchToggle: {
      let newState = _.cloneDeep(state);
      newState.SearchToggled = action.payload;
      return newState;
    }
    default:
      return state;
  }
};
export default siteReducer;
