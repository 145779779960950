import React from "react";
import Slider from "react-slick";
import newstyle from "./styles/newui.module.css";
import MainSliderItem from "./MainSliderItem";
import playliststyle from "./styles/playlist.module.css";
import player from "../data/player";
import apiRequest from "../api/clientRequest";
import ACTIONS from "../redux/actions/site";
import { connect } from "react-redux";

var mapStateToProps = (state) => {
  return {
    savedItems: state.site.savedItems,
  };
};
var mapDispatchToProps = (dispatch) => ({
  setSavedItems: (items) => dispatch(ACTIONS.setSavedItems(items)),
});

function MainSlider(props) {
  const sliderRef = React.useRef(null);
  const [activeSlide, setActiveSlide] = React.useState(0);
  const [sliderAutoplay, setSliderAutoplay] = React.useState(true);

  var settings = {
    dots: false,
    infinite: true,
    autoplay: sliderAutoplay,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: props.type == "Video" ? 30000 : 12000,
    pauseOnHover: false,
    fade: true,
    beforeChange: (current, next) => setActiveSlide(next),
  };

  var Player = player();

  const playAudio = async (type, media) => {
    if (type === "news") {
      await Player.playAudio({ ...media, recent: false }, "playNow");
    } else if (type === "podcast")
      Player.playPodcast(
        {
          episode: media,
          title: media.source,
          recent: false,
        },
        "playNow"
      );
    else if (type === "music") Player.playSpotifyTrack(media, "playNow");
    else if (type === "youtube") Player.playVideo(media, "playNow");
    else
      Player.playShorts(
        {
          ...media,
          title: `${media.title} : ${media.source}`,
        },
        "playNow"
      );
  };

  const saveItem = (item) => {
    if (props.savedItems.find((ite) => ite.track_id === item.id)) {
      item.clicked = false;
      props.setSavedItems(
        props.savedItems.filter((ite) => ite.track_id !== item.id)
      );
      apiRequest({ url: "/news/save", method: "POST", data: item });
    } else {
      item.clicked = true;
      apiRequest({ url: "/news/save", method: "POST", data: item });
      props.setSavedItems([
        { track_id: item.id, ...item },
        ...props.savedItems.map((ite) => ite),
      ]);
    }
  };

  return (
    <Slider
      {...settings}
      className={`${newstyle.mainSlider} mainSlider`}
      ref={sliderRef}
    >
      {props.items.map((item, index) => (
        <div className={newstyle.mainContainer}>
          <h1
            className={`${playliststyle.mainHeading} ${playliststyle.explodedHeading}`}
          >
            {props.type}
          </h1>

          {props.type == "Video" && activeSlide == index ? (
            <div class={newstyle.videoBackground}>
              <iframe
                width="100%"
                height="100%"
                src={`https://www.youtube.com/embed/${item.id}?controls=0&showInfo=0&autoplay=1&start=20&mute=1`}
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>{" "}
            </div>
          ) : (
            <div
              style={{
                backgroundImage: `url(${item.image})`,
              }}
            ></div>
          )}

          <div className={newstyle.topOverlay}></div>

          <div className={newstyle.itemContainer}>
            <MainSliderItem
              setAutoPlay={(cond) => setSliderAutoplay(cond)}
              saveItem={saveItem}
              data={item}
              onPlay={(data) => playAudio(data.type, data)}
            />
          </div>
        </div>
      ))}
    </Slider>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(MainSlider);
