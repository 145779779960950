import React from "react";
import contentStyle from "./styles/contentstyle.module.css";
import styles from "./styles/nowPlaying.module.css";
import player from "../data/player";
import { connect } from "react-redux";
import Actions from "../redux/actions/news";
import ShareModal from "./shareModal";
import ShareMethods from "./ShareMethods";
import FavoriteFeedModal from "./FavoriteFeedModal";
import podstyles from "./styles/podcastInfo.module.css";
import PlayHover from "./PlayHover";
import apiRequest from "../api/clientRequest";

var mapStateToProps = (state) => {
  return {
    queue: state.site.queue,
    savedItems: state.site.savedItems,
    completedPodcast: state.site.completedPodcast,
    podcasts: state.news.podcasts,
    subscribedItems: state.site.subscribedItems,
  };
};

var mapDispatchToProps = (dispatch) => ({
  setDoNotPlay: (item) => dispatch(Actions.setDoNotPlay(item)),
});

function ContentSidebarItem(props) {
  const Player = player();
  const container = React.useRef(null);

  const [modalVisible, setModalVisible] = React.useState(false);
  const [showShareHover, setShowShareHover] = React.useState(false);
  const [selectedArticle, setSelectedArticle] = React.useState(false);
  const [shareMode, setShareMode] = React.useState(true);
  const [likeHover, setLikeHover] = React.useState(false);
  const [playHover, setPlayHover] = React.useState(false);
  if (props.currentPlayed)
    if (props.currentPlayed.id == props.data.id)
      if (container && container.current) {
        setTimeout(() => {
          if (container && container.current)
            container.current.scrollIntoView({
              behavior: "smooth",
              block: props.isQueue ? "center" : "start",
            });
        }, 1200);
      }

  const setSelectArticle = (mode, item) => {
    setSelectedArticle(item);
    setShareMode(mode);
    setModalVisible(true);
  };

  let podcast = null;

  if (
    props.data.type == "podcast" &&
    props.podcasts &&
    props.podcasts.length > 0
  ) {
    let podcasts = props.podcasts.find((item) =>
      item.podcasts.find((ite) =>
        ite.episode.find((eps) => eps.id == props.data.id)
      )
    );
    if (podcasts) {
      podcasts = podcasts.podcasts;
      podcast = podcasts.find((item) =>
        item.episode.find((eps) => eps.id == props.data.id)
      );
    }
  }

  const DoNotPlayItem = (id) => {
    apiRequest({
      method: "POST",
      url: "/news/notplay",
      data: {
        item_id: id,
      },
    }).then((res) => {
      console.log(res.data);
      props.setDoNotPlay(res.data);
    });
  };

  return (
    <div
      ref={container}
      className={`${contentStyle.playingContainer} ${
        props.activeActivity && props.activeActivity.id !== props.data.id
          ? "d-none"
          : ""
      } ${
        props.currentPlayed && props.currentPlayed.id == props.data.id
          ? ""
          : contentStyle.playingSub
      }`}
      style={{
        backgroundImage: `url(${props.data.image})`,
      }}
      onMouseLeave={() => {
        setShowShareHover(false);
        setLikeHover(false);
        setPlayHover(false);
      }}
    >
      <ShareModal
        visible={modalVisible}
        setVisiblity={(cond) => setModalVisible(cond)}
        selectedItem={selectedArticle}
        type={props.type}
        mode={shareMode}
      />

      <div className={contentStyle.imageOverlay}> </div>

      <div
        className={
          showShareHover || likeHover || playHover
            ? contentStyle.itemHovered
            : ""
        }
      >
        <div
          className={`${
            showShareHover || likeHover || playHover ? "d-none" : "d-flex"
          }  justify-content-end align-items-center mb-2`}
        >
          {props.type == "podcast" ? (
            <img
              src="/images/Radio.png"
              className={styles.expandIcon}
              style={{ width: "25px", height: "25px", marginRight: "10px" }}
            />
          ) : props.type == "spotify" ? (
            <img
              src="/images/spotWhite.png"
              className={styles.expandIcon}
              style={{ width: "25px", height: "25px", marginRight: "10px" }}
            />
          ) : (
            <img
              src="/images/feedlyWhite.png"
              className={styles.expandIcon}
              style={{ width: "25px", height: "25px", marginRight: "10px" }}
            />
          )}
          <span
            className={`name mt-1 ${
              showShareHover || likeHover || playHover ? "d-none" : "d-flex"
            }`}
            style={{ textTransform: "capitalize", fontSize: "1.3em" }}
          >
            {props.type}
          </span>
        </div>
        <h3
          className={`${
            showShareHover || likeHover || playHover ? "d-none" : ""
          }`}
        >
          {props.data.title}
        </h3>
        <div
          className={`${
            showShareHover || likeHover || playHover ? "d-none" : "d-flex"
          } align-items-center mb-3 mt-2 sourceContainer`}
        >
          {props.data.sourceImage ? (
            <img
              src={props.data.sourceImage}
              style={{ width: "25px", height: "25px", marginRight: "10px" }}
            />
          ) : (
            ""
          )}
          <span className={`${styles.source} name mt-1`}>
            {props.data.source}
          </span>
        </div>

        {props.data.type == "podcast" &&
        props.currentPlayed &&
        podcast &&
        props.currentPlayed.id == props.data.id ? (
          props.subscribedItems.find((item) => {
            return item.id == podcast.id || item.item_id == podcast.id;
          }) ? (
            <button
              className={`${podstyles.subscribeBtn} ${podstyles.subscribedBtn}`}
              style={{ marginTop: "2em" }}
              onClick={() => props.subscribeTo(podcast)}
            >
              Subscribed
            </button>
          ) : (
            <button
              className={` ${podstyles.subscribeBtn}`}
              onClick={() => props.subscribeTo(podcast)}
              style={{ marginTop: "2em" }}
            >
              Subscribe
            </button>
          )
        ) : (
          ""
        )}
        <div className="">
          {props.queue.currentPlayed["id"] &&
          props.queue.currentPlayed.id == props.data.id ? (
            props.queue.currentPlayed.playing ? (
              <img
                src="/images/pause.png"
                className={`${styles.playIcon} `}
                onMouseEnter={() => setPlayHover(true)}
                alt=""
                onClick={() => {
                  Player.playQueue(props.data);
                }}
              />
            ) : (
              <img
                src="/images/playIcon.png"
                className={`${styles.playIcon} `}
                alt=""
                onMouseEnter={() => setPlayHover(true)}
                onClick={() => {
                  Player.playQueue(props.data);
                }}
              />
            )
          ) : (
            <img
              src="/images/playIcon.png"
              className={`${styles.playIcon} `}
              alt=""
              onMouseEnter={() => setPlayHover(true)}
              onClick={() => {
                if (props.isQueue) Player.playQueue(props.data);
                else Player.playAudio(props.data);
              }}
            />
          )}

          <div
            className="d-flex"
            style={{
              position: "absolute",
              right: "0",
              bottom: "0",
            }}
          >
            <div className="">
              <img
                src="/images/messageIcon.png"
                alt=""
                className={styles.actionBtn}
                onClick={() => props.showComments()}
              />

              {props.data.reaction_counts
                ? props.data.reaction_counts["comment"]
                : ""}
            </div>
            <div className="">
              <img
                alt=""
                className={styles.actionBtn}
                onClick={() => props.showDetail()}
              />
            </div>
            <div className="">
              <img
                src="/images/cluster.png"
                alt=""
                onMouseEnter={() => setShowShareHover(true)}
                className={styles.actionBtn}
              />
            </div>
            <div className="">
              {props.savedItems.find((ite) => ite.track_id == props.data.id) ? (
                <img
                  src="/images/HeartFill.png"
                  className={styles.actionBtn}
                  onClick={() => props.saveItem(props.data)}
                  onMouseEnter={() => setLikeHover(props.data.id)}
                  alt=""
                />
              ) : (
                <img
                  src="/images/Heart.png"
                  className={styles.actionBtn}
                  onClick={() => props.saveItem(props.data)}
                  onMouseEnter={() => setLikeHover(props.data.id)}
                  alt=""
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {props.currentPlayed && props.currentPlayed.id == props.data.id ? (
        <PlayHover
          open={playHover}
          setShow={() => setPlayHover(false)}
          id={props.data.id}
          setDoNotPlay={DoNotPlayItem}
          type={props.data.type}
          source={props.data.source}
        />
      ) : (
        ""
      )}

      <ShareMethods
        id={true}
        showHover={showShareHover}
        setShowHover={() => setShowShareHover(false)}
        setSelected={(mode) => {
          if (props.data.type == "podcast") {
            setSelectArticle(mode, {
              ...props.data,
              podcastTitle: podcast.title,
              podcastDescription: podcast.description,
            });
          } else setSelectArticle(mode, props.data);
        }}
      />

      <FavoriteFeedModal
        id={props.data.id}
        visible={likeHover}
        setHover={() => setLikeHover(false)}
        saveToCollection={() => props.saveItem(props.data)}
        selectedArticle={props.data}
        savedItems={props.savedItems}
        type={props.type}
      />
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentSidebarItem);
