import React, { Component } from "react";
import styles from "../styles/search.module.css";
import FilterItem from "./FilterItem";
import colors from "../../data/colors.json";

export default class SearchFilter extends Component {
  render() {
    return (
      <div>
        <h2 className={styles.headerHeading}>{this.props.data.heading}</h2>
        <div
          className={`d-flex filterContainer mt-4 ${styles.filterContainer} flex-wrap`}
        >
          {this.props.data.filters.map(item => {
            var color = false;
            if (this.props.theme == "color-theme")
              color = colors[Math.floor(Math.random() * colors.length)];
            else if (this.props.theme == "white-theme") color = "#EAEAEA";
            else color = "#2E2D31"
            return (
              <FilterItem
                color={color}
                active={this.props.selectedFilter == item.type}
                heading={item.heading}
                type={item.type}
                icon={item.icon}
                onSelect={val => this.props.onSelect(val)}
              />
            );
          })}
        </div>
      </div>
    );
  }
}
