import React, { Component } from "react";
import styles from "../styles/nowPlaying.module.css";
import { connect } from "react-redux";
import ACTIONS from "../../redux/actions/site";
import TEAMACTIONS from "../../redux/actions/team";
import MemberItem from "../MemberItem";
import AddGroup from "./AddGroup";
import EditGroup from "./EditGroup";
import { message } from "antd";
import apiRequest from "../../api/clientRequest";
import ScrollContainer from "react-indiana-drag-scroll";

var mapStateToProps = (state) => {
  return {
    savedItems: state.site.savedItems,
    selectedTeam: state.team.selectedTeam,
    teams: state.team.teams,
    user: state.auth.user,
  };
};

var mapDispatchToProps = (dispatch) => ({
  setSavedItems: (items) => dispatch(ACTIONS.setSavedItems(items)),
  setGroupSelected: (items) => dispatch(TEAMACTIONS.setGroupSelected(items)),
  setTeam: (item) => dispatch(TEAMACTIONS.setTeams(item)),
  setSelectedTeam: (item) => dispatch(TEAMACTIONS.setSelectedTeam(item)),
});

class GroupList extends Component {
  state = {
    editGroup: false,
    backgroundImage: null,
  };

  setGroupSelected = (id) => {
    const foundItem = this.props.selectedTeam;

    const foundUser = foundItem.users.find(
      (user) => user.email == this.props.user.email
    );
    if (foundUser && foundUser.role === "Member") {
      message.error("You do not have permission to perform this operation");
      return;
    }

    const foundGroup = this.props.selectedTeam.groups.find(
      (item) => item.id == id
    );
    this.props.setGroupSelected(foundGroup);
    this.setState({ editGroup: true });
  };

  deleteGroup = (id) => {
    const foundItem = this.props.selectedTeam;

    const foundUser = foundItem.users.find(
      (user) => user.email == this.props.user.email
    );
    if (foundUser && foundUser.role === "Member") {
      message.error("You do not have permission to perform this operation");
      return;
    }

    apiRequest({
      url: `/teams/group/${id}`,
      method: "DELETE",
    }).then((res) => {
      if (res.data.status == 200) {
        let allTeams = this.props.teams;
        const foundIndex = this.props.teams.findIndex(
          (item) => item.id == this.props.selectedTeam.id
        );

        allTeams[foundIndex].groups = allTeams[foundIndex].groups.filter(
          (item) => item.id !== id
        );
        this.props.setSelectedTeam(allTeams[foundIndex]);
        this.props.setTeam([...allTeams]);
      } else message.error(res.data);
    });
  };

  render() {
    return (
      <div className={`${styles.content} ${styles.podcastContent}`}>
        <>
          <div className="" style={{ width: "90%" }}>
            <h1
              className={`${styles.title} mt-5 text-right ${styles.podcastHead}`}
              style={{ flex: "none", width: "100%" }}
            >
              <img
                src="/images/userScreen.png"
                className={styles.typeImage}
                style={{
                  width: "20px",
                  marginRight: "0.7em",
                  marginBottom: "0.25em",
                }}
              />
              Team
            </h1>

            <EditGroup
              visible={this.state.editGroup}
              handleClose={() => this.setState({ editGroup: false })}
            />
            <div className={styles.titleContainer} style={{ marginTop: "6em" }}>
              <h1 className={styles.title} style={{ fontSize: "2.6em" }}>
                {this.props.selectedTeam ? this.props.selectedTeam.name : ""}
              </h1>
              <img
                src={
                  this.props.selectedTeam ? this.props.selectedTeam.logo : ""
                }
                style={{ width: "90px", height: "50px" }}
                alt=""
              />
              <div
                className="d-flex align-items-center"
                style={{ marginBottom: "3em" }}
              ></div>
            </div>
            <div className={styles.titleContainer} style={{ marginTop: "6em" }}>
              <h1 className={styles.title} style={{ fontSize: "1.6em" }}>
                Email Campaign Groups
              </h1>
              <ScrollContainer>
                <div className={styles.listContainer}>
                  {this.props.selectedTeam
                    ? this.props.selectedTeam.groups.map((item) => (
                        <MemberItem
                          onClick={this.setGroupSelected}
                          onDelete={this.deleteGroup}
                          data={{
                            avatar: item.avatar,
                            fullname: item.name,
                            id: item.id,
                          }}
                        />
                      ))
                    : ""}
                </div>
              </ScrollContainer>
            </div>

            <AddGroup />
          </div>
        </>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupList);
