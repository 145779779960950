import React, { Component } from "react";
import ACTIONS from "../redux/actions/site";
import TEAMACTIONS from "../redux/actions/team";
import { connect } from "react-redux";
import { Modal, Button, message } from "antd";
import modalstyles from "./styles/sharemodal.module.css";
import mainstyle from "./styles/addTeam.module.css";
import apiRequest from "../api/clientRequest";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import Suggestionitem from "./SuggestionItem";
import UserImage from "./UserImage";
import UserSearch from "./UserSearch";

var mapStateToProps = (state) => {
  return {
    savedItems: state.site.savedItems,
    theme: state.site.theme,
    selectedTeam: state.team.selectedTeam,
    recentUsers: state.site.recentUsers,
    teams: state.team.teams,
    user: state.auth.user,
  };
};
var mapDispatchToProps = (dispatch) => ({
  setSavedItems: (items) => dispatch(ACTIONS.setSavedItems(items)),
  setTeam: (item) => dispatch(TEAMACTIONS.setTeams(item)),
  setSelectedTeam: (item) => dispatch(TEAMACTIONS.setSelectedTeam(item)),
  setMeter: (items) => dispatch(ACTIONS.setMeter(items)),
  setMeterProgress: (items) => dispatch(ACTIONS.setMeterProgress(items)),
  setMeterTitle: (items) => dispatch(ACTIONS.setMeterTitle(items)),
});

class AddMember extends Component {
  state = {
    modalVisible: false,
    selectedUsers: [],
    emails: [],
    teamName: "",
    teamLogo: null,
  };

  handleOk = async () => {
    this.setState({ modalVisible: false });
  };

  componentWillReceiveProps(nextProp) {
    if (
      nextProp.selectedTeam &&
      this.state.selectedUsers.length !== nextProp.selectedTeam.users.length
    ) {
      this.setState({ selectedUsers: nextProp.selectedTeam.users });
    }
  }

  addToTeam = () => {
    if (
      (this.state.selectedUsers.length > 0 || this.state.emails.length > 0) &&
      this.props.selectedTeam
    ) {
      this.props.setMeterTitle("Adding Members");
      this.props.setMeter(true);
      this.props.setMeterProgress("25");

      setTimeout(() => {
        this.props.setMeterTitle("Sending Email Invites");
        this.props.setMeterProgress("50");
      }, 1500);

      apiRequest({
        url: "/teams/addmember",
        method: "post",
        data: {
          users: this.state.selectedUsers,
          teamid: this.props.selectedTeam.id,
          emails: this.state.emails,
        },
      }).then((res) => {
        this.props.setMeterTitle("Invites send");
        this.props.setMeterProgress("100");

        setTimeout(() => {
          this.props.setMeter(false);
        }, 1500);

        this.setState({ modalVisible: false });
        let restTeams = this.props.teams;
        let curTeam = this.props.teams.findIndex(
          (item) => item.id == this.props.selectedTeam.id
        );
        restTeams[curTeam].users = res.data.users;
        this.props.setTeam([...restTeams]);
        this.props.setSelectedTeam(restTeams[curTeam]);
        this.handleOk();
        if (res.data.status == 200) {
          message.success("Users Added to selected team");
        } else {
          message.error(res.data.msg);
        }
      });
    }
  };

  handleLogoChange(files) {
    if (files.length > 0) this.setState({ teamLogo: files[0] });
  }

  handleAddMember() {
    const foundItem = this.props.selectedTeam;

    const foundUser = foundItem.users.find(
      (user) => user.email == this.props.user.email
    );
    const allowedRoles = ["Member", "Editor"];

    if (foundUser && !allowedRoles.includes(foundUser["role"])) {
      this.setState({ modalVisible: true });
    } else {
      message.error("You do not have permission to perform this operation");
    }
  }

  render() {
    return (
      <div>
        <div
          className="d-flex align-items-center"
          style={{ cursor: "pointer", position: "absolute", bottom: "60px" }}
          onClick={this.handleAddMember.bind(this)}
        >
          <img
            src="/images/plusNewIcon.png"
            style={{
              width: "45px",
              marginRight: "10px",
            }}
          />
          <h2 style={{ fontSize: "1.8em", margin: "0", opacity: "0.55" }}>
            Add Members
          </h2>
        </div>
        <Modal
          visible={this.state.modalVisible}
          title="Create Team"
          onOk={this.handleOk}
          footer={[
            <Button key="back" onClick={this.handleOk}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" onClick={this.handleOk}>
              Share
            </Button>,
          ]}
          className={`${modalstyles.shareEmail} teamModal shareEmail share-modal ${this.props.theme}`}
          onCancel={this.handleOk}
        >
          <div className="p-3">
            <div className="d-flex justify-content-between">
              <h2 style={{ color: "#b8c0c9" }}>Invite New Members</h2>
              <i
                class={`${modalstyles.crossIcon} far fa-times-circle`}
                style={{ cursor: "pointer" }}
                onClick={() => this.handleOk()}
              ></i>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="px-4 mt-5">
                  <img
                    style={{ width: "100px", height: "50px" }}
                    src={
                      this.props.selectedTeam
                        ? this.props.selectedTeam.logo
                        : ""
                    }
                    alt=""
                  />
                </div>
                <div className="px-4 mt-5">
                  <h5 className={mainstyle.inputMainLabel}>
                    Invite Team Members
                  </h5>
                  <h6 className="mb-3">SUGGESTIONS</h6>
                  <div style={{ maxHeight: "210px", overflow: "auto" }}>
                    {this.props.recentUsers.map((item) => (
                      <Suggestionitem
                        data={item}
                        InviteMember={(item) => {
                          if (
                            !this.state.selectedUsers.find(
                              (ite) => ite.id == item.id
                            )
                          )
                            this.setState((prev) => ({
                              selectedUsers: [...prev.selectedUsers, item],
                            }));
                        }}
                      />
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="px-4 mt-5">
                  <h5 className={mainstyle.inputMainLabel}>Invited Members</h5>
                  <div className="d-flex">
                    {this.state.selectedUsers.map((item) => (
                      <UserImage avatar={item.avatar} name={item.name} />
                    ))}
                  </div>
                </div>
                <div className="px-4 " style={{ marginTop: "7.1em" }}>
                  <h6 className={` mb-3`}>SEARCH</h6>
                  <div className={mainstyle.inputContainer}>
                    <UserSearch
                      onSelect={(users) =>
                        this.setState({ selectedUsers: users })
                      }
                      selectedUsers={this.state.selectedUsers}
                    />
                  </div>

                  <h6 className={`mt-4 mb-3`}>INVITE BY EMAIL</h6>
                  <div className={mainstyle.inputContainer}>
                    {/* <input
                      type="text"
                      placeholder="Add email Addresses here"
                    /> */}
                    <ReactMultiEmail
                      placeholder="Add email Addresses here"
                      emails={this.state.emails}
                      onChange={(_emails) => {
                        this.setState({ emails: _emails });
                      }}
                      className="w-100"
                      validateEmail={(email) => {
                        return isEmail(email); // return boolean
                      }}
                      getLabel={(email, index, removeEmail) => {
                        return (
                          <div data-tag key={index}>
                            {email}
                            <span
                              data-tag-handle
                              onClick={() => removeEmail(index)}
                            >
                              ×
                            </span>
                          </div>
                        );
                      }}
                    />
                  </div>
                </div>
                <button
                  onClick={() => this.addToTeam()}
                  className={`btn btn-primary ${mainstyle.addBtn}`}
                >
                  Add Members
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddMember);
