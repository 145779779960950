import React, { Component } from 'react'
import Navbar from '../components/Navbar/FrontNavbar'
import { Switch, Route } from 'react-router-dom'
import Home from '../pages/home'
export default class FrontLayout extends Component {
  render() {
    const match = this.props.match
    return (
      <div className='body-wrapper'>
        <Navbar />
        <div className='page-content'>
          <Switch>
            {/* <Route path={`${match.url}linkAccount`} component={LinkAccount} /> */}
            {/* <Route path={`${match.url}signin`} component={signIn} /> */}
            {/* <Route path={`${match.url}signup/:teamid?`} component={signUp} /> */}
            <Route path={`${match.url}`} exact component={Home} />
          </Switch>
        </div>
      </div>
    )
  }
}
