// import { createStore, applyMiddleware } from "redux";
// import { composeWithDevTools } from "redux-devtools-extension";

// // Logger with default options
// import logger from "redux-logger";
// import thunk from "redux-thunk";

// import reducer from "./reducers/index";

// export default function configureStore(initialState) {
//   var store = createStore(reducer, initialState, applyMiddleware(logger));
//   return store;
// }

import { createStore, applyMiddleware, combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

import newsReducer from "./reducers/news";
// // Logger with default options
import logger from "redux-logger";
import thunk from "redux-thunk";

import reducer from "./reducers/index";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

// const persistConfig = {
//   key: "root",
//   storage,
//   blacklist: ["news"],
// };

// const newsPersistConfig = {
//   key: "news",
//   storage,
//   blacklist: ["radioMode"],
// };

// reducer["news"] = persistReducer(newsPersistConfig, newsReducer);

const rootReducer = combineReducers(reducer);

// const persistedReducer = persistReducer(persistConfig, rootReducer);

export default function configureStore(initialState) {
  if (process.env.REACT_APP_STAGE === "production")
    var store = createStore(rootReducer, initialState, applyMiddleware(thunk));
  else
    var store = createStore(
      rootReducer,
      initialState,
      applyMiddleware(thunk, logger)
    );

  //   var store = createStore(
  //     persistedReducer,
  //     initialState,
  //     applyMiddleware(logger, thunk)
  //   );

  // let persistor = persistStore(store);
  return store;
}
