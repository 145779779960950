import React from "react";
import styles from "./styles/newsitem.module.css";

export default function ShareMethods(props) {
  const { showHover, setShowHover, id, setSelected } = props;
  return (
    <div
      className={styles.playOver}
      onMouseLeave={() => setShowHover(false)}
      style={{
        display: showHover == id ? "flex" : "none",
        fontSize: "1.1em",
        zIndex: props.type == "video" ? 9999999 : 9999,
      }}
    >
      <div>
        Social
        <img
          src="/images/fb_icon.png"
          style={{ borderRadius: "50%" }}
          className={`${styles.playIcon} `}
          //   onClick={() => props.onPlayNowTrack()}
          alt=""
        />
        <img
          src="/images/twit_icon.png"
          style={{ borderRadius: "50%" }}
          className={`${styles.playIcon} `}
          //   onClick={() => props.onPlayNowTrack()}
          alt=""
        />
      </div>
      <div>
        Create Link{" "}
        <img
          src="/images/Link icon light.png"
          className={`${styles.playIcon} `}
          onClick={() => setSelected("shareLink")}
          style={{ width: "10px" }}
          alt=""
        />
      </div>
      <div>
        Email Campaign
        <img
          src={"/images/emailIcon.png"}
          className={`${styles.playIcon} `}
          onClick={() => setSelected("campaign")}
          alt=""
          style={{ height: "24px", width: "17px" }}
        />
      </div>
      <div>
        Audiogram
        <img
          src={"/images/megaPhoneIcon.png"}
          className={`${styles.playIcon} `}
          //   onClick={() => props.onPlay()}
          alt=""
        />
      </div>
    </div>
  );
}
