import React from "react";
import styles from "./styles/shorts.module.css";
import playstyles from "./styles/playlist.module.css";
import { expandIcon, actionIcon } from "./styles/newsitem.module.css";
import { playIcon, backArrowContainer } from "./styles/newsitem.module.css";
import colors from "../data/colors.json";
import { connect } from "react-redux";
import newstyles from "./styles/newsitem.module.css";
import homestyle from "./styles/home.module.css";

var mapStateToProps = (state) => {
  return {
    savedItems: state.site.savedItems,
    theme: state.site.theme,
  };
};

function StationItem(props) {
  var color = false;
  const [showPlayHover, setShowPlayHover] = React.useState(false);

  if (props.theme == "color-theme")
    color = colors[Math.floor(Math.random() * colors.length)];

  const { category } = props;
  return (
    <div
      className={`currency-box ${styles.shortItem} ${homestyle.itemBox}`}
      onMouseEnter={() => setShowPlayHover(true)}
      style={{ position: "relative" }}
    >
      <div
        className={newstyles.playOver}
        onMouseLeave={() => setShowPlayHover(false)}
        style={{ display: showPlayHover == 1 ? "flex" : "none" }}
      >
        <div>
          Play Now
          <img
            src={"/images/playNow.png"}
            className={`${newstyles.playIcon} `}
            onClick={() => props.OnClick(category, "playNow")}
            alt=""
          />
        </div>
        <div>
          Play Next
          <img
            src={"/images/playNext.png"}
            className={`${newstyles.playIcon} `}
            onClick={() => props.OnClick(category, "playNext")}
            alt=""
          />
        </div>
      </div>
      <div className={`${playstyles.playlistItem}  currency-pair   `}>
        <div
          className={`background-image `}
          style={{
            background: `${
              props.theme == "color-theme"
                ? `url(/images/newsImages.png) ${color}`
                : `url(/images/newsImages.png) #1c1c1c`
            }`,
          }}
        ></div>

        <div className={`cat-content `}>
          <p className={`${playstyles.playlistName} text-left `}>
            <img
              src={"/images/playIcon.png"}
              className={playIcon}
              style={{ float: "none" }}
              alt=""
            />
          </p>
          <div style={{ marginTop: "4em" }}>
            <p className={playstyles.article} style={{ color: "#08E1F4" }}>
              {category}
            </p>
          </div>
          <div className={playstyles.itemInfo}>
            <div
              className={playstyles.articleSideInfo}
              style={{ left: "12px" }}
            >
              <img
                src="/images/gearIcon.png"
                className={`${actionIcon} `}
                style={{ position: "absolute", bottom: "21px" }}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, null)(StationItem);
