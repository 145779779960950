import React from "react";
import styles from "../styles/search.module.css";
import { playIcon, actionIcon } from "../styles/newsitem.module.css";
import { connect } from "react-redux";
import ACTIONS from "../../redux/actions/site";
import apiRequest from "../../api/clientRequest";

const mapStateToProps = (state) => ({
  subscribedItems: state.site.subscribedItems,
});

const mapDispatchToProps = (dispatch) => ({
  setSubscribedItems: (items) => dispatch(ACTIONS.setSubscribedItems(items)),
  updateRecentPlayed: (items) => dispatch(ACTIONS.updateRecentPlayed(items)),
});

function PodcastListItem(props) {
  const { title, source, image, id } = props.data;

  const subscribeTo = (item) => {
    props.onAdd(props.data);

    item.type = "podcast";
    item["item_id"] = item.id;
    if (props.subscribedItems.find((ite) => ite.item_id == item.id)) {
      item.clicked = false;
      props.setSubscribedItems(
        props.subscribedItems.filter((ite) => ite.item_id !== item.id)
      );
      apiRequest({
        url: "/news/subscribe",
        method: "POST",
        data: { ...item, type: "podcast" },
      });
      // props.onSubscribe(item.id);
    } else {
      item.clicked = true;
      apiRequest({
        url: "/news/subscribe",
        method: "POST",
        data: { ...item, type: "podcast" },
      });
      props.setSubscribedItems([
        ...props.subscribedItems.map((ite) => ite),
        item,
      ]);
    }
  };

  return (
    <div
      className="d-flex mb-4 pb-2"
      onMouseEnter={() => props.onHover(props.data)}
      onMouseLeave={() => props.onHover(false)}
    >
      <img src={image} className={styles.podcastItemImage} />
      <div className={`w-75 ${styles.podcastItemTitle} mr-3`}>
        {title}
        <span className="mt-3 d-block">{source}</span>
      </div>
      {props.data.type == "episode" ? (
        ""
      ) : (
        <div>
          <button
            className={`${styles.podcastAdd} mt-0`}
            onClick={() => props.onAdd(props.data)}
          >
            ADD
          </button>
          {props.subscribedItems.find((item) => {
            return item.id == id || item.item_id == id;
          }) ? (
            <button
              className={styles.podcastAdd}
              onClick={() => subscribeTo(props.data)}
            >
              Subscribed
            </button>
          ) : (
            <button
              className={styles.podcastAdd}
              onClick={() => subscribeTo(props.data)}
            >
              Subscribe
            </button>
          )}
        </div>
      )}
      <img
        src={"/images/playIcon.png"}
        className={playIcon}
        onClick={() =>
          props.playPodCast({
            episode: props.data.episode[0],
            title: props.data.type == "episode" ? source : title,
          })
        }
      />
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(PodcastListItem);
