import React, { Component } from "react";
import styles from "./styles/shorts.module.css";
import playstyles from "./styles/playlist.module.css";
import { expandIcon, actionIcon } from "./styles/newsitem.module.css";
import { playIcon, backArrowContainer } from "./styles/newsitem.module.css";
import Player from "../data/player";
import MusicStyle from "./styles/musicitem.module.css";
import ShortPlaylistContainer from "./ShortPlaylistContainer";
import ACTIONS from "../redux/actions/site";
import { connect } from "react-redux";
import apiRequest from "../api/clientRequest";
import colors from "../data/colors.json";
import ShareModal from "./shareModal";
import newsStyle from "./styles/newsitem.module.css";
import ShareMethods from "./ShareMethods";
import FavoriteFeedModal from "./FavoriteFeedModal";

var player = Player();
var mapStateToProps = (state) => {
  return {
    savedItems: state.site.savedItems,
    theme: state.site.theme,
  };
};
var mapDispatchToProps = (dispatch) => ({
  setSavedItems: (items) => dispatch(ACTIONS.setSavedItems(items)),
});

class ShortsItem extends Component {
  state = {
    expanded: false,
    shareVisibile: false,
    selectedArticle: null,
    showPlayHover: false,
    showShareHover: false,
    mode: "",
    likeHover: false,
  };

  setSelectArticle(article, mode) {
    this.setState({
      selectedArticle: article,
      mode,
      shareVisibile: true,
    });
  }
  unExpand() {
    this.setState({
      expanded: false,
    });
  }
  playArticle(article, playAction = false) {
    player.playShorts(article, playAction);
  }
  playNextArticle(article) {
    player.playNext(article, "shorts");
  }

  playAllShorts(shorts) {
    var addTracks = async () => {
      const promises = shorts.map(async (article) =>
        player.playShorts(article)
      );
      return Promise.all(promises);
    };

    addTracks().then((res) => console.log(res));
  }

  saveItem(item, type = false) {
    item.type = type;
    if (this.props.savedItems.find((ite) => ite.track_id == item.id)) {
      item.clicked = false;
      this.props.setSavedItems(
        this.props.savedItems.filter((ite) => ite.track_id !== item.id)
      );
      apiRequest({ url: "/news/save", method: "POST", data: item });
    } else {
      item.clicked = true;
      item.category = this.props.data.category;
      apiRequest({ url: "/news/save", method: "POST", data: item });
      this.props.setSavedItems([
        {
          track_id: item.id,
          type: item.type,
          ...item,
        },
        ...this.props.savedItems.map((ite) => ite),
      ]);
    }
  }

  render() {
    let { category, shorts } = this.props.data;
    shorts = shorts.sort((a, b) => b.published - a.published);
    const short = shorts[0];

    const { showPlayHover, showShareHover, likeHover } = this.state;

    var color = false;
    if (this.props.theme == "color-theme")
      color = colors[Math.floor(Math.random() * colors.length)];

    return (
      <div
        className={`currency-box ${styles.shortItem}  ${
          this.state.expanded ? "expand" : ""
        }`}
      >
        <ShareModal
          visible={this.state.shareVisibile}
          setVisiblity={(cond) =>
            this.setState({
              shareVisibile: cond,
            })
          }
          selectedItem={this.state.selectedArticle}
          type="shorts"
          setSelectedArticle={(article) =>
            this.setState({
              selectedArticle: article,
            })
          }
          mode={this.state.mode}
        />

        <div className={`${playstyles.playlistItem}  currency-pair   `}>
          <div
            className={`background-image `}
            style={{
              background: `${
                this.props.theme == "color-theme"
                  ? `url(${short.image}) ${color}`
                  : `url(${short.image}) #1c1c1c`
              }`,
            }}
          ></div>
          <div className={`cat-content `}>
            <div className={`${this.state.expanded ? "d-none" : ""}`}>
              <p className={`${playstyles.playlistName} text-left `}>
                <img
                  src={"/images/playIcon.png"}
                  className={playIcon}
                  style={{ float: "none" }}
                  onClick={() => this.playAllShorts(shorts)}
                  alt=""
                />
              </p>
              <div style={{ marginTop: "4em" }}>
                <p className={playstyles.article} style={{ color: "white" }}>
                  #{category}
                </p>
              </div>
              <div className={playstyles.itemInfo}>
                <div
                  className={playstyles.articleSideInfo}
                  style={{ left: "12px" }}
                >
                  <img
                    src="/images/block-menu.png"
                    onClick={() => this.setState({ expanded: true })}
                    className={`${actionIcon} `}
                    style={{ position: "absolute", bottom: "21px" }}
                    alt=""
                  />
                  {this.props.savedItems.find(
                    (item) => item.track_id == category
                  ) ? (
                    <img
                      src="/images/HeartFill.png"
                      className={`${actionIcon}`}
                      style={{
                        position: "absolute",
                        bottom: "21px",
                        right: "0",
                      }}
                      onClick={() =>
                        this.saveItem({ id: category }, "shortscategory")
                      }
                      alt=""
                    />
                  ) : (
                    <img
                      src="/images/Heart.png"
                      className={`${actionIcon} `}
                      style={{
                        position: "absolute",
                        bottom: "21px",
                        right: "0",
                      }}
                      onClick={() =>
                        this.saveItem({ id: category }, "shortscategory")
                      }
                      alt=""
                    />
                  )}{" "}
                </div>
              </div>
            </div>

            {this.state.expanded ? (
              <div
                className={`row m-0 expanded-container ${
                  this.state.expanded ? "" : "d-none"
                }`}
              >
                <div
                  className={backArrowContainer}
                  onClick={this.unExpand.bind(this)}
                >
                  <img src="/images/backTo.svg" alt="" />
                  <img src="/images/back-left.png" alt="" />
                </div>
                <div
                  style={{
                    position: "fixed",
                    left: "31px",
                    top: "30%",
                    zIndex: "999",
                  }}
                >
                  <img
                    src={"/images/playIcon.png"}
                    className={`${styles.playIcon} `}
                    onClick={() => this.playAllArticles(shorts)}
                    style={{
                      width: "80px",
                      height: "80px",
                      marginBottom: "15px",
                    }}
                    alt=""
                  />
                  <p style={{ textAlign: "center" }}>Play All </p>
                </div>
                <ShortPlaylistContainer
                  heading={category.toUpperCase()}
                  data={{ category, articles: shorts }}
                />
                <h5 className="pl-3 text-white w-100">News Shorts</h5>
                {shorts.map((item) => (
                  <div className="d-flex" style={{ minHeight: "165px" }}>
                    <div
                      className={newsStyle.playOver}
                      onMouseLeave={() =>
                        this.setState({ showPlayHover: false })
                      }
                      style={{
                        display: showPlayHover == item.id ? "flex" : "none",
                      }}
                    >
                      <div>
                        Play Now{" "}
                        <img
                          src={"/images/playNow.png"}
                          className={`${newsStyle.playIcon} `}
                          onClick={() => {
                            this.playArticle(item, "playNow");
                          }}
                          alt=""
                        />
                      </div>
                      <div>
                        Play Next{" "}
                        <img
                          src={"/images/playNext.png"}
                          className={`${newsStyle.playIcon} `}
                          onClick={() => {
                            this.playNextArticle(item);
                          }}
                          alt=""
                        />
                      </div>
                      <div>
                        Play Summary{" "}
                        <img
                          src={"/images/playIcon.png"}
                          className={`${newsStyle.playIcon} `}
                          onClick={() => {
                            this.playArticle(item, "playNow");
                          }}
                          alt=""
                        />
                      </div>
                      <div>
                        Play In-Queue{" "}
                        <img
                          src={"/images/playIcon.png"}
                          className={`${newsStyle.playIcon} `}
                          onClick={() => {
                            this.playArticle(item);
                          }}
                          alt=""
                        />
                      </div>
                    </div>

                    <ShareMethods
                      id={item.id}
                      showHover={showShareHover}
                      setShowHover={() =>
                        this.setState({ showShareHover: false })
                      }
                      setSelected={(mode) => this.setSelectArticle(item, mode)}
                    />
                    <FavoriteFeedModal
                      id={item.id}
                      visible={likeHover}
                      setHover={() => this.setState({ likeHover: false })}
                      saveToCollection={() => this.saveItem(item, "shorts")}
                      selectedArticle={item}
                      savedItems={this.props.savedItems}
                      type="shorts"
                    />
                    <img
                      src={"/images/playIcon.png"}
                      onClick={() => this.playArticle(item)}
                      className={playIcon}
                      alt=""
                      onMouseEnter={() =>
                        this.setState({ showPlayHover: item.id })
                      }
                    />
                    <div className="article-container ml-4">
                      <div className="d-flex justify-content-between">
                        <span
                          className={`article-name ${MusicStyle.articleName} `}
                          style={{ fontSize: "1.3em" }}
                        >
                          {item.title}
                        </span>
                        <img
                          className={MusicStyle.trackImage}
                          src={item.image}
                          alt=""
                        />
                      </div>

                      <div className={`${styles.actionContainer} d-flex`}>
                        {" "}
                        <span
                          style={{ fontSize: "1em" }}
                          className="article-source"
                        >
                          {item.source}
                        </span>
                        <div className="d-flex ml-auto align-items-end">
                          <img
                            src="/images/cluster.png"
                            className={actionIcon}
                            alt=""
                            onMouseEnter={() =>
                              this.setState({ showShareHover: item.id })
                            }
                          />
                          {this.props.savedItems.find(
                            (ite) => ite.track_id == item.id
                          ) ? (
                            <img
                              src="/images/HeartFill.png"
                              className={`${actionIcon}`}
                              onClick={() => this.saveItem(item, "shorts")}
                              alt=""
                              onMouseEnter={() =>
                                this.setState({ likeHover: item.id })
                              }
                            />
                          ) : (
                            <img
                              src="/images/Heart.png"
                              className={`${actionIcon} `}
                              onClick={() => this.saveItem(item, "shorts")}
                              onMouseEnter={() =>
                                this.setState({ likeHover: item.id })
                              }
                              alt=""
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShortsItem);
