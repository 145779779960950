import React, { Component } from "react";
import styles from "./styles/podcastInfo.module.css";
import { connect } from "react-redux";
import ACTIONS from "../redux/actions/site";
import { playIcon, actionIcon } from "./styles/newsitem.module.css";
import apiRequest from "../api/clientRequest";
const mapStateToProps = (state) => ({
  subscribedItems: state.site.subscribedItems,
});

const mapDispatchToProps = (dispatch) => ({
  setSubscribedItems: (items) => dispatch(ACTIONS.setSubscribedItems(items)),
  updateRecentPlayed: (items) => dispatch(ACTIONS.updateRecentPlayed(items)),
});

class PodcastInfo extends Component {
  subscribeTo(item) {
    item.type = "podcast";
    item["item_id"] = item.id;
    if (this.props.subscribedItems.find((ite) => ite.item_id == item.id)) {
      item.clicked = false;
      this.props.setSubscribedItems(
        this.props.subscribedItems.filter((ite) => ite.item_id !== item.id)
      );
      apiRequest({
        url: "/news/subscribe",
        method: "POST",
        data: { ...item, type: "podcast" },
      });
      this.props.onSubscribe(item.id);
    } else {
      item.clicked = true;
      apiRequest({
        url: "/news/subscribe",
        method: "POST",
        data: { ...item, type: "podcast" },
      });
      this.props.setSubscribedItems([
        ...this.props.subscribedItems.map((ite) => ite),
        item,
      ]);
    }
  }

  render() {
    const {
      title,
      description,
      image,
      episode,
      source,
      id,
      published,
    } = this.props.data;
    return (
      <div className={`${styles.container} d-flex pinfoContainer`}>
        <img className={styles.image} src={image} alt="" />

        <div
          className={`${styles.contentContainer} ${styles.contentContainerMax} pr-5`}
        >
          <h3 className="text-white">{title}</h3>
          <p>{description}</p>

          {this.props.subscribedItems.find((item) => {
            return item.id == id || item.item_id == id;
          }) ? (
            <button
              className={`${styles.subscribeBtn} ${styles.subscribedBtn}`}
              onClick={() => this.subscribeTo(this.props.data)}
            >
              Subscribed
            </button>
          ) : (
            <button
              className={` ${styles.subscribeBtn}`}
              onClick={() => this.subscribeTo(this.props.data)}
            >
              Subscribe
            </button>
          )}
        </div>
        <div
          className={`${styles.contentContainer} ${styles.contentContainerMax30}  px-5`}
        >
          <h3 className="text-white">Latest Episode</h3>
          <div className="d-flex">
            <p>
              <img
                src={"/images/playIcon.png"}
                style={{ float: "none" }}
                className={playIcon}
                onClick={() => {
                  this.propss.updateRecentPlayed(episode[0]);
                  this.props.playPodCast({ episode: episode[0], title: title });
                }}
                alt=""
              />
            </p>
            <div className="ml-3">
              <p>{episode[0].title}</p>
              <span className="text-white">{source}</span>
            </div>
          </div>
        </div>
        <div className="ml-auto text-right">
          <div className="h-100" style={{ position: "relative" }}>
            {" "}
            <p
              style={{ fontSize: "1.6em" }}
              className={`${styles.noepisode} mb-1`}
            >
              {episode.length}
            </p>
            <span className="text-white">episodes</span>
            <div
              className={`d-flex ml-auto align-items-end ${styles.iconBelow}`}
            >
              <img
                src="/images/cluster.png"
                className={`${actionIcon}  `}
                alt=""
              />
              {this.props.savedItems.find((item) => item.track_id == id) ? (
                <img
                  src="/images/HeartFill.png"
                  className={`${actionIcon}`}
                  onClick={() => this.props.saveItem(this.props.data)}
                  alt=""
                />
              ) : (
                <img
                  src="/images/Heart.png"
                  className={`${actionIcon} `}
                  onClick={() => this.props.saveItem(this.props.data, "show")}
                  alt=""
                />
              )}{" "}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PodcastInfo);
