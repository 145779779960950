import React, { Component } from 'react'
import DashboardNavbar from '../components/Navbar/DashboardNavbar'
import DashboardFooter from '../components/Footer/DashboardFooter'
import apiRequest from '../api/clientRequest'
import IndexPage from '../pages/dashboard/IndexPage'
import ProfilePage from '../pages/dashboard/ProfilePage'
import { Route, Switch } from 'react-router-dom'
import NEWSACTIONS from '../redux/actions/news'
import SITEACTIONS from '../redux/actions/site'
import { connect } from 'react-redux'
import Environment from '../api/environment'
import socketIOClient from 'socket.io-client'
import DashboardSidebar from '../components/DashboardSidebar'
import LoadingMeter from '../components/LoadingMeter'
import MusicControlComponent from '../components/MusicControlComponent'
import Geocode from 'react-geocode'
import axios from 'axios'
import environment from '../api/environment'

Geocode.setApiKey(environment.GEOCODE)
// Enable or disable logs. Its optional.
Geocode.enableDebug()

var mapDispatchToProps = (dispatch) => ({
  setFeed: (item) => dispatch(NEWSACTIONS.setFeed(item)),
  setPlaylists: (item) => dispatch(NEWSACTIONS.setPlaylists(item)),
  setPodcast: (item) => dispatch(NEWSACTIONS.setPodcast(item)),
  setSocket: (item) => dispatch(SITEACTIONS.setSocket(item)),
  setSavedItems: (item) => dispatch(SITEACTIONS.setSavedItems(item)),
  setFeedlyFeed: (item) => dispatch(NEWSACTIONS.setFeedlyFeed(item)),
  setSubscribedItems: (items) =>
    dispatch(SITEACTIONS.setSubscribedItems(items)),
  setShorts: (items) => dispatch(SITEACTIONS.setShorts(items)),
  setSharedItems: (items) => dispatch(SITEACTIONS.setSharedItems(items)),
  setLastPlayed: (items) => dispatch(SITEACTIONS.setLastPlayed(items)),
  setQueue: (item) => dispatch(SITEACTIONS.setQueue(item)),
  setRecentUsers: (item) => dispatch(SITEACTIONS.setRecentUsers(item)),
  setFeed: (item) => dispatch(NEWSACTIONS.setFeed(item)),
  setReadLater: (item) => dispatch(NEWSACTIONS.setReadLater(item)),
  setBoards: (item) => dispatch(NEWSACTIONS.setBoards(item)),
  setListenLater: (item) => dispatch(NEWSACTIONS.setListenLater(item)),
  setImportantFeed: (item) => dispatch(NEWSACTIONS.setImportantFeed(item)),
  setStations: (item) => dispatch(NEWSACTIONS.setStations(item)),
  setWheather: (item) => dispatch(NEWSACTIONS.setWheather(item)),
  setTodayNews: (item) => dispatch(NEWSACTIONS.setSubscribedNews(item)),
  setVideos: (item) => dispatch(NEWSACTIONS.setYoutubeVideos(item)),
  setDoNotPlay: (item) => dispatch(NEWSACTIONS.setDoNotPlay(item)),
})

var mapStateToProps = (state) => {
  return {
    feedlyUser: state.news.feedlyUser,
    theme: state.site.theme,
    setSubscribedPodcasts: state.news.subscribedPodcasts,
    user: state.auth.user,
    tags: state.news.tags,
    socket: state.site.socket,
    youtubeUser: state.news.youtubeUser,
  }
}

class DashboardContainer extends Component {
  state = {
    searchQuery: '',
    loading: true,
    results: [],
    feeds: [],
    spotifyToken: window.localStorage.getItem('spotify_token'),
    musicPlaylist: [],
  }

  constructor(props) {
    super(props)
    console.log(this.props.user, 'user is')
    if (this.props.user?._id == null) {
      window.location = '/'
    }
  }

  componentWillReceiveProps(nextProp) {
    if (nextProp.feedlyUser.userId !== this.props.feedlyUser.userId)
      nextProp.socket.emit(
        'subscribeToFeedly',
        nextProp.feedlyUser,
        new Date(),
        this.props.user
      )
  }

  attachSockets(socket) {
    if (
      window.localStorage.getItem('feedly_token') &&
      window.localStorage.getItem('feedly_id')
    ) {
      socket.on('userFeed', (feeds) => {
        this.props.setFeed({ feed: feeds.articles, tags: feeds.tags })
        this.props.setImportantFeed(feeds.importantArticles)
        this.props.setPlaylists(feeds.playlists)
        this.props.setReadLater(feeds.readLater)
        this.props.setTodayNews(feeds.todayArticles)
      })

      socket.off('newFeed')
    } else {
      socket.on('newFeed', (feeds) => {
        this.props.setFeed({ feed: feeds.articles, tags: feeds.tags })
      })
      socket.emit('subscribeToNews', this.props.tags)
      socket.off('userFeed')
    }

    socket.on('userShorts', (dat) => {
      this.props.setShorts(dat.data)
    })
    socket.on('stationFeed', (data) => {
      this.props.setStations(data.stations)
    })
    socket.emit('subscribeToStation')
    socket.emit('subscribeToShorts')

    socket.on('userPodcasts', (dat) => {
      this.props.setPodcast(dat.data)
      // this.props.setSubscribedItems(dat.subscribedPodcasts);
    })
    socket.emit('subscribeToPodcasts', this.props.user._id)
  }

  async componentWillMount() {
    const socket = socketIOClient(Environment.serverurl, {
      transports: ['websocket'],
      upgrade: false,
      pingTimeout: 60000,
      forceNew: true,
    })

    socket.on('reconnect_attempt', () => {
      socket.io.opts.transports = ['websocket']
    })

    socket.on('connect_failed', function () {
      document.write('Sorry, there seems to be an issue with the connection!')
    })
    this.attachSockets(socket)
    this.props.setSocket(socket)

    apiRequest({
      url: '/news/notplay',
      method: 'GET',
    }).then((res) => {
      this.props.setDoNotPlay(res.data)
    })

    apiRequest({
      url: '/news/fetch/share/recent',
      method: 'GET',
    }).then((res) => {
      this.props.setRecentUsers(res.data.users)
    })

    apiRequest({
      url: '/news/read-later',
      method: 'GET',
    }).then((res) => {
      console.log(res.data)
      this.props.setListenLater(res.data)
    })

    apiRequest({
      url: '/news/sharedItems',
      method: 'POST',
      data: { feedlyUser: this.props.feedlyUser },
    }).then((res) => {
      this.props.setSharedItems(res.data.items)
    })

    apiRequest({
      url: '/news/fetch/audioshorts',
      method: 'GET',
    }).then((res) => {
      this.props.setShorts(res.data.data)
    })

    const podcasts = await apiRequest({
      url: '/news/fetch/podcast',
      method: 'GET',
    }).then((res) => {
      return res.data
    })
    this.props.setPodcast(podcasts.message)
    this.props.setSubscribedItems(podcasts.subscribedPodcasts)
  }

  componentDidMount() {
    const self = this
    navigator.geolocation.getCurrentPosition(function (position) {
      const { latitude, longitude } = position.coords
      Geocode.fromLatLng(latitude, longitude).then(
        (response) => {
          const address = response.results[0].formatted_address
          const { northeast, southwest } = response.results[0].geometry[
            'viewport'
          ]
            ? response.results[0].geometry.viewport
            : response.results[0].geometry.bounds
          const boundingBox = `${southwest.lat},${southwest.lng},${northeast.lat},${northeast.lng}`
          axios
            .get(
              `https://api.weatherstack.com/current?access_key=${environment.WHEATHERSTACK}&query=${address}`
            )
            .then((res) => {
              const temprature = res.data.current
              self.props.setWheather(temprature)
            })
            .catch((err) => {
              console.log(err)
            })

          // axios
          //   .get(
          //     `https://api.tomtom.com/traffic/services/4/incidentDetails/s3/${boundingBox}/10/-1/json?key=${environment.TOMTOM}`
          //   )
          //   .then((res) => console.log("Traffic is", res.data))
          //   .catch((err) => console.log(err.response));
        },
        (error) => {
          console.error(error)
        }
      )
    })
  }

  render() {
    const match = this.props.match
    return (
      <div className={`themeContainer ${this.props.theme} h-100`}>
        <div className='body-wrapper h-100'>
          <DashboardNavbar />
          {/* <DashboardSidebar /> */}
          <MusicControlComponent />
          <LoadingMeter />
          <Switch>
            <Route path={`${match.url}/profile`} component={ProfilePage} />
            <Route path={`${match.url}`} exact component={IndexPage} />
          </Switch>
          <DashboardFooter />
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer)
