import React, { Component } from "react";
import mainstyle from "./styles/addTeam.module.css";
import questyles from "./styles/Queuelist.module.css";
import { selectBtn } from "./styles/sharemodal.module.css";
import UserImage from "./UserImage";
export default function Suggestionitem(props) {
  return (
    <div className={mainstyle.suggestionItem}>
      <div>
        <div
          className={`${questyles.queue_item} col-md-12 p-0 mb-3 queue-item align-items-center`}
        >
          {" "}
          <div style={{ position: "relative" }}>
            <UserImage avatar={props.data.avatar} name={props.data.fullname} />
          </div>
          <div>
            <p className={mainstyle.suggestiontitle}>{props.data.fullname}</p>
          </div>
          <div className={questyles.actionContainer} style={{ width: "158px" }}>
            <button
              class={`btn ml-5 ${selectBtn}`}
              onClick={() => props.InviteMember(props.data)}
            >
              Invite
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
