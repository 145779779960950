import React from "react";
import { Helmet } from "react-helmet";

export default function ContentMetaTag(props) {
  const { media } = props;
  return (
    <Helmet>
      <title>{media ? `${media.title} | ${media.source}` : "Audio One"}</title>
      <meta
        name="title"
        content={media ? `${media.title} ${media.source}` : ""}
      />
      <meta name="url" content={window.location.href} />
      <meta name="description" content={media ? `${media.description}` : ""} />

      <meta property="twitter:url" content={window.location.href} />
      <meta
        property="twitter:title"
        content={media ? `${media.title} ${media.source}` : ""}
      />
      <meta
        property="twitter:description"
        content={media ? `${media.description}` : ""}
      />
      <meta property="twitter:image" content={media ? `${media.image}` : ""} />
      <meta property="twitter:card" content="summary_large_image" />

      <meta property="og:type" content={media ? `${media.type}` : ""} />
      <meta property="og:url" content={window.location.href} />
      <meta
        property="og:title"
        content={media ? `${media.title} ${media.source}` : ""}
      />
      <meta
        property="og:description"
        content={media ? `${media.description}` : ""}
      />
      <meta property="og:image" content={media ? `${media.image}` : ""} />
    </Helmet>
  );
}
