import React, { Component } from "react";
import { Modal, Button, message, Input } from "antd";
import { connect } from "react-redux";
import styles from "../styles/sharemodal.module.css";
import apiRequest from "../../api/clientRequest";
import ACTIONS from "../../redux/actions/site";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import moment from "moment";
import GroupSearch from "../GroupSearch";
import TEAMACTIONS from "../../redux/actions/team";
import CollectionItem from "../CollectionItem";
import "react-multi-email/style.css";
import CampaignItem from "./CampaignItem";

const mapStateToProps = (state) => ({
  theme: state.site.theme,
  teams: state.team.teams,
  feedlyUser: state.news.feedlyUser,
});
var mapDispatchToProps = (dispatch) => ({
  setMeter: (items) => dispatch(ACTIONS.setMeter(items)),
  setMeterProgress: (items) => dispatch(ACTIONS.setMeterProgress(items)),
  setMeterTitle: (items) => dispatch(ACTIONS.setMeterTitle(items)),
  setTeam: (item) => dispatch(TEAMACTIONS.setTeams(item)),
  setSelectedTeam: (item) => dispatch(TEAMACTIONS.setSelectedTeam(item)),
});

class CampaignModal extends Component {
  state = {
    emails: [],
    name: "",
    selectedTemplate: "",
    selectedGroups: [],
  };

  resetState() {
    this.setState({
      name: "",
      emails: "",
      selectedTeam: "",
      selectedGroups: [],
    });
  }

  createCampaign = () => {
    const config = {
      headers: {
        "Content-Type": `multipart/form-data;`,
      },
    };
    this.props.setMeterTitle("Creating Campaign");
    this.props.setMeter(true);
    this.props.setMeterProgress("25");

    const formData = new FormData();
    formData.append("name", this.state.name);
    if (this.state.emails.length > 0)
      formData.append("emails", this.state.emails);
    else
      formData.append(
        "group_id",
        JSON.stringify(this.state.selectedGroups.map((item) => item.id))
      );
    formData.append("team_id", this.props.selectedTeam.id);
    formData.append("selected_template", this.state.selectedTemplate);

    apiRequest({
      url: "/teams/campaign",
      method: "POST",
      data: formData,
      config,
    }).then((res) => {
      this.props.setMeterTitle("Added Campaign");
      this.props.setMeterProgress("100");

      setTimeout(() => {
        this.props.setMeter(false);
      }, 1000);

      if (res.data.status == 200) {
        this.resetState();
        let allTeams = this.props.teams;
        const foundIndex = this.props.teams.findIndex(
          (item) => item.id == this.props.selectedTeam.id
        );

        allTeams[foundIndex].campaigns.push(res.data.campaign);
        this.props.setSelectedTeam(allTeams[foundIndex]);
        this.props.setTeam([...allTeams]);
        this.props.setVisibility();
      } else {
        message.error(res.data.msg);
        this.props.setMeter(false);
      }
    });
  };

  render() {
    return (
      <Modal
        visible={this.props.visible}
        title="Share this Article"
        onOk={this.handleOk}
        footer={[
          <Button key="back" onClick={this.handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={this.handleOk}>
            Share
          </Button>,
        ]}
        className={`${styles.shareEmail} shareEmail share-modal ${this.props.theme}`}
        onCancel={this.handleCancel}
      >
        <div className="p-3">
          <div className="d-flex justify-content-between">
            <h2>Email Campaign</h2>
            <i
              class={`${styles.crossIcon} far fa-times-circle`}
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.props.setVisibility(false);
              }}
            ></i>
          </div>

          <div className="row">
            <div className="col-md-6">
              <h5 className="" style={{ fontSize: "1.3em", marginTop: "80px" }}>
                Step 1: Select Template
              </h5>

              <div className="d-flex mt-4" style={{ overflow: "auto" }}>
                <div className={styles.templateItem}>
                  <img
                    src="/images/personaltemplate.jpg"
                    className={`${styles.templateThumb} d-block`}
                    alt=""
                    style={{ marginBottom: "10px" }}
                  />
                  <span className={styles.templateThumbspan}>Default</span>
                  <button
                    class={`btn ${styles.selectBtn} ${
                      this.state.selectedTemplate == "personal"
                        ? styles.selectBtnActive
                        : ""
                    }`}
                    onClick={() =>
                      this.setState({ selectedTemplate: "personal" })
                    }
                  >
                    SELECT
                  </button>
                </div>
                <div className={styles.templateItem}>
                  <img
                    src="/images/personaltemplate.jpg"
                    className={`${styles.templateThumb} d-block`}
                    alt=""
                    style={{ marginBottom: "10px" }}
                  />
                  <span className={styles.templateThumbspan}>
                    {this.props.selectedTeam.name}
                  </span>
                  <button
                    class={`btn ${styles.selectBtn} ${
                      this.state.teamid == this.props.selectedTeam.id
                        ? styles.selectBtnActive
                        : ""
                    }`}
                    onClick={() =>
                      this.setState({
                        selectedTemplate: this.props.selectedTeam.name,
                        fromName: this.props.selectedTeam.name,
                        teamid: this.props.selectedTeam.id,
                        selectedGroups: [],
                      })
                    }
                  >
                    SELECT
                  </button>
                </div>
              </div>
            </div>

            <div className="col-md-5 ">
              <div className="mb-5 mt-4">
                <h5 className="mb-4" style={{ fontSize: "1.3em" }}>
                  Step 2: Add Campaign Name
                </h5>
                <Input
                  placeholder="Campaign Name"
                  className="campaignName"
                  onChange={(e) => this.setState({ name: e.target.value })}
                />
              </div>
              <div className="">
                <h5 className="mb-4" style={{ fontSize: "1.3em" }}>
                  Step 3: Add Recipients and Send
                </h5>
                <ReactMultiEmail
                  placeholder="Enter email"
                  emails={this.state.emails}
                  onChange={(_emails) => {
                    this.setState({ emails: _emails });
                  }}
                  validateEmail={(email) => {
                    return isEmail(email); // return boolean
                  }}
                  getLabel={(email, index, removeEmail) => {
                    return (
                      <div data-tag key={index}>
                        {email}
                        <span
                          data-tag-handle
                          onClick={() => removeEmail(index)}
                        >
                          ×
                        </span>
                      </div>
                    );
                  }}
                />
                <span
                  style={{ fontSize: "1.2em" }}
                  className="d-block text-center my-5 mx-3"
                >
                  OR
                </span>
                <h5 className="mb-4" style={{ fontSize: "1.3em" }}>
                  Select an Email Group
                </h5>
                <GroupSearch
                  groups={
                    this.props.selectedTeam
                      ? this.props.teams.find(
                          (item) => item.id == this.props.selectedTeam.id
                        ).groups
                      : []
                  }
                  onSelect={(group) =>
                    this.setState((prev) => ({
                      selectedGroups: [...prev.selectedGroups, group],
                    }))
                  }
                />
              </div>
            </div>
          </div>

          <button
            class={`btn ml-auto mt-3 mb-4 ${styles.selectBtn} ${styles.selectBtnActive}`}
            style={{ width: "145px" }}
            onClick={() => this.createCampaign()}
          >
            SAVE
          </button>

          <img
            style={{ width: "100px", float: "right" }}
            className="ml-auto"
            src="/images/AudioOneLogo@2x.png"
            alt=""
          />
        </div>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CampaignModal);
