import React, { Component } from "react";
import homestyle from "./styles/home.module.css";
import SharedItem from "./SharedItem";
import styles from "./styles/Queuelist.module.css";
import { connect } from "react-redux";
import player from "../data/player";

var Player = player();

var mapStateToProps = state => {
  return {
    sharedItems: state.site.sharedItems
  };
};

class CollectionContainer extends Component {
  state = {
    filter: "All",
    podcasts: [],
    allItems: [],
    shorts: [],
    news: [],
    music: []
  };

  componentWillReceiveProps(props) {
    let shorts = [];
    let podcasts = [];
    let news = [];
    let music = [];
    // if (
    //   props.shorts.length > 0 &&
    //   props.savedItems.filter(item => item.type == "shorts").length > 0
    // ) {
    //   shorts = props.savedItems.filter(item => item.type == "shorts");
    //   let allShorts = props.shorts.map(item => item.shorts);
    //   allShorts = [].concat.apply([], allShorts);
    //   shorts = allShorts.filter(r =>
    //     shorts.find(item => item.track_id == r.id)
    //   );
    //   const filteredArr = shorts.reduce((acc, current) => {
    //     const x = acc.find(item => item.id === current.id);
    //     if (!x) {
    //       return acc.concat([current]);
    //     } else {
    //       return acc;
    //     }
    //   }, []);
    //   shorts = filteredArr;
    //   shorts = shorts.map(item => {
    //     item.type = "shorts";
    //     return item;
    //   });
    // }

    // if (
    //   props.musicList.length > 0 &&
    //   props.savedItems.filter(item => item.type == "music").length > 0
    // ) {
    //   music = props.savedItems.filter(item => item.type == "music");
    //   let allShorts = props.musicList.map(item => item.tracks);
    //   allShorts = [].concat.apply([], allShorts);
    //   music = allShorts.filter(r => music.find(item => item.track_id == r.id));
    //   music = music.map(item => {
    //     item.source = item.album;
    //     item.type = "music";
    //     return item;
    //   });
    // }

    if (props.sharedItems.filter(item => item.type == "shorts")) {
      shorts = props.sharedItems.filter(item => item.type == "shorts");
    }

    if (props.sharedItems.filter(item => item.type == "podcast")) {
      podcasts = props.sharedItems.filter(item => item.type == "podcast");
    }

    if (props.sharedItems.filter(item => item.type == "news")) {
      news = props.sharedItems.filter(item => item.type == "news");
    }
    this.setState({
      podcasts: podcasts,
      allItems: [...podcasts, ...shorts, ...news, ...music],
      shorts: shorts,
      news: news,
      music: music
    });
  }

  playTrack(item, type) {
    if (type == "music") Player.playSpotifyTrack(item);
    else if (type == "podcast")
      Player.playPodcast({
        episode: item,
        title: item.podcastTitle
      });
    else if (type == "news") Player.playAudio(item);
    else if (type == "shorts") Player.playShorts(item);
  }

  render() {
    const { filter } = this.state;
    return (
      <div className="col-md-12 mb-5">
        <h5
          className={`pl-3 w-100 mb-4 ${homestyle.yellowColow} d-flex`}
          style={{ textTransform: "capitalize" }}
        >
          <img
            src="/images/cluster.png"
            style={{ width: "30px", height: "30px", marginRight: ".5em" }}
          />
          Shared{" "}
          <div className="ml-4">
            <button
              onClick={() => this.setState({ filter: "All" })}
              className={`btn ${styles.filter_btn} ${
                filter == "All" ? styles.filter_selected : ""
              }`}
            >
              All
            </button>
            <button
              onClick={() => this.setState({ filter: "News" })}
              className={`btn ${styles.filter_btn} ${
                filter == "News" ? styles.filter_selected : ""
              }`}
            >
              News
            </button>
            <button
              onClick={() => this.setState({ filter: "Music" })}
              className={`btn ${styles.filter_btn} ${
                filter == "Music" ? styles.filter_selected : ""
              }`}
            >
              Music
            </button>
            <button
              onClick={() => this.setState({ filter: "Podcast" })}
              className={`btn ${styles.filter_btn} ${
                filter == "Podcast" ? styles.filter_selected : ""
              }`}
            >
              Podcast
            </button>
            <button
              onClick={() => this.setState({ filter: "Shorts" })}
              className={`btn ${styles.filter_btn} ${
                filter == "Shorts" ? styles.filter_selected : ""
              }`}
            >
              Shorts
            </button>
          </div>
        </h5>
        <div className="d-flex flex-wrap">
          {this.state.filter == "All"
            ? this.state.allItems.map(item => (
                <SharedItem
                  type={item.type}
                  data={item}
                  onPlay={() => this.playTrack(item, item.type)}
                />
              ))
            : this.state.filter == "Podcast"
            ? this.state.podcasts.map(item => (
                <SharedItem
                  type="Podcast"
                  data={item}
                  onPlay={() => this.playTrack(item, item.type)}
                />
              ))
            : this.state.filter == "Shorts"
            ? this.state.shorts.map(item => (
                <SharedItem
                  type="Shorts"
                  data={item}
                  onPlay={() => this.playTrack(item, item.type)}
                />
              ))
            : this.state.filter == "News"
            ? this.state.news.map(item => (
                <SharedItem
                  type="news"
                  data={item}
                  onPlay={() => this.playTrack(item, item.type)}
                />
              ))
            : this.state.filter == "Music"
            ? this.state.music.map(item => (
                <SharedItem
                  type="music"
                  data={item}
                  onPlay={() => this.playTrack(item, item.type)}
                />
              ))
            : ""}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, null)(CollectionContainer);
