import React, { Component } from "react";
import { Icon, Input, AutoComplete, Button } from "antd";
import apiRequest from "../api/clientRequest";
const { Option } = AutoComplete;
function renderOption(item) {
  return (
    <Option key={item._id} text={item.name}>
      <div className="global-search-item d-flex justify-content-center align-items-center">
        <img src={item.image} alt="" />
        <div className="d-flex justify-content-between align-items-center name-container">
          <h4 style={{ color: "black" }}>{item.name}</h4>
          <button className="btn btn-black">Select</button>
        </div>{" "}
      </div>
    </Option>
  );
}

export default class CampaignSearch extends Component {
  state = {
    selectedUsers: [],
    dataSource: [],
  };

  async onSelect(value) {
    const selectedItem = this.props.campaigns.find((item) => item._id == value);
    this.props.onSelect(selectedItem);
  }
  async handleSearch(value) {
    this.setState({
      dataSource: value
        ? await this.searchResult(value).then((res) => res)
        : [],
    });
  }
  async searchResult(query) {
    return this.props.campaigns.filter((item) =>
      item.name.toLowerCase().includes(query)
    );
  }

  render() {
    const { dataSource } = this.state;

    return (
      <AutoComplete
        className="global-search mt-2"
        size="large"
        style={{ width: "100%" }}
        dataSource={dataSource.map(renderOption)}
        placeholder="Campaign Name"
        optionLabelProp="text"
        onSearch={this.handleSearch.bind(this)}
        onSelect={this.onSelect.bind(this)}
      >
        <Input
          suffix={
            <Button
              className="search-btn"
              style={{ marginRight: -12 }}
              size="large"
              type="primary"
            >
              <Icon type="search" />
            </Button>
          }
        />
      </AutoComplete>
    );
  }
}
