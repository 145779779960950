import React from "react";
import styles from "./styles/newsitem.module.css";
import apiRequest from "../api/clientRequest";
import { connect } from "react-redux";
import NEWSACTIONS from "../redux/actions/news";

var mapStateToProps = (state) => {
  const { importantFeed, playlists, feed, todayNews } = state.news;
  let news = [
    ...importantFeed,
    ...playlists.map((item) => item.articles),
    ...feed,
    ...todayNews,
  ];

  news = [].concat.apply([], news);

  return {
    feedlyUser: state.news.feedlyUser,
    listenLater: state.news.listenLater,
    savedItems: state.site.savedItems,
    news: news,
    shorts: state.site.shorts.map((item) => item.shorts),
    podcasts: state.news.podcasts.map((item) =>
      item.podcasts.map((item) => item.episode)
    ),
    music: state.site.musicList.map((item) => {
      return item.tracks.map((track) => {
        return {
          ...track,
          playlistId: item.playlistid,
        };
      });
    }),
  };
};
var mapDispatchToProps = (dispatch) => ({
  setListenLater: (item) => dispatch(NEWSACTIONS.setListenLater(item)),
});
function PlayHover(props) {
  const { open, setShow, id, setDoNotPlay } = props;

  var track = "";
  if (props.type.toLowerCase() == "news") {
    track = props.news.find((item) => item.id == id);
  } else if (props.type.toLowerCase() == "shorts") {
    track = props.shorts.find((item) => item.id == id);
  } else if (props.type.toLowerCase() == "podcast") {
    track = props.podcasts.find((item) => item.id == id);
  } else if (props.type.toLowerCase() == "music") {
    track = props.music.find((item) => item.id == id);
  }
  const readLater = () => {
    if (props.type.toLowerCase() == "news")
      apiRequest({
        method: "POST",
        url: `/news/read-later`,
        data: {
          ...track,
          item_id: track.id,
          feedlyUser: props.feedlyUser,
          articleId: track.id,
          type: "news",
        },
      });
    else {
      apiRequest({
        method: "POST",
        url: `/news/read-later`,
        data: {
          ...track,
          item_id: track.id,
          type: props.type.toLowerCase(),
        },
      });
    }
    if (props.listenLater.find((ite) => ite.track_id == track.id)) {
      props.setListenLater(
        props.listenLater.filter((ite) => ite.track_id !== track.id)
      );
    } else {
      props.setListenLater([
        { ...track, track_id: track.id, type: props.type.toLowerCase() },
        ...props.listenLater,
      ]);
    }
  };

  console.log(track);
  return (
    <div
      className={styles.playOver}
      onMouseLeave={() => setShow(false)}
      style={{
        display: open ? "flex" : "none",
        fontSize: "1.1em",
        zIndex: props.type == "video" ? 9999999 : 9999,
      }}
    >
      <div>
        Listen Later
        <img
          src={"/images/listenLaterIcon.png"}
          className={`${styles.playIcon} `}
          onClick={() => readLater()}
          alt=""
        />
      </div>
      <div>
        Do Not play this again
        <img
          src="/images/notAllowIcon.png"
          className={`${styles.playIcon} `}
          onClick={() => setDoNotPlay()}
          alt=""
        />
      </div>
      {props.type == "News" ? (
        <>
          <div>
            Do Not Play this Article Again
            <img
              src="/images/Link icon light.png"
              className={`${styles.playIcon} `}
              onClick={() => setDoNotPlay(id)}
              style={{ width: "10px" }}
              alt=""
            />
          </div>
          <div>
            Do Not Play this Article Source Again
            <img
              src={"/images/emailIcon.png"}
              className={`${styles.playIcon} `}
              onClick={() => setDoNotPlay(track.streamId)}
              alt=""
              style={{ height: "24px", width: "17px" }}
            />
          </div>
        </>
      ) : (
        ""
      )}
      {props.type == "Music" ? (
        <>
          <div>
            Do Not Play this Track Again
            <img
              src="/images/Link icon light.png"
              className={`${styles.playIcon} `}
              onClick={() => setDoNotPlay(id)}
              style={{ width: "10px" }}
              alt=""
            />
          </div>
          <div>
            Do Not Play this Playlist Again
            <img
              src={"/images/emailIcon.png"}
              className={`${styles.playIcon} `}
              onClick={() => setDoNotPlay(track.playlistId)}
              alt=""
              style={{ height: "24px", width: "17px" }}
            />
          </div>
        </>
      ) : (
        ""
      )}
      {props.type == "podcast" ? (
        <>
          <div>
            Do Not Play this Podcast Episode Again
            <img
              src="/images/Link icon light.png"
              className={`${styles.playIcon} `}
              onClick={() => setDoNotPlay(id)}
              style={{ width: "10px" }}
              alt=""
            />
          </div>
          <div>
            Do Not Play this Podcast Show Again
            <img
              src={"/images/emailIcon.png"}
              className={`${styles.playIcon} `}
              onClick={() => setDoNotPlay(track.podcast_id)}
              alt=""
              style={{ height: "24px", width: "17px" }}
            />
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayHover);
