import React, { useState, useEffect } from "react";
import NewsListItem from "./NewsListItem";
import styles from "../styles/search.module.css";
import player from "../../data/player";
import { connect } from "react-redux";
import apiRequest from "../../api/clientRequest";
import SITEACTIONS from "../../redux/actions/site";
import axios from "axios";
const Player = player();

var mapStateToProps = (state) => {
  return {
    feed: state.news.feed,
    savedItems: state.site.savedItems,
    feedlyUser: state.news.feedlyUser,
  };
};

var mapDispatchToProps = (dispatch) => ({
  setSavedItems: (items) => dispatch(SITEACTIONS.setSavedItems(items)),
});

function saveItem(item, props) {
  item.type = "news";
  if (props.savedItems.find((ite) => ite.track_id == item.id)) {
    item.clicked = false;
    props.setSavedItems(
      props.savedItems.filter((ite) => ite.track_id !== item.id)
    );
    apiRequest({ url: "/news/save", method: "POST", data: item });
  } else {
    item.clicked = true;
    apiRequest({ url: "/news/save", method: "POST", data: item });
    props.setSavedItems([
      ...props.savedItems.map((ite) => ite),
      { track_id: item.id, type: "news", ...item },
    ]);
  }
}

function newsCategories(feed, searchVal) {
  return feed.filter((item) => {
    return item.category.toLowerCase().includes(searchVal.toLowerCase());
  });
}

function newsTitles(feed, searchVal) {
  return feed.filter((item) => {
    return item.title.toLowerCase().includes(searchVal.toLowerCase());
  });
}

async function feedlyNews(query, feedlyUser, setDataItems) {
  const results = await apiRequest({
    method: "POST",
    url: `/news/search/streams/${query}`,
    data: {
      feedlyUser,
    },
  }).then((res) => res.data);
  setDataItems(results);
}

async function feedlyNewsArticles(query, feedlyUser, setDataItems) {
  const results = await apiRequest({
    method: "POST",
    url: `/news/search/feeds/${query}`,
    data: {
      feedlyUser,
    },
  }).then((res) => res.data);
  setDataItems(results);
}

async function getGoogleNews(query, setDataItems) {
  const results = await axios
    .post("https://tts.audioone.xyz/form", { query })
    .then((res) => res.data);
  setDataItems(results);
}

function NewsList(props) {
  const [dataItems, setDataItems] = useState([]);
  useEffect(() => {
    if (props.filter == "newsCategory" && props.query)
      setDataItems(newsCategories(props.feed, props.query));
    if (props.filter == "newsArticles" && props.query)
      setDataItems(newsTitles(props.feed, props.query));
    if (props.filter == "feedlyNews" && props.query)
      feedlyNews(props.query, props.feedlyUser, setDataItems);
    if (props.filter == "feedlyNewsArticles" && props.query)
      feedlyNewsArticles(props.query, props.feedlyUser, setDataItems);
    if (props.filter == "googleNews" && props.query)
      getGoogleNews(props.query, setDataItems);
  }, [props.filter, props.query]);

  return (
    <div className="d-flex h-100">
      <div className={`podcastList ${styles.NewsList} mr-3 h-100`}>
        {dataItems.map((item) => (
          <NewsListItem
            data={{ ...item, type: "news" }}
            playArticle={(item) => Player.playAudio(item, "playNow")}
            onAdd={(item) => saveItem(item, props)}
            savedItems={props.savedItems}
          />
        ))}
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsList);
