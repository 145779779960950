import React, { Component, useState } from "react";
import apiRequest from "../api/clientRequest";
import ShareModal from "./shareModal";
import styles from "./styles/newsitem.module.css";
import MusicStyle from "./styles/musicitem.module.css";
import podStyle from "./styles/podcastitem.module.css";
import MusicInfo from "./MusicInfo";
import TrackItem from "./TrackItem";
import { connect } from "react-redux";
import colors from "../data/colors.json";
import ACTIONS from "../redux/actions/site";

var mapStateToProps = (state) => {
  return {
    theme: state.site.theme,
    savedItems: state.site.savedItems,
  };
};
var mapDispatchToProps = (dispatch) => ({
  setSavedItems: (items) => dispatch(ACTIONS.setSavedItems(items)),
});
class MusicItem extends Component {
  state = {
    expanded: false,
    savedArticle: { id: false },
    shareVisibile: false,
    selectedArticle: null,
    showPlayHover: false,
  };

  unExpand() {
    this.setState({
      expanded: false,
    });
  }

  saveItem(item) {
    item.type = "musicCategory'";
    item.id = item.playlistid;
    if (this.props.savedItems.find((ite) => ite.track_id == item.playlistid)) {
      item.clicked = false;
      this.props.setSavedItems(
        this.props.savedItems.filter((ite) => ite.track_id !== item.playlistid)
      );
      apiRequest({ url: "/news/save", method: "POST", data: item });
    } else {
      item.clicked = true;
      apiRequest({ url: "/news/save", method: "POST", data: item });
      this.props.setSavedItems([
        ...this.props.savedItems.map((ite) => ite),
        { track_id: item.playlistid, type: "musicCategory" },
      ]);
    }
  }

  saveArticle(item) {
    item.type = "music";
    if (item.id == this.state.savedArticle.id) {
      item.clicked = false;
      this.setState({ savedArticle: item });
      this.props.setSavedItems(
        this.props.savedItems.filter((ite) => ite.track_id !== item.id)
      );
      apiRequest({ url: "/news/save", method: "POST", data: item });
    } else {
      item.clicked = true;
      this.setState({ savedArticle: item });
      apiRequest({ url: "/news/save", method: "POST", data: item });
      this.props.setSavedItems([
        { track_id: item.id, type: "music", ...item },
        ...this.props.savedItems.map((ite) => ite),
      ]);
    }
  }

  render() {
    const {
      title,
      image,
      tracks,
      description,
      followers,
      owner,
    } = this.props.data;

    let totalMins = 0;
    let totalSecs = 0;
    tracks.map((item) => {
      const duration = item.duration.split(":");
      totalMins += parseInt(duration[0]);
      totalSecs += parseInt(duration[1]);
    });

    totalMins += Math.floor(totalSecs / 60);
    const duration = `${Math.floor(totalMins / 60)} Hrs ${totalMins % 60} Mins`;
    var color = "";
    const { showPlayHover } = this.state;

    if (this.props.theme == "color-theme" && !image)
      color = colors[Math.floor(Math.random() * colors.length)];
    return (
      <div className={`currency-box  ${this.state.expanded ? "expand" : ""}`}>
        <ShareModal
          visible={this.state.shareVisibile}
          setVisiblity={(cond) =>
            this.setState({
              shareVisibile: cond,
            })
          }
          selectedItem={this.state.selectedArticle}
          type="musicList"
          setSelectedArticle={(article) =>
            this.setState({
              selectedArticle: article,
            })
          }
        />

        <div className="currency-pair">
          <div
            className={`background-image ${
              this.props.theme == "color-theme" && this.state.expanded
                ? "white-back"
                : ""
            }`}
            style={{
              background: `${
                this.state.expanded
                  ? this.props.theme == "color-theme"
                    ? "white"
                    : ""
                  : `url(${image}) ${color}`
              }`,
            }}
          ></div>

          <div
            className={styles.playOver}
            onMouseLeave={() => this.setState({ showPlayHover: false })}
            style={{ display: showPlayHover ? "flex" : "none" }}
          >
            <div>
              Play In-Queue{" "}
              <img
                src={"/images/playIcon.png"}
                className={`${styles.playIcon} `}
                onClick={() => this.props.playSpotifyList(this.props.data)}
                alt=""
              />
            </div>

            <div>
              Play Next{" "}
              <img
                src={"/images/playNext.png"}
                className={`${styles.playIcon} `}
                onClick={() =>
                  this.props.playSpotifyList(this.props.data, "playNow")
                }
                alt=""
              />
            </div>

            <div>
              Play Now{" "}
              <img
                src={"/images/playNow.png"}
                className={`${styles.playIcon} `}
                onClick={() =>
                  this.props.playSpotifyList(this.props.data, "playNow")
                }
                alt=""
              />
            </div>
          </div>

          <div className="cat-content">
            <img
              src="/images/block-menu.png"
              className={`${styles.expandIcon} ${
                this.state.expanded ? "d-none" : "d-inline-block"
              }`}
              alt=""
              onClick={() =>
                this.setState((prev) => ({ expanded: !prev.expanded }))
              }
            />
            <span
              className={`name mt-1  ${
                this.state.expanded ? "d-none" : "d-inline-block"
              }`}
            >
              {duration}
            </span>
            <img
              src={"/images/playIcon.png"}
              className={`${styles.playIcon} ${
                this.state.expanded ? "d-none" : ""
              }`}
              onMouseEnter={() => this.setState({ showPlayHover: true })}
              alt=""
              onClick={() => this.props.playSpotifyList(this.props.data)}
            />

            <div
              className={`article-container   ${
                this.state.expanded ? "d-none" : ""
              }`}
            >
              <span className="article-name"> {title}</span>
              <div className={styles.actionContainer}>
                <div className="article-source">
                  <p className={`${MusicStyle.noTracks}`}>{tracks.length}</p>{" "}
                  <sub>Tracks</sub>{" "}
                </div>
                <div className="d-flex ml-auto align-items-end">
                  <img
                    src="/images/cluster.png"
                    className={styles.actionIcon}
                    alt=""
                    onClick={() => {
                      this.setState({
                        shareVisibile: true,
                        selectedArticle: this.props.data,
                      });
                    }}
                  />
                  {this.props.savedItems.find(
                    (item) => item.track_id == this.props.data.playlistid
                  ) ? (
                    <img
                      src="/images/HeartFill.png"
                      className={`${styles.actionIcon} ${styles.likedIcon}`}
                      onClick={() => this.saveItem(this.props.data)}
                      alt=""
                    />
                  ) : (
                    <img
                      src="/images/Heart.png"
                      className={`${styles.actionIcon} `}
                      onClick={() => this.saveItem(this.props.data)}
                      alt=""
                    />
                  )}
                </div>
              </div>
            </div>

            {this.state.expanded ? (
              <div className={`row m-0 expanded-container `}>
                <div
                  className={styles.backArrowContainer}
                  onClick={this.unExpand.bind(this)}
                >
                  <img src="/images/back-to-latest2x.png" alt="" />
                  <img src="/images/back-left.png" alt="" />
                </div>
                <div style={{ position: "fixed", left: "31px", top: "30%" }}>
                  <img
                    src={"/images/playIcon.png"}
                    className={`${styles.playIcon} `}
                    onClick={() => this.playAllArticles(tracks)}
                    style={{
                      width: "80px",
                      height: "80px",
                      marginBottom: "15px",
                    }}
                    alt=""
                  />
                  <p style={{ textAlign: "center" }}>Play All </p>
                </div>
                <MusicInfo
                  data={{
                    title,
                    description,
                    image,
                    followers,
                    source: "emp",
                    duration,
                    tracks,
                    owner,
                  }}
                  playSpotifyList={this.props.playSpotifyList}
                />
                <h5 className="pl-3 text-white w-100">Playlist Tracks</h5>
                {tracks.map((track) => (
                  <TrackItem
                    playSpotifyTrack={(data, act) =>
                      this.props.playSpotifyTrack(data, act)
                    }
                    data={track}
                    savedItems={this.props.savedItems}
                    saveItem={this.saveArticle.bind(this)}
                  />
                ))}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MusicItem);
