var Types = {
  searchVal: "searchVal",
  searchType: "searchType",
  setSearchToggle: "setSearchToggle",
  setInstantSearch: "setInstantSearch"
};
var setSearchValue = cond => ({
  type: Types.searchVal,
  payload: cond
});

var setSearchType = cond => ({
  type: Types.searchType,
  payload: cond
});

var setSearchToggle = cond => ({
  type: Types.setSearchToggle,
  payload: cond
});

var setInstantSearch = cond => ({
  type: Types.setInstantSearch,
  payload: cond
});

export default {
  setInstantSearch,
  setSearchToggle,
  setSearchValue,
  setSearchType,
  Types
};
