import React, { Component } from "react";
import styles from "./styles/shorts.module.css";
import playstyles from "./styles/playlist.module.css";
import { expandIcon, actionIcon } from "./styles/newsitem.module.css";
import ACTIONS from "../redux/actions/team";
import { connect } from "react-redux";
import pstyles from "./styles/playlist.module.css";
import MusicStyle from "./styles/musicitem.module.css";
import newsstyle from "./styles/newsitem.module.css";
import questyles from "./styles/Queuelist.module.css";
import TeamEmailBanner from "./TeamEmailBanner";
import moment from "moment";
import player from "../data/player";

var mapStateToProps = (state) => {
  return {
    savedItems: state.site.savedItems,
    theme: state.site.theme,
  };
};
var mapDispatchToProps = (dispatch) => ({
  setEmailExpand: (items) => dispatch(ACTIONS.setEmailExpand(items)),
});

class TeamItemExpand extends Component {
  unExpand() {
    this.setState({
      expanded: false,
      selectedEmail: null,
    });
    this.props.setEmailExpand(false);
  }
  state = { expanded: false, filter: "Recent" };

  selectArticle(id) {
    let tempObj = this.props.items.find((item) => item._id == id);
    if (tempObj) {
      this.setState({ selectedEmail: tempObj });
      this.props.setEmailExpand(tempObj);
    }
  }

  player = player();

  playArticle(article) {
    this.player.playAudio(article);
  }

  renderArticles() {
    let articles = this.props.items;
    let selectedEmail = articles[0];
    const { filter } = this.state;

    articles = articles.sort(function (x, y) {
      return moment(y.sentAt).unix() - moment(x.sentAt).unix();
    });

    if (filter == "Recent") articles = articles.slice(0, 6);
    else if (filter == "All") articles = articles;
    else if (filter == "Week") {
      articles = articles.filter((item) => {
        if (moment(item.sentAt, "X").isSame(moment(), "week")) return true;
        else return false;
      });
    } else if (filter == "Month")
      articles = articles.filter((item) => {
        if (moment(item.sentAt, "X").isSame(moment(), "month")) return true;
        else return false;
      });

    articles = articles.map((item) => {
      let tempObj = JSON.parse(item.media);
      tempObj["id"] = item._id;
      return tempObj;
    });

    return articles.map((article) => (
      <div
        className="d-flex"
        style={{ cursor: "pointer" }}
        onClick={() => {
          this.selectArticle(article.id);
        }}
      >
        <img
          src={"/images/playIcon.png"}
          onClick={() => this.playArticle(article)}
          className={newsstyle.playIcon}
          alt=""
        />
        <div className="article-container " style={{ width: "87%" }}>
          <div className="d-flex justify-content-between">
            <span
              className={`article-name w-100 ${MusicStyle.articleName}`}
              style={{ wordBreak: "break-word" }}
            >
              {article.title}
            </span>
            <img className={MusicStyle.trackImage} src={article.image} alt="" />
          </div>

          <div className={newsstyle.actionContainer}>
            {" "}
            <span className="article-source">
              {article.source ? article.source.substr(0, 15) : ""}
            </span>
            <div className="d-flex ml-auto align-items-end">
              <img
                src="/images/cluster.png"
                className={newsstyle.actionIcon}
                alt=""
              />
              <img
                src="/images/HeartFill.png"
                className={`${newsstyle.actionIcon} ${newsstyle.likedIcon}`}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    ));
  }

  render() {
    const { team, count, thumb, id, type } = this.props;

    const { filter } = this.state;
    let articles = this.props.items;
    let selectedEmail = articles[0];

    selectedEmail = this.state.selectedEmail
      ? this.state.selectedEmail
      : selectedEmail;
    return (
      <div
        style={{ cursor: "pointer" }}
        className={`currency-box ${
          this.props.clicked ? pstyles.itemClicked : ""
        }   ${styles.shortItem}  ${this.state.expanded ? "expand" : ""}`}
      >
        <div className={`${playstyles.playlistItem}  currency-pair   `}>
          <div
            className={`background-image ${
              this.props.theme == "color-theme" && this.state.expanded
                ? "white-back"
                : ""
            }`}
            style={{
              background: `${
                this.props.theme == "color-theme" && this.state.expanded
                  ? "white"
                  : ``
              }`,
            }}
          ></div>
          <div className={`cat-content `}>
            <div className={`${this.state.expanded ? "d-none" : ""}`}>
              <p className={`${playstyles.playlistName} text-left `}>
                <img
                  src="/images/block-menu.png"
                  className={`${actionIcon} `}
                  style={{ float: "none" }}
                  alt=""
                  onClick={() => {
                    this.setState((prev) => ({ expanded: !prev.expanded }));
                    this.props.setEmailExpand(
                      this.state.selectedEmail
                        ? this.state.selectedEmail
                        : selectedEmail
                    );
                  }}
                />
              </p>
              <div style={{ marginTop: "3em" }}>
                <p
                  className={playstyles.article}
                  style={{
                    color: "#d19310",
                    fontSize: "1em",
                    textAlign: "right",
                  }}
                >
                  {team}
                </p>
              </div>
              <div
                className={playstyles.itemInfo}
                style={{ marginTop: "105px" }}
              >
                <div
                  className={playstyles.articleSideInfo}
                  style={{ left: "12px" }}
                >
                  {thumb ? (
                    <img
                      src={`/images/${thumb}`}
                      className={`${actionIcon} `}
                      style={{
                        position: "absolute",
                        bottom: "21px",
                        width: "39px",
                        height: "39px",
                      }}
                      alt=""
                    />
                  ) : (
                    ""
                  )}

                  <div className="text-right">
                    <p className={pstyles.noArticle}>{count}</p>
                    <p className={pstyles.article}>{type}</p>
                  </div>
                </div>
              </div>
            </div>

            {this.state.expanded ? (
              <div
                className={`row m-0 expanded-container ${
                  this.state.expanded ? "" : "d-none"
                }`}
              >
                {selectedEmail && selectedEmail.media ? (
                  <TeamEmailBanner data={selectedEmail} />
                ) : (
                  ""
                )}
                <div
                  className={newsstyle.backArrowContainer}
                  onClick={this.unExpand.bind(this)}
                >
                  <img src="/images/backTo.svg" alt="" />
                  <img src="/images/back-left.png" alt="" />
                </div>
                <section className="w-100">
                  <button
                    onClick={() => this.setState({ filter: "All" })}
                    className={`btn ${questyles.filter_btn} ${
                      filter == "All" ? questyles.filter_selected : ""
                    }`}
                  >
                    All
                  </button>
                  <button
                    onClick={() => this.setState({ filter: "Recent" })}
                    className={`btn ${questyles.filter_btn} ${
                      filter == "Recent" ? questyles.filter_selected : ""
                    }`}
                  >
                    Recent
                  </button>
                  <button
                    onClick={() => this.setState({ filter: "Week" })}
                    className={`btn ${questyles.filter_btn} ${
                      filter == "Week" ? questyles.filter_selected : ""
                    }`}
                  >
                    This Week
                  </button>
                  <button
                    onClick={() => this.setState({ filter: "Month" })}
                    className={`btn ${questyles.filter_btn} ${
                      filter == "Month" ? questyles.filter_selected : ""
                    }`}
                  >
                    This Month
                  </button>
                </section>

                {this.renderArticles()}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamItemExpand);
