import React, { Component } from "react";
import styles from "./styles/shorts.module.css";
import { subscribeBtn } from "./styles/podcastInfo.module.css";
import playstyles from "./styles/playlist.module.css";
import { expandIcon, actionIcon } from "./styles/newsitem.module.css";
import { playIcon } from "./styles/newsitem.module.css";
import ShortsItem from "./ShortsItem";
import { connect } from "react-redux";
import _ from "lodash";
import ScrollContainer from "react-indiana-drag-scroll";

var mapStateToProps = (state) => {
  return {
    shorts: state.site.shorts,
    theme: state.site.theme,
  };
};

class ShortsContainer extends Component {
  state = {
    shorts: [],
  };

  componentWillReceiveProps(nextProp) {
    this.setState({
      shorts: nextProp.shorts,
    });
  }
  

  render() {
    return (
      <ScrollContainer horizontal={true}>
        <div className={`d-flex ${styles.shortsContainer} w-100`}>
          <div
            className={styles.firstItem}
            style={{
              backgroundColor: `${
                this.props.theme == "color-theme" ? "#182c42" : "#141313"
              }`,
            }}
          >
            <p className="mb-4">
              Sometimes you don’t have time to listen to entire podcast.{" "}
              <span className="text-white font-weight-bold">
                Audio One Shorts
              </span>{" "}
              uses AI to basically find the most important points in a podcasts
              and deliver them in small excerpts. For each category of
              interests, you get a playlist of short clips.
            </p>
            <div className="d-flex">
              <button className={subscribeBtn} style={{ fontSize: ".8em" }}>
                Learn More
              </button>
              <div className="d-flex ml-auto align-items-end">
                <img src="/images/cluster.png" className={actionIcon} alt="" />
                <img
                  src="/images/Heart.png"
                  className={`${actionIcon} `}
                  alt=""
                />
              </div>
            </div>
          </div>
          {this.state.shorts.map((item) => (
            <ShortsItem data={item} />
          ))}
        </div>
      </ScrollContainer>
    );
  }
}

export default connect(mapStateToProps, null)(ShortsContainer);
