import React, { Component } from "react";
import playstyles from "./styles/playlist.module.css";

import ACTIONS from "../redux/actions/site";
import TEAMACTIONS from "../redux/actions/team";
import { connect } from "react-redux";
import { Modal, Button, message } from "antd";
import modalstyles from "./styles/sharemodal.module.css";
import mainstyle from "./styles/addTeam.module.css";
import apiRequest from "../api/clientRequest";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import Suggestionitem from "./SuggestionItem";
import UserImage from "./UserImage";
import { selectBtn } from "./styles/sharemodal.module.css";
import UserSearch from "./UserSearch";

var mapStateToProps = (state) => {
  return {
    savedItems: state.site.savedItems,
    theme: state.site.theme,
    recentUsers: state.site.recentUsers,
    teams: state.team.teams,
    selectedTeam: state.team.selectedTeam,
  };
};
var mapDispatchToProps = (dispatch) => ({
  setSavedItems: (items) => dispatch(ACTIONS.setSavedItems(items)),
  setTeam: (item) => dispatch(TEAMACTIONS.setTeams(item)),
  setSelectedTeam: (item) => dispatch(TEAMACTIONS.setSelectedTeam(item)),
  setMeter: (items) => dispatch(ACTIONS.setMeter(items)),
  setMeterProgress: (items) => dispatch(ACTIONS.setMeterProgress(items)),
  setMeterTitle: (items) => dispatch(ACTIONS.setMeterTitle(items)),
});

class EditTeam extends Component {
  state = {
    selectedUsers: [],
    emails: [],
    teamName: "",
    teamDescription: "",
    teamLogo: null,
    editName: false,
    editLogo: false,
    logoDisplay: null,
    siteUrl: "",
  };

  handleOk = async () => {
    this.resetState();
  };

  componentWillReceiveProps(nextProp) {
    if (
      nextProp.selectedTeam &&
      this.state.selectedUsers.length !== nextProp.selectedTeam.users.length
    ) {
      this.setState({
        selectedUsers: nextProp.selectedTeam.users,
        teamName: nextProp.selectedTeam.name,
        teamDescription: nextProp.selectedTeam.description,
        siteUrl: nextProp.selectedTeam.detailUrl,
      });
    }
  }

  editTeam = () => {
    const config = {
      headers: {
        "Content-Type": `multipart/form-data;`,
      },
    };
    this.props.setMeterTitle("Editing Team");
    this.props.setMeter(true);
    this.props.setMeterProgress("25");
    const formData = new FormData();
    formData.append("name", this.state.teamName);
    formData.append("avatar", this.state.teamLogo);
    formData.append("users", JSON.stringify(this.state.selectedUsers));
    formData.append("emails", JSON.stringify(this.state.emails));
    formData.append("description", this.state.teamDescription);
    formData.append("id", this.props.selectedTeam.id);
    formData.append("detailUrl", this.state.siteUrl);
    console.log(this.state);
    apiRequest({
      url: "/teams",
      method: "PUT",
      data: formData,
      config,
    }).then((res) => {
      this.props.setMeterTitle("Team Updated");
      this.props.setMeterProgress("100");
      setTimeout(() => {
        this.props.setMeter(false);
      }, 1000);
      if (res.data.status == 200) {
        this.resetState();
        let restTeams = this.props.teams;
        let curTeam = this.props.teams.findIndex(
          (item) => item.id == this.props.selectedTeam.id
        );
        restTeams[curTeam] = {
          ...res.data.team,
          emails: restTeams[curTeam]["emails"],
          sharedArticles: restTeams[curTeam]["sharedArticles"],
          campaigns: restTeams[curTeam]["campaigns"],
        };
        this.props.setSelectedTeam(restTeams[curTeam]);
        this.props.setTeam(restTeams);
      } else message.error(res.data.msg);
    });
  };

  handleLogoChange(files) {
    if (files.length > 0)
      this.setState({
        teamLogo: files[0],
        logoDisplay: URL.createObjectURL(files[0]),
      });
  }

  resetState() {
    this.setState({
      teamName: "",
      teamLogo: null,
      teamDescription: "",
      selectedUsers: [],
      emails: [],
      editName: false,
      editLogo: false,
    });
    this.props.handleClose();
  }

  removeUser = (id) => {
    let users = this.state.selectedUsers.filter((item) => item._id !== id);
    this.setState({ selectedUsers: users });
  };

  render() {
    return (
      <div>
        <Modal
          visible={this.props.visible}
          title="Create Team"
          onOk={this.handleOk}
          footer={[
            <Button key="back" onClick={this.handleOk}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" onClick={this.handleOk}>
              Share
            </Button>,
          ]}
          className={`${modalstyles.shareEmail} teamModal shareEmail share-modal ${this.props.theme}`}
          onCancel={this.handleOk}
        >
          <div className="p-3">
            <div className="d-flex justify-content-between">
              <h2 style={{ color: "#b8c0c9" }}>Edit Team</h2>
              <i
                class={`${modalstyles.crossIcon} far fa-times-circle`}
                style={{ cursor: "pointer" }}
                onClick={() => this.resetState()}
              ></i>
            </div>
            <div className="row">
              <div className="col-md-5">
                <div className="px-4 mt-5 pb-5">
                  {this.state.editName ? (
                    <>
                      <h5 className={mainstyle.inputMainLabel}>
                        Step 1: &nbsp; Change Team Name
                      </h5>
                      <div className={mainstyle.inputContainer}>
                        <input
                          type="text"
                          className={` ${mainstyle.input}`}
                          placeholder="Team Name"
                          onChange={(e) =>
                            this.setState({ teamName: e.target.value })
                          }
                          value={this.state.teamName}
                        />
                      </div>
                    </>
                  ) : (
                    <div
                      className={`d-flex p-0 mb-3 d-flex justify-content-between align-items-center`}
                    >
                      <p
                        style={{
                          fontSize: "2.7em",
                          fontWeight: "bold",
                          color: "white",
                          margin: 0,
                        }}
                      >
                        {this.props.selectedTeam
                          ? this.props.selectedTeam.name
                          : ""}
                      </p>
                      <button
                        class={`btn ml-5 ${selectBtn}`}
                        onClick={() => this.setState({ editName: true })}
                      >
                        Edit
                      </button>
                    </div>
                  )}
                </div>

                <div className="px-4 mt-5 pb-3">
                  <h5 className={mainstyle.inputMainLabel}>
                    Step 4: &nbsp; Invite Team Members
                  </h5>
                  <h6 className="mb-3">SUGGESTIONS</h6>
                  <div style={{ maxHeight: "210px", overflow: "auto" }}>
                    {this.props.recentUsers.map((item) => (
                      <Suggestionitem
                        data={item}
                        InviteMember={(item) => {
                          if (
                            !this.state.selectedUsers.find(
                              (ite) => ite._id == item._id
                            )
                          )
                            this.setState((prev) => ({
                              selectedUsers: [...prev.selectedUsers, item],
                            }));
                        }}
                      />
                    ))}
                  </div>
                </div>
                <div className="px-4 mt-5">
                  <h5 className={mainstyle.inputMainLabel}>Invited Members</h5>
                  <div className="d-flex">
                    {this.state.selectedUsers.map((item) => (
                      <div style={{ marginRight: "10px" }}>
                        <UserImage
                          avatar={item.avatar}
                          name={item.fullname}
                          delete={true}
                          id={item._id}
                          removeItem={this.removeUser}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-md-2 p-0">
                <div className="mt-5">
                  <h5 className={mainstyle.inputMainLabel}>
                    Step 2: &nbsp; Upload Logo
                  </h5>
                  {this.state.editLogo ? (
                    <div
                      style={{ cursor: "pointer" }}
                      // onClick={() => this.props.handleClick(id)}
                      className={`currency-box  ${mainstyle.imagePickContainer}`}
                    >
                      <div
                        className={`${playstyles.playlistItem}  currency-pair   `}
                        style={{
                          background: "#393939",
                          backgroundSize: "cover",
                          backgroundImage: this.state.logoDisplay
                            ? `url(${this.state.logoDisplay})`
                            : "",
                        }}
                      >
                        <div
                          className={`cat-content text-center`}
                          style={{ cursor: "pointer" }}
                        >
                          <div>
                            <img
                              src="/images/plusNewIcon.png"
                              style={{
                                width: "55px",
                                marginTop: "1em",
                              }}
                            />
                            <input
                              onChange={(e) =>
                                this.handleLogoChange(e.target.files)
                              }
                              type="file"
                              name=""
                              id=""
                              className={mainstyle.imagePicker}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className={`d-flex p-0 mb-3 d-flex justify-content-between align-items-center`}
                    >
                      <img
                        style={{ width: "100px", height: "50px" }}
                        src={
                          this.props.selectedTeam
                            ? this.props.selectedTeam.logo
                            : ""
                        }
                        alt=""
                      />

                      <button
                        class={`btn ml-5 ${selectBtn}`}
                        onClick={() => this.setState({ editLogo: true })}
                      >
                        Edit
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-5">
                <div className="px-4 mt-5">
                  <h5 className={mainstyle.inputMainLabel}>
                    Step 3: &nbsp; Add Description
                  </h5>
                  <div className={mainstyle.inputContainer}>
                    <textarea
                      type="text"
                      className={` ${mainstyle.input}`}
                      placeholder="Team Description"
                      rows="5"
                      style={{ resize: "none", height: "auto" }}
                      onChange={(e) =>
                        this.setState({ teamDescription: e.target.value })
                      }
                      value={this.state.teamDescription}
                    />
                  </div>
                </div>
                <div className="px-4 " style={{ marginTop: "7.1em" }}>
                  <h6 className={` mb-3`}>SEARCH</h6>
                  <div className={mainstyle.inputContainer}>
                    <UserSearch
                      onSelect={(users) =>
                        this.setState({ selectedUsers: users })
                      }
                      selectedUsers={this.state.selectedUsers}
                    />
                  </div>
                  <h6 className={`mt-4 mb-3`}>Site URL</h6>
                  <div className={mainstyle.inputContainer}>
                    <input
                      type="text"
                      className={` ${mainstyle.input}`}
                      placeholder="Team Detail url"
                      onChange={(e) =>
                        this.setState({ siteUrl: e.target.value })
                      }
                      value={this.state.siteUrl}
                    />
                  </div>
                  <h6 className={`mt-4 mb-3`}>INVITE BY EMAIL</h6>
                  <div className={mainstyle.inputContainer}>
                    <ReactMultiEmail
                      placeholder="Add email Addresses here"
                      emails={this.state.emails}
                      className="w-100"
                      onChange={(_emails) => {
                        this.setState({ emails: _emails });
                      }}
                      validateEmail={(email) => {
                        return isEmail(email); // return boolean
                      }}
                      getLabel={(email, index, removeEmail) => {
                        return (
                          <div data-tag key={index}>
                            {email}
                            <span
                              data-tag-handle
                              onClick={() => removeEmail(index)}
                            >
                              ×
                            </span>
                          </div>
                        );
                      }}
                    />
                  </div>
                </div>
                <button
                  onClick={() => this.editTeam()}
                  className={`btn btn-primary ${mainstyle.addBtn}`}
                >
                  Edit Team
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditTeam);
