import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import ACTIONS from "../../redux/actions/auth";
import SITEACTIONS from "../../redux/actions/site";
import NEWSACTIONS from "../../redux/actions/news";
import { connect } from "react-redux";
import styles from "../Navbar/dashNavbar.module.css";
import SpotifyLogin from "../SpotifyLogin";
import FeedlyLogin from "../FeedlyLogin";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
// changes made
var mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    feedlyUser: state.news.feedlyUser,
    tab: state.site.tab,
    SearchToggled: state.site.SearchToggled,
    radioMode: state.news.radioMode,
    musicList: state.site.musicList,
    subscribedItems: state.site.subscribedItems,
    podcasts: state.news.podcasts,
    recentlyPlayed: state.site.recentlyPlayed,
    shorts: state.site.shorts,
    savedItems: state.site.savedItems,
  };
};

var mapDispatchToProps = (dispatch) => ({
  setUser: (item) => dispatch(ACTIONS.setUser(item)),
  setTab: (item) => dispatch(SITEACTIONS.setTab(item)),
  setTheme: (item) => dispatch(SITEACTIONS.setTheme(item)),
  setSearchToggle: (item) => dispatch(SITEACTIONS.setSearchToggle(item)),
  setThemeSelector: (item) => dispatch(SITEACTIONS.setThemeSelector(item)),
  setRadioMode: (item) => dispatch(NEWSACTIONS.setRadioMode(item)),
});
class ContentNavbar extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.state = {
      dropdownOpen: false,
      tab: 0,
    };
  }

  switchRadioMode() {
    if (!this.props.radioMode) {
      this.props.setRadioMode(true);
    }
  }

  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  handleTabChange(event, newValue) {
    this.props.setTab(newValue);
  }
  render() {
    const spotUser = window.localStorage.getItem("spotify_token");

    if (this.props.user)
      var Menu = (
        <>
          {/* <div className={styles.linksHolder}>
            <Tabs
              value={this.props.tab}
              onChange={this.handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="Home" />
              <Tab label="News" />
              <Tab label="Podcast" />
              <Tab label="Music" />
              <Tab label="Teams" />
            </Tabs>
          </div> */}

          <div className={styles.logoHolder}>
            <SpotifyLogin />
            <div class="mr-3"></div>
            <FeedlyLogin />
            <div class="mr-3"></div>
            <img
              src="/images/Radio-Icon.webp"
              className={this.props.radioMode ? "" : styles.spotLogo}
              onClick={() => {
                this.switchRadioMode();
              }}
            />
          </div>
          <div className="links mr-4 d-flex">
            {/* <SearchBox /> */}

            {/* <a
              onClick={() =>
                this.props.setSearchToggle(!this.props.SearchToggled)
              }
            >
              Search
            </a> */}
            <Dropdown
              className="px-5"
              inNavbar={true}
              isOpen={this.state.dropdownOpen}
              toggle={this.toggle}
            >
              <span onClick={this.toggle} className="d-flex">
                <img className="user-img" src={this.props.user.avatar} alt="" />
                <div>
                  {" "}
                  <span
                    className={` mr-3 lead d-block mb-1 ${styles.userName}`}
                  >
                    {this.props.user.username}
                  </span>
                  <sub>Action</sub>
                </div>
              </span>
              <DropdownMenu>
                <DropdownItem>{this.props.user.username}</DropdownItem>
                <DropdownItem onClick={() => this.props.setThemeSelector(true)}>
                  Select Theme
                </DropdownItem>
                <DropdownItem
                  onClick={() => this.props.history.push("/dashboard/profile")}
                >
                  Profile
                </DropdownItem>
                <DropdownItem>Settings</DropdownItem>
                <DropdownItem
                  onClick={() => {
                    window.localStorage.removeItem("authToken");
                    window.localStorage.removeItem("spotify_token");
                    window.localStorage.removeItem("spotify_refresh");
                    this.props.setUser(null);
                    this.props.history.push("/");
                  }}
                >
                  Logout
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </>
      );
    else
      var Menu = (
        <>
          {/* <div className={`${styles.linksHolder} mr-auto`}></div> */}

          <div className={`${styles.linksHolder} ml-auto`}>
            <Tabs
              value={2}
              variant="scrollable"
              scrollButtons="auto"
              onChange={(event, newValue) => {
                if (newValue == 1) {
                  this.props.history.push("/signin");
                } else {
                  window.location = "https://audioone.xyz/trial/";
                }
              }}
            >
              {/* <Tab value="1" label="Login" /> */}
              <Tab value="2" label="Signup" />
            </Tabs>
          </div>
        </>
      );

    return (
      <nav className="navbar head">
        <div className="brand sign-brand">
          <span>
            <img
              src="/images/collapse@x.png"
              alt=""
              className={styles.collpaseIcon}
            />
            <a href="/">
              <img
                src="/images/AudioOneLogo@2x.png"
                alt=""
                style={{ width: "150px" }}
              />
            </a>
          </span>
        </div>
        {Menu}
      </nav>
    );
  }
}

const withRoute = withRouter(ContentNavbar);
export default connect(mapStateToProps, mapDispatchToProps)(withRoute);
