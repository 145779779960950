var Types = {
  setUser: "setUser",
  linkAccount: "linkAccount",
};
// actions
var setUser = (cond) => ({
  type: Types.setUser,
  payload: cond,
});

var setLinkAccountCreds = (cond) => ({
  type: Types.linkAccount,
  payload: cond,
});

export default {
  setUser,
  setLinkAccountCreds,
  Types,
};
