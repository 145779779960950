import React, { Component } from "react";
import styles from "../styles/shorts.module.css";
import playstyles from "../styles/playlist.module.css";
import ACTIONS from "../../redux/actions/site";
import TEAMACTIONS from "../../redux/actions/team";
import { connect } from "react-redux";
import { Modal, Button, message } from "antd";
import modalstyles from "../styles/sharemodal.module.css";
import mainstyle from "../styles/addTeam.module.css";
import apiRequest from "../../api/clientRequest";
import { ReactMultiEmail, isEmail } from "react-multi-email";

var mapStateToProps = (state) => {
  return {
    savedItems: state.site.savedItems,
    theme: state.site.theme,
    recentUsers: state.site.recentUsers,
    teams: state.team.teams,
    selectedTeam: state.team.selectedTeam,
    user: state.auth.user,
  };
};
var mapDispatchToProps = (dispatch) => ({
  setSavedItems: (items) => dispatch(ACTIONS.setSavedItems(items)),
  setTeam: (item) => dispatch(TEAMACTIONS.setTeams(item)),
  setSelectedTeam: (item) => dispatch(TEAMACTIONS.setSelectedTeam(item)),
  setMeter: (items) => dispatch(ACTIONS.setMeter(items)),
  setMeterProgress: (items) => dispatch(ACTIONS.setMeterProgress(items)),
  setMeterTitle: (items) => dispatch(ACTIONS.setMeterTitle(items)),
});

class AddGroup extends Component {
  state = {
    modalVisible: false,
    emails: [],
    teamName: "",
    teamDescription: "",
    teamLogo: null,
  };

  handleOk = async () => {
    this.resetState();
  };

  createTeam = () => {
    const config = {
      headers: {
        "Content-Type": `multipart/form-data;`,
      },
    };
    this.props.setMeterTitle("Creating Group");
    this.props.setMeter(true);
    this.props.setMeterProgress("25");

    const formData = new FormData();
    formData.append("name", this.state.teamName);
    formData.append("description", this.state.teamDescription);
    formData.append("emails", this.state.emails);
    formData.append("avatar", this.state.teamLogo);
    formData.append("team_id", this.props.selectedTeam.id);
    apiRequest({
      url: "/teams/group",
      method: "POST",
      data: formData,
      config,
    }).then((res) => {
      this.props.setMeterTitle("Added Group");
      this.props.setMeterProgress("100");

      setTimeout(() => {
        this.props.setMeter(false);
      }, 1000);

      if (res.data.status == 200) {
        this.resetState();
        let allTeams = this.props.teams;
        const foundIndex = this.props.teams.findIndex(
          (item) => item.id == this.props.selectedTeam.id
        );

        allTeams[foundIndex].groups.push(res.data.group);
        this.props.setSelectedTeam(allTeams[foundIndex]);
        this.props.setTeam([...allTeams]);
      } else {
        message.error(res.data.msg);
        this.props.setMeter(false);
      }
    });
  };

  handleLogoChange(files) {
    if (files.length > 0) this.setState({ teamLogo: files[0] });
  }

  handleCsvUpload(files) {
    if (files.length > 0) {
      var that = this;
      var reader = new FileReader();
      reader.onload = function () {
        console.log(reader.result);
        const emails = that.processCSvData(reader.result);
        that.setState((prev) => ({
          emails: [...prev.emails, ...emails],
        }));
      };
      // start reading the file. When it is done, calls the onload event defined above.
      reader.readAsBinaryString(files[0]);
    }
  }

  processCSvData(allText) {
    console.log(allText);
    allText = allText.replace(/,/g, "");
    console.log(allText);
    var allTextLines = allText.split(/\r\n|\n/);
    return allTextLines.slice(1, allTextLines.length - 2);
    // alert(lines);
  }

  resetState() {
    this.setState({
      teamName: "",
      teamDescription: "",
      emails: "",
      teamLogo: null,
      modalVisible: false,
    });
  }

  handleGroupAdd() {
    const foundItem = this.props.selectedTeam;
    const foundUser = foundItem.users.find(
      (user) => user.email == this.props.user.email
    );
    if (foundUser && foundUser.role !== "Member") {
      this.setState({ modalVisible: true });
    } else {
      message.error("You do not have permission to perform this operation");
    }
  }

  render() {
    const { dataSource } = this.state;
    return (
      <div>
        <div
          className="d-flex align-items-center"
          style={{ cursor: "pointer", position: "absolute", bottom: "60px" }}
          onClick={this.handleGroupAdd.bind(this)}
        >
          <img
            src="/images/plusNewIcon.png"
            style={{
              width: "45px",
              marginRight: "10px",
            }}
          />
          <h2 style={{ fontSize: "1.8em", margin: "0", opacity: "0.55" }}>
            Add Group
          </h2>
        </div>

        <Modal
          visible={this.state.modalVisible}
          title="Create Team"
          onOk={this.handleOk}
          footer={[
            <Button key="back" onClick={this.handleOk}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" onClick={this.handleOk}>
              Share
            </Button>,
          ]}
          className={`${modalstyles.shareEmail} teamModal shareEmail share-modal ${this.props.theme}`}
          onCancel={this.handleOk}
        >
          <div className="p-3">
            <div className="d-flex justify-content-between">
              <h2 style={{ color: "#b8c0c9" }}>Create Email Campaign Group</h2>
              <i
                class={`${modalstyles.crossIcon} far fa-times-circle`}
                style={{ cursor: "pointer" }}
                onClick={() => this.resetState()}
              ></i>
            </div>
            <div className="row">
              <div className="col-md-5">
                <div className="px-4 mt-5">
                  <h5 className={mainstyle.inputMainLabel}>
                    Step 1: &nbsp; Assign Group Name
                  </h5>
                  <div className={mainstyle.inputContainer}>
                    <input
                      type="text"
                      className={` ${mainstyle.input}`}
                      placeholder="Team Name"
                      onChange={(e) =>
                        this.setState({ teamName: e.target.value })
                      }
                      value={this.state.teamName}
                    />
                  </div>
                </div>
                <div className="px-4 mt-5">
                  <h5 className={mainstyle.inputMainLabel}>
                    Step 4: &nbsp; Add Email Address
                  </h5>
                  <div className="d-flex align-items-center multiLineMultiEmail">
                    <ReactMultiEmail
                      placeholder="Enter email"
                      emails={this.state.emails}
                      style={{ height: "200px" }}
                      onChange={(_emails) => {
                        this.setState({ emails: _emails });
                      }}
                      validateEmail={(email) => {
                        return isEmail(email); // return boolean
                      }}
                      getLabel={(email, index, removeEmail) => {
                        return (
                          <div data-tag key={index}>
                            {email}
                            <span
                              data-tag-handle
                              onClick={() => removeEmail(index)}
                            >
                              ×
                            </span>
                          </div>
                        );
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-2 p-0">
                <div className="mt-5">
                  <h5 className={mainstyle.inputMainLabel}>
                    Step 2: &nbsp; Upload Logo
                  </h5>
                  <div
                    style={{ cursor: "pointer" }}
                    // onClick={() => this.props.handleClick(id)}
                    className={`currency-box  ${mainstyle.imagePickContainer}`}
                  >
                    <div
                      className={`${playstyles.playlistItem}  currency-pair   `}
                      style={{ background: "#393939" }}
                    >
                      <div
                        className={`cat-content text-center`}
                        style={{ cursor: "pointer" }}
                      >
                        <div>
                          <img
                            src="/images/plusNewIcon.png"
                            style={{
                              width: "55px",
                              marginTop: "1em",
                            }}
                          />
                          <input
                            onChange={(e) =>
                              this.handleLogoChange(e.target.files)
                            }
                            type="file"
                            name=""
                            id=""
                            className={mainstyle.imagePicker}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="px-4 mt-5">
                  <h5 className={mainstyle.inputMainLabel}>
                    Step 3: &nbsp; Add Group Description
                  </h5>
                  <div className={mainstyle.inputContainer}>
                    <textarea
                      type="text"
                      className={` ${mainstyle.input}`}
                      placeholder="Team Description"
                      rows="5"
                      style={{ resize: "none", height: "auto" }}
                      onChange={(e) =>
                        this.setState({ teamDescription: e.target.value })
                      }
                      value={this.state.teamDescription}
                    />
                  </div>
                </div>
                <div className="px-4 mt-5">
                  <h5 className={mainstyle.inputMainLabel}>
                    Step 5: &nbsp; Upload CSV File
                  </h5>
                  <div
                    style={{ cursor: "pointer" }}
                    // onClick={() => this.props.handleClick(id)}
                    className={`currency-box  ${mainstyle.imagePickContainer}`}
                  >
                    <div
                      className={`${playstyles.playlistItem}  currency-pair   `}
                      style={{ background: "#393939" }}
                    >
                      <div
                        className={`cat-content text-center`}
                        style={{ cursor: "pointer" }}
                      >
                        <div>
                          <img
                            src="/images/plusNewIcon.png"
                            style={{
                              width: "55px",
                              marginTop: "1em",
                            }}
                          />
                          <input
                            onChange={(e) =>
                              this.handleCsvUpload(e.target.files)
                            }
                            type="file"
                            name=""
                            id=""
                            className={mainstyle.imagePicker}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  onClick={() => this.createTeam()}
                  className={`btn btn-primary ${mainstyle.addBtn}`}
                >
                  Add Group
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddGroup);
