import React, { Component } from "react";
import styles from "./styles/playlist.module.css";
import { expandIcon } from "./styles/newsitem.module.css";
import { connect } from "react-redux";
import player from "../data/player";
import colors from "../data/colors.json";
import ScrollContainer from "react-indiana-drag-scroll";

const mapStateToProps = (state) => ({
  podcasts: state.news.podcasts,
  theme: state.site.theme,
  subscribedItems: state.site.subscribedItems,
  instantSearch: state.site.instantSearch,
});

class PodcastContainer extends Component {
  player = player();

  playArticle(article) {
    this.player.playAudio(article);
  }

  async playPlaylist(item) {
    var addTracks = async () => {
      const promises = item.articles.map(async (article) =>
        this.player.playAudio(article, true)
      );
      return Promise.all(promises);
    };

    await addTracks().then((res) => console.log(res));
  }
  render() {
    let totalLength = 0;
    this.props.podcasts.map((item) => {
      item.podcasts.map((ite) => {
        totalLength += ite.episode.length;
      });
    });

    var color = false;
    if (this.props.theme == "color-theme")
      color = colors[Math.floor(Math.random() * colors.length)];

    return (
      <div
        className={styles.container}
        style={{ display: this.props.instantSearch ? "none" : "block" }}
      >
        <div className={styles.itemContainer}>
          <div
            className={`${styles.playlistItem} playlistItem`}
            style={{ backgroundColor: color }}
          >
            <p className={`${styles.playlistName} text-left `}>#ALL</p>

            <div className={styles.itemInfo}>
              <div className={styles.articleSideInfo} style={{ left: "12px" }}>
                <img
                  src="/images/block-menu.png"
                  className={`${expandIcon} `}
                  onClick={() => this.props.selectPodcastTag("ALL")}
                  style={{ position: "absolute", bottom: "21px" }}
                />

                <p className={styles.noArticle}>{totalLength}</p>
                <p className={styles.article}>episodes</p>
              </div>
            </div>
          </div>
          <div
            className={`${styles.playlistItem} playlistItem`}
            style={{ backgroundColor: color }}
          >
            <p className={`${styles.playlistName} text-left `}>#SUBSCRIBED</p>

            <div className={styles.itemInfo}>
              <div className={styles.articleSideInfo} style={{ left: "12px" }}>
                <img
                  src="/images/block-menu.png"
                  className={`${expandIcon} `}
                  onClick={() => this.props.selectPodcastTag("SUBSCRIBED")}
                  style={{ position: "absolute", bottom: "21px" }}
                />

                <p className={styles.noArticle}>
                  {
                    this.props.subscribedItems.filter(
                      (item) => item.type == "podcast"
                    ).length
                  }
                </p>
                <p className={styles.article}>shows</p>
              </div>
            </div>
          </div>
          {this.props.podcasts.map((item) => {
            let episodeLength = 0;
            item.podcasts.map((ite) => {
              episodeLength += ite.episode.length;
            });
            var color = false;
            if (this.props.theme == "color-theme")
              color = colors[Math.floor(Math.random() * colors.length)];

            return (
              <div
                className={`${styles.playlistItem} playlistItem`}
                style={{ backgroundColor: color }}
              >
                <p className={`${styles.playlistName} text-left `}>
                  #{item.genre}
                </p>

                <div className={styles.itemInfo}>
                  <div
                    className={styles.articleSideInfo}
                    style={{ left: "12px" }}
                  >
                    <img
                      src="/images/block-menu.png"
                      className={`${expandIcon} `}
                      onClick={() => this.props.selectPodcastTag(item.genre)}
                      alt=""
                      style={{ position: "absolute", bottom: "21px" }}
                    />

                    <p className={styles.noArticle}>{episodeLength}</p>
                    <p className={styles.article}>episodes</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, null)(PodcastContainer);
