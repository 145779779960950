import React from "react";
import sliderstyles from "./styles/sliderItemHover.module.css";
import newsstyles from "./styles/newsitem.module.css";
import apiRequest from "../api/clientRequest";
import NEWSACTIONS from "../redux/actions/news";
import { Popover, Button } from "antd";
import { connect } from "react-redux";
import _ from "lodash";
import { AutoComplete } from "antd";
import styles from "./styles/favoriteBoard.module.css";

export default function SliderItemLikeHover(props) {
  return (
    <div
      onMouseLeave={() => {
        props.setShowHover(false);
      }}
      style={{ display: props.visible ? "block" : "none" }}
      className={`${props.insideSearch ? sliderstyles.smallContainer : ""}  ${
        sliderstyles.container
      }`}
    >
      <div className="row ml-4">
        <div
          className={`col-md-3 px-0 ${props.insideSearch ? "p-0" : ""}`}
          style={{ backgroundColor: "transparent" }}
        >
          <div className="col-md-12 d-flex my-3">
            Social
            <img
              src="/images/fb_icon.png"
              style={{
                width: "25px",
                height: "20px",
                marginLeft: "12px",
                borderRadius: "50%",
              }}
              alt=""
            />
            <img
              src="/images/twit_icon.png"
              style={{
                width: "25px",
                height: "20px",
                marginLeft: "12px",
                borderRadius: "50%",
              }}
              alt=""
            />
          </div>
          <div className="col-md-12 d-flex">
            Create Link
            <img
              src="/images/Link icon light.png"
              onClick={() => props.setSelected("shareLink")}
              style={{ width: "25px", height: "20px", marginLeft: "12px" }}
            />
          </div>
        </div>
        <div
          className={`col-md-4  ${props.insideSearch ? "mt-0" : "mt-3"}`}
          style={{ backgroundColor: "transparent" }}
        >
          <div className="col-md-12 d-flex my-3">
            Email Campaign
            <img
              src={"/images/emailIcon.png"}
              onClick={() => props.setSelected("campaign")}
              alt=""
              style={{ width: "25px", height: "20px", marginLeft: "12px" }}
            />
          </div>
          <div className="col-md-12 d-flex my-3">
            Audiogram
            <img
              src={"/images/megaPhoneIcon.png"}
              style={{ width: "25px", height: "20px", marginLeft: "12px" }}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}
