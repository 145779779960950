import React, { Component } from "react";
import homestyle from "./styles/home.module.css";
import CollectionItem from "./CollectionItem";
import styles from "./styles/Queuelist.module.css";
import { connect } from "react-redux";
import player from "../data/player";
import apiRequest from "../api/clientRequest";
import ACTIONS from "../redux/actions/site";
import ShareModal from "./shareModal";

var Player = player();
var mapStateToProps = (state) => {
  return {
    savedItems: state.site.savedItems,
    podcasts: state.news.podcasts,
    shorts: state.site.shorts,
    musicList: state.site.musicList,
  };
};
var mapDispatchToProps = (dispatch) => ({
  setSavedItems: (items) => dispatch(ACTIONS.setSavedItems(items)),
});
class CollectionContainer extends Component {
  state = {
    filter: "All",
    allItems: [],
    selectedArticle: { type: null },
    shareVisibile: false,
  };

  componentWillReceiveProps(props) {
    if (props.data.length !== this.state.allItems) {
      let newdata = props.data.map((item) => {
        let newobj = JSON.parse(item.media);
        return newobj;
      });

      newdata = newdata.sort((a, b) => {
        return b.published - a.published;
      });
      this.setState({
        allItems: [...newdata],
      });
    }
  }

  playTrack(item, type) {
    if (type == "music") Player.playSpotifyTrack(item);
    else if (type == "podcast")
      Player.playPodcast({
        episode: item,
        title: item.podcastTitle,
      });
    else if (type == "news") Player.playAudio(item);
    else if (type == "shorts") Player.playShorts(item);
  }

  removeLiked(item) {
    item.clicked = false;
    this.props.setSavedItems(
      this.props.savedItems.filter((ite) => ite.track_id !== item.id)
    );
    apiRequest({ url: "/news/save", method: "POST", data: item });
  }

  setSelectedArticle(item, type) {
    var selArt = item;
    selArt.type = type;
    this.setState({
      selectedArticle: selArt,
      shareVisibile: true,
    });
  }

  render() {
    const { filter } = this.state;
    return (
      <div className="col-md-12 mb-5">
        <ShareModal
          visible={this.state.shareVisibile}
          setVisiblity={(cond) =>
            this.setState({
              shareVisibile: cond,
            })
          }
          selectedItem={this.state.selectedArticle}
          type={this.state.selectedArticle.type}
          setSelectedArticle={(article) =>
            this.setState({
              selectedArticle: article,
            })
          }
        />
        <h5
          className={`pl-3 w-100 mb-4 ${homestyle.yellowColow} d-flex`}
          style={{ textTransform: "capitalize" }}
        >
          <img
            src="/images/Heart.png"
            style={{ width: "30px", height: "30px", marginRight: ".5em" }}
          />
          Shared Items{" "}
          <div className="ml-4">
            <button
              onClick={() => this.setState({ filter: "All" })}
              className={`btn ${styles.filter_btn} ${
                filter == "All" ? styles.filter_selected : ""
              }`}
            >
              All
            </button>
            <button
              onClick={() => this.setState({ filter: "News" })}
              className={`btn ${styles.filter_btn} ${
                filter == "News" ? styles.filter_selected : ""
              }`}
            >
              News
            </button>
            <button
              onClick={() => this.setState({ filter: "Music" })}
              className={`btn ${styles.filter_btn} ${
                filter == "Music" ? styles.filter_selected : ""
              }`}
            >
              Music
            </button>
            <button
              onClick={() => this.setState({ filter: "Podcast" })}
              className={`btn ${styles.filter_btn} ${
                filter == "Podcast" ? styles.filter_selected : ""
              }`}
            >
              Podcast
            </button>
            <button
              onClick={() => this.setState({ filter: "Shorts" })}
              className={`btn ${styles.filter_btn} ${
                filter == "Shorts" ? styles.filter_selected : ""
              }`}
            >
              Shorts
            </button>
          </div>
        </h5>
        <div className="d-flex flex-wrap">
          {this.state.allItems.map((item) => {
            if (this.state.filter == "All")
              return (
                <CollectionItem
                  type={item.type}
                  data={item}
                  onPlay={() => this.playTrack(item, item.type)}
                  removeLiked={() => this.removeLiked(item)}
                  setSelected={() => this.setSelectedArticle(item, item.type)}
                />
              );
            else if (
              this.state.filter != "All" &&
              item.type.toLowerCase() == this.state.filter.toLowerCase()
            )
              return (
                <CollectionItem
                  type={item.type}
                  data={item}
                  onPlay={() => this.playTrack(item, item.type)}
                  removeLiked={() => this.removeLiked(item)}
                  setSelected={() => this.setSelectedArticle(item, item.type)}
                />
              );
          })}
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CollectionContainer);
