import React, { Component } from "react";
import apiRequest from "../../api/clientRequest";
import PlayIcon from "../../assets/img/play-icon.png";
import HeartIcon from "../../assets/img/favorite-icon.png";
import $ from "jquery";
import { connect } from "react-redux";
import ACTIONS from "../../redux/actions/news";
import player from "../../data/player";
import axios from "axios";
import ShareIcon from "../../assets/img/Share.png";

var mapStateToProps = (state) => {
  return {
    player: state.site.player,
  };
};

var mapDispatchToProps = (dispatch) => ({
  setSharedNews: (item) => dispatch(ACTIONS.setSharedNews(item)),
  setSavedNews: (item) => dispatch(ACTIONS.setSavedNews(item)),
});
class ProfilePage extends Component {
  state = {
    savedArticles: [],
    sharedArticles: [],
  };

  constructor(props) {
    super(props);
    this.player = player();
  }

  componentDidMount() {
    apiRequest({
      url: "/news/fetch",
      method: "GET",
    })
      .then((res) => {
        this.setState({
          savedArticles: res.data.articles,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    apiRequest({
      url: "/news/fetch/share",
      method: "GET",
    })
      .then((res) => {
        this.setState({
          sharedArticles: res.data.articles,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  removeArticle(artid) {
    // const article = this.state.savedArticles.find(item => item.sid == artid);
    // this.setState(prev => ({
    //   savedArticles: prev.state.savedArticles.filter(item => item.sid !== artid)
    // }));
    // article.clicked = false;
    // apiRequest({
    //   url: "/news",
    //   method: "POST",
    //   data: article
    // }).then(res => {});
  }

  playAudio(item) {
    this.player.playAudio(item);
  }

  renderSavedItems() {
    return this.state.savedArticles.map((ite, index) => {
      var item = ite.article;
      return (
        <div className="mx-auto px-2 tab-news mb-3 " key={index}>
          <div className="art-image-container play-btn">
            <img
              onClick={this.playAudio.bind(this, item)}
              src={PlayIcon}
              alt=""
              className="article-play-btn"
            />
          </div>
          <div className="ml-5 article-title-container">
            {" "}
            <p className="article-title">{item.title}</p>
            <div className="meta-section  text-left d-flex">
              <span>{item.source}</span>
              <span className="font-weight-bold ml-auto">#{item.category}</span>
            </div>
          </div>
          <div className="action-icons mt-2">
            <img
              src={ShareIcon}
              // onClick={e => this.shareItem(item)}
              className="mr-3"
            ></img>
            <img
              src={HeartIcon}
              onClick={(e) => this.removeArticle(item)}
              alt=""
            />
          </div>
        </div>
      );
    });
  }

  renderSharedArticles() {
    return this.state.sharedArticles.map((ite, index) => {
      const item = ite.article;
      return (
        <div className="mx-auto px-2 tab-news mb-3 w-75" key={index}>
          <div className="art-image-container play-btn">
            <img
              onClick={this.playAudio.bind(this, item)}
              src={PlayIcon}
              alt=""
              className="article-play-btn"
            />
          </div>
          <div className="ml-5">
            {" "}
            <p className="article-title">{item.title}</p>
            <div className="meta-section  text-left d-flex">
              <span>{item.source}</span>
              <span className="font-weight-bold ml-auto">#{item.category}</span>
            </div>
          </div>

          <div className="action-icons">
            <img
              src={HeartIcon}
              onClick={(e) => this.removeArticle(item)}
              alt=""
            />
          </div>
        </div>
      );
    });
  }
  render() {
    return (
      <div className="page-content">
        <div className="ch-pad">
          <section className="session">
            <h1>Profile</h1>
          </section>
        </div>
        <div className="row text-center mt-5">
          <div className="offset-md-2 col-md-4">
            <h2>Saved Items</h2>
            {this.renderSavedItems()}
          </div>
          <div className="col-md-4">
            <h2>Shared With Me</h2>
            {this.renderSharedArticles()}
          </div>{" "}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
