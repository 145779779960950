import React, { Component, useEffect, useState } from "react";
import apiRequest from "./api/clientRequest";
import FrontLayout from "./containers/FrontLayout.1";
import { withRouter, Route, Switch, useHistory } from "react-router-dom";
import DashboardContainer from "./containers/DashboardContainer";
import OnBoarding from "./components/onboarding/index";
import ACTIONS from "./redux/actions/auth";
import NEWSACTIONS from "./redux/actions/news";
import SITEACTIONS from "./redux/actions/site";
import { connect, useDispatch } from "react-redux";
import RestrictedRoute from "./components/RestrictedRoute";
import ThemSelector from "./components/ThemSelector";
import NetworkDetector from "./components/NetworkDetector";
import Environment from "./api/environment";
import ContentPage from "./pages/dashboard/ContentPage";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useAuth0 } from "@auth0/auth0-react";

const App = () => {
  const dispatch = useDispatch();
  const { loginWithRedirect, user, getAccessTokenSilently, getIdTokenClaims } =
    useAuth0();

  const history = useHistory();
  useEffect(() => {
    if (user) {
      tokens();
      localStorage.setItem("sub", user.sub);
    }
  }, [user]);

  const tokens = async () => {
    const accessToken = await getAccessTokenSilently();
    if (accessToken) {
      localStorage.setItem("authToken", accessToken);
      getData();
    }
  };

  const getData = async () => {
    const theme = window.localStorage.getItem("theme");
    if (theme) {
      dispatch(SITEACTIONS.setTheme(theme));
    } else {
      dispatch(SITEACTIONS.setTheme("black-theme"));
    }

    const token = window.localStorage.getItem("authToken");

    if (token) {
      apiRequest({
        url: "/users/me",
        method: "GET",
      })
        .then((res) => {
          dispatch(ACTIONS.setUser(res.data));
          if (res.data.username) {
              history.push("/dashboard");
          } else {
            history.push("/onboarding");
          }
        })
        .catch((err) => {
          history.push("/");
        });

      const podcasts = await apiRequest({
        url: "/news/fetch/podcast",
        method: "GET",
      }).then((res) => {
        return res.data;
      });
      dispatch(NEWSACTIONS.setPodcast(podcasts.message));
      dispatch(SITEACTIONS.setSubscribedItems(podcasts.subscribedPodcasts));
    }
  };

  return (
    <div>
      <ThemSelector />
      <NetworkDetector
        message="No Connection"
        style={{
          color: "#fff",
          backgroundColor: "#000",
          textAlign: "center",
        }}
      />
      <Switch>
        {/* <Route path='/content/:emailid/:userid' component={ContentPage} /> */}
        <Route
          path="/dashboard"
          component={withAuthenticationRequired(DashboardContainer, {
            onRedirecting: () => <div>Loading...</div>,
          })}
        />
        <Route path="/onboarding/:source?" component={OnBoarding} />
        <Route path="/" component={FrontLayout} />
      </Switch>
    </div>
  );
};

export default App;
