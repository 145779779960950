import React, { Component } from "react";
import { Icon, Input, AutoComplete, Button } from "antd";
import apiRequest from "../api/clientRequest";
import { connect } from "react-redux";
const { Option } = AutoComplete;

const mapStateToProps = (state) => ({
  teams: state.team.teams,
});

function renderOption(item) {
  return (
    <Option key={item.id} text={item.name}>
      <div className="global-search-item d-flex justify-content-center align-items-center">
        <img src={item.avatar} alt="" />
        <div className="d-flex justify-content-between align-items-center name-container">
          <h4 style={{ color: "black" }}>{item.fullname}</h4>
          <button className="btn btn-black">Select</button>
        </div>{" "}
      </div>
    </Option>
  );
}

class UserSearch extends Component {
  state = {
    selectedUsers: [...this.props.selectedUsers],
    dataSource: [],
  };

  componentWillReceiveProps(nextProp) {
    console.log(nextProp);
    if (nextProp.selectedUsers.length !== this.state.selectedUsers.length) {
      this.setState({ selectedUsers: nextProp.selectedUsers });
    }
  }

  async onSelect(value) {
    const selectedItem = this.state.searchResults.find(
      (item) => item.id == value
    );
    if (
      this.state.selectedUsers.filter((item) => item.id === value).length == 0
    )
      this.props.onSelect([...this.state.selectedUsers, selectedItem]);
  }
  async handleSearch(value) {
    this.setState({
      dataSource: value
        ? await this.searchResult(value).then((res) => res)
        : [],
    });
  }
  async searchResult(query) {
    var teams = [];
    if (this.props.teamSearch) {
      teams = this.props.teams.filter((item) =>
        item.name.toLowerCase().includes(query.toLowerCase())
      );
      teams = teams.map((item) => {
        return {
          ...item,
          avatar: item.logo,
          fullname: item.name,
          type: "team",
        };
      });
    }
    const users = await apiRequest({
      url: "/users/get-users",
      method: "post",
      data: { query: query },
    }).then((res) => {
      var userslist = res.data.map((item) => {
        return {
          ...item,
          type: "user",
        };
      });
      this.setState({
        searchResults: [...userslist, ...teams],
      });
      return [...userslist, ...teams];
    });

    return users;
  }

  render() {
    const { dataSource } = this.state;

    return (
      <AutoComplete
        className="global-search mt-2"
        size="large"
        style={{ width: "100%" }}
        dataSource={dataSource.map(renderOption)}
        placeholder="Search name of person to invite"
        optionLabelProp="text"
        onSearch={this.handleSearch.bind(this)}
        onSelect={this.onSelect.bind(this)}
      >
        <Input
          suffix={
            <Button
              className="search-btn"
              style={{ marginRight: -12 }}
              size="large"
              type="primary"
            >
              <Icon type="search" />
            </Button>
          }
        />
      </AutoComplete>
    );
  }
}

export default connect(mapStateToProps, null)(UserSearch);
