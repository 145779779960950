import React from "react";
import styles from "./commentcontainer.module.css";
import CommentItem from "./CommentItem";
import ScrollContainer from "react-indiana-drag-scroll";
import apiRequest from "../../api/clientRequest";
import { CloseOutline } from "@ant-design/icons";
const _ = require("underscore");

export default function CommentContainer(props) {
  const {
    id,
    reactions: { comment },
  } = props.activity;
  const [commentText, setCommentText] = React.useState("");
  const addComment = () => {
    apiRequest({
      method: "GET",
      url: `/social/comment/${id}/${props.userId}/${commentText}`,
    }).then((res) => props.addNewComment(res.data));
    setCommentText("");
  };
  const allComments = _.chunk(comment, 5);
  return (
    <div className={styles.commentContainer}>
      <div className="row h-100">
        <div className={styles.closeBtn}>
          <img
            src="/images/closeCircleOutlined.svg"
            alt=""
            onClick={() => props.onClose()}
          />
          <h1>Reactions</h1>
        </div>

        <div className={styles.leftSideContainer}>
          <div className={`d-flex align-items-center ${styles.inputContainer}`}>
            <img src={props.userAvatar} className={styles.logo} alt="" />
            <textarea
              type="text"
              rows={5}
              value={commentText}
              onKeyDown={(e) => (e.key == "Enter" ? addComment() : "")}
              onChange={(e) => setCommentText(e.target.value)}
            />
          </div>
        </div>
        <ScrollContainer
          style={{ overflowX: "scroll" }}
          className={`d-flex ${styles.rightSideContainer}`}
        >
          {allComments.map((comments) => (
            <div>
              {comments.map((item) => (
                <CommentItem data={item} />
              ))}
            </div>
          ))}
        </ScrollContainer>
      </div>
    </div>
  );
}
