import React, { Component } from "react";
import contentStyle from "./styles/contentstyle.module.css";
import styles from "./styles/nowPlaying.module.css";
import player from "../data/player";
import { connect } from "react-redux";
import ShareModal from "./shareModal";
import ShareMethods from "./ShareMethods";
import FavoriteFeedModal from "./FavoriteFeedModal";
import podstyles from "./styles/podcastInfo.module.css";
import YouTube from "react-youtube";

var mapStateToProps = (state) => {
  return {
    queue: state.site.queue,
    savedItems: state.site.savedItems,
    completedPodcast: state.site.completedPodcast,
    podcasts: state.news.podcasts,
  };
};

class VideoQueueCard extends Component {
  constructor(props) {
    super(props);
    this.container = React.createRef();
    this.state = {
      likeHover: false,
      showShareHover: false,
      youtubePlayer: null,
      modalVisible: false,
      shareMode: "",
      selectedArticle: null,
    };
    this.Player = player();
  }

  componentDidMount() {
    window.addEventListener("message", this.receiveMessage);
    const { youtubePlayer } = this.state;
    const props = this.props;
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.receiveMessage);
    clearInterval(this.videoInterval);
  }

  receiveMessage = (e) => {
    const { youtubePlayer } = this.state;
    let props = this.props;
    if (props.queue.currentPlayed.id == props.data.id) {
      if (e.data.type == "toggleVideo") {
        if (props.queue.currentPlayed.playing) youtubePlayer.pauseVideo();
        else youtubePlayer.playVideo();
      } else if (e.data.type == "setVideoPosition") {
        const trackPosition = e.data.value * youtubePlayer.getDuration();
        youtubePlayer.seekTo(trackPosition);
      }
    }
  };

  _onReady = (event) => {
    const youtubePlayer = event.target;
    const props = this.props;
    this.setState({ youtubePlayer });

    this.videoInterval = setInterval(() => {
      if (
        props.queue.currentPlayed.id == props.data.id &&
        youtubePlayer &&
        props.queue.currentPlayed.playing
      ) {
        this.Player.setVideoTimer(
          youtubePlayer.getCurrentTime(),
          youtubePlayer.getDuration()
        );
      }
    }, 1000);
  };

  onEnd = () => {
    this.Player.videoEnded(this.props.data);
  };

  setSelectArticle = (mode, item) => {
    this.setState({
      ShareModal: mode,
      selectedArticle: item,
      modalVisible: true,
    });
  };

  render() {
    const {
      likeHover,
      showShareHover,
      youtubePlayer,
      modalVisible,
      shareMode,
      selectedArticle,
    } = this.state;
    const props = this.props;
    if (props.queue.currentPlayed)
      if (props.queue.currentPlayed.id == props.data.id)
        if (this.container && this.container.current) {
          setTimeout(() => {
            if (this.container && this.container.current)
              this.container.current.scrollIntoView({
                behavior: "smooth",
                block: props.isQueue ? "center" : "start",
              });
          }, 1000);
        }

    const playerOptions = {
      height: "100%",
      width: "100%",
      playerVars: {
        autoplay: 1,
        controls: 0,
        showInfo: 0,
        autoplay: 1,
        disablekb: 1,
        rel: 0,
        modestbranding: 1,
        iv_load_policy: 3,
      },
    };

    return (
      <div
        ref={this.container}
        className={`${contentStyle.playingContainer} ${
          props.activeActivity && props.activeActivity.id !== props.data.id
            ? "d-none"
            : ""
        }  ${
          props.queue.currentPlayed &&
          props.queue.currentPlayed.id == props.data.id
            ? ""
            : contentStyle.playingSub
        }`}
        style={{
          backgroundImage: `url(${props.data.image})`,
        }}
        onMouseLeave={() => {
          this.setState({ showShareHover: false, likeHover: false });
        }}
      >
        <ShareModal
          visible={modalVisible}
          setVisiblity={(cond) => this.setState({ modalVisible: cond })}
          selectedItem={selectedArticle}
          type={"video"}
          mode={shareMode}
        />

        <div
          className={contentStyle.imageOverlay}
          style={{ zIndex: "99999999" }}
        ></div>

        <div class={contentStyle.videoBackground}>
          {props.queue.currentPlayed["id"] &&
          props.queue.currentPlayed.id == props.data.id ? (
            <YouTube
              videoId={props.data.id}
              opts={playerOptions}
              onReady={this._onReady}
              onEnd={this.onEnd}
            />
          ) : (
            ""
          )}
        </div>

        <div
          className={
            showShareHover || likeHover ? contentStyle.itemHovered : ""
          }
        >
          <div
            className={`${
              showShareHover || likeHover ? "d-none" : "d-flex"
            }  justify-content-end align-items-center mb-2`}
          >
            <img
              src="/images/youtubeWhiteIcon.png"
              className={styles.expandIcon}
              style={{ width: "25px", height: "25px", marginRight: "10px" }}
            />
            <span
              className={`name mt-1 ${
                showShareHover || likeHover ? "d-none" : "d-flex"
              }`}
              style={{ textTransform: "capitalize", fontSize: "1.3em" }}
            >
              Youtube
            </span>
          </div>
          <h3 className={`${showShareHover || likeHover ? "d-none" : ""}`}>
            {props.data.title}
          </h3>
          <div
            className={`${
              showShareHover || likeHover ? "d-none" : "d-flex"
            } align-items-center mb-3 mt-2 sourceContainer`}
          >
            {props.data.sourceImg ? (
              <img
                src={props.data.sourceImg}
                style={{ width: "25px", height: "25px", marginRight: "10px" }}
              />
            ) : (
              ""
            )}
            <span className={`${styles.source} name mt-1`}>
              {props.data.source}
            </span>
          </div>

          <div className="">
            {props.queue.currentPlayed["id"] &&
            props.queue.currentPlayed.id == props.data.id ? (
              props.queue.currentPlayed.playing ? (
                <img
                  src="/images/pause.png"
                  className={`${styles.playIcon} `}
                  alt=""
                  onClick={() => {
                    youtubePlayer.pauseVideo();

                    this.Player.playQueue(props.data);
                  }}
                />
              ) : (
                <img
                  src="/images/playIcon.png"
                  className={`${styles.playIcon} `}
                  alt=""
                  onClick={() => {
                    youtubePlayer.playVideo();
                    this.Player.playQueue(props.data);
                  }}
                />
              )
            ) : (
              <img
                src="/images/playIcon.png"
                className={`${styles.playIcon} `}
                alt=""
                onClick={() => {
                  youtubePlayer.playVideo();
                  if (props.isQueue) this.Player.playQueue(props.data);
                  else this.Player.playVideo(props.data);
                }}
              />
            )}

            <div
              className="d-flex"
              style={{
                position: "absolute",
                right: "0",
                bottom: "0",
              }}
            >
              <div className="">
                <img
                  src="/images/messageIcon.png"
                  alt=""
                  className={styles.actionBtn}
                  onClick={() => props.showComments()}
                />

                {props.data.reaction_counts
                  ? props.data.reaction_counts["comment"]
                  : ""}
              </div>
              <div className="">
                <img
                  src="/images/moreIcon.png"
                  alt=""
                  className={styles.actionBtn}
                  onClick={() => props.showDetail()}
                />
              </div>
              <div className="">
                <img
                  src="/images/cluster.png"
                  alt=""
                  onMouseEnter={() => this.setState({ showShareHover: 1 })}
                  className={styles.actionBtn}
                />
              </div>
              <div className="">
                {props.savedItems.find(
                  (ite) => ite.track_id == props.data.id
                ) ? (
                  <img
                    src="/images/HeartFill.png"
                    className={styles.actionBtn}
                    onClick={() => props.saveItem(props.data)}
                    onMouseEnter={() => this.setState({ likeHover: 1 })}
                    alt=""
                  />
                ) : (
                  <img
                    src="/images/Heart.png"
                    className={styles.actionBtn}
                    onClick={() => props.saveItem(props.data)}
                    onMouseEnter={() => this.setState({ likeHover: 1 })}
                    alt=""
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <ShareMethods
          id={1}
          showHover={showShareHover}
          setShowHover={() => this.setState({ showShareHover: false })}
          setSelected={(mode) => this.setSelectArticle(mode, this.props.data)}
          type="video"
        />
        <FavoriteFeedModal
          id={1}
          visible={likeHover}
          setHover={() => this.setState({ likeHover: false })}
          saveToCollection={() => props.saveItem(this.props.data)}
          selectedArticle={this.props.data}
          savedItems={this.props.savedItems}
          type="video"
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, null)(VideoQueueCard);
