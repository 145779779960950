import React, { Component } from "react";
import styles from "./styles/shorts.module.css";
import playstyles from "./styles/playlist.module.css";
import ACTIONS from "../redux/actions/site";
import TEAMACTIONS from "../redux/actions/team";
import { connect } from "react-redux";
import { Modal, Button, message } from "antd";
import modalstyles from "./styles/sharemodal.module.css";
import mainstyle from "./styles/addTeam.module.css";
import apiRequest from "../api/clientRequest";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import Suggestionitem from "./SuggestionItem";
import UserImage from "./UserImage";
import UserSearch from "./UserSearch";

var mapStateToProps = (state) => {
  return {
    savedItems: state.site.savedItems,
    theme: state.site.theme,
    recentUsers: state.site.recentUsers,
    teams: state.team.teams,
  };
};
var mapDispatchToProps = (dispatch) => ({
  setSavedItems: (items) => dispatch(ACTIONS.setSavedItems(items)),
  setTeam: (item) => dispatch(TEAMACTIONS.setTeams(item)),
  setMeter: (items) => dispatch(ACTIONS.setMeter(items)),
  setMeterProgress: (items) => dispatch(ACTIONS.setMeterProgress(items)),
  setMeterTitle: (items) => dispatch(ACTIONS.setMeterTitle(items)),
});

class AddTeam extends Component {
  state = {
    modalVisible: false,
    dataSource: [],
    selectedUsers: [],
    emails: [],
    teamName: "",
    teamDescription: "",
    teamLogo: null,
    logoDisplay: null,
    siteUrl: "",
  };

  handleOk = async () => {
    this.resetState();
  };

  createTeam = () => {
    const config = {
      headers: {
        "Content-Type": `multipart/form-data;`,
      },
    };
    this.props.setMeterTitle("Creating Team");
    this.props.setMeter(true);
    this.props.setMeterProgress("25");
    const formData = new FormData();
    formData.append("name", this.state.teamName);
    formData.append("description", this.state.teamDescription);
    formData.append("avatar", this.state.teamLogo);
    formData.append("users", JSON.stringify(this.state.selectedUsers));
    formData.append("emails", JSON.stringify(this.state.emails));
    formData.append("detailUrl", this.state.siteUrl);

    apiRequest({
      url: "/teams",
      method: "POST",
      data: formData,
      config,
    }).then((res) => {
      this.props.setMeterTitle("Team Created");
      this.props.setMeterProgress("100");

      setTimeout(() => {
        this.props.setMeter(false);
      }, 1000);

      if (res.data.status == 200) {
        this.resetState();
        this.props.setTeam([...this.props.teams, res.data.team]);
      } else message.error(res.data.msg);
    });
  };

  handleLogoChange(files) {
    if (files.length > 0)
      this.setState({
        teamLogo: files[0],
        logoDisplay: URL.createObjectURL(files[0]),
      });
  }

  resetState() {
    this.setState({
      teamName: "",
      teamLogo: null,
      selectedUsers: [],
      modalVisible: false,
    });
  }

  render() {
    const { dataSource } = this.state;
    return (
      <div
        style={{ cursor: "pointer" }}
        // onClick={() => this.props.handleClick(id)}
        className={`currency-box ${styles.shortItem} `}
      >
        <div
          className={`${playstyles.playlistItem}  currency-pair   `}
          style={{ background: "#161616" }}
        >
          <div
            className={`cat-content text-center`}
            style={{ cursor: "pointer" }}
            onClick={() => this.setState({ modalVisible: true })}
          >
            <img
              src="/images/plusNewIcon.png"
              style={{
                width: "55px",
                marginTop: "2.5em",
              }}
            />
            <h2
              style={{ fontSize: "1.5em", marginTop: "0.9em", opacity: "0.55" }}
            >
              Add Team
            </h2>
          </div>
        </div>

        <Modal
          visible={this.state.modalVisible}
          title="Create Team"
          onOk={this.handleOk}
          footer={[
            <Button key="back" onClick={this.handleOk}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" onClick={this.handleOk}>
              Share
            </Button>,
          ]}
          className={`${modalstyles.shareEmail} teamModal shareEmail share-modal ${this.props.theme}`}
          onCancel={this.handleOk}
        >
          <div className="p-3">
            <div className="d-flex justify-content-between">
              <h2 style={{ color: "#b8c0c9" }}>Create Team</h2>
              <i
                class={`${modalstyles.crossIcon} far fa-times-circle`}
                style={{ cursor: "pointer" }}
                onClick={() => this.resetState()}
              ></i>
            </div>
            <div className="row">
              <div className="col-md-5">
                <div className="px-4 mt-5">
                  <h5 className={mainstyle.inputMainLabel}>
                    Step 1: &nbsp; Assign Team Name
                  </h5>
                  <div className={mainstyle.inputContainer}>
                    <input
                      type="text"
                      className={` ${mainstyle.input}`}
                      placeholder="Team Name"
                      onChange={(e) =>
                        this.setState({ teamName: e.target.value })
                      }
                      value={this.state.teamName}
                    />
                  </div>
                </div>
                <div className="px-4 mt-5">
                  <h5 className={mainstyle.inputMainLabel}>
                    Step 4: &nbsp; Invite Team Members
                  </h5>
                  <h6 className="mb-3">SUGGESTIONS</h6>
                  <div style={{ maxHeight: "210px", overflow: "auto" }}>
                    {this.props.recentUsers.map((item) => (
                      <Suggestionitem
                        data={item}
                        InviteMember={(item) => {
                          if (
                            !this.state.selectedUsers.find(
                              (ite) => ite.id == item.id
                            )
                          )
                            this.setState((prev) => ({
                              selectedUsers: [...prev.selectedUsers, item],
                            }));
                        }}
                      />
                    ))}
                  </div>
                </div>
                <div className="px-4 mt-5">
                  <h5 className={mainstyle.inputMainLabel}>Invited Members</h5>
                  <div className="d-flex">
                    {this.state.selectedUsers.map((item) => (
                      <UserImage avatar={item.avatar} name={item.fullname} />
                    ))}
                  </div>
                </div>
              </div>

              <div className="col-md-2 p-0">
                <div className="mt-5">
                  <h5 className={mainstyle.inputMainLabel}>
                    Step 2: &nbsp; Upload Logo
                  </h5>
                  <div
                    style={{ cursor: "pointer" }}
                    // onClick={() => this.props.handleClick(id)}
                    className={`currency-box  ${mainstyle.imagePickContainer}`}
                  >
                    <div
                      className={`${playstyles.playlistItem}  currency-pair   `}
                      style={{
                        background: "#393939",
                        backgroundSize: "cover",
                        backgroundImage: this.state.logoDisplay
                          ? `url(${this.state.logoDisplay})`
                          : "",
                      }}
                    >
                      <div
                        className={`cat-content text-center`}
                        style={{ cursor: "pointer" }}
                      >
                        <div>
                          <img
                            src="/images/plusNewIcon.png"
                            style={{
                              width: "55px",
                              marginTop: "1em",
                            }}
                          />
                          <input
                            onChange={(e) =>
                              this.handleLogoChange(e.target.files)
                            }
                            type="file"
                            name=""
                            id=""
                            className={mainstyle.imagePicker}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="px-4 mt-5">
                  <h5 className={mainstyle.inputMainLabel}>
                    Step 3: &nbsp; Add Description
                  </h5>
                  <div className={mainstyle.inputContainer}>
                    <textarea
                      type="text"
                      className={` ${mainstyle.input}`}
                      placeholder="Team Description"
                      rows="5"
                      style={{ resize: "none", height: "auto" }}
                      onChange={(e) =>
                        this.setState({ teamDescription: e.target.value })
                      }
                      value={this.state.teamDescription}
                    />
                  </div>
                </div>

                <div className="px-4 " style={{ marginTop: "7.1em" }}>
                  <h6 className={` mb-3`}>SEARCH</h6>
                  <div className={mainstyle.inputContainer}>
                    <UserSearch
                      onSelect={(users) =>
                        this.setState({ selectedUsers: users })
                      }
                      selectedUsers={this.state.selectedUsers}
                    />
                  </div>

                  <h6 className={`mt-4 mb-3`}>Site URL</h6>
                  <div className={mainstyle.inputContainer}>
                    <input
                      type="text"
                      className={` ${mainstyle.input}`}
                      placeholder="Team Detail url"
                      onChange={(e) =>
                        this.setState({ siteUrl: e.target.value })
                      }
                      value={this.state.siteUrl}
                    />
                  </div>

                  <h6 className={`mt-4 mb-3`}>INVITE BY EMAIL</h6>
                  <div className={mainstyle.inputContainer}>
                    {/* <input
                      type="text"
                      placeholder="Add email Addresses here"
                    /> */}
                    <ReactMultiEmail
                      placeholder="Add email Addresses here"
                      emails={this.state.emails}
                      className="w-100"
                      onChange={(_emails) => {
                        this.setState({ emails: _emails });
                      }}
                      validateEmail={(email) => {
                        return isEmail(email); // return boolean
                      }}
                      getLabel={(email, index, removeEmail) => {
                        return (
                          <div data-tag key={index}>
                            {email}
                            <span
                              data-tag-handle
                              onClick={() => removeEmail(index)}
                            >
                              ×
                            </span>
                          </div>
                        );
                      }}
                    />
                  </div>
                </div>
                <button
                  onClick={() => this.createTeam()}
                  className={`btn btn-primary ${mainstyle.addBtn}`}
                >
                  Add Team
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddTeam);
