import React, { Component } from "react";
import CategoryNav from "../CategoryNav";
import NewsTable from "../NewsTable";
import ACTIONS from "../../redux/actions/news";
import SITEACTIONS from "../../redux/actions/site";
import player from "../../data/player";
import { connect } from "react-redux";
import axios from "axios";
import SpotifyLogin from "../SpotifyLogin";
import QueueList from "../QueueList";
import MusicContainer from "../MusicContainer";
import MusicItem from "../MusicItem";
import colors from "../../data/colors.json";
import ScrollContainer from "react-indiana-drag-scroll";
import MainSlider from "../MainSlider";

var mapStateToProps = (state) => {
  return {
    musicList: state.site.musicList,
    spotifyLog: state.site.spotifyLog,
  };
};
var mapDispatchToProps = (dispatch) => ({});

var mapDispatchToProps = (dispatch) => ({
  setTags: (item) => dispatch(ACTIONS.setTags(item)),
  setFeed: (item) => dispatch(ACTIONS.setFeed(item)),
  setFeedlyUser: (item) => dispatch(ACTIONS.setFeedlyUser(item)),
  setPlaylists: (item) => dispatch(ACTIONS.setPlaylists(item)),
  setMusicList: (item) => dispatch(SITEACTIONS.setMusicList(item)),
  spotifyFetchList: (item) => dispatch(SITEACTIONS.spotifyFetchList(item)),
});

class NewsBoard extends Component {
  state = {
    spotifyToken: window.localStorage.getItem("spotify_token"),
    musicPlaylist: this.props.musicList,
  };

  componentDidMount() {
    const mainPage = document.querySelector(".main-page");
    mainPage.addEventListener("scroll", this.onScroll);
    mainPage.classList.add("main-page-padding");
  }
  componentWillUnmount() {
    const mainPage = document.querySelector(".main-page");
    mainPage.removeEventListener("scroll", this.onScroll);
  }

  onScroll = () => {
    const mainPage = document.querySelector(".main-page");
    const mainSlider = document.querySelector(".musicBoard .mainSlider");
    mainSlider.style.opacity = 1 - mainPage.scrollTop / 500;
  };

  player = player();

  componentWillMount() {
    this.fetchMusicList(this.state.spotifyToken);
  }

  componentWillReceiveProps(nextProp) {
    if (nextProp.spotifyLog.fetch) {
      this.props.spotifyFetchList({ fetch: false, token: false });
      this.fetchMusicList(nextProp.spotifyLog.token);
    }
  }

  playSpotifyList(item, action = false) {
    this.player.playSpotifyList(item, action);
  }

  playSpotifyTrack(item) {
    this.player.playSpotifyTrack(item);
  }

  millisToMinutesAndSeconds(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
  }

  async fetchMusicList(token) {
    let that = this;
    const spotToken = token;
    if (!spotToken) return;
    await axios
      .get("https://api.spotify.com/v1/me/playlists?limit=50", {
        headers: { Authorization: `Bearer ${spotToken}` },
      })
      .then(async (res) => {
        var spotifyList = [];
        await Promise.all(
          res.data.items.map(async (playlist) => {
            return await axios
              .get(playlist.tracks.href, {
                headers: { Authorization: `Bearer ${spotToken}` },
              })
              .then(async (response) => {
                const playlistData = await axios
                  .get(`https://api.spotify.com/v1/playlists/${playlist.id}`, {
                    headers: { Authorization: `Bearer ${spotToken}` },
                  })
                  .then((res) => res.data);

                var tracks = [];
                let trackdata = response.data;
                trackdata.items.map((item) => {
                  let trackimage = "";
                  if (item.track == null) return;
                  if (item.track.album)
                    if (item.track.album.images.length > 0)
                      trackimage = item.track.album.images[0].url;
                    else trackimage = "";
                  else if (item.track.images)
                    if (item.track.images.length > 0)
                      trackimage = item.track.images[0].url;
                    else trackimage = "";

                  let trackartist = "";

                  if (item.track.artists)
                    trackartist =
                      item.track.artists.length > 0
                        ? ` : ${item.track.artists[0].name}`
                        : "";
                  const tracktitle = item.track.name;
                  tracks.push({
                    href: item.track.href,
                    url: item.track.uri,
                    title: tracktitle + trackartist,
                    id: item.track.id,
                    image: trackimage,
                    duration: that.millisToMinutesAndSeconds(
                      item.track.duration_ms
                    ),
                    album: item.track.album ? item.track.album.name : "",
                  });
                });
                var playitem = {
                  href: playlist.href,
                  description: playlist.description,
                  playlistid: playlist.id,
                  tracks: tracks,
                  followers: playlistData.followers.total,
                  title: playlist.name,
                  owner: playlist.owner.display_name,
                  image:
                    playlist.images.length > 0 ? playlist.images[0]["url"] : "",
                };

                spotifyList.push(playitem);
              });
          })
        );
        that.props.setMusicList(spotifyList);
      });
  }

  render() {
    let sliderTracks = [];

    if (this.props.musicList.length > 0) {
      let playlist = this.props.musicList[
        Math.floor(Math.random() * this.props.musicList.length)
      ];

      sliderTracks = playlist.tracks.map((item) => {
        return {
          ...item,
          source: item.album,
          type: "music",
        };
      });
      sliderTracks = sliderTracks.filter((item) => item.image);
    }

    return (
      <div
        className="col-md-9 musicBoard"
        style={{ display: this.props.style, paddingTop: "430px" }}
      >
        <MainSlider items={sliderTracks} type="Music" />
        <div className="">
          <div className="row scroll pl-3 pr-0 mx-0 w-100">
            <div className="col-md-11 pr-5 mb-2">
              <MusicContainer />
            </div>
            <h5 className="pl-3 w-100">Music Playlists</h5>
            <div className="row row-eq-height mx-0 w-100">
              {this.props.musicList.map((item) => (
                <MusicItem
                  data={item}
                  playSpotifyList={this.playSpotifyList.bind(this)}
                  playSpotifyTrack={this.playSpotifyTrack.bind(this)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(NewsBoard);
