import React, { Component } from "react";
import Upload from "antd/lib/upload";
import Icon from "antd/lib/icon";
import message from "antd/lib/message";
import apiRequest from "../../api/clientRequest";
import styles from "../styles/onboarding.module.css";

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};
export default class step2 extends Component {
  state = {
    loading: false,
    username: "",
    avatar: ""
  };

  handleChange = info => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      this.getBase64(info.file.originFileObj, imageUrl => {
        this.setState({
          imageUrl,
          loading: false,
          avatar: info.file.originFileObj
        });
      });
    }
  };
  getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  handleSubmit(e) {
    e.preventDefault();
    if (!this.state.username) message.error("Please Enter a username");
    else {
      apiRequest({
        url: "/auth/username/verify",
        method: "POST",
        data: { username: this.state.username }
      })
        .then(res => {
          console.log(res);
          this.props.submitHandle({
            username: this.state.username,
            avatar: this.state.avatar
          });
        })
        .catch(err => {
          message.error(err.data.message);
        });
    }
  }

  render() {
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? "loading" : "plus"} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    const { imageUrl } = this.state;
    const { bindSubmitForm, submitHandle } = this.props;

    return (
      <div className={styles.stepContainer}>
        <div className="row">
          <div className={`col-md-3 ${styles.leftSide}`}>
            <h2 className={styles.secondHeading}>
              Please select an avatar for your profile
            </h2>
            {this.props.source ? (
              ""
            ) : (
              <div className="avat-upload mx-auto" style={{ width: "110px" }}>
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  onChange={this.handleChange}
                  customRequest={dummyRequest}
                >
                  {imageUrl ? (
                    <img
                      src={imageUrl}
                      alt="avatar"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </div>
            )}
            <h2 className={styles.secondHeading}>
              {" "}
              Please select a username for your profile
            </h2>
            <input
              className="form-control"
              style={{ width: "450px" }}
              type="text"
              placeholder="Username"
              value={this.state.username}
              onChange={e => this.setState({ username: e.target.value })}
            />
          </div>

          <button
            ref={fr => {
              bindSubmitForm(fr);
            }}
            onClick={this.handleSubmit.bind(this)}
            type="submit"
            className="d-none btn"
          >
            click me
          </button>
        </div>
      </div>
    );
  }
}
