import React, { Component } from "react";
import styles from "./styles/playlist.module.css";
import { expandIcon } from "./styles/newsitem.module.css";
import { connect } from "react-redux";
import colors from "../data/colors.json";
const mapStateToProps = (state) => ({
  musicList: state.site.musicList,
  theme: state.site.theme,
});

const playlists = [
  "All",
  "Made for me",
  "Recently Played",
  "Liked Songs",
  "Browse",
  "All Latest",
];

class PodcastContainer extends Component {
  render() {
    return (
      <div className={styles.container}>
        <div className={styles.itemContainer}>
          {playlists.map((item) => {
            var color = false;
            if (this.props.theme == "color-theme")
              color = colors[Math.floor(Math.random() * colors.length)];

            return (
              <div
                className={`${styles.playlistItem} playlistItem`}
                style={{ backgroundColor: color }}
              >
                <p className={`${styles.playlistName} text-left `}>{item}</p>

                <div className={styles.itemInfo}>
                  <div
                    className={styles.articleSideInfo}
                    style={{ left: "12px" }}
                  >
                    <img
                      src="/images/block-menu.png"
                      className={`${expandIcon} `}
                      //   onClick={() => this.props.selectPodcastTag(item.genre)}
                      alt=""
                      style={{ position: "absolute", bottom: "21px" }}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, null)(PodcastContainer);
