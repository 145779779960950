import React, { Component } from "react";
import styles from "./styles/nowPlaying.module.css";
import { connect } from "react-redux";
import player from "../data/player";

const mapStateToProps = (state) => ({
  data: state.team.emailExpanded,
});

class EmailView extends Component {
  state = {
    shouldDisplay: true,
    contentOverflow: false,
    type: "none",
    activeTrack: null,
    backgroundImage: null,
    trackPlaying: false,
  };

  contentElement = null;
  player = player();
  

  render() {
    if (!this.props.data) return <></>;

    const data = JSON.parse(this.props.data.media);

    const TotalEmails = [
      ...new Map(
        this.props.data.events.map((item) => [item["recipient"], item])
      ).values(),
    ];
    return (
      <div className={`${styles.nowPlaying} nowPlaying`}>
        <div className={styles.rightGradient} />
        <div
          className={`${styles.rightImage} sidebarImage`}
          style={{ backgroundImage: `url(${data.image})` }}
        />

        <div
          className={`${styles.content} ${styles.podcastContent}`}
          style={{ paddingLeft: "25px" }}
        >
          <>
            <div className="w-100 ">
              <h1
                className={`${styles.title} ${styles.podcastHead} mt-4`}
                style={{ flex: "none", marginRight: 20 }}
              >
                <img
                  src="/images/feedlyWhite.png"
                  className={styles.typeImage}
                />
                Email Campaign
              </h1>
              <div
                style={{
                  marginTop: "7em",
                  display: "flex",
                  justifyContent: "space-around",
                  flexWrap: "wrap",
                  padding: "0 5em",
                }}
              >
                <div className="mt-3" style={{ flex: "0 50%" }}>
                  <h3 className={styles.emailItemTitle}>Total Messages Sent</h3>
                  <h4 className={styles.emailItemNum}>{TotalEmails.length}</h4>
                </div>
                <div className="mt-3 text-right" style={{ flex: "0 50%" }}>
                  <h3 className={styles.emailItemTitle}>Failed</h3>
                  <h4 className={styles.emailItemNum}>
                    {
                      this.props.data.events.filter(
                        (item) => item.event == "failed"
                      ).length
                    }
                  </h4>
                </div>

                <div className="mt-3" style={{ flex: "0 50%" }}>
                  <h3 className={styles.emailItemTitle}>Delivered</h3>
                  <h4 className={styles.emailItemNum}>
                    {" "}
                    {
                      this.props.data.events.filter(
                        (item) => item.event == "delivered"
                      ).length
                    }
                  </h4>
                </div>

                <div className="mt-3 text-right" style={{ flex: "0 50%" }}>
                  <h3 className={styles.emailItemTitle}>Accepted</h3>
                  <h4 className={styles.emailItemNum}>
                    {" "}
                    {
                      this.props.data.events.filter(
                        (item) => item.event == "accepted"
                      ).length
                    }
                  </h4>
                </div>

                <div className="mt-3" style={{ flex: "0 50%" }}>
                  <h3 className={styles.emailItemTitle}>Opened</h3>
                  <h4 className={styles.emailItemNum}>
                    {" "}
                    {
                      this.props.data.events.filter(
                        (item) => item.event == "opened"
                      ).length
                    }
                  </h4>
                </div>

                <div className="mt-3 text-right" style={{ flex: "0 50%" }}>
                  <h3 className={styles.emailItemTitle}>Clicked</h3>
                  <h4 className={styles.emailItemNum}>
                    {
                      this.props.data.events.filter(
                        (item) => item.event == "clicked"
                      ).length
                    }
                  </h4>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, null)(EmailView);
