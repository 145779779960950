import ACTIONS from "../actions/team";
import _ from "lodash";
import player from "../../data/player";

const playerInstance = player();
var defaultState = {
  teams: [],
  teamtemplates: [],
  selectedTeam: null,
  tab: 0,
  emailTab: 0,
  emailExpanded: false,
  groupSelected: null,
};

var siteReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ACTIONS.Types.SET_CAMPAIGN_ITEMS: {
      let newState = _.cloneDeep(state);
      const { items, campId } = action.payload;
      const teams = [...newState.teams];
      const teamFoundIndex = teams.findIndex((item) =>
        item.campaigns.find((ite) => ite._id == campId)
      );
      teams[teamFoundIndex]["campaigns"] = teams[teamFoundIndex][
        "campaigns"
      ].map((item) => {
        if (item._id == campId) {
          item["items"] = items;
        }
        return item;
      });

      newState.teams = teams;

      if (
        newState.selectedTeam &&
        newState.selectedTeam._id == teams[teamFoundIndex]["_id"]
      ) {
        newState.selectedTeam = teams[teamFoundIndex];
      }
      return newState;
    }

    case ACTIONS.Types.SET_GROUP_SELECTED: {
      let newState = _.cloneDeep(state);
      newState.groupSelected = action.payload;
      return newState;
    }

    case ACTIONS.Types.SET_TEAM: {
      let newState = _.cloneDeep(state);
      newState.teams = action.payload;
      return newState;
    }
    case ACTIONS.Types.SET_TEAM_TEMPLATE: {
      let newState = _.cloneDeep(state);
      newState.teamtemplates = action.payload;
      return newState;
    }
    case ACTIONS.Types.SET_SELECTED_TEAM: {
      let newState = _.cloneDeep(state);
      newState.selectedTeam = action.payload;
      return newState;
    }
    case ACTIONS.Types.SET_TEAM_TAB: {
      let newState = _.cloneDeep(state);
      newState.tab = action.payload;
      return newState;
    }
    case ACTIONS.Types.SET_EMAIL_TAB: {
      let newState = _.cloneDeep(state);
      newState.emailTab = action.payload;
      return newState;
    }
    case ACTIONS.Types.SET_EMAIL_EXPAND: {
      let newState = _.cloneDeep(state);
      newState.emailExpanded = action.payload;
      return newState;
    }

    default:
      return state;
  }
};
export default siteReducer;
