import React, { Component } from "react";
import styles from "../styles/onboarding.module.css";

var dataset = {
  children: [
    { name: "Rock" },
    { name: "Dance" },
    { name: "Melody" },
    { name: "Mid" },
    { name: "Electrifying" },
    { name: "Cool" },
    { name: "Awesome" },
    { name: "Spotify" },
    { name: "topmusic" },
    { name: "latest" },
    { name: "hotest" },
    { name: "recommended" },
    { name: "new arrivals" },
    { name: "Rock" },
    { name: "Dance" },
    { name: "Melody" },
    { name: "Rock" },
    { name: "Dance" },
    { name: "Melody" },
    { name: "Mid" },
    { name: "Electrifying" },
    { name: "Cool" },
    { name: "Awesome" },
    { name: "Spotify" },
    { name: "topmusic" },
    { name: "latest" },
    { name: "hotest" },
    { name: "recommended" },
    { name: "new arrivals" },
    { name: "Rock" },
    { name: "Dance" },
    { name: "Melody" }
  ]
};

export default class step2 extends Component {
  handleSubmit(e) {
    e.preventDefault();

    this.props.submitHandle("val");
  }
  state = {
    loggedIn: false
  };
  constructor(props) {
    super(props);
    const authEndpoint = "https://accounts.spotify.com/authorize";
    // Replace with your app's client ID, redirect URI and desired scopes
    const clientId = "47827d1d96cc4167aaa863d61f791d2c";
    const redirectUri =
      "https://spotify-authrization.herokuapp.com/auth/callback";
    const scopes = [
      "user-top-read",
      "user-read-currently-playing",
      "user-read-playback-state",
      "playlist-read-private",
      "playlist-read-collaborative",
      "streaming",
      "user-read-email",
      "user-read-private",
      "user-read-recently-played"
    ];

    var url = `${authEndpoint}?client_id=${clientId}&redirect_uri=${encodeURIComponent(
      redirectUri
    )}&scope=${scopes.join("%20")}&response_type=code&show_dialog=true`;

    this.spotifyUrl = url;

    this.receiver = document.getElementById("receiver").contentWindow;
    window.addEventListener("message", this.receiveMessage.bind(this));
  }
  receiveMessage(e) {
    if (e.data.type == "spotifyAuth")
      this.spotifyToken({
        acToken: e.data.accessToken,
        refToken: e.data.refreshToken
      });
  }

  spotifyToken(tokens) {
    this.setState({
      loggedIn: true
    });
    window.localStorage.setItem("spotify_token", tokens.acToken);
    window.localStorage.setItem("spotify_refresh", tokens.refToken);
    var d = new Date();
    d.setMinutes(d.getMinutes() + 60);
    window.localStorage.setItem("token_time", d);
  }
  spotifyLogIn(e) {
    e.preventDefault();
    var spotifyLoginWindow = window.open(
      this.spotifyUrl,
      "Login with Spotify",
      "width=550,height=600"
    );
  }
  componentDidMount() {
    var diameter = 1100;

    var bubble = window.d3
      .pack(dataset)
      .size([diameter, diameter])
      .padding(function(d) {
        return d.height > 1 ? 5 / 0.35 : 20;
      });
    var svg = window.d3
      .select("#chart")
      .append("svg")
      .attr("width", diameter)
      .attr("height", diameter)
      .attr("class", "bubble")
      .style("transform", "rotate(-90deg)");

    var nodes = window.d3.hierarchy(dataset).sum(function(d) {
      return Math.floor(Math.random() * 400) + 200;
    });

    var node = svg
      .selectAll(".node")
      .data(bubble(nodes).descendants())
      .enter()
      .filter(function(d) {
        return !d.children;
      })
      .append("g")
      .attr("class", function(d){
        // if(self.props.data.tags.includes(d.data.name))
        // return "node active"
        // else
        return "node"
      })
      .attr("transform", function(d) {
        return "translate(" + d.x + "," + d.y + ") ";
      })
      .on("mouseover", function(d, i) {
        window.d3.select(this).style("cursor", "pointer");
      })
      .on("click", function(d) {
        if (window.d3.select(this).classed("active"))
          window.d3.select(this).classed("active", false);
        else window.d3.select(this).classed("active", true);
      });

    node.append("title").text(function(d) {
      return d.name + ": " + d.Count;
    });

    node
      .append("circle")
      .attr("r", function(d) {
        return d.r;
      })
      .style("fill", "#1C1C1C");

    node
      .append("text")
      .attr("dy", ".2em")
      .style("text-anchor", "middle")
      .style("font-size", "14px")
      .style("transform", "rotate(90deg)")
      .text(function(d) {
        console.log(d);
        return d.data.name.substring(0, d.r / 3);
      })
      .attr("font-family", "sans-serif")
      .attr("fill", "white");

    node
      .append("svg:foreignObject")
      .attr("height", "30px")
      .attr("width", "30px")

      .attr("margin-top", "10px")
      .html('<i class="fas fa-check"></i>');
  }
  render() {
    const { bindSubmitForm, submitHandle } = this.props;

    return (
      <div className={styles.stepContainer}>
        <div className="row">
          <div className={`col-md-3 ${styles.leftSide}`}>
            <h1 className={styles.heading} style={{ position: "absolute" }}>
              MUSIC
            </h1>
            <img
              src="/images/musicImage.png"
              style={{ height: "480px" }}
              className={styles.stepImage}
            />
            <h2 className={styles.secondHeading}>
              Bring your own Music Service
            </h2>

            <p className={styles.paraContent}>
              Audio One does not offer music streaming at the moment. However,
              we still would like to know what genres of music you prefer to
              make your listening experience better.
            </p>
            <p className={styles.paraContent}>
              We have made it easy for you to bring your own music to Audio One.
            </p>
            <p className={styles.paraContent}>
              Login to Spotify right now. Apple Music and other services will be
              available shortly, so follow the announcement.
            </p>

            <button
              className={styles.logBtn}
              onClick={this.spotifyLogIn.bind(this)}
            >
              {" "}
              <img src="/images/spotWhite.png" />{" "}
              {this.state.loggedIn ? "Logged into Spotify" : "Login To Spotify"}
            </button>
          </div>
          <div className={`col-md-9 ${styles.rightContainer}`}>
            <div id="chart"></div>
          </div>

          <button
            ref={fr => {
              bindSubmitForm(fr);
            }}
            onClick={this.handleSubmit.bind(this)}
            type="submit"
            className="d-none btn"
          >
            click me
          </button>
        </div>
      </div>
    );
  }
}
