import React, { Component } from "react";
import styles from "./styles/home.module.css";
import StationItem from "./StationItem";
import { connect } from "react-redux";
import NEWSACTIONS from "../redux/actions/news";
import musicPlayer from "../data/player";
import moment from "moment";

var mapStateToProps = (state) => {
  return {
    radioMode: state.news.radioMode,
    stationMode: state.news.stationMode,
    stations: state.news.stations,
    recentlyPlayed: state.site.recentlyPlayed,
    playlist: state.site.queue.items,
    wheather: state.news.wheather,
  };
};

var mapDispatchToProps = (dispatch) => ({
  setRadioMode: (item) => dispatch(NEWSACTIONS.setRadioMode(item)),
  setStationMode: (item) => dispatch(NEWSACTIONS.setStationMode(item)),
});

class StationContainer extends Component {
  MusicPlayer = musicPlayer();
  stations = ["Sports"];

  state = {
    station: null,
    round: 1,
  };

  // componentWillReceiveProps(nextProp) {
  //   if (nextProp.stationMode !== this.props.stationMode) this.addShorts();
  // }

  receiveMessage(e) {
    const { round } = this.state;

    if (e.data.type == "playQueue") {
      if (this.state.station && e.data.items) {
        if (e.data.items.length == 1) {
          const newTrack = e.data.currentPlayed;
          if (newTrack.type == "shorts") {
            this.addNews();
          } else if (newTrack.type == "podcast") {
            this.addNews();
          } else if (newTrack.type == "normal") {
            if (round == 1) {
              this.addPodcast();
              this.setState({ round: 2 });
            } else {
              this.addShorts();
              this.setState({ round: 1 });
            }
          }
        }
      }
    }
  }

  addShorts() {
    const foundItem = this.props.stations.find(
      (item) => item.station.toLowerCase() == this.state.station
    );

    var shorts = foundItem["items"]["shorts"];
    let shortCount = 0;
    shorts = shorts.sort((a, b) => b.published - a.published);
    if (shorts.length > 0) {
      shorts.filter((item) => {
        if (shortCount >= 2) return;
        const curDate = moment();
        const podDate = moment(item.published);

        if (podDate.diff(curDate, "days") <= 7) {
          const testItem = this.props.recentlyPlayed.find(
            (itee) => itee.id == item.id
          );
          var condition = true;
          if (testItem) {
            if (testItem.completed) {
              condition = false;
            } else condition = true;
          } else condition = true;

          if (condition) {
            if (!this.props.playlist.find((tr) => tr.id == item.id)) {
              shortCount += 1;
              this.MusicPlayer.playShorts(item);
            }
          }
        }
      });
    }

    if (shortCount < 2) this.addNews();
  }

  addPodcast(count = 1) {
    console.log("adding podcast");
    let podCount = 0;
    let countLimit = count;
    var radioPodcasts = [];
    const foundItem = this.props.stations.find(
      (item) => item.station.toLowerCase() == this.state.station
    );
    var podcasts = foundItem["items"]["podcasts"];

    if (podcasts.length > 0) {
      if (podCount < countLimit) {
        podcasts = podcasts.sort((a, b) => b.published - a.published);

        podcasts.map((podcast) => {
          if (podCount >= countLimit) return;
          const curDate = moment();
          const podDate = moment(podcast.published);
          if (podDate.diff(curDate, "days") <= 7) {
            const testItem = this.props.recentlyPlayed.find(
              (itee) => itee.id == podcast.episode[0].id
            );
            var condition = true;
            if (testItem) {
              if (testItem.completed) {
                condition = false;
              } else condition = true;
            } else condition = true;

            if (condition)
              if (
                !this.props.playlist.find(
                  (tr) => tr.id == podcast.episode[0].id
                )
              ) {
                podCount += 1;
                radioPodcasts.push({
                  episode: podcast.episode[0],
                  title: podcast.title,
                });
              }
          }
        });
      }
    }

    radioPodcasts.map((podcast) => {
      this.MusicPlayer.playPodcast(podcast);
    });

    if (radioPodcasts.length == 0) this.addNews();
  }

  addNews() {
    const { round } = this.state;
    let newsCount = 3;
    if (round == 2) newsCount = 1;

    const foundItem = this.props.stations.find(
      (item) => item.station.toLowerCase() == this.state.station
    );
    var allTracks = foundItem["items"]["news"];

    allTracks = allTracks.sort((a, b) => b.published - a.published);
    allTracks = allTracks.filter(
      (v, i, a) => a.findIndex((t) => t.id === v.id) === i
    );

    allTracks = allTracks.filter((item) => {
      const fItem = this.props.recentlyPlayed.find(
        (itee) => itee.id == item.id
      );

      if (fItem) {
        if (fItem.completed) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    });

    let selTracks = [];
    for (var i = 0; selTracks.length <= newsCount; i++) {
      const selectedItem = allTracks[i];
      if (selectedItem)
        if (!this.props.playlist.find((tr) => tr.id == selectedItem.id)) {
          selTracks.push(selectedItem);
        }
    }
    var that = this;
    var addTracks = async () => {
      const promises = selTracks.map(async (article) =>
        that.MusicPlayer.playAudio(article, true)
      );
      return Promise.all(promises);
    };

    if (selTracks.length == 0) this.addPodcast();

    addTracks().then((res) => console.log(res));
  }

  componentDidMount() {
    window.addEventListener("beforeunload", this.onUnload);
    window.addEventListener("message", this.receiveMessage.bind(this));
  }

  startStation = (station, mode) => {
    this.MusicPlayer.toggleStation(
      mode,
      "station",
      "You are listening to AudioOne Sports Radio.  The latest sports news and podcasts, updated in real-time"
    );
    setTimeout(() => {
      this.setState({ station: station.toLowerCase() }, () => {
        this.addShorts();
      });
    }, 1000);
  };

  render() {
    return (
      <div className="col-md-12 mb-5">
        <h5
          className={`pl-3 w-100 mb-4 ${styles.orangeColor}`}
          style={{ textTransform: "capitalize" }}
        >
          <img
            src="/images/Radio.png"
            style={{ width: "30px", marginRight: ".5em" }}
          />
          Stations{" "}
          <img
            src="/images/PlusIcon.png"
            style={{ width: "20px", marginLeft: ".5em" }}
          />
        </h5>
        <div className="d-flex overflow-x">
          {this.stations.map((item) => (
            <StationItem category={item} OnClick={this.startStation} />
          ))}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StationContainer);
