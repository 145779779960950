import React, { Component } from "react";
import styles from "./styles/loadingMeter.module.css";
import { connect } from "react-redux";
import ACTIONS from "../redux/actions/site";
var mapStateToProps = (state) => {
  return {
    meterVisible: state.site.meterVisible,
    meterProgress: state.site.meterProgress,
    meterTitle: state.site.meterTitle,
  };
};

var mapDispatchToProps = (dispatch) => ({
  setMeter: (items) => dispatch(ACTIONS.setMeter(items)),
  setMeterProgress: (items) => dispatch(ACTIONS.setMeterProgress(items)),
  setMeterTitle: (items) => dispatch(ACTIONS.setMeterTitle(items)),
});

class LoadingMeter extends Component {
  render() {
    return (
      <div
        className={styles.container}
        style={{ display: this.props.meterVisible ? "block" : "none" }}
      >
        <div
          className="d-flex"
          style={{ padding: "2.6em", paddingBottom: "0" }}
        >
          <img
            src="/images/lockIcon.png"
            alt=""
            style={{ width: "35px", height: "15px" }}
          />
          <p className={styles.title}>{this.props.meterTitle}</p>
        </div>
        <div
          className="d-flex justify-content-center"
          style={{ padding: "0 2.6em" }}
        >
          <div className={styles.sliderBackground}>
            <span
              className={styles.slider}
              style={{ width: `${this.props.meterProgress}%` }}
            ></span>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoadingMeter);
