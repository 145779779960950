import React, { Component } from "react";
import FeedlyIcon from "../assets/img/feedly monochrome_large.png";
import ACTIONS from "../redux/actions/news";
import { connect } from "react-redux";
import styles from "./Navbar/dashNavbar.module.css";
import apiRequest from "../api/clientRequest";
import axios from "axios";
import environment from "../api/environment";
var mapStateToProps = (state) => {
  return {
    youtubeUser: state.news.youtubeUser,
    youtubeVideos: state.news.youtubeVideos,
  };
};
// Change
var mapDispatchToProps = (dispatch) => ({
  setYoutubeUser: (item) => dispatch(ACTIONS.setYoutubeUser(item)),
  setVideos: (item) => dispatch(ACTIONS.setYoutubeVideos(item)),
});

class YoutubeLogin extends Component {
  constructor(props) {
    super(props);
    const authEndpoint = "https://accounts.google.com/o/oauth2/v2/auth";
    // Replace with your app's client ID, redirect URI and desired scopes
    const clientId = `${environment.GOOGLE_CLIENT_ID}`;
    const redirectUri = `${environment.serverurl}api/v1/auth/youtubeRedirect`;
    const scope = "https://www.googleapis.com/auth/youtube";
    console.log(redirectUri);
    console.log(encodeURIComponent(redirectUri));
    this.state = {
      youtubeToken: "",
    };

    var url = `${authEndpoint}?access_type=offline&prompt=consent&client_id=${clientId}&redirect_uri=${encodeURIComponent(
      redirectUri
    )}&scope=${scope}&response_type=code`;

    this.authurl = url;
    window.addEventListener("message", this.receiveMessage.bind(this));
  }

  async componentDidMount() {
    var timer = 1900000;
    const that = this;

    await that.getRefreshedToken();

    if (window.localStorage.getItem("youtube_token"))
      setInterval(async () => {
        await that.getRefreshedToken();
      }, timer);
  }

  receiveMessage(e) {
    console.log(e.data);
    if (e.data.type == "youtubeAuth" && e.data.accessToken) {
      this.props.setYoutubeUser({
        token: e.data.accessToken,
        refreshToken: e.data.refreshToken,
      });
      window.localStorage.setItem("youtube_token", e.data.accessToken);
      window.localStorage.setItem("youtube_refresh", e.data.refreshToken);
      var d = new Date();
      d.setMinutes(d.getMinutes() + 60);
      window.localStorage.setItem("youtube_token_time", d);
      this.fetchVideos();
    }
  }

  async getRefreshedToken() {
    var refToken = await apiRequest({
      url: "/auth/youtube/refresh",
      method: "POST",
      data: {
        refresh_token: window.localStorage.getItem("youtube_refresh"),
      },
    }).then((res) => res.data);

    this.props.setYoutubeUser({
      token: refToken,
      refreshToken: window.localStorage.getItem("youtube_refresh"),
    });

    var d = new Date();
    d.setMinutes(d.getMinutes() + 60);

    window.localStorage.setItem("youtube_token", refToken);
    window.localStorage.setItem("token_time", d);
    this.fetchVideos();
  }

  loginYoutube(e) {
    e.preventDefault();
    window.open(this.authurl, "Login with Youtube", "width=550,height=600");
  }

  fetchVideos() {
    const accessToken = window.localStorage.getItem("youtube_token");
    apiRequest({
      url: "/video/youtube",
      method: "POST",
      data: {
        accessToken,
      },
    })
      .then((res) => {
        console.log(res.data);
        this.props.setVideos(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  youtubeLogOut() {
    window.localStorage.removeItem("youtube_token");
    window.localStorage.removeItem("youtube_refresh");
    this.props.setYoutubeUser(null);
  }

  render() {
    return (
      <>
        {this.props.youtubeUser ? (
          <img
            src="/images/youtubeGreyIcon.png"
            alt=""
            onClick={this.youtubeLogOut.bind(this)}
          />
        ) : (
          <img
            src="/images/youtubeIcon.png"
            className={styles.spotLogo}
            alt=""
            onClick={this.loginYoutube.bind(this)}
          />
        )}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(YoutubeLogin);
