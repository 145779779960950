import React from "react";
import questyles from "./styles/Queuelist.module.css";

export default function UserImage(props) {
  if (!props.name) return <></>;

  const namelat = props.name.split(" ");

  return (
    <div
      style={{ position: "relative" }}
      className={questyles.albumImgContainer}
    >
      <div
        style={{
          position: "relative",
          borderRadius: "50%",
          textAlign: "center",
          lineHeight: "38px",
          backgroundColor: "mediumpurple",
          textTransform: "uppercase",
        }}
        className={questyles.albumimg}
      >
        {props.avatar ? (
          <div style={{ position: "relative", width: "100%" }}>
            <img
              src={props.avatar}
              alt=""
              style={{ width: "100%", borderRadius: "50%", height: "40px" }}
            />
            {props.delete ? (
              <i
                class={`fas fa-times ${questyles.crossImg}`}
                onClick={(e) => {
                  e.preventDefault();
                  props.removeItem(props.id);
                }}
              ></i>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div>
            {`${namelat[0] !== undefined ? namelat[0][0] : ""} ${
              namelat[1] !== undefined ? namelat[1][0] : ""
            }`}
          </div>
        )}
      </div>
    </div>
  );
}
