import React, { useStatem, useRef } from "react";
import styles from "../styles/search.module.css";
import debounce from "lodash.debounce";

export default function SearchInput(props) {
  const myInput = useRef();

  return (
    <div className="col-md-12" style={{ maxHeight: "126px" }}>
      <input
        type="text"
        ref={props.setRef}
        className={styles.input}
        placeholder={`Search For ${props.filter}`}
        value={props.searchVal}
        onChange={(e) => {
          props.inputChange(e.target.value);
        }}
      />

      <i
        onClick={() => props.closeSearch()}
        class="fas fa-times-circle close-search"
        style={{ fontSize: "7em", marginLeft: "0.2em", cursor: "pointer" }}
      ></i>
    </div>
  );
}
