import React, { Component } from "react";
import styles from "./styles/nowPlaying.module.css";

import { connect } from "react-redux";
import ACTIONS from "../redux/actions/site";
import MemberItem from "./MemberItem";
import AddMember from "./AddMember";

var mapStateToProps = (state) => {
  return {
    data: state.team.emailExpanded,
  };
};

var mapDispatchToProps = (dispatch) => ({
  setSavedItems: (items) => dispatch(ACTIONS.setSavedItems(items)),
});

class EmailRecipients extends Component {
  render() {
    if (!this.props.data) return <></>;

    const data = this.props.data.user_emails;

    return (
      <div className={`${styles.content} ${styles.podcastContent}`}>
        <>
          <div className="" style={{ width: "90%" }}>
            <h1
              className={`${styles.title} mt-5 text-right ${styles.podcastHead}`}
              style={{ flex: "none", width: "100%" }}
            >
              <img
                src="/images/userScreen.png"
                className={styles.typeImage}
                style={{
                  width: "20px",
                  marginRight: "0.7em",
                  marginBottom: "0.25em",
                }}
              />
              Campaign
            </h1>

            <div className={styles.titleContainer} style={{ marginTop: "6em" }}>
              <h1 className={styles.title} style={{ fontSize: "1.6em" }}>
                Recipients
              </h1>
              {data.map((item) => (
                <MemberItem data={{ avatar: "", email: "", fullname: item }} />
              ))}
            </div>
          </div>
        </>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailRecipients);
