import React, { Component } from "react";
import styles from "./styles/nowPlaying.module.css";
import player from "../data/player";
import queuestyles from "./styles/Queuelist.module.css";
import { connect } from "react-redux";
import ACTIONS from "../redux/actions/site";
import apiRequest from "../api/clientRequest";

var Player = player();

var mapStateToProps = state => {
  return {
    savedItems: state.site.savedItems,
    queue: state.site.queue
  };
};

var mapDispatchToProps = dispatch => ({
  setSavedItems: items => dispatch(ACTIONS.setSavedItems(items))
});
class PodcastNowPlaying extends Component {
  state = {
    shouldDisplay: true,
    selectedTab: "episode"
  };
  player = player();
  saveItem(item) {
    item.type = "podcast";
    if (this.props.savedItems.find(ite => ite.track_id == item.id)) {
      item.clicked = false;
      this.props.setSavedItems(
        this.props.savedItems.filter(ite => ite.track_id !== item.id)
      );
      apiRequest({ url: "/news/save", method: "POST", data: item });
    } else {
      item.clicked = true;
      apiRequest({ url: "/news/save", method: "POST", data: item });
      this.props.setSavedItems([
        ...this.props.savedItems.map(ite => ite),
        { track_id: item.id, type: "podcast" }
      ]);
    }
  }
  render() {
    const curPlayed = this.props.curPlayed;
    const { title, description, source, image } = this.props.data.newArticle;
    const podcastDescription = this.props.data.newPodcast.description;
    return (
      <div className={`${styles.content} ${styles.podcastContent}`}>
        {this.state.shouldDisplay ? (
          <>
            <div className="d-flex flex-wrap">
              <h1 className={`${styles.title} ${styles.podcastHead}`}>
                <img src="/images/Radio.png" className={styles.typeImage} />
                Podcast
              </h1>
              <div className={styles.contentGradient}></div>

              <div
                className={styles.titleContainer}
                style={{ marginTop: "8em" }}
              >
                <h1 className={styles.title}>{title}</h1>
                <div
                  className="d-flex align-items-center"
                  style={{ marginBottom: "3em" }}
                >
                  <img src={image} className={styles.sourceImg} />
                  <span className={styles.source}>{source}</span>
                </div>
                <div className="my-4">
                  <button
                    className={`btn ${styles.podcastBtn} ${
                      this.state.selectedTab == "episode"
                        ? styles.podcastBtnSelected
                        : ""
                    }`}
                    onClick={() =>
                      this.setState({
                        selectedTab: "episode"
                      })
                    }
                  >
                    Episode
                  </button>
                  <button
                    className={`btn ${styles.podcastBtn} ${
                      this.state.selectedTab == "series"
                        ? styles.podcastBtnSelected
                        : ""
                    }`}
                    onClick={() =>
                      this.setState({
                        selectedTab: "series"
                      })
                    }
                  >
                    Show Description
                  </button>
                  <button
                    className={`btn ${styles.podcastBtn} ${
                      this.state.selectedTab == "moreEpisode"
                        ? styles.podcastBtnSelected
                        : ""
                    }`}
                    onClick={() =>
                      this.setState({
                        selectedTab: "moreEpisode"
                      })
                    }
                  >
                    More Episodes
                  </button>
                </div>
                {this.state.selectedTab == "episode" ? (
                  <p
                    className={styles.artContent}
                    ref={el => {
                      this.contentElement = el;
                    }}
                    dangerouslySetInnerHTML={{
                      __html: description.replace(/<img[^>]*>/g, "")
                    }}
                  ></p>
                ) : (
                  ""
                )}
                {this.state.selectedTab == "series" ? (
                  <p
                    className={styles.artContent}
                    ref={el => {
                      this.contentElement = el;
                    }}
                    dangerouslySetInnerHTML={{
                      __html: podcastDescription.replace(/<img[^>]*>/g, "")
                    }}
                  ></p>
                ) : (
                  ""
                )}
                {this.state.selectedTab == "moreEpisode" ? (
                  <div className={styles.artContent}>
                    {this.props.data.newPodcast.episode.map((item, index) => {
                      let artist = false;
                      let newtitle = "";
                      if (item.title && item.title.includes(":")) {
                        artist = item.title.split(":");
                        newtitle = artist[0];
                        artist = artist[1];
                      } else {
                        newtitle = item.title;
                      }

                      return (
                        <div
                          className={`${queuestyles.queue_item} col-md-12 p-0 mb-3 queue-item`}
                        >
                          {" "}
                          <div
                            style={{ position: "relative" }}
                            className={queuestyles.albumImgContainer}
                          >
                            <div style={{ position: "relative" }}>
                              <img
                                src={item.image}
                                className={queuestyles.albumimg}
                                alt=""
                              />
                            </div>
                          </div>
                          <div>
                            <p
                              // className={
                              //   item.id == curPlayed.id
                              //     ? queuestyles.itemActive
                              //     : ""
                              // }
                              style={{ color: "white" }}
                              onClick={() => {
                                if (
                                  this.props.queue.items.find(
                                    ite => ite.id == item.id
                                  )
                                )
                                  Player.playQueue(item);
                                else
                                  Player.playPodcast({
                                    episode: item,
                                    title: this.props.data.newPodcast.title
                                  });
                              }}
                            >
                              {newtitle}
                            </p>

                            <p
                              onClick={() => {
                                if (
                                  this.props.queue.items.find(
                                    ite => ite.id == item.id
                                  )
                                )
                                  Player.playQueue(item);
                                else
                                  Player.playPodcast({
                                    episode: item,
                                    title: this.props.data.newPodcast.title
                                  });
                              }}
                              style={{ color: "white" }}
                            >
                              {artist}
                            </p>
                          </div>
                          <div className={queuestyles.actionContainer}>
                            {item.id == curPlayed.id && curPlayed.playing ? (
                              <img
                                src="/images/pauseIcon.png"
                                className={queuestyles.play}
                                alt=""
                                onClick={() => {
                                  if (
                                    this.props.queue.items.find(
                                      ite => ite.id == item.id
                                    )
                                  )
                                    Player.playQueue(item);
                                  else
                                    Player.playPodcast({
                                      episode: item,
                                      title: this.props.data.newPodcast.title
                                    });
                                }}
                              />
                            ) : (
                              <img
                                src="/images/playIcon.png"
                                className={queuestyles.play}
                                alt=""
                                onClick={() => {
                                  if (
                                    this.props.queue.items.find(
                                      ite => ite.id == item.id
                                    )
                                  )
                                    Player.playQueue(item);
                                  else
                                    Player.playPodcast({
                                      episode: item,
                                      title: this.props.data.newPodcast.title
                                    });
                                }}
                              />
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  ""
                )}

                {this.contentElement ? (
                  this.contentElement.offsetHeight <
                    this.contentElement.scrollHeight ||
                  this.contentElement.offsetWidth <
                    this.contentElement.scrollWidth ? (
                    <img
                      src="/images/down-menu-arrow-botton.png"
                      className={styles.downArrow}
                    />
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div>
              <div className={styles.iconContainer}></div>
            </div>
            <div style={{ marginTop: "20em" }}>
              <div className="mb-3 text-center">
                <div className={styles.playingIcons}>
                  <img src="/images/Shape.png" />
                </div>
                Comment
              </div>
              <div className="mb-3 text-center">
                <div className={styles.playingIcons}>
                  {this.props.savedItems.find(
                    item => item.track_id == this.props.data.newArticle.id
                  ) ? (
                    <img
                      src="/images/HeartFill.png"
                      onClick={() => this.saveItem(this.props.data.newArticle)}
                      alt=""
                    />
                  ) : (
                    <img
                      src="/images/Heart.png"
                      onClick={() => this.saveItem(this.props.data.newArticle)}
                      alt=""
                    />
                  )}
                </div>
                513
              </div>
              <div className="mb-3 text-center">
                <div className={styles.playingIcons}>
                  <img src="/images/Ellipse_1.png" />
                </div>
                View
              </div>
              <div className="mb-3 text-center">
                <div className={styles.playingIcons}>
                  <img src="/images/cluster-data-group-organize.png" />
                </div>
                Share
              </div>
            </div>
            {this.props.playing ? (
              <img
                src="/images/pause.png"
                className={styles.playIcon}
                onClick={() => {
                  this.player.playQueue(this.props.data.newArticle);
                }}
              />
            ) : (
              <img
                src="/images/playIcon.png"
                className={styles.playIcon}
                onClick={() => {
                  this.player.playQueue(this.props.data.newArticle);
                }}
              />
            )}
          </>
        ) : (
          <h1>Nothing Playing</h1>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PodcastNowPlaying);
