import React, { Component } from "react";
import styles from "./styles/nowPlaying.module.css";
import { connect } from "react-redux";
import ArticleNowPlaying from "./ArticleNowPlaying";
import PodcastNowPlaying from "./PodcastNowPlaying";
import MusicNowPlaying from "./MusicNowPlaying";
import ShortsNowPlaying from "./ShortsNowPlaying";
const mapStateToProps = (state) => ({
  queue: state.site.queue,
  feed: state.news.feed,
  playlists: state.news.playlists,
  podcasts: state.news.podcasts,
  musicList: state.site.musicList,
  shorts: state.site.shorts,
  savedItems: state.site.savedItems,
});

class NowPlaying extends Component {
  state = {
    shouldDisplay: true,
    contentOverflow: false,
    type: "none",
    activeTrack: null,
    backgroundImage: null,
    trackPlaying: false,
  };

  componentWillMount() {
    const curPlayed = this.props.queue.currentPlayed;

    if (curPlayed.type == "normal") {
      var nowArticle = this.checkNewsId(curPlayed.id, this.props);
      if (nowArticle) {
        this.setState({
          activeTrack: nowArticle,
          backgroundImage: nowArticle.image,
          type: "normal",
          trackPlaying: curPlayed.playing,
        });
      }
    } else if (curPlayed.type == "podcast") {
      var nowArticle = this.checkPodcastId(curPlayed.id, this.props);

      if (nowArticle) {
        this.setState({
          activeTrack: nowArticle,
          backgroundImage: nowArticle.newArticle.image,
          type: "podcast",
          trackPlaying: curPlayed.playing,
        });
      }
    } else if (curPlayed.type == "shorts") {
      var nowArticle = this.checkShortsId(curPlayed.id, this.props);

      if (nowArticle) {
        this.setState({
          activeTrack: nowArticle,
          backgroundImage: nowArticle.image,
          type: "short",
          trackPlaying: curPlayed.playing,
        });
      }
    } else if (curPlayed.type == "spotify") {
      var nowArticle = this.checkMusicsId(curPlayed.id);
      if (nowArticle) {
        this.setState({
          activeTrack: nowArticle,
          backgroundImage: nowArticle.image,
          type: "spotify",
          trackPlaying: curPlayed.playing,
        });
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    const curPlayed = nextProps.queue.currentPlayed;
    if (curPlayed.type == "normal") {
      var nowArticle = this.checkNewsId(curPlayed.id, nextProps);
      if (nowArticle) {
        this.setState({
          activeTrack: nowArticle,
          backgroundImage: nowArticle.image,
          type: "normal",
          trackPlaying: curPlayed.playing,
        });
      }
    } else if (curPlayed.type == "podcast") {
      var nowArticle = this.checkPodcastId(curPlayed.id, nextProps);

      if (nowArticle) {
        this.setState({
          activeTrack: nowArticle,
          backgroundImage: nowArticle.newArticle.image,
          type: "podcast",
          trackPlaying: curPlayed.playing,
        });
      }
    } else if (curPlayed.type == "shorts") {
      var nowArticle = this.checkShortsId(curPlayed.id, nextProps);

      if (nowArticle) {
        this.setState({
          activeTrack: nowArticle,
          backgroundImage: nowArticle.image,
          type: "short",
          trackPlaying: curPlayed.playing,
        });
      }
    } else if (curPlayed.type == "spotify") {
      var nowArticle = this.checkMusicsId(curPlayed.id);
      if (nowArticle) {
        this.setState({
          activeTrack: nowArticle,
          backgroundImage: nowArticle.image,
          type: "spotify",
          trackPlaying: curPlayed.playing,
        });
      }
    }
  }
  checkPodcastId(id, nextProp) {
    var nowArticles = [
      ...nextProp.podcasts.map((item) => {
        return item.podcasts.map((ite) => ite.episode);
      }),
    ];

    nowArticles = [].concat.apply([], nowArticles);
    nowArticles = [].concat.apply([], nowArticles);

    const newArticle = nowArticles.find((item) => item.id == id);
    let newPodcast = nextProp.podcasts.find((item) =>
      item.podcasts.find((ite) => ite.id == newArticle.podcast_id)
    );

    newPodcast = newPodcast.podcasts.find(
      (item) => item.id == newArticle.podcast_id
    );
    return { newArticle, newPodcast };
  }

  checkNewsId(id, prop) {
    var nowArticles = [
      ...prop.feed,
      ...this.props.playlists.map((item) => item.articles),
      ...this.props.savedItems.filter((item) => item.type == "news"),
    ];
    nowArticles = [].concat.apply([], nowArticles);
    const newArticle = nowArticles.find((item) => item.id == id);
    return newArticle;
  }

  checkMusicsId(id, prop) {
    var nowArticles = [
      ...this.props.musicList.map((item) => {
        return item.tracks.map((ite) => {
          const newobj = ite;
          newobj.sourceImage = item.image;
          return newobj;
        });
      }),
    ];

    nowArticles = [].concat.apply([], nowArticles);
    const newArticle = nowArticles.find((item) => item.id == id);
    return newArticle;
  }

  checkShortsId(id, prop) {
    var nowArticles = [
      ...this.props.shorts.map((item) => {
        return item.shorts;
      }),
    ];

    nowArticles = [].concat.apply([], nowArticles);
    const newArticle = nowArticles.find((item) => item.id == id);
    return newArticle;
  }

  contentElement = null;

  render() {
    return (
      <div className={`${styles.nowPlaying} nowPlaying`}>
        <div className={styles.rightGradient} />
        <div
          className={`${styles.rightImage} sidebarImage`}
          style={{ backgroundImage: `url(${this.state.backgroundImage})` }}
        />
        {this.state.type == "normal" ? (
          <ArticleNowPlaying
            data={this.state.activeTrack}
            playing={this.state.trackPlaying}
          />
        ) : (
          ""
        )}
        {this.state.type == "podcast" ? (
          <PodcastNowPlaying
            data={this.state.activeTrack}
            playing={this.state.trackPlaying}
            curPlayed={this.props.queue.currentPlayed}
          />
        ) : (
          ""
        )}

        {this.state.type == "spotify" ? (
          <MusicNowPlaying
            data={this.state.activeTrack}
            playing={this.state.trackPlaying}
          />
        ) : (
          ""
        )}

        {this.state.type == "short" ? (
          <ShortsNowPlaying
            data={this.state.activeTrack}
            playing={this.state.trackPlaying}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, null)(NowPlaying);
