import React, { Component } from "react";
import ACTIONS from "../../redux/actions/news";
import player from "../../data/player";
import { connect } from "react-redux";
import _ from "lodash";
import MainSlider from "../MainSlider";
import VideoItem from "../VideoItem";

var mapStateToProps = (state) => {
  return {
    feedlyUser: state.news.feedlyUser,
    videos: state.news.youtubeVideos,
  };
};

var mapDispatchToProps = (dispatch) => ({
  setTags: (item) => dispatch(ACTIONS.setTags(item)),
  setFeed: (item) => dispatch(ACTIONS.setFeed(item)),
  setFeedlyUser: (item) => dispatch(ACTIONS.setFeedlyUser(item)),
  setPlaylists: (item) => dispatch(ACTIONS.setPlaylists(item)),
  setNewsLogChange: (item) => dispatch(ACTIONS.setNewsLogChange(item)),
});

class VideosBoard extends Component {
  state = {
    videos: [],
    feed: this.props.videos,
    oldFeed: this.props.videos,
    filter: "All",
    sliderItems: [],
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const mainPage = document.querySelector(".main-page");
    mainPage.addEventListener("scroll", this.onScroll);
  }

  componentWillUnmount() {
    const mainPage = document.querySelector(".main-page");
    mainPage.removeEventListener("scroll", this.onScroll);
  }

  onScroll = () => {
    const mainPage = document.querySelector(".main-page");
    const mainSlider = document.querySelector(".videoBoard .mainSlider");
    mainSlider.style.opacity = 1 - mainPage.scrollTop / 500;
  };

  Player = player();

  playVideo(item, action) {
    this.Player.playVideo(item, action);
  }

  render() {
    const sliderItems = this.props.videos.slice(0, 10);
    return (
      <div
        className="col-md-9 videoBoard"
        style={{ display: this.props.style, paddingTop: "430px" }}
      >
        <div className="mainBodyScroll w-100">
          <div className="row scroll pl-3 pr-0 mx-0">
            <MainSlider items={sliderItems} type="Video" />
            <h5
              className="pl-3 w-100 d-flex"
              style={{ zIndex: 999, fontSize: "1.7em", marginTop: "141px" }}
            >
              Latest Videos
            </h5>
            <div className="row row-eq-height mx-0 w-100">
              {this.props.videos.map((data) => (
                <VideoItem
                  data={data}
                  playVideo={(action = false) => this.playVideo(data, action)}
                />
              ))}
            </div>{" "}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VideosBoard);
