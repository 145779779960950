import React from "react";
import styles from "./styles/newsitem.module.css";
import { connect } from "react-redux";
import colors from "../data/colors.json";

var mapStateToProps = state => {
  return {
    theme: state.site.theme
  };
};

function StoryItem(props) {
  var color = false;
  if (props.theme == "color-theme")
    color = colors[Math.floor(Math.random() * colors.length)];

  return (
    <div className={`currency-box  pb-0`} style={{ minWidth: "304px" }}>
      <div className="currency-pair">
        <div
          className={`background-image `}
          style={{
            background: `${
              props.theme == "color-theme"
                ? `url(/images/newsImages.png) ${color}`
                : `url(/images/newsImages.png) #1c1c1c`
            }`
          }}
        ></div>

        <div className="cat-content">
          <span className={`name mt-1  `}>#category</span>
          <img
            src={"/images/playIcon.png"}
            className={`${styles.playIcon} `}
            alt=""
          />

          <div className={`article-container   `}>
            <span className="article-name" style={{ fontWeight: "normal" }}>
              How they managed to build and design this object ?
            </span>
            <div className={styles.actionContainer}>
              <span className="article-source">engadget</span>
              <div className="d-flex ml-auto align-items-end">
                <img
                  src="/images/cluster.png"
                  className={styles.actionIcon}
                  alt=""
                />
                <img
                  src="/images/Heart.png"
                  className={`${styles.actionIcon} ${styles.likedIcon}`}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, null)(StoryItem);
