import React from "react";
import styles from "../styles/search.module.css";

export default function FilterItem(props) {
  return (
    <div
      className={`${styles.filterItem} filterItem ${
        props.active ? "active" : ""
      }`}
      style={{ backgroundColor: `${props.color}` }}
      onClick={() => {
        props.onSelect({
          searchFilter: props.type,
          filterHeading: props.heading
        });
      }}
    >
      <h3 className={`${styles.filterHeading} filterHeading `}>{props.heading}</h3>
      <img
        src={`/images/${props.icon}.png`}
        className={styles.filterItemIcon}
      />
    </div>
  );
}
