import React from "react";
import styles from "./styles/shorts.module.css";
import playstyles from "./styles/playlist.module.css";
import { expandIcon, actionIcon } from "./styles/newsitem.module.css";
import { playIcon, backArrowContainer } from "./styles/newsitem.module.css";
import colors from "../data/colors.json";
import { connect } from "react-redux";
import homestyle from "./styles/home.module.css";
import podcaststyle from "./styles/playlist.module.css";

var mapStateToProps = (state) => {
  return {
    theme: state.site.theme,
  };
};

function StationItem(props) {
  var color = false;
  if (props.theme == "color-theme")
    color = colors[Math.floor(Math.random() * colors.length)];

  return (
    <div className={`currency-box ${styles.shortItem} ${homestyle.itemBox}`}>
      <div className={`${playstyles.playlistItem}  currency-pair   `}>
        <div
          className={`background-image `}
          style={{
            background: `${
              props.theme == "color-theme"
                ? `url(/images/newsImages.png) ${color}`
                : `url(/images/newsImages.png) #1c1c1c`
            }`,
          }}
        ></div>

        <div className={`cat-content `}>
          <p className={`${playstyles.playlistName} text-left `}>
            <img
              src={"/images/playIcon.png"}
              className={playIcon}
              style={{ float: "none" }}
              onClick={() => props.onPlay(props.session)}
              alt=""
            />
          </p>
          <div style={{ marginTop: "3em" }}>
            <p
              className={playstyles.article}
              style={{ color: "white", fontWeight: "normal" }}
            >
              {props.session}
            </p>
          </div>
          <div className={playstyles.itemInfo}>
            <div
              className={playstyles.articleSideInfo}
              style={{ left: "12px" }}
            >
              <img
                src="/images/gearIcon.png"
                className={`${actionIcon} `}
                style={{ position: "absolute", bottom: "21px" }}
                alt=""
              />
              <div
                style={{
                  position: "absolute",
                  bottom: "0",
                  right: "0",
                  fontSize: "0.7em",
                }}
              >
                <p
                  style={{ fontWeight: "normal" }}
                  className={podcaststyle.noArticle}
                >
                  {props.duration}
                </p>
                <p className={podcaststyle.article}>minutes</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, null)(StationItem);
