import React, { Component } from "react";
import styles from "./styles/sharemodal.module.css";
import { Modal, Button, message } from "antd";
import { connect } from "react-redux";
import SITEACTIONS from "../redux/actions/site";

var mapDispatchToProps = dispatch => ({
  setTheme: item => dispatch(SITEACTIONS.setTheme(item)),
  setThemeSelector: item => dispatch(SITEACTIONS.setThemeSelector(item))
});

var mapStateToProps = state => {
  return {
    theme: state.site.theme,
    themeSelector: state.site.themeSelector
  };
};

class ThemeModal extends Component {
  state = {};

  handleOk = async () => {
    this.props.setThemeSelector(false);
  };

  handleCancel = () => {
    this.props.setThemeSelector(false);
  };

  render() {
    return (
      <Modal
        visible={this.props.themeSelector}
        title="Share this Article"
        onOk={this.handleOk}
        className={`share-modal themeSelectModal`}
        onCancel={this.handleCancel}
      >
        <div>
          <div className="d-flex justify-content-between mb-4 align-items-center">
            <img
              src="/images/AudioOneLogo@2x.png"
              alt=""
              className={styles.headImage}
            />

            <h3 className={styles.sharingHeading}>Themes</h3>
          </div>
          <div className="my-4 d-flex justify-content-between">
            <div>
              <img
                src="/images/whiteTheme.png"
                style={{
                  width: "160px",
                  height: "110px"
                }}
              />
              <p className="mb-0 font-weight-bold">Brilliant</p>
            </div>
            <button
              className="btn btn-black"
              onClick={() => this.props.setTheme("color-theme")}
            >
              {this.props.theme == "color-theme" ? "Selected" : "Select"}
            </button>
          </div>
          <div className="my-4 d-flex justify-content-between">
            <div>
              <img
                src="/images/stealthImage.png"
                style={{
                  width: "160px",
                  height: "110px"
                }}
              />
              <p className="mb-0 font-weight-bold">Stealth</p>
            </div>
            <button
              className="btn btn-black"
              onClick={() => this.props.setTheme("black-theme")}
            >
              {this.props.theme == "black-theme" ? "Selected" : "Select"}
            </button>
          </div>
          <div className="my-4 d-flex justify-content-between">
            <div>
              <img
                src="/images/somewhatImage.png"
                style={{
                  width: "160px",
                  height: "110px"
                }}
              />
              <p className="mb-0 font-weight-bold">Somewhat White</p>
            </div>
            <button
              className="btn btn-black"
              onClick={() => this.props.setTheme("white-theme")}
            >
              {this.props.theme == "white-theme" ? "Selected" : "Select"}
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ThemeModal);
