import { combineReducers } from "redux";
import Auth from "./auth";
import Site from "./site";
import News from "./news";
import Search from "./search";
import Teams from "./team";
var reducers = {
  auth: Auth,
  site: Site,
  search: Search,
  team: Teams,
  news: News,
};

export default reducers;
