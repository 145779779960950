import React, { Component } from "react";
import styles from "./styles/podcastInfo.module.css";
import MusicStyle from "./styles/musicinfo.module.css";
import { playIcon, actionIcon } from "./styles/newsitem.module.css";
export default class PodcastInfo extends Component {
  render() {
    const {
      title,
      description,
      image,
      followers,
      tracks,
      duration,
      owner
    } = this.props.data;
    return (
      <div className={`${styles.container} d-flex pinfoContainer`}>
        <img className={styles.image} src={image} alt="" />

        <div
          className={`${styles.contentContainer} ${styles.contentContainerMax30} pr-5`}
        >
          <h3 className="text-white">{title}</h3>
          <img
            src={"/images/playIcon.png"}
            style={{ float: "none" }}
            className={`${playIcon} ${MusicStyle.bigPlayIcon}`}
            alt=""
            onClick={() => this.props.playSpotifyList(this.props.data)}
          />{" "}
        </div>
        <div
          className={`${styles.contentContainer} ${MusicStyle.contentContainer40}  px-3`}
        >
          <p className={MusicStyle.paraGraph}>Created by {owner}</p>
          <p className={MusicStyle.paraGraph}>{tracks.length} Tracks</p>
          <p className={MusicStyle.paraGraph}>{duration}</p>
          <p className="mb-0 mt-4">{description}</p>
        </div>
        <div
          className={`ml-auto text-right ${styles.contentContainer} ${MusicStyle.contentContainer8}`}
        >
          <div className="h-100" style={{ position: "relative" }}>
            {" "}
            <p
              style={{ fontSize: "1.6em" }}
              className={`${styles.noepisode}  mb-1`}
            >
              {followers}
            </p>
            <span className="text-white">Followers</span>
            <div
              className={`d-flex ml-auto align-items-end ${styles.iconBelow}`}
            >
              <img
                src="/images/cluster.png"
                className={`${actionIcon}  `}
                alt=""
              />
              <img src="/images/Heart.png" className={actionIcon} alt="" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
