import React, { Component } from "react";
import styles from "./styles/home.module.css";
import shortstyle from "./styles/shorts.module.css";
import playstyle from "./styles/playlist.module.css";
import { expandIcon, actionIcon } from "./styles/newsitem.module.css";
import { subscribeBtn } from "./styles/podcastInfo.module.css";
import StationContainer from "./StationContainer";
import SessionContainer from "./SessionContainer";
import ShortsContainer from "./ShortsContainer";
import StoriesContainer from "./StoriesContainer";
import CollectionContainer from "./CollectionContainer";
import SharedContainer from "./SharedContainer";
import ScrollContainer from "react-indiana-drag-scroll";
import HomeSlider from "./HomeSlider";
import ListenLaterContainer from "./ListenLaterContainer";

export default class HomeBoard extends Component {
  render() {
    return (
      <div
        className="col-md-9 "
        style={{ display: this.props.style }}
        id="container"
      >
        <ScrollContainer className="scroll-container">
          <div
            className="row scroll pl-3 pr-0 mx-0 w-100 mt-5"
            style={{ marginBottom: "180px", overflowX: "hidden" }}
          >
            <HomeSlider />
            <StationContainer />
            <SessionContainer />
            <StoriesContainer />
            <div className="mb-5 col-md-12">
              <h5
                className={`pl-3 w-100 mb-4 ${styles.greenColor}`}
                style={{ textTransform: "capitalize" }}
              >
                <img
                  src="/images/Radio.png"
                  style={{ width: "30px", marginRight: ".5em" }}
                />
                AudioOne Shorts{" "}
              </h5>
              <div className="d-flex overflow-x pl-3">
                <ShortsContainer />
              </div>
            </div>
            <ListenLaterContainer />
            <CollectionContainer />
            {/* <SharedContainer /> */}
          </div>
        </ScrollContainer>
      </div>
    );
  }
}
