import React from 'react'
import ReactDOM from 'react-dom'

import App from './App'
import 'antd/dist/antd.css' // or 'antd/dist/antd.less'
import { unregister } from './registerServiceWorker'

import './assets/styles/theme.css'
import './assets/styles/spotify.css'
import './assets/styles/pace.css'
import './assets/styles/style.css'
import './assets/styles/navbar.css'
import './assets/styles/footer.css'
import './assets/styles/buttons.css'
import './assets/styles/onboarding.css'
import './assets/styles/animations.css'
import './assets/styles/news.css'
import './assets/styles/dashboard.css'
import './assets/styles/demo.css'
import './assets/styles/component.css'
import './assets/styles/responsive.css'
import { Provider as ReduxProvider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import configureStore from './redux/store'
import { Auth0Provider } from '@auth0/auth0-react'

var store = configureStore()

if (process.env.REACT_APP_STAGE == 'production') console.log = () => {}

ReactDOM.render(
  <ReduxProvider store={store}>
    {/* <PersistGate loading={null} persistor={persistor}> */}

    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      scope={process.env.REACT_APP_AUTH0_SCOPE}
    >
      <Router>
        <App />
      </Router>
    </Auth0Provider>

    {/* </PersistGate> */}
  </ReduxProvider>,
  document.getElementById('root')
)

unregister()
