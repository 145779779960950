import React, { useState, useEffect } from "react";
import PodcastListItem from "./PodcastListItem";
import styles from "../styles/search.module.css";
import apiRequest from "../../api/clientRequest";
import { connect } from "react-redux";
import player from "../../data/player";
import ACTIONS from "../../redux/actions/news";
import SITEACTIONS from "../../redux/actions/site";
const Player = player();

const mapStateToProps = (state) => ({
  podcasts: state.news.podcasts,
  savedItems: state.site.savedItems,
});

var mapDispatchToProps = (dispatch) => ({
  addToPodcast: (items) => dispatch(ACTIONS.addToPodcast(items)),
  setSavedItems: (items) => dispatch(SITEACTIONS.setSavedItems(items)),
});

async function fetchPodcastShows(val, setDataItems) {
  const data = await apiRequest({
    url: "/news/search/podcast",
    method: "POST",
    data: { query: val },
  }).then((res) => {
    return res.data.data;
  });
  setDataItems(data);
}

async function fetchEpisode(val, setDataItems) {
  const result = await apiRequest({
    url: "/news/search/podcast/episode",
    method: "POST",
    data: { query: val },
  }).then((res) => {
    return res.data.data;
  });
  setDataItems(result);
}

function podcastCategories(podcasts, searchVal) {
  const genres = podcasts.filter((item) =>
    item.genre.toLowerCase().includes(searchVal.toLowerCase())
  );

  const podcast = genres.map((item) => item.podcasts);
  const data = [].concat.apply([], podcast);
  return data;
}

function saveItem(item, props, type = false) {
  if (props.savedItems.find((ite) => ite.track_id == item.id)) {
    item.clicked = false;
  } else {
    item.clicked = true;
    props.addToPodcast(item);
    apiRequest({ url: "/news/save/podcast", method: "POST", data: item });
  }
}

function PodcastList(props) {
  const [dataItems, setDataItems] = useState([]);
  const [hoverItem, setHoverItem] = useState(false);
  useEffect(() => {
    if (props.filter == "podcastCategory" && props.query)
      setDataItems(podcastCategories(props.podcasts, props.query));
    else if (props.filter == "podcastShow" && props.query) {
      fetchPodcastShows(props.query, setDataItems);
    } else if (props.filter == "podcastEpisodes" && props.query) {
      fetchEpisode(props.query, setDataItems);
    }
  }, [props.filter, props.query]);

  return (
    <div className="d-flex h-100">
      <div className={`podcastList ${styles.PodcastList} mr-3 h-100`}>
        {dataItems.map((item) => (
          <PodcastListItem
            data={item}
            playPodCast={(item) => Player.playPodcast(item, "playNow")}
            onAdd={(item) => saveItem(item, props)}
            onHover={async (item) => {
              setHoverItem(item);
            }}
          />
        ))}
      </div>
      {hoverItem ? (
        <div className="ml-4 pl-2 pr-5 mr-3 mt-2">
          <h2 className={styles.secondaryHeading}> {hoverItem.title} </h2>{" "}
          <p className={styles.genContent}> {hoverItem.description} </p>{" "}
          {props.filter !== "podcastEpisodes" &&
          hoverItem.episode.length > 0 ? (
            <>
              <h2 className={styles.secondaryHeading}> Latest Episode </h2>{" "}
              <p className={`${styles.genContent} ml-4`}>
                {" "}
                {hoverItem.episode[0].title}{" "}
                <sub className="d-block mt-4">
                  {" "}
                  {hoverItem.episode[0].source}{" "}
                </sub>{" "}
              </p>{" "}
            </>
          ) : (
            ""
          )}{" "}
        </div>
      ) : (
        ""
      )}{" "}
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(PodcastList);
