import React, { Component } from "react";
import apiRequest from "../api/clientRequest";
import SITEACTIONS from "../redux/actions/site";
import { connect } from "react-redux";
import Environment from "../api/environment";
import moment from "moment";
import axios from "axios";
import musicPlayer from "../data/player";
var mapDispatchToProps = (dispatch) => ({
  setRecentPlayed: (items) => dispatch(SITEACTIONS.setRecentPlayed(items)),
  setLastPlayed: (items) => dispatch(SITEACTIONS.setLastPlayed(items)),
  setQueue: (item) => dispatch(SITEACTIONS.setQueue(item)),
});

const stations = ["Sports Station (AudioOne)"];

var mapStateToProps = (state) => {
  return {
    feedlyUser: state.news.feedlyUser,
    theme: state.site.theme,
    setSubscribedPodcasts: state.news.subscribedPodcasts,
    radioMode: state.news.radioMode,
    subscribedItems: state.site.subscribedItems,
    podcasts: state.news.podcasts,
    musicList: state.site.musicList,
    recentlyPlayed: state.site.recentlyPlayed,
    shorts: state.site.shorts,
    savedItems: state.site.savedItems,
    playlist: state.site.queue.items,
    playlists: state.news.playlists,
    importantFeed: state.news.importantFeed,
    subscribedItems: state.site.subscribedItems,
    todayfeed: state.news.todayNews,
    doNotPlay: state.news.doNotPlay,
  };
};

class MusicControl extends Component {
  state = {
    searchQuery: "",
    loading: true,
    results: [],
    feeds: [],
    spotifyToken: window.localStorage.getItem("spotify_token"),
    musicPlaylist: [],
  };

  MusicPlayer = musicPlayer();

  onUnload = (e) => {
    // the method that will be used for both add and remove event
  };

  componentDidMount() {
    console.log("adding listeneres");
    window.addEventListener("beforeunload", this.onUnload);
    window.addEventListener("message", this.receiveMessage.bind(this));
  }

  componentWillReceiveProps(nextProp) {
    if (nextProp.radioMode !== this.props.radioMode) this.addMusic();
  }

  receiveMessage(e) {
    if (e.data.type == "playQueue") {
      if (this.props.radioMode && e.data.items) {
        if (e.data.items.length == 1) {
          const newTrack = e.data.currentPlayed;
          if (newTrack.type == "spotify") {
            this.addShorts();
            this.addPodcast();
          } else if (newTrack.type == "podcast") {
            this.addNews();
          } else if (newTrack.type == "normal") {
            this.addMusic();
          }
        }
      }
      this.props.setQueue(e.data);
    }
    if (e.data.type == "setLastPlayed") {
      apiRequest({
        url: "/news/lastplayed",
        method: "POST",
        data: { duration: e.data.duration, track_id: e.data.track_id },
      });
    }
    if (e.data.type == "setAudioHistory") {
      apiRequest({
        url: "/news/audioqueue",
        method: "POST",
        data: { tracks: e.data.queue },
      });
    }
    if (e.data.type == "markArticleAsRead") {
      apiRequest({
        url: "/news/article/read",
        method: "POST",
        data: { article: e.data.article, feedlyUser: this.props.feedlyUser },
      });
    }

    if (e.data.type == "playerLoaded") {
      this.MusicPlayer.appLoaded();

      const player = document.getElementById("receiver").contentWindow;
      var obj = {};
      obj.type = "changeTheme";

      const theme = window.localStorage.getItem("theme");
      if (theme) {
        obj.theme = theme;
      } else {
        obj.theme = "color-theme";
      }

      player.postMessage(obj, Environment.PLAYER_URL);

      // apiRequest({
      //   url: "/news/lastplayed",
      //   method: "GET",
      // }).then((res) => {
      //   let lastPlayedAction = {
      //     type: "lastPlayedAcdd",
      //     data: res.data.item,
      //   };
      //   player.postMessage(lastPlayedAction, Environment.PLAYER_URL);
      // });

      // apiRequest({
      //   url: "/news/audioqueue",
      //   method: "GET",
      // }).then((res) => {
      //   let lastPlayedAction = {
      //     type: "setAudioHistory",
      //     data: res.data.data,
      //   };

      //   player.postMessage(lastPlayedAction, Environment.PLAYER_URL);
      // });

      // apiRequest({
      //   url: "/news/recentPlayed",
      //   method: "GET",
      // }).then((res) => {
      //   this.props.setRecentPlayed(res.data.data);
      // });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onUnload);
  }

  addShorts() {
    const shortSaved = this.props.savedItems.filter(
      (item) => item.type == "shortscategory"
    );

    var shorts = [];
    let shortCount = 0;

    if (shortSaved.length > 0) {
      const savedCats = shortSaved.map((item) => item.track_id);
      const allItems = this.props.shorts.filter((item) =>
        savedCats.includes(item.category)
      );
      shorts = allItems.map((item) => item.shorts);
    }

    if (shorts.length == 0) {
      shorts = this.props.shorts.map((item) => {
        return item.shorts;
      });
    }

    shorts = shorts.filter(
      (v, i, a) => a.findIndex((t) => t.id === v.id) === i
    );

    shorts = [].concat.apply([], shorts);
    shorts = shorts.sort((a, b) => b.published - a.published);
    if (shorts.length > 0) {
      shorts.filter((item) => {
        if (shortCount >= 2) return;
        const curDate = moment();
        const podDate = moment(item.published);
        if (podDate.diff(curDate, "days") <= 7) {
          const testItem = this.props.recentlyPlayed.find(
            (itee) => itee.id == item.id
          );
          var condition = true;
          if (testItem) {
            if (testItem.completed) {
              condition = false;
            } else condition = true;
          } else condition = true;

          if (condition) {
            if (
              !this.props.playlist.find((tr) => tr.id == item.id) &&
              this.checkDoNotPlay(item.id)
            ) {
              shortCount += 1;
              this.MusicPlayer.playShorts(item);
            }
          }
        }
      });
    }
  }

  addMusic(count = 6) {
    if (this.props.musicList.length > 0) {
      let tempArr = [...this.props.musicList];

      for (let i = tempArr.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [tempArr[i], tempArr[j]] = [tempArr[j], tempArr[i]];
      }

      let initialArr = tempArr[Math.floor(Math.random() * tempArr.length)];
      var tracksArr = [];
      while (tracksArr.length <= 5) {
        var rand =
          initialArr.tracks[
            Math.floor(Math.random() * initialArr.tracks.length)
          ];
        if (
          !tracksArr.find((tr) => tr.id == rand.id) &&
          !this.props.playlist.find((tr) => tr.id == rand.id) &&
          this.checkDoNotPlay(rand.id, rand.playlistId)
        )
          tracksArr.push(rand);
      }

      this.MusicPlayer.playSpotifyList(
        { ...initialArr, tracks: tracksArr },
        window.localStorage.getItem("spotify_token")
      );
    }
  }

  addPodcast(count = 1) {
    let podCount = 0;
    let countLimit = count;
    var radioPodcasts = [];

    if (
      this.props.subscribedItems.filter((item) => item.type == "podcast")
        .length > 0
    ) {
      const subPodcasts = this.props.subscribedItems
        .filter((item) => item.type == "podcast")
        .sort((a, b) => b.published - a.published);
      subPodcasts.filter((item) => {
        if (podCount >= countLimit) return;
        const curDate = moment();
        const podDate = moment(item.published);
        if (podDate.diff(curDate, "days") <= 7) {
          const testItem = this.props.recentlyPlayed.find(
            (itee) => itee.id == item.episode[0].id
          );

          var condition = true;
          if (testItem) {
            if (testItem.completed) {
              condition = false;
            } else condition = true;
          } else condition = true;

          if (condition) {
            if (
              !this.props.playlist.find((tr) => tr.id == item.episode[0].id) &&
              this.checkDoNotPlay(
                item.episode[0].id,
                item.episode[0].podcast_id
              )
            ) {
              podCount += 1;
              radioPodcasts.push({
                episode: item.episode[0],
                title: item.title,
              });
            }
          }
        }
      });
    }
    var currentPodcasts = [];

    if (this.props.podcasts.length > 0) {
      if (podCount < countLimit) {
        let podcasts = this.props.podcasts.map((item) => item.podcasts);
        podcasts = [].concat.apply([], podcasts);
        podcasts = podcasts.sort((a, b) => b.published - a.published);

        podcasts.map((podcast) => {
          if (podCount >= countLimit) return;
          const curDate = moment();
          const podDate = moment(podcast.published);
          if (podDate.diff(curDate, "days") <= 7) {
            const testItem = this.props.recentlyPlayed.find(
              (itee) => itee.id == podcast.episode[0].id
            );

            var condition = true;
            if (testItem) {
              if (testItem.completed) {
                condition = false;
              } else condition = true;
            } else condition = true;

            if (
              !this.props.playlist.find(
                (tr) => tr.id == podcast.episode[0].id
              ) &&
              this.checkDoNotPlay(
                podcast.episode[0].id,
                podcast.episode[0].podcast_id
              )
            ) {
              podCount += 1;
              currentPodcasts.push({
                episode: podcast.episode[0],
                title: podcast.title,
              });
            }
          }
        });
      }
    }

    radioPodcasts.push(...currentPodcasts);
    radioPodcasts.map((podcast) => {
      this.MusicPlayer.playPodcast(podcast);
    });
  }

  addNews() {
    if (this.props.playlists.length > 0) {
      let allTracks = [];
      const playlists = this.props.playlists.filter(
        (item) => !stations.includes(item.category)
      );
      allTracks = playlists.map((item) => {
        return item.articles;
      });

      allTracks = [].concat.apply([], allTracks);

      allTracks = allTracks.sort((a, b) => b.published - a.published);
      allTracks = allTracks.filter(
        (v, i, a) => a.findIndex((t) => t.id === v.id) === i
      );

      let subscribedCategories = this.props.subscribedItems.map(
        (item) => item.item_id
      );
      let subsArticles = this.props.todayfeed.filter((item) =>
        subscribedCategories.includes(item.category)
      );

      allTracks.unshift(...subsArticles);

      allTracks.unshift(...this.props.importantFeed);

      allTracks = allTracks.filter((item) => {
        const fItem = this.props.recentlyPlayed.find(
          (itee) => itee.id == item.id
        );

        if (fItem) {
          if (fItem.completed) {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      });

      let selTracks = [];
      for (var i = 0; selTracks.length <= 3; i++) {
        const selectedItem = allTracks[i];
        if (selectedItem)
          if (
            !this.props.playlist.find((tr) => tr.id == selectedItem.id) &&
            this.checkDoNotPlay(selectedItem.id, selectedItem.streamId)
          ) {
            selTracks.push(selectedItem);
          }
      }

      var that = this;
      var addTracks = async () => {
        const promises = selTracks.map(async (article) =>
          that.MusicPlayer.playAudio(article, true)
        );
        return Promise.all(promises);
      };

      addTracks().then((res) => console.log(res));
    }
  }

  checkDoNotPlay(id, secType = false) {
    return !this.props.doNotPlay.find((tr) => tr.item_id == id) && secType
      ? !this.props.doNotPlay.find((tr) => tr.item_id == secType)
      : true;
  }

  render() {
    return <div className={`themeContainer`}></div>;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MusicControl);
