import React, { Component } from "react";
import styles from "../styles/rightSidebar.module.css";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { connect } from "react-redux";
import ACTIONS from "../../redux/actions/team";
import TeamMember from "../TeamMember";
import EmailView from "../EmailView";
import EmailRecipients from "../EmailRecipients";
const mapStateToProps = (state) => ({
  tab: state.team.emailTab,
  theme: state.site.theme,
});

const mapDispatchToProps = (dispatch) => ({
  setTab: (item) => dispatch(ACTIONS.setEmailTab(item)),
});

class TeamSidebar extends Component {
  handleTabChange(event, newValue) {
    this.props.setTab(newValue);
  }

  render() {
    const tabs = [
      <Tab label="Statistics" value={0} disableRipple={true} />,
      <Tab label="Recipients" value={1} disableRipple={true} />,
    ];

    return (
      <div
        className={`col-md-3 p-0 `}
        style={{
          backgroundImage:
            "linear-gradient(180deg, rgba(0, 0, 0, 0.84) 0%, rgba(0, 0, 0, 0.58) 48%, rgba(0, 0, 0, 0.69) 54%, rgba(0, 0, 0, 0.84) 100%)",
        }}
      >
        <div
          className={`histogram team-sidebar px-3 pb-3 pt-0 ${styles.histogram}`}
          style={{ overflow: this.props.tab == 0 ? "hidden" : "" }}
        >
          <div
            className={`histogram-logo ${styles.sidebarContainer} ${
              this.props.theme !== "black-theme" &&
              (this.props.tab == 1 || this.props.tab == 2)
                ? styles.tabColor
                : ""
            }`}
          >
            <Tabs
              value={this.props.tab}
              onChange={this.handleTabChange.bind(this)}
              TabIndicatorProps={{ style: { display: "none" } }}
              variant={"standard"}
              tabItemContainerStyle={{
                position: "fixed",
                bottom: "0",
              }}
            >
              {tabs.find((item, index) => index == this.props.tab)}
              {tabs.filter((item, index) => index != this.props.tab)}
            </Tabs>
          </div>
          <div className={styles.tabContainer}>
            {this.props.tab == 0 ? <EmailView /> : ""}
            {this.props.tab == 1 ? <EmailRecipients /> : ""}
            {/* {this.props.tab == 1 ? <QueueList /> : ""} */}
            {/* {this.props.tab == 2 ? <RecentPlayed /> : ""} */}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamSidebar);
