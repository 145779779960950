import env from '../api/environment'
import $ from 'jquery'
import axios from 'axios'
import { act } from 'react-dom/test-utils'

// test deployment
const player = function () {
  var obj = {
    player: document.getElementById('receiver').contentWindow,
    playCount: 0,
    postMessage(obj) {
      $('#receiver').addClass('popup')
      this.player.postMessage(obj, env.PLAYER_URL)
    },
    toggleStation(mode, playType, data) {
      var obj = { type: 'stationToggle', data, mode, playType }
      this.postMessage(obj)
    },
    updateRecentPlayed(recentPlayed) {
      var obj = {}
      obj.type = 'updateRecentPlayed'
      obj.tracks = recentPlayed
      this.player.postMessage(obj, env.PLAYER_URL)
    },
    async playNext(item, type) {
      if (type == 'news') item = await this.createNewsAudio(item)
      else if (type == 'shorts') item = await this.createShort(item)
      else if (type == 'podcast') item = await this.createPodcast(item)

      var obj = { ...item, type: 'playNext' }
      this.postMessage(obj)
    },
    updateRecentPlayed(recentPlayed) {
      var obj = {}
      obj.type = 'updateRecentPlayed'
      obj.tracks = recentPlayed
      this.player.postMessage(obj, env.PLAYER_URL)
    },
    changeTheme(theme) {
      var obj = {}
      obj.type = 'changeTheme'
      obj.theme = theme

      this.postMessage(obj)
    },
    async playQueue(item) {
      var obj = {}
      obj.data = item
      obj.type = 'playQueue'
      this.postMessage(obj)
    },
    async sendSpotifyToken(token) {
      var obj = {}
      obj.refreshToken = window.localStorage.getItem('spotify_refresh')
      obj.type = 'spotifyToken'
      obj.token = window.localStorage.getItem('spotify_token')
      this.postMessage(obj)
    },
    async playSpotifyList(item, action = false) {
      var obj = {}
      obj.tracks = item.tracks
      obj.type = 'spotifylist'
      obj.subtype = action
      obj.token = window.localStorage.getItem('spotify_token')
      this.postMessage(obj)
    },
    async playSpotifyTrack(item, type = 'spotifyplay') {
      var obj = {}
      obj.link = item.url ? item.url : item.link
      obj.sid = item.id
      obj.image = item.image
      obj.title = item.title
      obj.duration = item.duration
      obj.type = type
      obj.subtype = 'music'
      obj.tracktype = 'spotify'
      obj.token = window.localStorage.getItem('spotify_token')
      obj.recent = item.recent ? item.recent : false
      obj.refreshToken = window.localStorage.getItem('spotify_refresh')

      this.postMessage(obj)
    },
    async addSpotifyTrack(item) {
      var obj = {}
      obj.link = item.url
      obj.sid = item.id
      obj.image = item.image
      obj.title = item.title
      obj.duration = item.duration
      obj.type = 'spotifyaddplay'
      obj.tracktype = 'spotify'
      obj.token = window.localStorage.getItem('spotify_token')
      obj.recent = item.recent ? item.recent : false
      obj.refreshToken = window.localStorage.getItem('spotify_refresh')

      this.postMessage(obj)
    },
    createPodcast(item) {
      var obj = {}
      obj.link = item.episode.link
      obj.sid = item.episode.id
      obj.title = `${item.episode.title.replace(/[^\w\s]/gi, '')} :  ${
        item.title
      }`
      obj.image = item.episode.image
      obj.length = item.episode.audioLength
      obj.description = item.episode.description
      obj.type = 'newaudio'
      obj.tracktype = 'podcast'
      obj.podcast = true
      obj.recent = item.recent ? item.recent : false
      obj.isCampaignPage = item.isCampaign ? item.isCampaign : false
      obj.summary = item['summary']
      return obj
    },
    async playPodcast(item, playAction = false) {
      var obj = this.createPodcast(item)
      if (playAction == 'playNow') {
        obj['type'] = 'playNow'
      }

      this.postMessage(obj)
    },

    createShort(item) {
      var obj = {}
      obj.link = item.link
      obj.sid = item.id
      obj.title = `${item.title.replace(/[^\w\s]/gi, '')} : ${item.source}`
      obj.image = item.image
      obj.length = item.length
      obj.description = item.description
      obj.type = 'newaudio'
      obj.tracktype = 'shorts'
      obj.shorts = true
      obj.isCampaignPage = item.isCampaign ? item.isCampaign : false
      return obj
    },
    async playShorts(item, playAction = false) {
      var obj = this.createShort(item)
      if (playAction == 'playNow') {
        obj['type'] = 'playNow'
      }

      this.postMessage(obj)
    },
    async playRecentShorts(item) {
      var obj = {}
      obj.link = item.link
      obj.sid = item.id
      obj.title = `${item.title.replace(/[^\w\s]/gi, '')} : ${
        item.title.split(':')[1]
      }`

      obj.image = item.image
      obj.length = item.length
      obj.description = item.description
      obj.type = 'newaudio'
      obj.tracktype = 'shorts'
      obj.shorts = true
      obj.recent = true
      obj.isCampaignPage = item.isCampaign ? item.isCampaign : false
      this.postMessage(obj)
    },
    async playRecentPodcast(item) {
      var obj = item
      obj.sid = item.id
      obj.type = 'newaudio'
      obj.tracktype = 'podcast'
      obj.recent = true
      obj.podcast = true
      obj.isCampaignPage = item.isCampaign ? item.isCampaign : false

      this.postMessage(obj)
    },
    async updateOrder(order) {
      var obj = {
        type: 'updateQueue',
        order: order,
      }
      this.player.postMessage(obj, env.PLAYER_URL)
    },
    async removeItem(item, podcastremove) {
      var obj = {
        type: 'removeItem',
        track: item,
        podcastremove,
      }
      this.player.postMessage(obj, env.PLAYER_URL)
    },
    async appLoaded() {
      var obj = {
        type: 'appLoaded',
      }
      this.player.postMessage(obj, env.PLAYER_URL)
    },
    async createNewsAudio(item, isPlaylist = false, isPost) {
      this.playCount += 1
      var musicParam = ''
      if (this.playCount == 6) {
        this.playCount = 1
        musicParam = 'music'
      }

      const response = await axios
        .post(`${env.tts_url}/getArticle`, {
          article: item,
          isPlaylist: isPlaylist,
          fyi: true,
        })
        .then((res) => {
          if (res.data.error) {
            return
          }
          var data = res.data
          var obj = {}

          obj.title = `${item.title} : ${item.source}`
          obj.link = isPost
            ? isPost
            : `${env.tts_url}/getAudio/${data.request_id}/${musicParam}`
          obj.sid = item.id
          obj.image = data.image
          obj.description = data.description
          obj.recent = item.recent ? item.recent : false
          obj.isCampaignPage = item.isCampaign ? item.isCampaign : false
          obj.type = 'newaudio'

          return obj
        })
        .catch((err) => false)

      return response
    },
    async playAudio(item, isPlaylist = false) {
      var obj = await this.createNewsAudio(item, isPlaylist)
      if (isPlaylist == 'playNow') {
        obj['type'] = 'playNow'
      }
      this.postMessage(obj)
      return true
    },
    async videoEnded(item) {
      var obj = {}
      obj.title = `${item.title} : ${item.source}`
      obj.sid = item.id
      obj.link = ''
      obj.image = item.image
      obj.type = 'videoEnded'
      obj.tracktype = 'youtube'
      this.postMessage(obj)
      return true
    },
    async setVideoTimer(remTime, duration) {
      var obj = {}
      obj.duration = duration
      obj.remTime = remTime
      obj.type = 'videoTimer'
      obj.tracktype = 'youtube'
      this.postMessage(obj)
      return true
    },

    async playVideo(item, action) {
      var obj = {}
      obj.title = `${item.title} : ${item.source}`
      obj.sid = item.id
      obj.link = ''
      obj.image = item.image
      obj.length = item.length
      obj.type = action ? action : 'playVideo'
      obj.tracktype = 'youtube'
      obj.subtype = 'video'
      this.postMessage(obj)

      return true
    },
    async setVideoControl(item) {
      var obj = {}
      obj.videoControl = JSON.parse(JSON.stringify(item))
      console.log(item)
      obj.type = 'videoControl'
      this.postMessage(obj)

      return true
    },
    async playTeamBrand(item) {
      const announcer = `You're listening to AudioOne Radio. The following is brought to you by ${item.name}. ${item.description}.`

      var obj = {
        title: `${item.name} : ${announcer}`,
        image: item.logo,
        description: item.description,
        announcer: announcer,
      }

      obj['type'] = 'teamBrand'

      this.postMessage(obj)

      return true
    },
  }

  return obj
}

export default player
