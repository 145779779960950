import React from "react";
import contentStyle from "./styles/contentstyle.module.css";
import styles from "./styles/nowPlaying.module.css";
import player from "../data/player";
import { connect } from "react-redux";
import ShareModal from "./shareModal";
import ShareMethods from "./ShareMethods";

var mapStateToProps = (state) => {
  return {
    queue: state.site.queue,
    savedItems: state.site.savedItems,
    completedPodcast: state.site.completedPodcast,
  };
};

function TeamQueueCard(props) {
  const img = React.useRef(null);
  const [teamColor, setTeamColor] = React.useState("black");
  const [fetchingColro, setFetchingColor] = React.useState(false);

  if (img.current && !fetchingColro) {
    const { image } = props.data;
    const colorThief = new window.ColorThief();
    let tempImage = img.current;
    setFetchingColor(true);

    if (tempImage.complete) {
      console.log("got color");
      const color = colorThief.getColor(tempImage);
      if (color) setTeamColor(`rgb(${color[0]},${color[1]},${color[2]})`);
    } else {
      tempImage.addEventListener("load", function () {
        const color = colorThief.getColor(tempImage);
        if (color) setTeamColor(`rgb(${color[0]},${color[1]},${color[2]})`);
      });
    }
  }

  const { name, description, logo } = props.data;

  return (
    <div
      className={`${contentStyle.playingContainer} ${
        contentStyle.teamQueueCard
      } ${
        props.queue && props.queue.currentPlayed.id !== "1"
          ? contentStyle.playingSub
          : ""
      }`}
      style={{
        background: teamColor,
      }}
    >
      <div>
        <div className={contentStyle.teamTitleContainer}>
          <img src={logo} crossorigin="anonymous" ref={img} alt="" />
          {/* <h3>{name}</h3> */}
        </div>
        <div className="align-items-center mb-3 mt-2 ">
          <p className={`d-block ${contentStyle.teamDescription} `}>
            {description}
          </p>
        </div>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, null)(TeamQueueCard);
