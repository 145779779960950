import React, { Component } from "react";
// import PodcastContainer from "./PodcastoldContainer";
import player from "../../data/player";
import { connect } from "react-redux";
import ACTIONS from "../../redux/actions/search";
import styles from "../styles/podcastInfo.module.css";
import PodcastContainer from "../PodcastContainer";
import PodcastItem from "../PodcastItem";
import _ from "lodash";
import ShortsContainer from "../ShortsContainer";
import ScrollContainer from "react-indiana-drag-scroll";
import MainSlider from "../MainSlider";
import InfiniteScroll from "react-infinite-scroller";

var mapStateToProps = (state) => {
  return {
    podcasts: state.news.podcasts,
    searchType: state.search.searchType,
    searchVal: state.search.searchVal,
    subscribedItems: state.site.subscribedItems,
    searchType: state.search.searchType,
    instantSearch: state.search.instantSearch,
  };
};

var mapDispatchToProps = (dispatch) => ({
  setSearchValue: (item) => dispatch(ACTIONS.setSearchValue(item)),
});

function search(array, keyword) {
  const regExp = new RegExp(keyword, "gi");
  const check = (obj) => {
    if (obj !== null && typeof obj === "object") {
      return Object.values(obj).some(check);
    }
    if (Array.isArray(obj)) {
      return obj.some(check);
    }
    return (
      (typeof obj === "string" || typeof obj === "number") && regExp.test(obj)
    );
  };
  return array.filter(check);
}

class PodcastBoard extends Component {
  state = {
    podcasts: this.props.podcasts,
    podcastBoardHeading: "Latest",
  };

  constructor(props) {
    super(props);

    this.player = player();
  }

  componentDidMount() {
    const mainPage = document.querySelector(".main-page");
    mainPage.addEventListener("scroll", this.onScroll);
  }
  componentWillUnmount() {
    const mainPage = document.querySelector(".main-page");
    mainPage.removeEventListener("scroll", this.onScroll);
  }

  onScroll = () => {
    const mainPage = document.querySelector(".main-page");
    const mainSlider = document.querySelector(".podcastContainer .mainSlider");
    mainSlider.style.opacity = 1 - mainPage.scrollTop / 500;
  };
  componentWillReceiveProps(nextProp) {
    this.updatePodcast(nextProp);
  }
  updatePodcast(prop) {
    let podcasts = _.cloneDeep(prop.podcasts);
    if (prop.searchVal) {
      if (prop.searchType == "podcastCategory") {
        if (prop.instantSearch) {
          podcasts = podcasts.reduce(function (filtered, item) {
            const filteredArticles = item.podcasts.filter((art) =>
              art.title.toLowerCase().includes(prop.searchVal.toLowerCase())
            );
            if (filteredArticles.length > 0) {
              let tempObj = item;
              tempObj.podcasts = filteredArticles;
              filtered.push(tempObj);
            }
            return filtered;
          }, []);
        }
        // podcasts = podcasts.filter(item =>
        //   item.genre.toLowerCase().includes(prop.searchVal.toLowerCase())
        // );
      }
    } else if (!prop.searchVal && prop.instantSearch) {
      this.setState((prev) => ({
        podcasts: podcasts,
      }));
    }

    if (this.state.podcastBoardHeading == "Latest") {
      this.setState((prev) => ({
        podcasts: podcasts,
      }));
    }
  }

  async playPodCast(item, playAction = false) {
    this.player.playPodcast(item, playAction);
  }

  playNextPodcast(item) {
    this.player.playNext(item, "podcast");
  }

  selectPodcastTag(tag) {
    if (tag == "ALL") {
      this.setState((prev) => ({
        podcasts: this.props.podcasts,
        podcastBoardHeading: "Latest",
      }));
    } else if (tag == "SUBSCRIBED") {
      this.refreshSubscribed();
    } else
      this.setState((prev) => ({
        podcasts: this.props.podcasts.filter((item) => item.genre == tag),
        podcastBoardHeading: tag,
      }));
  }

  refreshSubscribed(id = null) {
    let subItems = this.props.subscribedItems.filter(
      (item) => item.type == "podcast"
    );
    if (id) subItems = subItems.filter((item) => item.item_id !== id);

    var dataItems = _.chain(subItems)
      .groupBy("genre")
      .map((value, key) => ({ genre: key, podcasts: value }))
      .value();

    dataItems = dataItems.map((item) => {
      var newObj = {
        genre: item.genre,
        podcasts: _.clone(item.podcasts),
      };
      return newObj;
    });
    this.setState((prev) => ({
      podcasts: dataItems,
      podcastBoardHeading: "Subscribed",
    }));
  }

  render() {
    let podcasts = this.state.podcasts.map((item) => item.podcasts);
    podcasts = [].concat.apply([], podcasts);
    podcasts = podcasts.sort((a, b) => b.published - a.published);
    podcasts = podcasts.filter((item) => item.episode.length > 0);

    let sliderItems = [
      ...this.props.subscribedItems.filter((item) => item.type == "podcast"),
    ];

    sliderItems = sliderItems.map((item) => {
      return {
        type: "podcast",
        subtype: "Subscribed Podcasts",
        ...item.episode[0],
      };
    });

    sliderItems = [
      ...sliderItems,
      ...podcasts.slice(0, 5).map((item) => {
        return {
          type: "podcast",
          subtype: "Prefered Podcasts",
          ...item.episode[0],
        };
      }),
    ];

    sliderItems = sliderItems.filter((item) => item.image);

    return (
      <div
        className="col-md-9 podcastContainer"
        style={{ display: this.props.style, paddingTop: "430px" }}
      >
        <MainSlider items={sliderItems} type="Podcasts" />
        <div className="w-100">
          <div className="row scroll pl-3 pr-0 mx-0 w-100">
            <div className="col-md-11 pr-5 mb-2">
              <ScrollContainer>
                <PodcastContainer
                  selectPodcastTag={this.selectPodcastTag.bind(this)}
                />
              </ScrollContainer>
            </div>

            <div
              className="col-md-11 pr-5"
              style={{ display: this.props.instantSearch ? "none" : "block" }}
            >
              <h5
                className={`pl-3 w-100`}
                style={{ textTransform: "capitalize" }}
              >
                <img
                  src="/images/Radio.png"
                  style={{ width: "30px", marginRight: "1em" }}
                />
                AudioOne Shorts{" "}
              </h5>
              <ShortsContainer />
            </div>
            <h5
              className={`pl-3 w-100`}
              style={{ textTransform: "capitalize", zIndex: 999 }}
            >
              {this.state.podcastBoardHeading
                ? this.state.podcastBoardHeading
                : "Latest"}{" "}
              Podcast Episodes{" "}
              {this.props.searchVal ? (
                <button
                  className={` ${styles.subscribeBtn} ml-3`}
                  onClick={() => this.props.setSearchValue("")}
                >
                  Show All
                </button>
              ) : (
                ""
              )}
            </h5>
            <div className="row row-eq-height mx-0 w-100">
              {podcasts.map((item) => {
                return (
                  <PodcastItem
                    data={item}
                    playPodCast={this.playPodCast.bind(this)}
                    playNext={this.playNextPodcast.bind(this)}
                    subscribedChanged={(id) => {
                      this.refreshSubscribed(id);
                    }}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PodcastBoard);
