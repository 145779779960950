import React, { Component } from "react";
import styles from "./styles/nowPlaying.module.css";
import player from "../data/player";
import ShareModal from "./shareModal";
import axios from "axios";
import { connect } from "react-redux";
import ACTIONS from "../redux/actions/site";
import apiRequest from "../api/clientRequest";

var mapStateToProps = state => {
  return {
    savedItems: state.site.savedItems
  };
};

var mapDispatchToProps = dispatch => ({
  setSavedItems: items => dispatch(ACTIONS.setSavedItems(items))
});

class ArticleNowPlaying extends Component {
  state = {
    shouldDisplay: true,
    selectedTab: "episode",
    selectedArticle: null,
    shareVisibile: false,
    articleImage: ""
  };
  player = player();

  setModalVisibility(cond) {
    this.setState({
      shareVisibile: cond
    });
  }

  setSelectArticle(article) {
    this.setState({
      selectedArticle: article
    });
  }

  extractHostname(url) {
    var hostname;
    //find & remove protocol (http, ftp, etc.) and get hostname

    if (url.indexOf("//") > -1) {
      hostname = url.split("/")[2];
    } else {
      hostname = url.split("/")[0];
    }

    //find & remove port number
    hostname = hostname.split(":")[0];
    //find & remove "?"
    hostname = hostname.split("?")[0];

    return hostname.replace("www.", "");
  }

  componentDidMount() {
    let sourceUrl = this.props.data.sourceUrl;
    sourceUrl = this.extractHostname(sourceUrl);
    axios.get(`https://favicongrabber.com/api/grab/${sourceUrl}`).then(res => {
      if (res.data.icons)
        this.setState({
          articleImage: `//images.weserv.nl/?url=${res.data.icons[0].src}`
        });
    });
  }

  saveItem(item) {
    item.type = "news";
    if (this.props.savedItems.find(ite => ite.track_id == item.id)) {
      item.clicked = false;
      this.props.setSavedItems(
        this.props.savedItems.filter(ite => ite.track_id !== item.id)
      );
      apiRequest({ url: "/news/save", method: "POST", data: item });
    } else {
      item.clicked = true;
      apiRequest({ url: "/news/save", method: "POST", data: item });
      this.props.setSavedItems([
        ...this.props.savedItems.map(ite => ite),
        { track_id: item.id, type: "news" }
      ]);
    }
  }

  render() {
    const { title, description, source, image } = this.props.data;
    return (
      <div className={`${styles.content} ${styles.podcastContent}`}>
        {this.state.shouldDisplay ? (
          <>
            <div className="d-flex flex-wrap">
              <ShareModal
                visible={this.state.shareVisibile}
                setVisiblity={this.setModalVisibility.bind(this)}
                selectedItem={this.state.selectedArticle}
                setSelectedArticle={this.setSelectArticle.bind(this)}
              />
              <h1 className={`${styles.title} ${styles.podcastHead}`}>
                <img
                  src="/images/feedlyWhite.png"
                  className={styles.typeImage}
                />
                News
              </h1>
              <div className={styles.contentGradient}></div>
              <div
                className={styles.titleContainer}
                style={{ marginTop: "8em" }}
              >
                <h1 className={styles.title}>{title}</h1>
                <div
                  className="d-flex align-items-center"
                  style={{ marginBottom: "3em" }}
                >
                  <img
                    src={this.state.articleImage}
                    className={`${styles.sourceImg} ${
                      this.state.articleImage ? "" : "d-none"
                    }`}
                  />
                  <span className={styles.source}>{source}</span>
                </div>
                <p
                  className={styles.artContent}
                  ref={el => {
                    this.contentElement = el;
                  }}
                  dangerouslySetInnerHTML={{
                    __html: description.replace(/<img[^>]*>/g, "")
                  }}
                ></p>

                {this.contentElement ? (
                  this.contentElement.offsetHeight <
                    this.contentElement.scrollHeight ||
                  this.contentElement.offsetWidth <
                    this.contentElement.scrollWidth ? (
                    <img
                      src="/images/down-menu-arrow-botton.png"
                      className={styles.downArrow}
                    />
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div>
              <div className={styles.iconContainer}></div>
            </div>
            <div style={{ marginTop: "20em" }}>
              <div className="mb-3 text-center">
                <div className={styles.playingIcons}>
                  <img src="/images/Shape.png" />
                </div>
                Comment
              </div>
              <div className="mb-3 text-center">
                <div className={styles.playingIcons}>
                  {this.props.savedItems.find(
                    item => item.track_id == this.props.data.id
                  ) ? (
                    <img
                      src="/images/HeartFill.png"
                      onClick={() => this.saveItem(this.props.data)}
                      alt=""
                    />
                  ) : (
                    <img
                      src="/images/Heart.png"
                      onClick={() => this.saveItem(this.props.data)}
                      alt=""
                    />
                  )}
                </div>
                513
              </div>
              <div className="mb-3 text-center">
                <div className={styles.playingIcons}>
                  <img src="/images/Ellipse_1.png" />
                </div>
                View
              </div>
              <div
                className="mb-3 text-center"
                onClick={() => {
                  this.setModalVisibility(true);
                  this.setSelectArticle(this.props.data);
                }}
              >
                <div className={styles.playingIcons}>
                  <img src="/images/cluster-data-group-organize.png" />
                </div>
                Share
              </div>
            </div>

            {this.props.playing ? (
              <img
                src="/images/pause.png"
                className={styles.playIcon}
                onClick={() => {
                  this.player.playQueue(this.props.data);
                }}
              />
            ) : (
              <img
                src="/images/playIcon.png"
                className={styles.playIcon}
                onClick={() => {
                  this.player.playQueue(this.props.data);
                }}
              />
            )}
          </>
        ) : (
          <h1>Nothing Playing</h1>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticleNowPlaying);
