import React from "react";
import styles from "../styles/search.module.css";
import { playIcon, actionIcon } from "../styles/newsitem.module.css";
import SliderItemLikeHover from "../SliderItemLikeHover";
import SliderItemShareHover from "../SliderItemShareHover";
import ShareModal from "../shareModal";
import { connect } from "react-redux";
import ACTIONS from "../../redux/actions/site";
import apiRequest from "../../api/clientRequest";

var mapStateToProps = (state) => {
  return {
    savedItems: state.site.savedItems,
  };
};

var mapDispatchToProps = (dispatch) => ({
  setSavedItems: (items) => dispatch(ACTIONS.setSavedItems(items)),
});

function NewsListItem(props) {
  const { title, image, source } = props.data;
  const [likeHover, setLikeHover] = React.useState(false);
  const [shareHover, setShareHover] = React.useState(false);
  const [mode, setMode] = React.useState(false);
  const [shareVisible, setShareVisible] = React.useState(false);

  const saveItem = (item) => {
    if (props.savedItems.find((ite) => ite.track_id == item.id)) {
      item.clicked = false;
      props.setSavedItems(
        props.savedItems.filter((ite) => ite.track_id !== item.id)
      );

      apiRequest({ url: "/news/save", method: "POST", data: item });
    } else {
      item.clicked = true;
      apiRequest({ url: "/news/save", method: "POST", data: item });
      props.setSavedItems([
        { track_id: item.id, type: "news", ...item },
        ...props.savedItems.map((ite) => ite),
      ]);
    }
  };
  const setSelectArticle = (mode) => {
    setMode(mode);
    setShareVisible(true);
  };
  return (
    <div
      className="d-flex pb-2 pt-3"
      style={{
        position: "relative",
        lineHeight: "1.5em",
        marginBottom: "1rem",
      }}
    >
      <img src={image} className={styles.podcastItemImage} />
      <div className={`w-75 ${styles.podcastItemTitle}`}>
        {title}
        <span className="mt-3 d-block">{source}</span>
      </div>
      <img
        src="/images/cluster.png"
        alt=""
        onMouseEnter={() => setShareHover(true)}
        className={`${actionIcon} mr-3 ml-4 mt-1`}
      />
      {props.savedItems.find((item) => item.track_id == props.data.id) ? (
        <img
          src="/images/HeartFill.png"
          className={`${actionIcon} mr-3  mt-1`}
          onClick={() => props.onAdd(props.data)}
          onMouseEnter={() => setLikeHover(true)}
          alt=""
        />
      ) : (
        <img
          src="/images/Heart.png"
          className={`${actionIcon} mr-3  mt-1`}
          onClick={() => props.onAdd(props.data)}
          onMouseEnter={() => setLikeHover(true)}
          alt=""
        />
      )}

      <ShareModal
        visible={shareVisible}
        setVisiblity={() => {
          setShareVisible(false);
          // props.setAutoPlay(true);
        }}
        selectedItem={props.data}
        type={props.data.type}
        setSelectedArticle={setSelectArticle}
        mode={mode}
      />

      <SliderItemLikeHover
        visible={likeHover}
        setLikeHover={setLikeHover}
        saveToCollection={() => saveItem(props.data)}
        selectedArticle={props.data}
        type={props.data.type}
        insideSearch={true}
      />

      <SliderItemShareHover
        visible={shareHover}
        setShowHover={setShareHover}
        setSelected={(mode) => setSelectArticle(mode)}
        insideSearch={true}
      />
      <img
        src={"/images/playIcon.png"}
        className={playIcon}
        onClick={() => props.playArticle(props.data)}
      />
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsListItem);
