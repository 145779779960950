import axios from "axios";
import environment from "./environment";

const apiRequest = function (options) {
  const authToken = window.localStorage.getItem("authToken");

  const onSuccess = (response) => {
    // log each request response
    return response;
  };
  const onError = function (error) {
    if (error.response)
      // some error happened with the server side
      console.log(error.response);
    // some error happened while processing the request
    else console.error("Error Message:", error.message);
    return Promise.reject(error.response || error.message);
  };
  return axios({
    baseURL: environment.api.URL,
    ...options,
    headers: { Authorization: `Bearer ${authToken}`, Uid: localStorage.getItem('sub') },
  })
    .then(onSuccess)
    .catch(onError);
};
export default apiRequest;
