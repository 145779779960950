const prod = {
  api: {
    URL: "https://fmback.audioone.cloud/api/v1",
  },

  serverurl: "https://fmback.audioone.cloud/",
  GOOGLE_CLIENT_ID:
    "658063193596-pnfteohj77tr64u68iuarubft6tpipg4.apps.googleusercontent.com",
  FACEBOOK_APP_ID: "380088062878594",
  TOMTOM: "dK07vJzhtvmkNJt44D4RwSVJt7GmFaW7",
  WHEATHERSTACK: "953f81c99ab907e28ce5d3dd820011bd",
  GEOCODE: "AIzaSyBpB8NUTEKPyDfOzPrG8eq81Tzh8QifrkU",
  PLAYER_URL: "https://playerprod.audioone.cloud",

  googleClientSecret: "zRZyCXFoen_PxT6tsLFIVGwk",
  tts_url: "let u",
};

const dev = {
  api: {
    URL: "https://fmbackdev.audioone.cloud/api/v1",
  },
  serverurl: "https://fmbackdev.audioone.cloud/",
  FACEBOOK_APP_ID: "380088062878594",
  TOMTOM: "dK07vJzhtvmkNJt44D4RwSVJt7GmFaW7",
  WHEATHERSTACK: "953f81c99ab907e28ce5d3dd820011bd",
  GEOCODE: "AIzaSyBpB8NUTEKPyDfOzPrG8eq81Tzh8QifrkU",
  PLAYER_URL: "https://playerdev.audioone.cloud",
  GOOGLE_CLIENT_ID:
    "658063193596-pnfteohj77tr64u68iuarubft6tpipg4.apps.googleusercontent.com",

  googleClientSecret: "zRZyCXFoen_PxT6tsLFIVGwk",
  tts_url: "https://ttsdev.audioone.cloud",
  // PLAYER_URL: "http://localhost:3002/",
};

const staging = {
  api: {
    URL: "https://fmbackstaging.audioone.cloud/api/v1",
  },
  serverurl: "https://fmbackstaging.audioone.cloud/",
  GOOGLE_CLIENT_ID:
    "658063193596-pnfteohj77tr64u68iuarubft6tpipg4.apps.googleusercontent.com",

  FACEBOOK_APP_ID: "380088062878594",
  TOMTOM: "dK07vJzhtvmkNJt44D4RwSVJt7GmFaW7",
  WHEATHERSTACK: "953f81c99ab907e28ce5d3dd820011bd",
  GEOCODE: "AIzaSyBpB8NUTEKPyDfOzPrG8eq81Tzh8QifrkU",
  PLAYER_URL: "https://playerdev.audioone.cloud",

  tts_url: "https://ttsdev.audioone.cloud",
};

const local = {
  api: {
    URL: "http://localhost:4000/api/v1",
  },
  serverurl: "http://localhost:4000/",
  GOOGLE_CLIENT_ID:
    "658063193596-pnfteohj77tr64u68iuarubft6tpipg4.apps.googleusercontent.com",

  FACEBOOK_APP_ID: "2455129998053566",
  TOMTOM: "dK07vJzhtvmkNJt44D4RwSVJt7GmFaW7",
  WHEATHERSTACK: "953f81c99ab907e28ce5d3dd820011bd",
  GEOCODE: "AIzaSyBpB8NUTEKPyDfOzPrG8eq81Tzh8QifrkU",
  PLAYER_URL: "https://playerdev.audioone.cloud",

  tts_url: "https://ttsdev.audioone.cloud",

  // PLAYER_URL: "http://localhost:3001/",
};

// console.log(process.env.REACT_APP_STAGE);

const config =
  process.env.REACT_APP_STAGE === "production"
    ? prod
    : process.env.REACT_APP_STAGE == "development"
    ? dev
    : process.env.REACT_APP_STAGE == "staging"
    ? staging
    : local;

export default {
  ...config,
};
