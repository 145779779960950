import React, { Component } from 'react'
import styles from './dashNavbar.module.css'
import { Link } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

const FrontNavbar = () => {
  const { loginWithRedirect } = useAuth0()

  return (
    <nav className='navbar head'>
      <div className='brand sign-brand'>
        <span>
          <img
            src='/images/AudioOneLogo@2x.png'
            alt=''
            className='ml-4'
            style={{ width: '150px', filter: 'brightness(0)' }}
          />
        </span>
      </div>
      <div className='links mr-4 d-flex'>
        {/* <SearchBox /> */}

        <Link className='mx-4' to='/'>
          Home
        </Link>
        <a
          href='#'
          onClick={() => {
            loginWithRedirect({ screen_hint: 'login' })
          }}
          className='mx-4'
        >
          Sign In
        </a>
        <a
          onClick={() => {
            loginWithRedirect({ screen_hint: 'signup' })
          }}
          className='mx-4'
          to='/signup'
        >
          Sign Up
        </a>
      </div>
    </nav>
  )
}

export default FrontNavbar
