import React, { Component } from "react";
import ContentNavbar from "../../components/contentsection/ContentNavbar";
import contstyles from "../../components/styles/contentstyle.module.css";
import apiRequest from "../../api/clientRequest";
import player from "../../data/player";
import DashboardFooter from "../../components/Footer/DashboardFooter";
import Axios from "axios";
import SITEACTIONS from "../../redux/actions/site";
import { connect } from "react-redux";
import Environment from "../../api/environment";
import { sourceImg } from "../../components/styles/nowPlaying.module.css";
import { Carousel } from "antd";
import QueueCard from "../../components/QueueCard";
import ScrollContainer from "react-indiana-drag-scroll";
import TeamQueueCard from "../../components/TeamQueueCard";
import CommentContainer from "../../components/contentsection/CommentContainer";
import DetailContainer from "../../components/contentsection/DetailContainer";
import contentstyle from "../../components/contentsection/contentpage.module.css";
import VideoQueueCard from "../../components/VideoQueueCard";
import newstyle from "../../components/styles/newui.module.css";

// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { Carousel } from "antd";

import ContentMetaTag from "../../components/contentsection/ContentMetaTag";
var mapDispatchToProps = (dispatch) => ({
  setQueue: (item) => dispatch(SITEACTIONS.setQueue(item)),
});

var mapStateToProps = (state) => {
  return {
    queue: state.site.queue,
    user: state.auth.user,
  };
};

class ContentPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      media: null,
      type: null,
      related: [],
      trackPlaying: false,
      playAll: [],
      team: null,
      activeActivity: null,
      showComments: false,
      showDetail: false,
      activeSlide: 0,
    };

    if (!this.props.match.params.emailid) this.props.history.push("/");
    else {
      const emailId = this.props.match.params.emailid;
      const userId = this.props.match.params.userid;
      apiRequest({
        url: `/email/${emailId}/${userId}`,
        method: "GET",
      }).then((res) => {
        let related = res.data.media.related.map((item) => {
          item["recent"] = true;
          return item;
        });
        let media = res.data.media;
        if (media.type == "news" && !media.description) {
          media = related.find((item) => item.description);
          related = related.filter((item) => item.id !== media.id);
        }

        this.setState(
          {
            media: { ...media, recent: true },
            type: media.type,
            related,
            team: res.data.team,
          },
          () => {
            this.extractSourceImage();
          }
        );
      });
    }
  }

  componentDidMount() {
    window.addEventListener("beforeunload", this.onUnload);
    window.addEventListener("message", this.receiveMessage.bind(this));
  }

  receiveMessage(e) {
    if (e.data.type == "playQueue") {
      this.props.setQueue(e.data);
    }

    if (e.data.type == "playerLoaded") {
      this.player.appLoaded();

      const player = document.getElementById("receiver").contentWindow;
      var obj = {};
      obj.type = "changeTheme";

      obj.theme = "black-theme";
      player.postMessage(obj, Environment.PLAYER_URL);
      player.postMessage({ type: "disableShuffle" }, Environment.PLAYER_URL);
    }
  }

  extractHostname(url) {
    var hostname;
    //find & remove protocol (http, ftp, etc.) and get hostname

    if (url.indexOf("//") > -1) {
      hostname = url.split("/")[2];
    } else {
      hostname = url.split("/")[0];
    }

    //find & remove port number
    hostname = hostname.split(":")[0];
    //find & remove "?"
    hostname = hostname.split("?")[0];

    return hostname.replace("www.", "");
  }

  async extractSourceImage() {
    if (this.state.media.type == "music") return;
    let items = [this.state.media, ...this.state.related];
    items.map(async (item) => {
      let sourceUrl = item.link;
      if (!sourceUrl) return;
      sourceUrl = this.extractHostname(sourceUrl);
      await Axios.get(`https://favicongrabber.com/api/grab/${sourceUrl}`).then(
        (res) => {
          if (res.data.icons.length > 0) {
            if (item.id == this.state.media.id) {
              let media = this.state.media;
              media[
                "sourceImage"
              ] = `//images.weserv.nl/?url=${res.data.icons[0].src}`;
              this.setState({ media });
            } else {
              let related = this.state.related;
              const foundIndex = this.state.related.findIndex(
                (ite) => ite.id == item.id
              );
              related[foundIndex][
                "sourceImage"
              ] = `//images.weserv.nl/?url=${res.data.icons[0].src}`;
              this.setState({ related });
            }
          }
        }
      );
    });
  }

  async playAudio(type, media) {
    console.log(type, media);
    if (type == "news") {
      await this.player.playAudio({ ...media, recent: false });
    } else if (type == "podcast")
      this.player.playPodcast({
        episode: media,
        title: media.source,
        recent: false,
      });
    else if (type == "music" || type == "spotify")
      this.player.playSpotifyTrack(media);
    else if (type == "video") this.player.playVideo(media);
    else
      this.player.playShorts({
        ...media,
        title: `${media.title} : ${media.source}`,
      });
  }

  addComment = async (comment) => {
    let activeActivity = this.state.activeActivity;
    activeActivity["reactions"]["comment"].unshift(comment);
    this.setState({ activeActivity });
  };

  player = player();

  render() {
    let items = [];
    const { activeSlide } = this.state;
    if (this.state.media) {
      items = [this.state.media, ...this.state.related];
    }

    const { playAll } = this.state;
    var currentPlayed = null;
    if (this.props.queue.currentPlayed.id) {
      currentPlayed = items.find(
        (item) => item.id == this.props.queue.currentPlayed.id
      );
    }

    var settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplaySpeed: 5000,
      pauseOnHover: false,
      fade: true,
      beforeChange: (current, next) => this.setState({ activeSlide: next }),
    };

    return (
      <div className={`themeContainer black-theme h-100`}>
        <div className="body-wrapper h-100">
          <ContentNavbar />
          <ContentMetaTag media={this.state.media} />
          <div className="main-page">
            {this.state.showComments ? (
              <CommentContainer
                comments={this.state.comments}
                activity={this.state.activeActivity}
                userId={this.props.user ? this.props.user._id : ""}
                userAvatar={this.props.user ? this.props.user.avatar : ""}
                addNewComment={(comment) => this.addComment(comment)}
                onClose={() =>
                  this.setState({ activeActivity: false, showComments: false })
                }
              />
            ) : (
              ""
            )}
            {this.state.showDetail ? (
              <DetailContainer
                activity={this.state.activeActivity}
                userId={this.props.user ? this.props.user._id : ""}
                onClose={() =>
                  this.setState({ showDetail: false, activeActivity: false })
                }
              />
            ) : (
              ""
            )}
            {items.length > 0 ? (
              <Carousel {...settings} style={{ height: "100vh" }}>
                {items.map((item, index) => {
                  return (
                    <div>
                      <div
                        className={`body-overlay ${contentstyle.backgroundOverlay}`}
                      ></div>
                      <div
                        className={`page-content contentPage ${newstyle.mainContainer}  ${contentstyle.backgroundImage}`}
                        style={{
                          backgroundImage: `url('${item.image}')`,
                        }}
                      >
                        <div>
                          <h1 className={contentstyle.itemType}>{item.type}</h1>
                        </div>
                        {item.type == "youtube" && activeSlide == index ? (
                          <div class={newstyle.videoBackground}>
                            <iframe
                              width="100%"
                              height="100%"
                              src={`https://www.youtube.com/embed/${item.id}?controls=0&showInfo=0&autoplay=1&start=20&mute=1`}
                              frameborder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowfullscreen
                            ></iframe>{" "}
                          </div>
                        ) : (
                          <div
                            style={{
                              backgroundImage: `url(${item.image})`,
                            }}
                          ></div>
                        )}
                        <div
                          className={`row  h-100 ${newstyle.itemContainer} mt-0`}
                        >
                          <div
                            className={`w-100 ${
                              this.state.trackPlaying ? "justifyLeft" : ""
                            }`}
                            style={{
                              display: `flex`,
                            }}
                          >
                            <div
                              className={`col-md-9  ${
                                this.state.trackPlaying ? "mobileFirst" : ""
                              }`}
                              style={{ display: this.props.style }}
                            >
                              <div>
                                {this.props.queue.currentPlayed["id"] ? (
                                  this.props.queue.currentPlayed.playing ? (
                                    <img
                                      src="/images/pause.png"
                                      alt=""
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        this.player.playQueue(
                                          this.props.queue.currentPlayed
                                        );
                                      }}
                                      className={contstyles.playIcon}
                                    />
                                  ) : (
                                    <img
                                      src="/images/playIconBig.png"
                                      alt=""
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        this.player.playQueue(
                                          this.props.queue.currentPlayed
                                        );
                                      }}
                                      className={contstyles.playIcon}
                                    />
                                  )
                                ) : (
                                  <img
                                    src="/images/playIconBig.png"
                                    alt=""
                                    style={{ cursor: "pointer" }}
                                    onClick={async () => {
                                      this.setState({ trackPlaying: true });
                                      let playAll = [item, ...items];

                                      playAll = playAll.filter(
                                        (v, i, a) =>
                                          a.findIndex((t) => t.id === v.id) ===
                                          i
                                      );

                                      this.setState({ playAll });
                                      if (
                                        this.state.team &&
                                        this.state.team.logo &&
                                        this.state.team.descripiton
                                      )
                                        await this.player.playTeamBrand(
                                          this.state.team
                                        );
                                      for (let i = 0; i < playAll.length; i++) {
                                        await this.playAudio(
                                          playAll[i]["type"],
                                          playAll[i]
                                        );
                                      }
                                    }}
                                    className={contstyles.playIcon}
                                  />
                                )}

                                <div>
                                  <div
                                    className={`${contstyles.titleContainer} titleContainer`}
                                    style={{
                                      flexDirection: "column",
                                      display: "flex",
                                    }}
                                  >
                                    <h5
                                      className="w-100 d-flex"
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {item.type == "podcast" ? (
                                        <img
                                          src="/images/Radio.png"
                                          className={contentstyle.actionBtn}
                                        />
                                      ) : item.type == "music" ? (
                                        <img
                                          src="/images/spotWhite.png"
                                          className={contentstyle.actionBtn}
                                        />
                                      ) : (
                                        <img
                                          src="/images/feedlyWhite.png"
                                          className={contentstyle.actionBtn}
                                        />
                                      )}{" "}
                                      {item.type}
                                    </h5>
                                    <h2 className={contstyles.artTitle}>
                                      <span>{item.title}</span>
                                      <h4
                                        className="mt-2 d-flex"
                                        style={{
                                          fontSize: "18px",
                                          fontWeight: "normal",
                                        }}
                                      >
                                        <img
                                          src={item["sourceImage"]}
                                          className={`${sourceImg}`}
                                        />
                                        {item.source}
                                      </h4>
                                    </h2>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Carousel>
            ) : (
              ""
            )}

            {this.state.trackPlaying ? (
              <div className={`col-md-3 campaignQueue ${contentstyle.queue}`}>
                <ScrollContainer
                  style={{ height: "4000px", overflowY: "scroll" }}
                >
                  {this.state.team && this.state.team.logo ? (
                    <TeamQueueCard data={this.state.team} />
                  ) : (
                    ""
                  )}

                  {playAll.map((ite) => {
                    return ite.type == "youtube" ? (
                      <VideoQueueCard
                        data={{ ...ite, type: ite.type }}
                        isQueue={false}
                        saveItem={(item) => this.saveItem(item)}
                      />
                    ) : (
                      <QueueCard
                        showComments={this.state.showComments}
                        activeActivity={this.state.activeActivity}
                        data={{ ...ite, type: ite.type }}
                        type={ite.type}
                        currentPlayed={currentPlayed}
                        playing={this.props.queue.currentPlayed.playing}
                        queue={this.props.queue}
                        showComments={() =>
                          this.setState({
                            showComments: true,
                            activeActivity: ite,
                          })
                        }
                        showDetail={() =>
                          this.setState({
                            showDetail: true,
                            activeActivity: ite,
                          })
                        }
                      />
                    );
                  })}
                </ScrollContainer>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <DashboardFooter />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentPage);
