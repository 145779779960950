import React, { Component } from "react";
import { connect } from "react-redux";
import ACTIONS from "../redux/actions/site";
import player from "../data/player";
import styles from "./styles/Queuelist.module.css";
import ScrollContainer from "react-indiana-drag-scroll";
import apiRequest from "../api/clientRequest";

var mapStateToProps = (state) => {
  return {
    queue: state.site.queue,
    currentlyPlaying: state.site.currentlyPlaying,
    recentlyPlayed: state.site.recentlyPlayed,
    savedItems: state.site.savedItems,
  };
};
var mapDispatchToProps = (dispatch) => ({
  setSavedItems: (items) => dispatch(ACTIONS.setSavedItems(items)),
  setRecentPlayed: (items) => dispatch(ACTIONS.setRecentPlayed(items)),
  updateRecentPlayed: (items) => dispatch(ACTIONS.updateRecentPlayed(items)),
});
var Player = player();

class QueueList extends Component {
  state = {
    filter: "All",
    items: this.props.recentlyPlayed,
  };
  componentWillReceiveProps(nextProp) {
    this.setState({
      items: nextProp.recentlyPlayed,
    });
  }
  saveItem(item) {
    let type = "";
    if (item.type == "normal") type = "news";
    else if (item.type == "spotify") type = "music";
    else if (item.type == "shorts") type = "shorts";
    else type = "podcast";
    item.type = type;
    if (this.props.savedItems.find((ite) => ite.track_id == item.id)) {
      item.clicked = false;
      this.props.setSavedItems(
        this.props.savedItems.filter((ite) => ite.track_id !== item.id)
      );
      apiRequest({ url: "/news/save", method: "POST", data: item });
    } else {
      item.clicked = true;
      apiRequest({ url: "/news/save", method: "POST", data: item });
      this.props.setSavedItems([
        { track_id: item.id, type: type, ...item },
        ...this.props.savedItems.map((ite) => ite),
      ]);
    }
  }
  removeItem(track) {
    const newItems = this.props.recentlyPlayed.filter(
      (item) => item.id !== track.id
    );

    // this.props.setRecentPlayed(newItems);
  }
  renderItems() {
    const { filter } = this.state;
    const curPlayed = this.props.queue.currentPlayed;
    let items = this.state.items;
    if (filter != "All") {
      if (filter == "News")
        items = items.filter((item) => item.type == "normal");
      else if (filter == "Music")
        items = items.filter((item) => item.type == "spotify");
      else if (filter == "Podcast")
        items = items.filter((item) => item.type == "podcast");
    }

    return items.map((item) => {
      let artist = false;
      let newtitle = "";
      if (item.title && item.title.includes(":")) {
        artist = item.title.split(":");
        newtitle = artist[0];
        artist = artist[1];
      } else {
        newtitle = item.title;
      }

      newtitle = newtitle.substr(0, 50);
      return (
        <div className={`${styles.queue_item} col-md-12 p-0 mb-3 queue-item`}>
          {" "}
          <div
            style={{ position: "relative" }}
            className={styles.albumImgContainer}
          >
            <div style={{ position: "relative" }}>
              <img src={item.image} className={styles.albumimg} alt="" />
              <i
                class={`fas fa-times ${styles.crossImg}`}
                onClick={(e) => {
                  e.preventDefault();
                  this.removeItem(item);
                }}
              ></i>
            </div>

            {item.type == "normal" ? (
              <img src="/images/roundNews.png" className={styles.imageType} />
            ) : (
              ""
            )}
            {item.type == "spotify" ? (
              <img src="/images/roundMusic.png" className={styles.imageType} />
            ) : (
              ""
            )}
            {item.type == "podcast" ? (
              <img src="/images/roundMic.png" className={styles.imageType} />
            ) : (
              ""
            )}
          </div>
          <div
            onClick={() => {
              if (item.type == "spotify")
                Player.playSpotifyTrack({
                  ...item,
                  url: item.link,
                  duration: item.length,
                  recent: true,
                });
              else if (item.type == "normal")
                Player.playAudio({ ...item, recent: true });
              else if (item.type == "podcast") {
                this.props.updateRecentPlayed(item);
                Player.playRecentPodcast(item);
              } else if (item.type == "shorts") {
                this.props.updateRecentPlayed(item);
                Player.playRecentShorts(item);
              }
            }}
          >
            <p>{newtitle}</p>

            <p>{artist}</p>
          </div>
          <div className={styles.actionContainer}>
            {this.props.savedItems.find((ite) => ite.track_id == item.id) ? (
              <img
                src="/images/HeartFill.png"
                className={styles.heart}
                onClick={() => this.saveItem(item)}
                alt=""
              />
            ) : (
              <img
                src="/images/Heart.png"
                className={styles.heart}
                onClick={() => this.saveItem(item)}
                alt=""
              />
            )}

            {item.id == curPlayed.id && curPlayed.playing ? (
              <img
                src="/images/pauseIcon.png"
                className={styles.play}
                alt=""
                onClick={() => {
                  // Player.playQueue(item);
                }}
              />
            ) : (
              <img
                src="/images/playIcon.png"
                className={styles.play}
                alt=""
                onClick={() => {
                  if (item.type == "spotify")
                    Player.playSpotifyTrack({
                      ...item,
                      url: item.link,
                      duration: item.length,
                      recent: true,
                    });
                  else if (item.type == "normal")
                    Player.playAudio({ ...item, recent: true });
                  else if (item.type == "podcast") {
                    this.props.updateRecentPlayed(item);
                    Player.playRecentPodcast(item);
                  } else if (item.type == "shorts") {
                    this.props.updateRecentPlayed(item);
                    Player.playRecentShorts(item);
                  }
                }}
              />
            )}
          </div>
        </div>
      );
    });
  }

  render() {
    const { filter } = this.state;
    const curPlayed = this.props.queue.currentPlayed;
    return (
      <div
        className={`row mx-0 queue-container ${styles.queueListContainer}`}
        style={{ top: "205px" }}
      >
        <div>
          <button
            onClick={() => this.setState({ filter: "All" })}
            className={`btn ${styles.filter_btn} ${
              filter == "All" ? styles.filter_selected : ""
            }`}
          >
            All
          </button>
          <button
            onClick={() => this.setState({ filter: "News" })}
            className={`btn ${styles.filter_btn} ${
              filter == "News" ? styles.filter_selected : ""
            }`}
          >
            News
          </button>
          <button
            onClick={() => this.setState({ filter: "Music" })}
            className={`btn ${styles.filter_btn} ${
              filter == "Music" ? styles.filter_selected : ""
            }`}
          >
            Music
          </button>
          <button
            onClick={() => this.setState({ filter: "Podcast" })}
            className={`btn ${styles.filter_btn} ${
              filter == "Podcast" ? styles.filter_selected : ""
            }`}
          >
            Podcast
          </button>
        </div>

        <div>
          <ScrollContainer>{this.renderItems()}</ScrollContainer>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QueueList);
