import React from "react";
import styles from "../styles/newsitem.module.css";
import { connect } from "react-redux";
import colors from "../../data/colors.json";

var mapStateToProps = (state) => {
  return {
    theme: state.site.theme,
  };
};

function CollectionItem(props) {
  var color = false;
  if (props.theme == "color-theme")
    color = colors[Math.floor(Math.random() * colors.length)];
  const { title, image, source } = props.data;
  const [showPlayHover, setshowPlayHover] = React.useState(false);
  const [showShareHover, setShareHover] = React.useState(false);

  return (
    <div
      className={`currency-box  pb-0 mb-3 sortModalGridItem`}
      style={{ minWidth: "280px", width: "280px" }}
    >
      <div className="currency-pair">
        <div
          className={`background-image `}
          style={{
            background: `${
              props.theme == "color-theme"
                ? `url(${image}) ${color}`
                : `url(${image}) #1c1c1c`
            }`,
          }}
        ></div>
        <div
          className={styles.playOver}
          onMouseLeave={() => {
            setshowPlayHover(false);
            setShareHover(false);
          }}
          style={{ display: showPlayHover ? "flex" : "none" }}
        >
          <div>
            Play Now{" "}
            <img
              src={"/images/playNow.png"}
              className={`${styles.playIcon} `}
              onClick={() => props.onPlayNowTrack()}
              alt=""
            />
          </div>
          <div>
            Play Next{" "}
            <img
              src={"/images/playNext.png"}
              className={`${styles.playIcon} `}
              onClick={() => props.onPlayNext()}
              alt=""
            />
          </div>
          <div>
            {props.data["leoSummary"] ? "Play Leo Summary" : "Play Summary"}
            <img
              src={"/images/playIcon.png"}
              className={`${styles.playIcon} `}
              onClick={() => props.onPlaySummary()}
              alt=""
            />
          </div>
          <div>
            Play In-Queue{" "}
            <img
              src={"/images/playIcon.png"}
              className={`${styles.playIcon} `}
              onClick={() => props.onPlay()}
              alt=""
            />
          </div>
        </div>

        <div className="cat-content">
          {props.type == "podcast" ? (
            <img
              src="/images/Radio.png"
              className={styles.expandIcon}
              style={{ width: "25px", height: "25px", marginRight: "1em" }}
            />
          ) : props.type == "music" ? (
            <img
              src="/images/spotWhite.png"
              className={styles.expandIcon}
              style={{ width: "25px", height: "25px", marginRight: "1em" }}
            />
          ) : (
            <img
              src="/images/feedlyWhite.png"
              className={styles.expandIcon}
              style={{ width: "25px", height: "25px", marginRight: "1em" }}
            />
          )}

          <span
            className={`name mt-1  `}
            style={{ textTransform: "capitalize" }}
          >
            {props.type}
          </span>
          <img
            src={"/images/playIcon.png"}
            className={`${styles.playIcon} `}
            onClick={() => props.onPlay()}
            onMouseEnter={() => setshowPlayHover(true)}
            alt=""
          />

          <div className={`article-container   `}>
            <span className="article-name" style={{ fontWeight: "normal" }}>
              {title}
            </span>
            <div className={styles.actionContainer}>
              <span className="article-source">{source}</span>
              <div className="d-flex ml-auto align-items-end">
                {props.notShare ? (
                  ""
                ) : (
                  <i
                    class={`${styles.crossIcon} far fa-times-circle`}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      props.onRemove(props.data);
                    }}
                  ></i>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, null)(CollectionItem);
