import React, { Component } from "react";
import NewsItem from "../NewsItem";
import ACTIONS from "../../redux/actions/news";
import player from "../../data/player";
import { connect } from "react-redux";
import _ from "lodash";
import PlaylistContainer from "../PlaylistContainer";
import { VariableSizeList as List } from "react-window";
import styles from "../styles/Queuelist.module.css";
import ScrollContainer from "react-indiana-drag-scroll";
import MainSlider from "../MainSlider";
import TagItem from "../TagItem";
import moment from "moment";
var mapStateToProps = (state) => {
  return {
    feedlyUser: state.news.feedlyUser,
    tags: state.news.tags,
    feed: state.news.feed,
    importantFeed: state.news.importantFeed,
    readLater: state.news.readLater,
    boards: state.news.boards,
    socket: state.site.socket,
    queue: state.site.queue,
    feedlyLogChanged: state.news.feedlyLogChanged,
    tab: state.site.tab,
    playlists: state.news.playlists,
    todayfeed: state.news.todayNews,
    searchType: state.search.searchType,
    searchVal: state.search.searchVal,
    subscribedItems: state.site.subscribedItems,
  };
};

var mapDispatchToProps = (dispatch) => ({
  setTags: (item) => dispatch(ACTIONS.setTags(item)),
  setFeed: (item) => dispatch(ACTIONS.setFeed(item)),
  setFeedlyUser: (item) => dispatch(ACTIONS.setFeedlyUser(item)),
  setPlaylists: (item) => dispatch(ACTIONS.setPlaylists(item)),
  setNewsLogChange: (item) => dispatch(ACTIONS.setNewsLogChange(item)),
});

class NewsBoard extends Component {
  state = {
    news: [],
    feed: this.props.feed,
    oldFeed: this.props.feed,
    subscribed: false,
    refreshTime: new Date(),
    filter: "Today",
    sliderItems: [],
  };

  constructor(props) {
    super(props);
    this.updateNews(this.props);
  }

  componentDidMount() {
    const mainPage = document.querySelector(".main-page");
    mainPage.addEventListener("scroll", this.onScroll);
  }

  componentWillUnmount() {
    const mainPage = document.querySelector(".main-page");
    mainPage.removeEventListener("scroll", this.onScroll);
  }

  onScroll = () => {
    const mainPage = document.querySelector(".main-page");
    const mainSlider = document.querySelector(".newsBoard .mainSlider");

    mainSlider.style.opacity = 1 - mainPage.scrollTop / 500;
  };

  componentWillReceiveProps(nextProp) {
    this.updateNews(nextProp, this.state.filter);
  }

  updateNews(nextProp, filter) {
    if (nextProp.feedlyLogChanged) {
      this.setState({
        subscribed: false,
        feed: [],
        oldFeed: [],
        news: [],
      });
      this.props.setNewsLogChange(false);

      return;
    }

    if (filter == "All") var feed = nextProp.feed;
    else if (filter == "ReadLater") var feed = nextProp.readLater;
    else if (filter == "Tags") var feed = nextProp.boards;
    else if (filter == "Today") var feed = nextProp.todayfeed;
    else var feed = nextProp.importantFeed;

    if (this.props.feedlyUser.token)
      var newFeed = nextProp.feed.filter((item) => {
        var feedVar = this.state.oldFeed.find(
          (ite) => ite.article_id === item.article_id
        );
        return !feedVar;
      });
    else
      var newFeed = nextProp.feed.filter((item) => {
        var feedVar = this.state.oldFeed.find((ite) => ite._id === item._id);
        return !feedVar;
      });

    if (filter == "All" && this.state.filter !== "Tags") {
      if (this.state.tagClicked) {
        feed = feed.filter((item) => item.category == this.state.tagClicked);
      }

      if (this.props.feedlyUser.token)
        feed = feed.filter((item) => {
          var feedVar = this.state.feed.find(
            (ite) => ite.article_id === item.article_id
          );
          return !feedVar;
        });
      else
        feed = feed.filter((item) => {
          var feedVar = this.state.feed.find((ite) => ite._id === item._id);
          return !feedVar;
        });
      var allArr = [...feed, ...this.state.feed];
    } else {
      var allArr = [...feed];
    }

    allArr = allArr.sort((b, a) => {
      return a.published - b.published;
    });

    let subscribedCategories = this.props.subscribedItems.map(
      (item) => item.item_id
    );
    let subsArticles = this.props.todayfeed.filter((item) =>
      subscribedCategories.includes(item.category)
    );
    if (subsArticles && subsArticles.length > 0) {
      subsArticles = subsArticles.filter((item) => {
        if (moment(item.published).diff(moment(), "days") == 0) return true;
      });
      subsArticles = subsArticles.map((item) => {
        return {
          ...item,
          subtype: "Subscribed Articles",
        };
      });
    } else
      subsArticles = this.props.playlists.map((item) =>
        item.articles.map((ite) => {
          return {
            ...ite,
            subtype: "Top News Articles",
          };
        })
      );

    let sliderItems = [
      ...nextProp.importantFeed.map((item) => {
        return {
          ...item,
          subtype: "Priority News Articles",
        };
      }),
      ...subsArticles,
      ...nextProp.readLater.slice(0, 5).map((item) => {
        return {
          ...item,
          subtype: "Listen Later Articles",
        };
      }),
    ];
    sliderItems = [].concat.apply([], sliderItems);
    sliderItems = sliderItems.filter((item) => item.image);
    sliderItems = sliderItems.map((item) => {
      return {
        ...item,
        type: "news",
      };
    });

    this.setState(
      (prev) => ({
        feed: allArr,
        oldFeed: [...newFeed, ...prev.oldFeed],
        tags: nextProp.tags,
        sliderItems,
      }),
      () => {
        this.groupNews(nextProp, filter);
      }
    );
  }

  attachFeed(feeds) {
    this.setState({
      refreshTime: new Date(),
    });
    this.props.setFeed({ feed: feeds.articles, tags: feeds.tags });
  }

  groupNews(nextProp, filter) {
    const self = this;
    if (filter == "Tags") var data = this.state.feed;
    else {
      var data = _.chain(this.state.feed)
        .groupBy("category")
        .map((value, key) => ({ category: key, articles: value }))
        .value();

      if (nextProp.searchVal)
        if (nextProp.searchType == "newsCategory") {
          data = data.filter((item) => {
            return item.category
              .toLowerCase()
              .includes(nextProp.searchVal.toLowerCase());
          });
        } else if (nextProp.searchType == "articles") {
          let filtered = [];
          data = data.reduce(function (filtered, item) {
            const filteredArticles = item.articles.filter((art) =>
              art.title.toLowerCase().includes(nextProp.searchVal.toLowerCase())
            );
            if (filteredArticles.length > 0) {
              let tempObj = item;
              tempObj.articles = filteredArticles;
              filtered.push(tempObj);
            }
            return filtered;
          }, []);
        }
    }

    this.setState({
      news: data,
      filter,
    });
  }

  player = player();

  playNext(article) {
    this.player.playNext(article, "news");
  }

  playArticle(article, playAction = false) {
    this.player.playAudio(article, playAction);
  }

  render() {
    const { filter } = this.state;

    return (
      <div
        className="col-md-9 newsBoard"
        style={{ display: this.props.style, paddingTop: "430px" }}
      >
        <div className="mainBodyScroll w-100">
          <div className="row scroll pl-3 pr-0 mx-0">
            <MainSlider items={this.state.sliderItems} type="News" />

            <div className="col-md-11 pr-5 mb-2 w-100">
              <ScrollContainer>
                <PlaylistContainer
                  playlists={this.props.playlists}
                  heading={"News"}
                />
              </ScrollContainer>
            </div>
            <h5
              className="pl-3 w-100 d-flex"
              style={{ zIndex: 999, fontSize: "1.7em" }}
            >
              News Articles
              <div className="ml-4">
                <button
                  onClick={() => {
                    this.updateNews(this.props, "Priority");
                  }}
                  className={`btn ${styles.filter_btn} ${
                    filter == "Priority" ? styles.filter_selected : ""
                  }`}
                >
                  Priority
                </button>
                <button
                  onClick={() => {
                    this.updateNews(this.props, "Today");
                  }}
                  className={`btn ${styles.filter_btn} ${
                    filter == "Today" ? styles.filter_selected : ""
                  }`}
                >
                  Today
                </button>
                <button
                  onClick={() => {
                    this.updateNews(this.props, "All");
                  }}
                  className={`btn ${styles.filter_btn} ${
                    filter == "All" ? styles.filter_selected : ""
                  }`}
                >
                  Categories
                </button>
                <button
                  onClick={() => {
                    this.updateNews(this.props, "Tags");
                  }}
                  className={`btn ${styles.filter_btn} ${
                    filter == "Tags" ? styles.filter_selected : ""
                  }`}
                >
                  Tags
                </button>
                <button
                  onClick={() => {
                    this.updateNews(this.props, "ReadLater");
                  }}
                  className={`btn ${styles.filter_btn} ${
                    filter == "ReadLater" ? styles.filter_selected : ""
                  }`}
                >
                  Listen Later
                </button>
              </div>
            </h5>

            <div className="row row-eq-height mx-0 w-100">
              {this.state.filter == "Tags"
                ? this.state.news.map((data) => (
                    <TagItem
                      data={data}
                      filter={this.state.filter}
                      playNext={this.playNext.bind(this)}
                      playArticle={this.playArticle.bind(this)}
                    />
                  ))
                : this.state.news.map((data) => (
                    <NewsItem
                      data={data}
                      filter={this.state.filter}
                      playNext={this.playNext.bind(this)}
                      playArticle={this.playArticle.bind(this)}
                    />
                  ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsBoard);
