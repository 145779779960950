import React from "react";
import styles from "./commenItem.module.css";
export default function CommentItem(props) {
  const {
    data: { text },
    user: { avatar, fullname },
  } = props.data;

  return (
    <div className={styles.commentItem}>
      <img src={avatar} className={styles.logo} alt="" />
      <div>
        <div className="d-flex">
          <h2>{fullname}</h2>
          <span>8h ago</span>
        </div>
        <div className="d-flex">
          <span className={styles.tags}>#tag</span>
          <span className={styles.tags}>#tag</span>
          <span className={styles.tags}>#tag</span>
        </div>
        <p>{text}</p>
        <div className="d-flex">
          <div className="d-flex">
            <img
              src="/images/messageIcon.png"
              className={`${styles.actionIcon} `}
              alt=""
              style={{ width: "25px" }}
            />
            <span>0</span>
          </div>

          <div className="d-flex ml-4">
            <img
              src="/images/Heart.png"
              className={`${styles.actionIcon} `}
              alt=""
            />
            <span>0</span>
          </div>
        </div>
      </div>
    </div>
  );
}
