import React, { Component } from "react";

export default class NewsTable extends Component {
  render() {
    return (
      <div className="row flex-column">
        <div className="col-md-12">
          <div className="line"></div>

          <div className="table-logo my-4">
            <span>Executed Traders</span>
          </div>

          <div className="currency-table overflow-auto">
            <ul className="table-header">
              <li className="header-itm">
                TRADE ID{" "}
                <span>
                  <i className="fas fa-lightbulb"></i>
                </span>
              </li>
              <li className="header-itm">
                STATUS{" "}
                <span>
                  <i className="fas fa-lightbulb"></i>
                </span>
              </li>
              <li className="header-itm">
                TRADE DATE{" "}
                <span>
                  <i className="fas fa-lightbulb"></i>
                </span>
              </li>
              <li className="header-itm">
                DIRECTION{" "}
                <span>
                  <i className="fas fa-lightbulb"></i>
                </span>
              </li>
              <li className="header-itm">
                CCYCCY{" "}
                <span>
                  <i className="fas fa-lightbulb"></i>
                </span>
              </li>
              <li className="header-itm">
                DEALT CCY{" "}
                <span>
                  <i className="fas fa-lightbulb"></i>
                </span>
              </li>
              <li className="header-itm">
                NOTIONAL{" "}
                <span>
                  <i className="fas fa-lightbulb"></i>
                </span>
              </li>
              <li className="header-itm">
                RATE{" "}
                <span>
                  <i className="fas fa-lightbulb"></i>
                </span>
              </li>
              <li className="header-itm">
                VALUE DATE{" "}
                <span>
                  <i className="fas fa-lightbulb"></i>
                </span>
              </li>
              <li className="header-itm">
                TRADER{" "}
                <span>
                  <i className="fas fa-lightbulb"></i>
                </span>
              </li>
            </ul>
            <div className="table-content-box">
              <ul className="table-content">
                <li></li>
                <li className="content-itm">478</li>
                <li className="content-itm">Reiected</li>
                <li className="content-itm">25-nov-2019</li>
                <li className="content-itm">Sell</li>
                <li className="content-itm">EURCAD</li>
                <li className="content-itm">GBP</li>
                <li className="content-itm">1,000.00</li>
                <li className="content-itm">140.442</li>
                <li className="content-itm">27-nov-2019</li>
                <li className="content-itm">LMO</li>
              </ul>
              <ul className="table-content">
                <li></li>
                <li className="content-itm">478</li>
                <li className="content-itm">Reiected</li>
                <li className="content-itm">25-nov-2019</li>
                <li className="content-itm">Sell</li>
                <li className="content-itm">EURCAD</li>
                <li className="content-itm">GBP</li>
                <li className="content-itm">1,000.00</li>
                <li className="content-itm">140.442</li>
                <li className="content-itm">27-nov-2019</li>
                <li className="content-itm">LMO</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
