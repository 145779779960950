import React, { Component } from "react";
import style from "./styles/home.module.css";
import { connect } from "react-redux";
import colors from "../data/colors.json";
import podcaststyle from "./styles/playlist.module.css";
import newstyle from "./styles/newsitem.module.css";
import { Carousel } from "antd";
import player from "../data/player.js";
import moment from "moment";
import SITEACTIONS from "../redux/actions/site";
import NEWSACTIONS from "../redux/actions/news";
var Player = player();

var mapStateToProps = (state) => {
  return {
    theme: state.site.theme,
    radioMode: state.news.radioMode,
    subscribedItems: state.site.subscribedItems,
    podcasts: state.news.podcasts,
    musicList: state.site.musicList,
    recentlyPlayed: state.site.recentlyPlayed,
    shorts: state.site.shorts,
    savedItems: state.site.savedItems,
    playlists: state.news.playlists,
  };
};

var mapDispatchToProps = (dispatch) => ({
  setTab: (item) => dispatch(SITEACTIONS.setTab(item)),
  setRadioMode: (item) => dispatch(NEWSACTIONS.setRadioMode(item)),
});
function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }

  return array;
}
class HomeSlider extends Component {
  state = { slides: [] };
  componentWillReceiveProps(prop) {
    this.selectSlides(prop);
  }

  componentDidMount() {
    setTimeout(() => {
      this.selectSlides(this.props);
    }, 15000);
  }

  selectSlides(prop) {
    var radioPodcast = [];
    var radioMusic = [];
    var radioArticle = [];
    var slides = [];
    if (prop.subscribedItems.length > 0) {
      const subPodcasts = prop.subscribedItems
        .filter((item) => item.type == "podcast")
        .sort((a, b) => b.published - a.published);
      subPodcasts.filter((item) => {
        if (radioPodcast.length == 2) return;
        const curDate = moment();
        const podDate = moment(item.published);
        if (podDate.diff(curDate, "days") <= 7) {
          const testItem = prop.recentlyPlayed.find(
            (itee) => itee.id == item.episode[0].id
          );

          var condition = true;
          if (testItem) {
            if (testItem.completed) {
              condition = false;
            } else condition = true;
          } else condition = true;

          if (condition) {
            let tempItem = item;
            tempItem.type = "podcast";
            radioPodcast.push(tempItem);
          }
        }
      });
    }

    if (radioPodcast.length < 2) {
      let podcasts = prop.podcasts.map((item) => item.podcasts);
      podcasts = [].concat.apply([], podcasts);
      podcasts = podcasts.sort((a, b) => b.published - a.published);

      podcasts.map((podcast) => {
        if (radioPodcast.length == 2) return;

        const curDate = moment();
        const podDate = moment(podcast.published);
        if (podDate.diff(curDate, "days") <= 7) {
          const testItem = prop.recentlyPlayed.find(
            (itee) => itee.id == podcast.episode[0].id
          );
          var condition = true;
          if (testItem) {
            if (testItem.completed) {
              condition = false;
            } else condition = true;
          } else condition = true;
          if (condition) {
            let tempItem = podcast;
            tempItem.type = "podcast";
            radioPodcast.push(tempItem);
          }
        }
      });
    }
    let tempArr = [...prop.musicList];
    for (let i = tempArr.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [tempArr[i], tempArr[j]] = [tempArr[j], tempArr[i]];
    }
    if (tempArr.length > 0 && tempArr[0].tracks.length > 1) {
      let tempItem = tempArr[0].tracks[0];
      tempItem.type = "music";
      radioMusic.push(tempItem);
      let tempItem1 = tempArr[0].tracks[1];
      tempItem1.type = "music";
      radioMusic.push(tempItem1);
    }

    if (prop.playlists.length > 0) {
      if (prop.playlists[0].articles.length > 2) {
        let tempobj = prop.playlists[0].articles[0];
        tempobj.type = "news";
        radioArticle.push(tempobj);
        let tempobj1 = prop.playlists[0].articles[1];
        tempobj1.type = "news";
        radioArticle.push(tempobj1);
      } else {
        prop.playlists.map((item) => {
          if (radioArticle.length == 2) return;
          if (item.articles.length <= 0) return;
          let tempItems = item.articles.sort(
            (a, b) => b.published - a.published
          );
          let tempobj = tempItems[0];
          tempobj.type = "news";
          radioArticle.push(tempobj);
        });
      }
    }

    slides.push(...radioArticle, ...radioMusic, ...radioPodcast);
    slides = shuffleArray(slides);
    this.setState({ slides });
  }

  switchRadioMode() {
    if (!this.props.radioMode) {
      this.props.setRadioMode(true);
    }
  }

  render() {
    var color = false;
    if (this.props.theme == "color-theme")
      color = colors[Math.floor(Math.random() * colors.length)];

    return (
      <div className="col-md-12 mb-5 ml-4">
        <Carousel
          autoplay
          fade={true}
          cssEase={"linear"}
          autoplayInterval={15000}
        >
          {this.state.slides.map((item) => (
            <SliderItemContainer
              data={item}
              onRadio={() => this.switchRadioMode()}
            />
          ))}
        </Carousel>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeSlider);

function SliderItemContainer(props) {
  var color = false;
  if (props.theme == "color-theme")
    color = colors[Math.floor(Math.random() * colors.length)];

  return (
    <div className={` currency-pair ${style.homeSlider}`}>
      <div
        className={`background-image `}
        style={{
          backgroundBlendMode: "unset",
          background: `${
            props.theme == "color-theme"
              ? `url(${props.data.image}) ${color}`
              : `url(${props.data.image}) #1c1c1c`
          }`,
        }}
      ></div>
      <div className="d-flex cat-content mt-3">
        <img
          src="/images/playIconBig.png"
          className={`${style.homePlayIcon} ml-5`}
          alt=""
          style={{ cursor: "pointer" }}
          onClick={() => props.onRadio()}
        />
        <div className="ml-5">
          <h3
            className={`mb-0 ${style.whiteColor}`}
            style={{ fontSize: "1.3em" }}
          >
            PERSONAL
          </h3>
          <h2 className={` ${style.grayColor}`}>Radio</h2>
        </div>
        <div
          className={style.verticalLine}
          style={{ marginLeft: "13em" }}
        ></div>
        <div className="ml-5">
          <h3
            className={`mb-4 ${style.whiteColor}`}
            style={{ fontSize: "1.2em", fontWeight: "normal" }}
          >
            {props.data.type == "music"
              ? "Music Track"
              : props.data.type == "news"
              ? "Latest News"
              : props.data.type == "podcast"
              ? "Latest Episode"
              : ""}
          </h3>
          <p className="mb-4" style={{ width: "190px", color: "#b7b7b7" }}>
            {props.data.type == "music"
              ? props.data.title.split(":")[0]
              : props.data.type == "news"
              ? props.data.title
              : props.data.type == "podcast"
              ? props.data.episode[0].title
              : ""}
          </p>
          <span className={style.whiteColor}>
            {props.data.type == "music"
              ? props.data.title.split(":")[1]
              : props.data.type == "news"
              ? props.data.source
              : props.data.type == "podcast"
              ? props.data.episode[0].source
              : ""}
          </span>
        </div>
        <div
          className={style.verticalLine}
          style={{ marginLeft: "15em" }}
        ></div>
        <div className="pr-3" style={{ flex: "1" }}>
          <div className={style.rightContainer}>
            <p
              className="mb-0"
              style={{
                fontWeight: "normal",
                color: "white",
                fontSize: "2.3em",
                textTransform: "uppercase",
              }}
              className={podcaststyle.noArticle}
            >
              {moment(props.data.published).format("MMM")}
            </p>
            <p
              style={{
                fontWeight: "normal",
                color: "white",
                fontSize: ".9em",
              }}
              className={podcaststyle.noArticle}
            >
              {moment(props.data.published).format("DD, YYYY")}
            </p>
          </div>
          <div
            className={`d-flex ml-auto align-items-end ${style.actionContainer}`}
          >
            <img
              src="/images/cluster.png"
              className={newstyle.actionIcon}
              alt=""
            />
            <img
              src="/images/Heart.png"
              className={`${newstyle.actionIcon} ${newstyle.likedIcon}`}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const SliderItem = connect(
  mapStateToProps,
  mapDispatchToProps
)(SliderItemContainer);
