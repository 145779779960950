var Types = {
  SET_TEAM: "SET_TEAM",
  SET_TEAM_TEMPLATE: "SET_TEAM_TEMPLATE",
  SET_SELECTED_TEAM: "SET_SELECTED_TEAM",
  SET_TEAM_TAB: "SET_TEAM_TAB",
  SET_EMAIL_TAB: "SET_EMAIL_TAB",
  SET_EMAIL_EXPAND: "SET_EMAIL_EXPAND",
  SET_GROUP_SELECTED: "SET_GROUP_SELECTED",
  SET_CAMPAIGN_ITEMS: "SET_CAMPAIGN_ITEMS",
};
var setTeams = (cond) => ({
  type: Types.SET_TEAM,
  payload: cond,
});

var setTeamTemplates = (cond) => ({
  type: Types.SET_TEAM_TEMPLATE,
  payload: cond,
});

var setSelectedTeam = (cond) => ({
  type: Types.SET_SELECTED_TEAM,
  payload: cond,
});

var setTeamTab = (cond) => ({
  type: Types.SET_TEAM_TAB,
  payload: cond,
});

var setEmailTab = (cond) => ({
  type: Types.SET_EMAIL_TAB,
  payload: cond,
});

var setEmailExpand = (cond) => ({
  type: Types.SET_EMAIL_EXPAND,
  payload: cond,
});

var setGroupSelected = (cond) => ({
  type: Types.SET_GROUP_SELECTED,
  payload: cond,
});

var setCampaignItems = (cond) => ({
  type: Types.SET_CAMPAIGN_ITEMS,
  payload: cond,
});

export default {
  setCampaignItems,
  setGroupSelected,
  setEmailExpand,
  setEmailTab,
  setTeams,
  setTeamTab,
  setTeamTemplates,
  setSelectedTeam,
  Types,
};
