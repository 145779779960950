import React, { Component } from "react";
import SessionItem from "./SessionItem";
import shortstyle from "./styles/shorts.module.css";
import style from "./styles/home.module.css";
import { expandIcon, actionIcon } from "./styles/newsitem.module.css";
import { subscribeBtn } from "./styles/podcastInfo.module.css";
import StoryItem from "./StoryItem";

export default class StoriesContainer extends Component {
  render() {
    return (
      <div className="col-md-12 mb-5">
        <h5
          className={`pl-3 w-100 mb-4 ${style.lighGreenColor}`}
          style={{ textTransform: "capitalize" }}
        >
          <img
            src="/images/Radio.png"
            style={{ width: "30px", marginRight: ".5em" }}
          />
          Audio One Stories{" "}
        </h5>
        <div className="d-flex overflow-x">
          <StoryItem />
          <StoryItem />
          <StoryItem />

          <div
            className={`${shortstyle.firstItem} ml-3`}
            style={{
              backgroundColor: `${
                this.props.theme == "color-theme" ? "#182c42" : "#141313"
              }`
            }}
          >
            <p className="mb-4">
              <b>Audio One Sessions</b> are preset and user defined playlists of
              music, news and podcasts. Some are contextual based on time,
              location, and activities so that you get the best audio experience
              possible. Customize each session how you want.
            </p>
            <div className="d-flex">
              <button className={subscribeBtn} style={{ fontSize: ".8em" }}>
                Learn More
              </button>
              <div className="d-flex ml-auto align-items-end">
                <img src="/images/cluster.png" className={actionIcon} alt="" />
                <img
                  src="/images/Heart.png"
                  className={`${actionIcon} `}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
