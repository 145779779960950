import React, { Component } from "react";
import { Modal, Button, message } from "antd";
import { connect } from "react-redux";
import ACTIONS from "../redux/actions/site";
import ShareLink from "./ShareLink";
import AddCampaignItem from "./AddCampaignItem";

const mapStateToProps = (state) => ({
  theme: state.site.theme,
  teamtemplate: state.team.teamtemplates,
});

var mapDispatchToProps = (dispatch) => ({
  setMeter: (items) => dispatch(ACTIONS.setMeter(items)),
  setMeterProgress: (items) => dispatch(ACTIONS.setMeterProgress(items)),
  setMeterTitle: (items) => dispatch(ACTIONS.setMeterTitle(items)),
});

class shareModal extends Component {
  state = {
    loadingLink: false,
    loadedArticle: false,
    emailShareVisible: false,
    emailLinkVisible: false,
  };

  componentDidMount() {
    message.config({
      bottom: 100,
      left: 100,
    });
  }

  componentWillReceiveProps(nextProp) {
    if (nextProp.visible) {
      this.createCampaignShare(nextProp);
      if (nextProp.mode == "campaign")
        this.setState({ emailShareVisible: true });
      else if (nextProp.mode == "shareLink")
        this.setState({ emailLinkVisible: true });
    } else this.setState({ emailShareVisible: false, emailLinkVisible: false });
  }

  async createCampaignShare(props) {
    var loadedArticle = props.selectedItem;

    if (props.type == "music" || props.type == "musicList") {
      loadedArticle.link = loadedArticle.href;
    }

    loadedArticle["source"] = props.selectedItem.source;
    loadedArticle["type"] = props.type;

    this.setState({
      loadedArticle: loadedArticle,
    });
  }

  render() {
    return this.state.emailShareVisible ? (
      <AddCampaignItem
        loadedArticle={this.state.loadedArticle}
        setVisibility={(val) => {
          this.setState({ loadedArticle: null });
          this.props.setVisiblity(false);
        }}
        isTag={this.props.isTag}
      />
    ) : this.state.emailLinkVisible ? (
      <ShareLink
        loadedArticle={this.state.loadedArticle}
        templates={this.props.teamtemplate}
        type={this.props.type}
        isTag={this.props.isTag}
        setVisibility={(val) => {
          this.setState({ loadedArticle: null });
          this.props.setVisiblity(false);
        }}
      />
    ) : (
      ""
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(shareModal);
