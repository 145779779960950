import React, { Component } from "react";
import { connect } from "react-redux";
import ACTIONS from "../redux/actions/site";
import player from "../data/player";
import styles from "./styles/Queuelist.module.css";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import apiRequest from "../api/clientRequest";
import QueueCard from "./QueueCard";
import ScrollContainer from "react-indiana-drag-scroll";
import VideoQueueCard from "./VideoQueueCard";

var mapStateToProps = (state) => {
  return {
    queue: state.site.queue,
    savedItems: state.site.savedItems,
    completedPodcast: state.site.completedPodcast,
    subscribedItems: state.site.subscribedItems,
    podcasts: state.news.podcasts,
  };
};
var mapDispatchToProps = (dispatch) => ({
  setSavedItems: (items) => dispatch(ACTIONS.setSavedItems(items)),
  setCompletedPodcast: (items) => dispatch(ACTIONS.setCompletedPodcast(items)),
  setSubscribedItems: (items) => dispatch(ACTIONS.setSubscribedItems(items)),
});

var Player = player();

async function playSpotifyTrack(item) {
  Player.playSpotifyTrack(item, window.localStorage.getItem("spotify_token"));
}

class QueueList extends Component {
  state = {
    filter: "All",
    items: this.props.queue.items,
  };

  componentWillReceiveProps(nextProp) {
    this.setState({
      items: nextProp.queue.items,
    });
  }
  saveItem(item) {
    let type = "";
    if (item.type == "normal") type = "news";
    else if (item.type == "spotify") {
      type = "music";
      item["url"] = item["link"];
    } else if (item.type == "shorts") type = "shorts";
    else if (item.type == "youtube") type = "youtube";
    else type = "podcast";
    item.type = type;

    if (this.props.savedItems.find((ite) => ite.track_id == item.id)) {
      item.clicked = false;
      this.props.setSavedItems(
        this.props.savedItems.filter((ite) => ite.track_id !== item.id)
      );
      apiRequest({ url: "/news/save", method: "POST", data: item });
    } else {
      item.clicked = true;
      apiRequest({ url: "/news/save", method: "POST", data: item });
      this.props.setSavedItems([
        { track_id: item.id, type: type, ...item },
        ...this.props.savedItems.map((ite) => ite),
      ]);
    }
  }
  subscribeTo = (pod) => {
    var item = pod;
    item.type = "podcast";
    item["item_id"] = item.id;
    if (this.props.subscribedItems.find((ite) => ite.item_id == item.id)) {
      item.clicked = false;
      this.props.setSubscribedItems(
        this.props.subscribedItems.filter((ite) => ite.item_id !== item.id)
      );
      apiRequest({
        url: "/news/subscribe",
        method: "POST",
        data: { ...item, type: "podcast" },
      });
      // this.props.onSubscribe(item.id);
    } else {
      item.clicked = true;
      apiRequest({
        url: "/news/subscribe",
        method: "POST",
        data: { ...item, type: "podcast" },
      });
      this.props.setSubscribedItems([
        ...this.props.subscribedItems.map((ite) => ite),
        item,
      ]);
    }
  };
  renderItems() {
    const { filter } = this.state;
    const curPlayed = this.props.queue.currentPlayed;
    let items = this.state.items;
    if (filter != "All") {
      if (filter == "News")
        items = items.filter((item) => item.type == "normal");
      else if (filter == "Music")
        items = items.filter((item) => item.type == "spotify");
      else if (filter == "Podcast")
        items = items.filter((item) => item.type == "podcast");
    }
    // const SortableItem = SortableElement(({ value }) => {
    // const data = {
    //   ...value,
    //   title: value.title.split(":")[0],
    //   source: value.title.split(":")[1],
    //   type: value.type == "normal" ? "News" : value.type,
    // };

    //   return (
    //     <QueueCard
    //       data={data}
    //       type={data.type}
    //       currentPlayed={this.props.queue.currentPlayed}
    //       playing={this.props.queue.currentPlayed.playing}
    //       queue={this.props.queue}
    //       isQueue={true}
    //       saveItem={(item) => this.saveItem(item)}
    //     />
    //   );
    // });

    return (
      <ScrollContainer style={{ overflowY: "scroll" }}>
        {items.map((value, index) => {
          const data = {
            ...value,
            title: value.title.split(":")[0],
            source: value.title.split(":")[1],
            type: value.type == "normal" ? "News" : value.type,
          };

          return value.type == "youtube" ? (
            <VideoQueueCard
              data={data}
              isQueue={true}
              saveItem={(item) => this.saveItem(item)}
            />
          ) : (
            <QueueCard
              data={data}
              type={data.type}
              currentPlayed={this.props.queue.currentPlayed}
              playing={this.props.queue.currentPlayed.playing}
              queue={this.props.queue}
              isQueue={true}
              saveItem={(item) => this.saveItem(item)}
              subscribedItems={this.props.subscribedItems}
              subscribeTo={this.subscribeTo}
            />
          );

          // <SortableItem key={`item-${index}`} index={index} value={value} />
        })}
      </ScrollContainer>
    );
    const SortableList = SortableContainer(({ items }) => {
      return (
        <ScrollContainer>
          {items.map((data, index) => (
            <QueueCard
              data={data}
              type={data.type}
              currentPlayed={this.props.queue.currentPlayed}
              playing={this.props.queue.currentPlayed.playing}
              queue={this.props.queue}
              isQueue={true}
              saveItem={(item) => this.saveItem(item)}
            />

            // <SortableItem key={`item-${index}`} index={index} value={value} />
          ))}
        </ScrollContainer>
      );
    });
    return (
      <SortableList
        distance={1}
        items={items}
        onSortEnd={this.onSortEnd.bind(this)}
      />
    );
  }
  removeItem(item) {
    var podcastCondition = false;
    if (item.type !== "spotify") {
      podcastCondition = true;
    }

    Player.removeItem(item, podcastCondition);
  }
  onSortEnd = ({ oldIndex, newIndex }) => {
    var newOrder = {
      new_index: newIndex,
      old_index: oldIndex,
    };
    Player.updateOrder(newOrder);
    this.setState(({ items }) => ({
      items: arrayMove(items, oldIndex, newIndex),
    }));
  };

  render() {
    const { filter } = this.state;
    const curPlayed = this.props.queue.currentPlayed;
    return (
      <div className={`row mx-0 queue-container ${styles.queueListContainer}`}>
        <div>
          <button
            onClick={() => this.setState({ filter: "All" })}
            className={`btn ${styles.filter_btn} ${
              filter == "All" ? styles.filter_selected : ""
            }`}
          >
            All
          </button>
          <button
            onClick={() => this.setState({ filter: "News" })}
            className={`btn ${styles.filter_btn} ${
              filter == "News" ? styles.filter_selected : ""
            }`}
          >
            News
          </button>
          <button
            onClick={() => this.setState({ filter: "Music" })}
            className={`btn ${styles.filter_btn} ${
              filter == "Music" ? styles.filter_selected : ""
            }`}
          >
            Music
          </button>
          <button
            onClick={() => this.setState({ filter: "Podcast" })}
            className={`btn ${styles.filter_btn} ${
              filter == "Podcast" ? styles.filter_selected : ""
            }`}
          >
            Podcast
          </button>
        </div>

        {this.renderItems()}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QueueList);
