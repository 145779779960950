import React, { Component } from "react";
import styles from "./styles/shorts.module.css";
import playstyles from "./styles/playlist.module.css";
import { expandIcon, actionIcon } from "./styles/newsitem.module.css";
import Player from "../data/player";
import ACTIONS from "../redux/actions/site";
import { connect } from "react-redux";
import pstyles from "./styles/playlist.module.css";

var player = Player();
var mapStateToProps = (state) => {
  return {
    savedItems: state.site.savedItems,
    theme: state.site.theme,
  };
};
var mapDispatchToProps = (dispatch) => ({
  setSavedItems: (items) => dispatch(ACTIONS.setSavedItems(items)),
});

class TeamItem extends Component {
  state = { expanded: false };

  componentDidMount() {
    if (this.img) {
      const { thumb } = this.props;
      const that = this;
      const colorThief = new window.ColorThief();

      if (this.img.complete) {
        colorThief.getColor(that.img);
      } else {
        this.img.addEventListener("load", function () {
          const color = colorThief.getColor(that.img);
          console.log(color);
        });
      }
    }
  }

  render() {
    const { team, count, thumb, id, type } = this.props;

    return (
      <div
        style={{ cursor: "pointer" }}
        onClick={() => this.props.handleClick(id)}
        className={`currency-box ${
          this.props.clicked ? pstyles.itemClicked : ""
        }   ${styles.shortItem}  ${this.state.expanded ? "expand" : ""}`}
      >
        <div
          className={`${playstyles.playlistItem}  currency-pair   `}
          style={{ background: "#2e2d31" }}
        >
          <div className={`cat-content `}>
            <div className={`${this.state.expanded ? "d-none" : ""}`}>
              <p className={`${playstyles.playlistName} text-right pl-2 pt-2`}>
                <img
                  src="/images/gearIcon.png"
                  className={`${actionIcon} `}
                  onClick={() => this.props.editTeam(id)}
                  style={{ float: "none" }}
                  alt=""
                />
              </p>
              <div style={{ marginTop: "3em" }}>
                <p
                  className={playstyles.article}
                  style={{
                    color: "#d19310",
                    fontSize: "1em",
                    textAlign: "right",
                  }}
                >
                  {team}
                </p>
              </div>
              <div
                className={playstyles.itemInfo}
                style={{ marginTop: "105px" }}
              >
                <div
                  className={playstyles.articleSideInfo}
                  style={{ left: "12px" }}
                >
                  {thumb ? (
                    <img
                      src={`${thumb}`}
                      ref={(img) => (this.img = img)}
                      crossorigin="anonymous"
                      className={`${actionIcon} `}
                      style={{
                        position: "absolute",
                        bottom: "21px",
                        width: "39px",
                        height: "39px",
                      }}
                      alt=""
                    />
                  ) : (
                    ""
                  )}

                  <div className="text-right">
                    <p className={pstyles.noArticle}>{count}</p>
                    <p className={pstyles.article}>{type}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamItem);
