import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
var mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};
const RestrictedRoute = ({
  component: Component,
  authUser,
  user: user,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      user ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/signin",
            state: {
              from: props.location,
            },
          }}
        />
      )
    }
  />
);

export default connect(mapStateToProps, null)(RestrictedRoute);
