import React, { Component } from "react";
import questyles from "./styles/Queuelist.module.css";
import UserImage from "./UserImage";
import { Popover, message } from "antd";
import styles from "./styles/addTeam.module.css";
import apiRequest from "../api/clientRequest";
import { connect } from "react-redux";
import TEAMACTIONS from "../redux/actions/team";

var mapStateToProps = (state) => {
  return {
    savedItems: state.site.savedItems,
    selectedTeam: state.team.selectedTeam,
    teams: state.team.teams,
    user: state.auth.user,
  };
};

var mapDispatchToProps = (dispatch) => ({
  setTeam: (item) => dispatch(TEAMACTIONS.setTeams(item)),
  setSelectedTeam: (item) => dispatch(TEAMACTIONS.setSelectedTeam(item)),
});

class MemberItem extends Component {
  state = { visible: false };

  assignRole(role) {
    const foundItem = this.props.selectedTeam;

    const foundUser = foundItem.users.find(
      (user) => user.email == this.props.user.email
    );
    const allowedRoles = ["Member", "Editor"];

    if (foundUser && allowedRoles.includes(foundUser["role"])) {
      message.error("You do not have permission to perform this operation");
      return;
    }

    apiRequest({
      url: `/teams/updaterole`,
      method: "POST",
      data: {
        teamid: this.props.selectedTeam.id,
        userid: this.props.data._id,
        role,
      },
    }).then((res) => {
      if (res.data.status == 200) {
        message.success("Role assigned to user");
        let allTeams = this.props.teams;
        const foundIndex = this.props.teams.findIndex(
          (item) => item.id == this.props.selectedTeam.id
        );

        allTeams[foundIndex].users = allTeams[foundIndex].users.map((item) => {
          let tempObj = {
            ...item,
          };
          if (tempObj._id == this.props.data._id) {
            tempObj["role"] = role;
          }
          return tempObj;
        });
        this.props.setSelectedTeam(allTeams[foundIndex]);
        this.props.setTeam([...allTeams]);
      } else message.error(res.data);
    });
  }

  render() {
    const { email, fullname, avatar } = this.props.data;

    const popContent = (
      <div
        style={{
          backgroundColor: "#3b3b3b",
          color: "#898484",
          padding: "1.3em",
          borderRadius: "8px",
          cursor: "pointer",
        }}
      >
        <p
          style={{
            color: "#ddd8d8",
            fontSize: "1.3em",
            textAlign: "center",
            marginBottom: "10px",
          }}
        >
          Assign Roles
        </p>
        <p
          style={{ fontSize: "1.2em", marginBottom: "0" }}
          onClick={() => this.assignRole("Member")}
        >
          Member
        </p>
        <p
          style={{ fontSize: "1.2em", marginBottom: "0" }}
          onClick={() => this.assignRole("Editor")}
        >
          Editor
        </p>
        <p
          style={{ fontSize: "1.2em", marginBottom: "0" }}
          onClick={() => this.assignRole("Admin")}
        >
          Admin
        </p>
        <p
          style={{ fontSize: "1.2em", marginBottom: "0" }}
          onClick={() => this.assignRole("Owner")}
        >
          Owner
        </p>
      </div>
    );

    return (
      <div>
        <div
          className={`${questyles.queue_item} col-md-12 p-0 mb-3 queue-item`}
        >
          {" "}
          <UserImage avatar={avatar} name={fullname} />
          <div style={{ marginRight: 0 }}>
            <p style={{ color: "white", fontSize: "1.2em" }}>{fullname}</p>
            {this.props.data.role ? <p>{this.props.data.role}</p> : ""}
            {/* <p>{email}</p> */}
          </div>
          <div
            style={{ marginRight: 0, marginLeft: "auto" }}
            className={questyles.actionContainer}
          >
            {this.props.toolPop ? (
              <Popover
                content={popContent}
                placement="top"
                className="rolePopup"
              >
                <img
                  src="/images/plusNewIcon.png"
                  className={questyles.play}
                  alt=""
                  onClick={() => {
                    this.setState({ visible: true });
                  }}
                  style={{
                    filter: "brightness(2)",
                    marginRight: "10px",
                  }}
                />{" "}
              </Popover>
            ) : (
              <img
                src="/images/plusNewIcon.png"
                className={questyles.play}
                alt=""
                onClick={() => {
                  if (!this.props.disabled)
                    this.props.onClick(this.props.data.id);
                }}
                style={{
                  filter: "brightness(2)",
                  marginRight: "10px",
                  cursor: this.props.disabled ? "no-drop" : "pointer",
                }}
              />
            )}

            <img
              style={{ cursor: "pointer" }}
              src="/images/trashIcon.png"
              className={questyles.heart}
              onClick={() => {
                this.props.onDelete(this.props.data.id);
              }}
              alt=""
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MemberItem);
