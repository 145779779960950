import React, { Component } from "react";
import { Modal, Button, message } from "antd";
import { connect } from "react-redux";
import styles from "./styles/sharemodal.module.css";
import apiRequest from "../api/clientRequest";
import ACTIONS from "../redux/actions/site";
import moment from "moment";
import TEAMACTIONS from "../redux/actions/team";
import axios from "axios";
import "react-multi-email/style.css";

const mapStateToProps = (state) => ({
  theme: state.site.theme,
  teams: state.team.teams,
  feedlyUser: state.news.feedlyUser,
});
var mapDispatchToProps = (dispatch) => ({
  setMeter: (items) => dispatch(ACTIONS.setMeter(items)),
  setMeterProgress: (items) => dispatch(ACTIONS.setMeterProgress(items)),
  setMeterTitle: (items) => dispatch(ACTIONS.setMeterTitle(items)),
  setTeam: (item) => dispatch(TEAMACTIONS.setTeams(item)),
  setSelectedTeam: (item) => dispatch(TEAMACTIONS.setSelectedTeam(item)),
});

class shareModal extends Component {
  state = {
    dataSource: [],
    visible: false,
    loadedLink: "",
  };

  componentDidMount() {
    this.shareByEmail();
  }

  shareByEmail() {
    this.props.setMeterTitle("Creating Link");
    this.props.setMeter(true);
    this.props.setMeterProgress("50");

    const { description, link } = this.props.loadedArticle;

    apiRequest({
      url: "/news/share/article/link",
      method: "POST",
      data: {
        loadedLink: link,
        shareEmails: this.state.emails,
        groups: this.state.selectedGroups,
        fullname: this.state.fromName,
        teamid: this.state.teamid,
        type: this.props.type,
        selectedTemplate: this.state.selectedTemplate,
        date: moment().format("X"),
        feedlyUser: this.props.feedlyUser,
        isTag: this.props.isTag,
        ...this.props.loadedArticle,
        description:
          this.props.loadedArticle["description"] !== undefined
            ? description
            : "",
      },
    }).then((res) => {
      if (res.data.success) {
        axios
          .post(
            "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyDeuM4cwVCPAzurlaz_8avDSJ77IY-6pls",
            {
              dynamicLinkInfo: {
                domainUriPrefix: "https://audioone.link",
                link: res.data.link,
              },
            }
          )
          .then((response) => {
            this.props.setMeterTitle("Link Created successfully");
            this.props.setMeterProgress("100");
            this.setState({
              visible: true,
              loadedLink: response.data.shortLink,
            });
            setTimeout(() => {
              this.props.setMeter(false);
            }, 2000);
          })
          .catch((err) => {
            console.log(err.response);
          });
      } else {
        this.props.setMeter(false);
        message.error(res.data.error);
      }
    });
  }
  copyText(text) {
    const self = this;
    navigator.clipboard.writeText(text).then(
      function () {
        message.success("Copied To Clipboard", 7);
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  }

  render() {
    return (
      <Modal
        visible={this.state.visible}
        title="Share this Article"
        onOk={this.handleOk}
        footer={[]}
        className={`${styles.shareEmail} shareEmail share-modal shareLinkModal ${this.props.theme}`}
        onCancel={() => {
          this.props.setVisibility();
          this.setState({ visible: false });
        }}
      >
        <div className="p-3">
          <div className="mb-4 d-flex">
            <img
              className={styles.shareArticleImage}
              src={
                this.props.loadedArticle ? this.props.loadedArticle.image : ""
              }
            />
            <h4 className={styles.shareArticleTitle}>
              {this.props.loadedArticle ? this.props.loadedArticle.title : ""}
            </h4>
          </div>
          <div className="d-flex w-100 justify-content-between">
            <a href={this.state.loadedLink} target="_blank" className="mr-3">
              {this.state.loadedLink}
            </a>
            <Button
              className="btn btn-black"
              onClick={() => this.copyText(this.state.loadedLink)}
            >
              Copy
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(shareModal);
