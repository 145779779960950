import React, { Component, useState } from "react";
import styles from "./styles/newsitem.module.css";
import { connect } from "react-redux";
import podStyle from "./styles/podcastitem.module.css";
import moment from "moment";
import PodcastInfo from "./PodcastInfo";
import colors from "../data/colors.json";
import apiRequest from "../api/clientRequest";
import ACTIONS from "../redux/actions/site";
import NEWSACTIONS from "../redux/actions/news";
import ShareModal from "./shareModal";
import $ from "jquery";
import ShareMethods from "./ShareMethods";
import FavoriteFeedModal from "./FavoriteFeedModal";

var mapStateToProps = (state) => {
  return {
    podcasts: state.news.podcasts,
    theme: state.site.theme,
    savedItems: state.site.savedItems,
  };
};
var mapDispatchToProps = (dispatch) => ({
  setSavedItems: (items) => dispatch(ACTIONS.setSavedItems(items)),
  updateRecentPlayed: (items) => dispatch(ACTIONS.updateRecentPlayed(items)),
  addPodcastEpisode: (items) => dispatch(NEWSACTIONS.addPodcastEpisodes(items)),
});
class PodcastItem extends Component {
  state = {
    expanded: false,
    loadingImage: true,
    hoverImg: false,
    savedArticle: { id: false },
    shareVisibile: false,
    selectedArticle: null,
    showPlayHover: false,
    showShareHover: false,
    mode: null,
    likeHover: false,
  };

  unExpand() {
    $(".instantSearch").toggleClass("d-none");

    this.setState({
      expanded: false,
    });
  }

  expand() {
    if (this.props.data.episode.length == 1)
      apiRequest({
        url: `/news/fetch/podcast/${this.props.data.id}/episodes`,
        method: "GET",
      }).then((res) => {
        this.props.addPodcastEpisode({
          episode: res.data.episodes,
          id: this.props.data.id,
        });
      });
    $(".instantSearch").toggleClass("d-none");
    this.setState({ expanded: true });
  }

  setSelectArticle(article, mode) {
    this.setState({
      selectedArticle: article,
      mode,
      shareVisibile: true,
    });
  }

  saveItem(item, type = false) {
    if (!type) item.type = "podcast";
    else item.type = type;
    if (this.props.savedItems.find((ite) => ite.track_id == item.id)) {
      item.clicked = false;
      this.props.setSavedItems(
        this.props.savedItems.filter((ite) => ite.track_id !== item.id)
      );
      apiRequest({ url: "/news/save", method: "POST", data: item });
    } else {
      item.clicked = true;
      apiRequest({ url: "/news/save", method: "POST", data: item });
      this.props.setSavedItems([
        { track_id: item.id, type: "podcast", ...item },
        ...this.props.savedItems.map((ite) => ite),
      ]);
    }
  }
  render() {
    let {
      genre,
      episode,
      source,
      title,
      description,
      image,
      id,
    } = this.props.data;
    const episodes = episode;
    var color = "";
    const { showPlayHover, showShareHover, likeHover } = this.state;

    if (this.props.theme == "color-theme" && !image)
      color = colors[Math.floor(Math.random() * colors.length)];
    return (
      <div className={`currency-box  ${this.state.expanded ? "expand" : ""}`}>
        <ShareModal
          visible={this.state.shareVisibile}
          setVisiblity={(cond) =>
            this.setState({
              shareVisibile: cond,
            })
          }
          selectedItem={this.state.selectedArticle}
          type="podcast"
          setSelectedArticle={(article) =>
            this.setState({
              selectedArticle: article,
            })
          }
          mode={this.state.mode}
        />

        <div className="currency-pair">
          <div
            className={`background-image ${
              this.props.theme == "color-theme" && this.state.expanded
                ? "white-back"
                : ""
            }`}
            style={{
              background: `${
                this.state.expanded
                  ? this.props.theme == "color-theme"
                    ? "white"
                    : ""
                  : `url(${image}) ${color}`
              }`,
            }}
          ></div>

          <div
            className={styles.playOver}
            onMouseLeave={() =>
              this.setState({ showPlayHover: false, showShareHover: false })
            }
            style={{ display: showPlayHover == 1 ? "flex" : "none" }}
          >
            <div>
              Play Now{" "}
              <img
                src={"/images/playNow.png"}
                className={`${styles.playIcon} `}
                onClick={() => {
                  this.props.updateRecentPlayed(episodes[0]);
                  this.props.playPodCast(
                    {
                      episode: episodes[0],
                      title: title,
                    },
                    "playNow"
                  );
                }}
                alt=""
              />
            </div>
            <div>
              Play Next{" "}
              <img
                src={"/images/playNext.png"}
                className={`${styles.playIcon} `}
                onClick={() => {
                  this.props.updateRecentPlayed(episodes[0]);
                  this.props.playNext({
                    episode: episodes[0],
                    title: title,
                  });
                }}
                alt=""
              />
            </div>
            <div>
              Play Preview{" "}
              <img
                src={"/images/playIcon.png"}
                className={`${styles.playIcon} `}
                onClick={() => {
                  this.props.updateRecentPlayed(episodes[0]);
                  this.props.playPodCast(
                    {
                      episode: episodes[0],
                      title: title,
                      summary: true,
                    },
                    "playNow"
                  );
                }}
                alt=""
              />
            </div>
            <div>
              Play In-Queue{" "}
              <img
                src={"/images/playIcon.png"}
                className={`${styles.playIcon} `}
                onClick={() => {
                  this.props.updateRecentPlayed(episodes[0]);
                  this.props.playPodCast({
                    episode: episodes[0],
                    title: title,
                  });
                }}
                alt=""
              />
            </div>
          </div>

          <ShareMethods
            id={1}
            showHover={showShareHover}
            setShowHover={() => this.setState({ showShareHover: false })}
            setSelected={(mode) =>
              this.setSelectArticle(
                {
                  ...episodes[0],
                  podcastTitle: title,
                  podcastDescription: description,
                },
                mode
              )
            }
          />

          <FavoriteFeedModal
            id={episode[0]._id}
            visible={likeHover}
            setHover={() => this.setState({ likeHover: false })}
            saveToCollection={() => this.saveItem(episode[0])}
            selectedArticle={episode[0]}
            savedItems={this.props.savedItems}
            type="podcast"
          />

          <div className="cat-content">
            <img
              src="/images/block-menu.png"
              className={`${styles.expandIcon} ${
                this.state.expanded ? "d-none" : "d-inline-block"
              }`}
              alt=""
              onClick={this.expand.bind(this)}
            />
            <span
              className={`name mt-1  ${
                this.state.expanded ? "d-none" : "d-inline-block"
              }`}
            >
              #{genre}
            </span>
            <img
              src={"/images/playIcon.png"}
              onClick={() => {
                this.props.updateRecentPlayed(episodes[0]);
                this.props.playPodCast({ episode: episodes[0], title: title });
              }}
              onMouseEnter={() => this.setState({ showPlayHover: 1 })}
              className={`${styles.playIcon} ${
                this.state.expanded ? "d-none" : ""
              }`}
              alt=""
            />

            <div
              className={`article-container   ${
                this.state.expanded ? "d-none" : ""
              }`}
            >
              <span className="article-name"> {episodes[0].title}</span>
              <div className={styles.actionContainer}>
                <span className="article-source">{title}</span>
                <div className="d-flex ml-auto align-items-end">
                  <img
                    src="/images/cluster.png"
                    className={styles.actionIcon}
                    alt=""
                    onMouseEnter={() => this.setState({ showShareHover: 1 })}
                  />
                  {this.props.savedItems.find(
                    (item) => item.track_id == episode[0].id
                  ) ? (
                    <img
                      src="/images/HeartFill.png"
                      className={`${styles.actionIcon} ${styles.likedIcon}`}
                      onClick={() => this.saveItem(episodes[0])}
                      onMouseEnter={() =>
                        this.setState({ likeHover: episode[0]._id })
                      }
                      alt=""
                    />
                  ) : (
                    <img
                      src="/images/Heart.png"
                      className={`${styles.actionIcon} `}
                      onMouseEnter={() =>
                        this.setState({ likeHover: episode[0]._id })
                      }
                      onClick={() => this.saveItem(episodes[0])}
                      alt=""
                    />
                  )}
                </div>
              </div>
            </div>
            {this.state.expanded ? (
              <div
                className={`row m-0 expanded-container ${
                  this.state.expanded ? "" : "d-none"
                }`}
              >
                <div
                  className={styles.backArrowContainer}
                  onClick={this.unExpand.bind(this)}
                >
                  <img src="/images/back-to-latest2x.png" alt="" />
                  <img src="/images/back-left.png" alt="" />
                </div>
                <div style={{ position: "fixed", left: "31px", top: "30%" }}>
                  <img
                    src={"/images/playIcon.png"}
                    className={`${styles.playIcon} `}
                    onClick={() => this.playAllArticles(episodes)}
                    style={{
                      width: "80px",
                      height: "80px",
                      marginBottom: "15px",
                    }}
                    alt=""
                  />
                  <p style={{ textAlign: "center" }}>Play All </p>
                </div>
                <PodcastInfo
                  playPodCast={this.props.playPodCast}
                  data={{
                    title,
                    description,
                    image,
                    episode,
                    source,
                    id,
                    genre,
                  }}
                  savedItems={this.props.savedItems}
                  saveItem={this.saveItem.bind(this)}
                  onSubscribe={(id) => {
                    this.props.subscribedChanged(id);
                  }}
                />
                <h5 className="pl-3 text-white w-100">Podcast Episodes</h5>
                {episodes.map((podcast) => (
                  <div className={`d-flex ${podStyle.podExtendItem}`}>
                    <div
                      className={styles.playOver}
                      onMouseLeave={() =>
                        this.setState({ showPlayHover: false })
                      }
                      style={{
                        display: showPlayHover == podcast.id ? "flex" : "none",
                      }}
                    >
                      <div>
                        Play Now{" "}
                        <img
                          src={"/images/playNow.png"}
                          className={`${styles.playIcon} `}
                          onClick={() => {
                            this.props.updateRecentPlayed(episodes[0]);
                            this.props.playPodCast(
                              {
                                episode: podcast,
                                title: title,
                              },
                              "playNow"
                            );
                          }}
                          alt=""
                        />
                      </div>
                      <div>
                        Play Next{" "}
                        <img
                          src={"/images/playNext.png"}
                          className={`${styles.playIcon} `}
                          onClick={() => {
                            this.props.updateRecentPlayed(episodes[0]);
                            this.props.playNext({
                              episode: podcast,
                              title: title,
                            });
                          }}
                          alt=""
                        />
                      </div>
                      <div>
                        Play Preview{" "}
                        <img
                          src={"/images/playIcon.png"}
                          className={`${styles.playIcon} `}
                          onClick={() => {
                            this.props.updateRecentPlayed(episodes[0]);
                            this.props.playPodCast(
                              {
                                episode: podcast,
                                title: title,
                                summary: true,
                              },
                              "playNow"
                            );
                          }}
                          alt=""
                        />
                      </div>
                      <div>
                        Play In-Queue{" "}
                        <img
                          src={"/images/playIcon.png"}
                          className={`${styles.playIcon} `}
                          onClick={() => {
                            this.props.updateRecentPlayed(episodes[0]);
                            this.props.playPodCast({
                              episode: podcast,
                              title: title,
                            });
                          }}
                          alt=""
                        />
                      </div>
                    </div>

                    <ShareMethods
                      id={podcast.id}
                      showHover={showShareHover}
                      setShowHover={() =>
                        this.setState({ showShareHover: false })
                      }
                      setSelected={(mode) =>
                        this.setSelectArticle(
                          {
                            ...podcast,
                            podcastTitle: title,
                            podcastDescription: description,
                          },
                          mode
                        )
                      }
                    />
                    <FavoriteFeedModal
                      id={podcast.id}
                      visible={likeHover}
                      setHover={() => this.setState({ likeHover: false })}
                      saveToCollection={() => this.saveItem(podcast)}
                      selectedArticle={podcast}
                      savedItems={this.props.savedItems}
                      type="podcast"
                    />
                    <img
                      src={"/images/playIcon.png"}
                      onClick={() => {
                        this.props.updateRecentPlayed(podcast);
                        this.props.playPodCast({
                          episode: podcast,
                          title: title,
                        });
                      }}
                      className={styles.playIcon}
                      onMouseEnter={() =>
                        this.setState({ showPlayHover: podcast.id })
                      }
                      alt=""
                    />
                    <div
                      className={`article-container ${podStyle.artContainer}`}
                    >
                      <span className="article-name">{podcast.title}</span>
                      <div className={styles.actionContainer}>
                        {" "}
                        <span className="article-source">
                          {source.substr(0, 15)}
                        </span>
                        <div className="d-flex ml-auto align-items-end">
                          <img
                            src="/images/cluster.png"
                            className={styles.actionIcon}
                            alt=""
                            onMouseEnter={() =>
                              this.setState({ showShareHover: podcast.id })
                            }
                          />
                          {this.props.savedItems.find(
                            (item) => item.track_id == podcast.id
                          ) ? (
                            <img
                              src="/images/HeartFill.png"
                              className={`${styles.actionIcon} ${styles.likedIcon}`}
                              onMouseEnter={() =>
                                this.setState({ likeHover: podcast.id })
                              }
                              onClick={() => this.saveItem(podcast)}
                              alt=""
                            />
                          ) : (
                            <img
                              src="/images/Heart.png"
                              className={`${styles.actionIcon} `}
                              onMouseEnter={() =>
                                this.setState({ likeHover: podcast.id })
                              }
                              onClick={() => this.saveItem(podcast)}
                              alt=""
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <span className={podStyle.published}>
                      {moment(podcast.published).format("MMMM Do, YYYY")}
                    </span>
                  </div>
                ))}{" "}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PodcastItem);
