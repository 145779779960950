import React, { Component } from "react";
import { connect } from "react-redux";
import ACTIONS from "../../redux/actions/site";
import SEARCHACTIONS from "../../redux/actions/search";
import SearchInput from "../../components/search/SearchInput";
import SearchFilter from "../../components/search/SearchFilter";
import debounce from "lodash.debounce";
import PodcastList from "../../components/search/PodcastList";
import NewsList from "../../components/search/NewsList";
import styles from "../../components/styles/search.module.css";
var mapStateToProps = (state) => {
  return {
    tab: state.site.tab,
    SearchToggled: state.search.SearchToggled,
    instantSearch: state.search.instantSearch,
    theme: state.site.theme,
  };
};

var mapDispatchToProps = (dispatch) => ({
  setTab: (item) => dispatch(ACTIONS.setTab(item)),
  setSearchToggle: (item) => dispatch(SEARCHACTIONS.setSearchToggle(item)),
  setSearchValue: (item) => dispatch(SEARCHACTIONS.setSearchValue(item)),
  setSearchType: (item) => dispatch(SEARCHACTIONS.setSearchType(item)),
  setInstantSearch: (item) => dispatch(SEARCHACTIONS.setInstantSearch(item)),
});

const filters = [
  {
    tab: 0,
    heading: "HOW DO YOU WANT TO SEARCH FOR PODCASTS",
    filters: [
      {
        type: "podcastShow",
        icon: "Radio",
        heading: "Podcast Shows",
      },
      {
        type: "podcastEpisodes",
        icon: "Radio",
        heading: "Podcast Episodes",
      },
      {
        type: "podcastCategory",
        icon: "Radio",
        heading: "Podcast Categories",
      },
      {
        type: "spotifyPodcast",
        icon: "spotWhite",
        heading: "Spotify Podcast Shows",
      },
    ],
  },
  {
    tab: 1,
    heading: "HOW DO YOU WANT TO SEARCH FOR NEWS",
    filters: [
      {
        type: "newsCategory",
        icon: "Radio",
        heading: "News Categories",
      },
      {
        type: "feedlyNews",
        icon: "feedlyWhite",
        heading: "Feedly Search within Categories",
      },
      {
        type: "feedlyNewsArticles",
        icon: "feedlyWhite",
        heading: "Feedly Topic Search",
      },
      {
        type: "newsArticles",
        icon: "Radio",
        heading: "News Articles",
      },
      {
        type: "beyondFeedly",
        icon: "feedlyWhite",
        heading: "Beyond Feedly",
        disabled: true,
      },
      {
        type: "googleNews",
        icon: "feedlyWhite",
        heading: "Google News Search",
        disabled: true,
      },
    ],
  },
  {
    tab: 4,
    heading: "HOW DO YOU WANT TO SEARCH FOR PODCASTS",
    filters: [
      {
        type: "podcastShow",
        icon: "Radio",
        heading: "Podcast Shows",
      },
      {
        type: "podcastEpisodes",
        icon: "Radio",
        heading: "Podcast Episodes",
      },
      {
        type: "podcastCategory",
        icon: "Radio",
        heading: "Podcast Categories",
      },
      {
        type: "spotifyPodcast",
        icon: "spotWhite",
        heading: "Spotify Podcast Shows",
      },
    ],
  },
  {
    tab: 2,
    heading: "HOW DO YOU WANT TO SEARCH FOR PODCASTS",
    filters: [
      {
        type: "podcastShow",
        icon: "Radio",
        heading: "Podcast Shows",
      },
      {
        type: "podcastEpisodes",
        icon: "Radio",
        heading: "Podcast Episodes",
      },
      {
        type: "podcastCategory",
        icon: "Radio",
        heading: "Podcast Categories",
      },
      {
        type: "spotifyPodcast",
        icon: "spotWhite",
        heading: "Spotify Podcast Shows",
      },
    ],
  },
  {
    tab: 3,
    heading: "HOW DO YOU WANT TO SEARCH FOR MUSIC",
    filters: [
      {
        type: "newsCategory",
        icon: "Radio",
        heading: "News Categories",
      },
      {
        type: "newsArticles",
        icon: "Radio",
        heading: "News Articles",
      },
      {
        type: "feedlyNews",
        icon: "feedlyWhite",
        heading: "Feedly News Feeds",
      },

      {
        type: "beyondFeedly",
        icon: "feedlyWhite",
        heading: "Beyond Feedly",
      },
      {
        type: "googleNews",
        icon: "feedlyWhite",
        heading: "Google News Search",
      },
    ],
  },
  {
    tab: 5,
    heading: "HOW DO YOU WANT TO SEARCH FOR MUSIC",
    filters: [
      {
        type: "newsCategory",
        icon: "Radio",
        heading: "News Categories",
      },
      {
        type: "newsArticles",
        icon: "Radio",
        heading: "News Articles",
      },
      {
        type: "feedlyNews",
        icon: "feedlyWhite",
        heading: "Feedly News Feeds",
      },

      {
        type: "beyondFeedly",
        icon: "feedlyWhite",
        heading: "Beyond Feedly",
      },
      {
        type: "googleNews",
        icon: "feedlyWhite",
        heading: "Google News Search",
      },
    ],
  },
];

class SearchPage extends Component {
  constructor(props) {
    super(props);

    this.setSearchVal = this.setSearchVal.bind(this);
    this.setSearchFilter = this.setSearchFilter.bind(this);
    this.state = {
      searchVal: "",
      searchQuery: "",
      searchFilter: "",
      filterHeading: "",
    };
    this.setRef = this.setRef.bind(this);
    this.onChangeDebounced = debounce(this.onChangeDebounced, 2000);
  }

  componentDidMount() {
    const self = this;

    document.addEventListener("keydown", function (e) {
      if (self.props.tab == 4) return;
      var keyCode = e.keyCode || e.which;
      // detect ismodal open
      var models = document.querySelectorAll(".ant-modal-mask");
      var popovers = document.querySelectorAll(".ant-popover");
      if (models.length > 0) {
        const NodeList = [...models];
        let writeCondition = true;
        NodeList.map((item) => {
          writeCondition = item.classList.contains("ant-modal-mask-hidden");
        });
        if (!writeCondition) return;
      }

      if (popovers.length > 0) {
        const NodeList = [...popovers];
        let writeCondition = true;
        NodeList.map((item) => {
          writeCondition = item.classList.contains("ant-popover-hidden");
        });
        if (!writeCondition) return;
      }
      // detect escape key
      if (keyCode === 27 && self.props.SearchToggled) {
        self.closeSearch();
        return;
      }

      if (!self.props.SearchToggled) {
        self.props.setSearchToggle(true);
        if (self.childRef) self.childRef.focus();
        self.props.setInstantSearch(true);
        if (self.props.tab == 1) {
          self.setState({
            searchFilter: filters[1].filters[0].type,
            filterHeading: filters[1].filters[0].heading,
          });
          self.props.setSearchType(filters[1].filters[0].type);
        } else if (self.props.tab == 2) {
          self.setState({
            searchFilter: filters[0].filters[2].type,
            filterHeading: filters[0].filters[2].heading,
          });
          self.props.setSearchType(filters[0].filters[2].type);
        }
      }
    });
  }

  closeSearch() {
    this.props.setSearchToggle(false);
    this.props.setInstantSearch(false);
    this.props.setSearchType("");
    this.props.setSearchValue("");
    this.setState({ searchQuery: "" });
  }

  componentWillReceiveProps(nextProp) {
    if (nextProp.SearchToggled !== this.props.SearchToggled) {
      if (this.childRef) this.childRef.focus();
      this.setState({
        searchVal: "",
      });
      this.props.setSearchValue("");
    }
    if (
      this.props.tab !== nextProp.tab &&
      this.state.searchFilter &&
      this.state.filterHeading
    )
      this.setState({ searchFilter: "", searchVal: "", filterHeading: "" });
    if (nextProp.tab == 1)
      this.setState({
        searchFilter: filters[1].filters[0].type,
        filterHeading: filters[1].filters[0].heading,
      });
    else if (nextProp.tab == 2)
      this.setState({
        searchFilter: filters[0].filters[0].type,
        filterHeading: filters[0].filters[0].heading,
      });
  }

  setSearchVal(val) {
    this.setState({ searchQuery: val });
    this.props.setSearchValue(val);
    this.onChangeDebounced(val);
  }

  onChangeDebounced = (val) => {
    this.setState({ searchVal: val });
  };

  setSearchFilter(val) {
    this.setState({
      searchFilter: val.searchFilter,
      filterHeading: val.filterHeading,
    });
  }

  setRef(input) {
    this.childRef = input;
  }

  render() {
    return (
      <>
        <SearchInput
          inputChange={this.setSearchVal}
          filter={this.state.filterHeading}
          searchVal={this.state.searchQuery}
          setRef={this.setRef}
          closeSearch={this.closeSearch.bind(this)}
        />
        {this.props.instantSearch ? (
          <button
            className={`${styles.podcastAdd} mt-2`}
            style={{
              width: "160px",
              padding: "0.2em 1em",
              marginLeft: "1em",
            }}
            onClick={() => this.props.setInstantSearch(false)}
          >
            GO TO FULL SEARCH
          </button>
        ) : (
          ""
        )}
        {this.props.instantSearch ? (
          ""
        ) : (
          <div className="row mt-5 mx-0" style={{ height: "60%" }}>
            <div className="col-md-5 ">
              <SearchFilter
                data={filters.find((item) => item.tab == this.props.tab)}
                onSelect={this.setSearchFilter}
                selectedFilter={this.state.searchFilter}
                theme={this.props.theme}
              />
            </div>
            <div className={`col-md-7 ${styles.listContainer} h-100`}>
              {this.props.tab == 2 ? (
                <PodcastList
                  query={this.state.searchVal}
                  filter={this.state.searchFilter}
                />
              ) : (
                ""
              )}
              {this.props.tab == 1 ? (
                <NewsList
                  query={this.state.searchVal}
                  filter={this.state.searchFilter}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        )}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchPage);
