import React from "react";
import styles from "./commentcontainer.module.css";

export default function DetailContainer(props) {
  const { activity } = props;
  let title =
    activity.type == "podcast" ? activity.podcastTitle : activity.title;
  return (
    <div className={styles.commentContainer}>
      <div className="row h-100">
        <div className={styles.closeBtn}>
          <img
            src="/images/closeCircleOutlined.svg"
            alt=""
            onClick={() => props.onClose()}
          />
          <h1>Detail</h1>
        </div>

        <div
          className={styles.leftSideContainer}
          style={{ justifyContent: "flex-start" }}
        >
          <img src={activity.image} alt="" className={styles.detailImage} />
          <h2>{title}</h2>
          <p>{activity.podcastDescription}</p>
        </div>
        <div className={`d-flex ${styles.rightSideContainer} flex-column px-5`}>
          {activity.type == "podcast" ? (
            <h2>Episode Notes</h2>
          ) : activity.type == "news" ? (
            <h2>Article Details</h2>
          ) : activity.type == "shorts" ? (
            <h2>Shorts Details</h2>
          ) : activity.type == "music" ? (
            <h2>Music Details</h2>
          ) : (
            ""
          )}

          <p
            className={styles.descriptionContainer}
            style={{
              width:
                activity.description.length < 1000
                  ? "250px"
                  : activity.description.length < 2000
                  ? "50%"
                  : "100%",
              columnCount:
                activity.description.length < 1000
                  ? 1
                  : activity.description.length < 2000
                  ? 2
                  : 3,
            }}
          >
            {activity.description.replace(/(<([^>]+)>)/gi, "")}
          </p>
        </div>
      </div>
    </div>
  );
}
