import React, { Component } from "react";
// import PodcastContainer from "./PodcastoldContainer";
import { connect } from "react-redux";
import ACTIONS from "../../redux/actions/team";
import { message } from "antd";
import _ from "lodash";
import apiRequest from "../../api/clientRequest";
import playliststyles from "../styles/playlist.module.css";
import TeamItem from "../TeamItem";
import TeamSharedContainer from "../TeamSharedContainer";
import TeamItemExpand from "../TeamItemExpand";
import AddTeam from "../AddTeam";
import AddGroup from "../TeamGroup/AddGroup";
import EditTeam from "../EditTeam";
import ScrollContainer from "react-indiana-drag-scroll";

var mapStateToProps = (state) => {
  return {
    teams: state.team.teams,
    selectedTeam: state.team.selectedTeam,
    user: state.auth.user,
  };
};

var mapDispatchToProps = (dispatch) => ({
  setTeam: (item) => dispatch(ACTIONS.setTeams(item)),
  setTeamTemplates: (item) => dispatch(ACTIONS.setTeamTemplates(item)),
  setSelectedTeam: (item) => dispatch(ACTIONS.setSelectedTeam(item)),
  setTab: (item) => dispatch(ACTIONS.setTeamTab(item)),
});

class TeamsBoard extends Component {
  state = {
    teamname: "",
    dataSource: [],
    selectedUsers: [],
    searchResults: [],
    selectedTeam: null,
    selectedTeamForTemplate: null,
    selectedTemplate: null,
    teams: [],
    editTeam: false,
  };

  constructor(props) {
    super(props);
    apiRequest({
      url: "/teams",
      method: "GET",
    }).then((res) => {
      this.props.setTeam([...res.data.teams]);
      apiRequest({
        method: "GET",
        url: "/teams/gettemplate",
      }).then((res) => {
        this.props.setTeamTemplates(res.data.msg);
      });
    });
  }

  addTemplateToTeam = () => {
    if (this.state.selectedTemplate && this.state.selectedTeamForTemplate) {
      apiRequest({
        url: "/teams/addtemplate",
        method: "post",
        data: {
          template: this.state.selectedTemplate,
          teamid: this.state.selectedTeamForTemplate,
        },
      }).then((res) => {
        if (res.data.status == 200) {
          message.success("Template added to team");
        } else {
          message.error(res.data.msg);
        }
      });
    }
  };

  handleItemClick = (id) => {
    const allData = this.props.teams.map((item) => {
      if (item.id == id) item.clicked = true;
      else item.clicked = false;
      return item;
    });

    const foundItem = this.props.teams.find((item) => item.id == id);
    this.props.setTeam(allData);
    this.props.setSelectedTeam(foundItem);
    this.props.setTab(0);
  };

  handleTeamEdit = (id) => {
    const foundItem = this.props.teams.find((item) => item.id == id);
    const foundUser = foundItem.users.find(
      (user) => user.email == this.props.user.email
    );
    const allowedRoles = ["Member", "Editor"];

    if (foundUser && !allowedRoles.includes(foundUser["role"])) {
      this.props.setSelectedTeam(foundItem);
      this.setState({ editTeam: true });
    } else {
      message.error("You do not have permission to perform this operation");
    }
  };

  render() {
    const { dataSource } = this.state;

    return (
      <div className="col-md-9 " style={{ display: this.props.style }}>
        <ScrollContainer>
          <div className="">
            <div className="row scroll pl-3 pr-0 mx-0 w-100">
              <div
                className="col-md-12 mb-2"
                style={{
                  position: "absolute",
                  fontSize: "2.1em",
                  left: "-3em",
                  top: "-2em",
                }}
              >
                <div className={playliststyles.container}>
                  <div className={playliststyles.headingContainer}>
                    <h1
                      className={`${playliststyles.mainHeading} ${playliststyles.explodedHeading}`}
                      // style={{ opacity: "0.05" }}
                    >
                      TEAMS
                    </h1>
                  </div>
                </div>
              </div>
              <h5
                className={`pl-3 w-100 mt-5`}
                style={{ textTransform: "capitalize" }}
              >
                Select Team
              </h5>
              <ScrollContainer>
                <div className="col-md-12 mb-5 d-flex">
                  <AddTeam />
                  <EditTeam
                    visible={this.state.editTeam}
                    handleClose={() => this.setState({ editTeam: false })}
                  />
                  {this.props.teams.map((item) => (
                    <TeamItem
                      id={item.id}
                      handleClick={this.handleItemClick}
                      editTeam={this.handleTeamEdit}
                      clicked={item.clicked}
                      thumb={item.logo}
                      team={item.name}
                      count={item.users.length}
                      type={"members"}
                    />
                  ))}
                </div>
              </ScrollContainer>
              <h5
                className={`pl-3 w-100`}
                style={{ textTransform: "capitalize" }}
              >
                Campaigns
              </h5>
              <div className="col-md-12 mb-5 d-flex">
                {this.props.selectedTeam ? (
                  <TeamItemExpand
                    title="Emails Sent"
                    count={
                      this.props.selectedTeam
                        ? this.props.selectedTeam.emails.length
                        : "1"
                    }
                    items={this.props.selectedTeam.emails}
                    type="messages"
                  />
                ) : (
                  ""
                )}
                <TeamItem title="Emails Template" count="1" type="templates" />
              </div>

              <TeamSharedContainer
                data={
                  this.props.selectedTeam
                    ? [
                        ...this.props.selectedTeam.emails,
                        ...this.props.selectedTeam.sharedArticles,
                      ]
                    : []
                }
              />
            </div>
          </div>
        </ScrollContainer>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(TeamsBoard);
