import React, { Component } from "react";
import FeedlyIcon from "../assets/img/feedly monochrome_large.png";
import ACTIONS from "../redux/actions/news";
import { connect } from "react-redux";
import styles from "./Navbar/dashNavbar.module.css";
import apiRequest from "../api/clientRequest";
import axios from "axios";
import SITEACTIONS from "../redux/actions/site";

var mapStateToProps = (state) => {
  return {
    feedlyUser: state.news.feedlyUser,
    socket: state.site.socket,
  };
};
// Change
var mapDispatchToProps = (dispatch) => ({
  setTags: (item) => dispatch(ACTIONS.setTags(item)),
  setFeed: (item) => dispatch(ACTIONS.setFeed(item)),
  setFeedlyUser: (item) => dispatch(ACTIONS.setFeedlyUser(item)),
  setFeedlyFeed: (item) => dispatch(ACTIONS.setFeedlyFeed(item)),
  setPlaylists: (item) => dispatch(ACTIONS.setPlaylists(item)),
  setNewsLogChange: (item) => dispatch(ACTIONS.setNewsLogChange(item)),
  setImportantFeed: (item) => dispatch(ACTIONS.setImportantFeed(item)),
  setReadLater: (item) => dispatch(ACTIONS.setReadLater(item)),
  setBoards: (item) => dispatch(ACTIONS.setBoards(item)),
  setTodayNews: (item) => dispatch(ACTIONS.setSubscribedNews(item)),
  setSavedItems: (item) => dispatch(SITEACTIONS.setSavedItems(item)),
  setStations: (item) => dispatch(ACTIONS.setStations(item)),
});

class FeedlyLogin extends Component {
  constructor(props) {
    super(props);
    const authEndpoint = "https://cloud.feedly.com/v3/auth/auth";
    // Replace with your app's client ID, redirect URI and desired scopes
    const clientId = "audioone";
    const redirectUri = "https://feedlyauth.herokuapp.com/feedlyCallback";
    const scope = "https://cloud.feedly.com/subscriptions";

    var url = `${authEndpoint}?client_id=${clientId}&redirect_uri=${encodeURIComponent(
      redirectUri
    )}&scope=${scope}&response_type=code`;

    this.feedlyUrl = url;
    window.addEventListener("message", this.receiveMessage.bind(this));
  }

  receiveMessage(e) {
    if (e.data.type == "feedlyAuth") {
      window.localStorage.setItem("feedly_token", e.data.accessToken);
      window.localStorage.setItem("feedly_refresh", e.data.refreshToken);
      window.localStorage.setItem("feedly_id", e.data.userId);
      this.props.socket.off("newFeed");
      this.props.setFeedlyUser({
        token: e.data.accessToken,
        user_id: e.data.userId,
      });
      var self = this;
      this.props.setFeed({ feed: [], tags: [] });

      setTimeout(() => {
        self.feedlyLoggedIn();
      }, 1300);
    }
  }

  feedlyLogIn(e) {
    e.preventDefault();
    var spotifyLoginWindow = window.open(
      this.feedlyUrl,
      "Login with Feedly",
      "width=550,height=600"
    );
  }

  componentDidMount() {
    var that = this;
    that.getRefreshedToken();
    var timer = 55 * 60000;

    if (window.localStorage.getItem("feedly_token"))
      setInterval(async () => {
        await that.getRefreshedToken();
      }, timer);
  }

  async getRefreshedToken() {
    var refreshed_token = await axios
      .post("https://feedlyauth.herokuapp.com/refresh_token", {
        refresh_token: window.localStorage.getItem("feedly_refresh"),
      })
      .then((res) => res.data);
    var d = new Date();
    d.setMinutes(d.getMinutes() + 60);
    window.localStorage.setItem("feedly_token", refreshed_token);
    window.localStorage.setItem("token_time", d);

    const feedlyToken = window.localStorage.getItem("feedly_token");
    const feedlyId = window.localStorage.getItem("feedly_id");
    this.props.setFeedlyUser({ token: feedlyToken, user_id: feedlyId });

    var that = this;
    setTimeout(() => {
      that.fetchNews();
    }, 1000);
  }

  async fetchNews() {
    if (!this.props.feedlyUser.token) {
      const tempobj = await apiRequest({
        url: "/news/excel-feed",
        method: "GET",
      }).then((res) => {
        var obj = {};
        obj.feed = res.data.message;
        obj.tags = res.data.tags;
        return obj;
      });
      this.props.setFeed(tempobj);
      const playLists = await apiRequest({
        url: "/news/fetch/playlist",
        method: "GET",
      }).then((res) => {
        return res.data.message;
      });
      this.props.setPlaylists(playLists);
    } else {
      apiRequest({
        url: "/stations/Sports",
        method: "POST",
        data: { feedlyUser: this.props.feedlyUser },
      }).then((res) => {
        this.props.setStations(res.data);
      });
      apiRequest({
        url: "/news/savedItems",
        method: "POST",
        data: { feedlyUser: this.props.feedlyUser },
      }).then((res) => {
        this.props.setSavedItems(res.data.items);
      });
      apiRequest({
        url: "/feedly/news",
        method: "POST",
        data: { feedlyUser: this.props.feedlyUser, date: new Date() },
      }).then((res) => {
        this.props.setFeed({
          feed: res.data.news.articles,
          tags: res.data.news.tags,
        });
        this.props.setImportantFeed(res.data.importantArticles);
        this.props.setTodayNews(res.data.todayArticles);
        this.props.setPlaylists(res.data.playlists);
        this.props.setReadLater(res.data.readLater);
        this.props.setBoards(res.data.boards);
        apiRequest({
          url: "/feedly/news/refresh",
          method: "POST",
          data: { feedlyUser: this.props.feedlyUser, date: new Date() },
        }).then((response) => {
          this.props.setFeed({
            feed: response.data.news.articles,
            tags: response.data.news.tags,
          });
          this.props.setImportantFeed(response.data.importantArticles);
          this.props.setTodayNews(response.data.todayArticles);
          this.props.setPlaylists(res.data.playlists);
          this.props.setReadLater(res.data.readLater);
          this.props.setBoards(res.data.boards);
        });
      });
    }
  }

  feedlyLogOut() {
    window.localStorage.removeItem("feedly_token");
    window.localStorage.removeItem("feedly_refresh");
    window.localStorage.removeItem("feedly_id");
    this.props.socket.off("userFeed");

    this.props.setFeedlyUser({
      token: null,
      user_id: null,
    });
    this.props.setFeed({ feed: [], tags: [] });

    var self = this;
    setTimeout(() => {
      self.feedlyLoggedIn();
    }, 1300);
  }

  async feedlyLoggedIn() {
    this.props.setNewsLogChange(true);
    this.fetchNews();
  }

  render() {
    return (
      <>
        {!this.props.feedlyUser.token ? (
          <img
            src="/images/feedLogo.png"
            className={styles.spotLogo}
            alt=""
            onClick={this.feedlyLogIn.bind(this)}
          />
        ) : (
          <img
            src="/images/feedLogo.png"
            alt=""
            onClick={this.feedlyLogOut.bind(this)}
          />
        )}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FeedlyLogin);
