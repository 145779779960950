import React, { Component, useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

const Home = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <div className='container ch-pad'>
      <section className='session'>
        <h1>Get started with your Audio Profile</h1>

        <div className='session-form form'>
          <p className='actions'>
            <input
              type='submit'
              name='commit'
              value='Sign in'
              className='btn-primary btn black-square-btn'
              data-disable-with='Signing in…'
              onClick={() => {
                loginWithRedirect({ screen_hint: 'login' })
              }}
            />
          </p>
        </div>

        <div className='session-form form mt-0'>
          <p className='actions'>
            <input
              type='submit'
              name='commit'
              value='Sign Up'
              className='btn-primary btn black-square-btn'
              data-disable-with='Signing in…'
              onClick={() => {
                loginWithRedirect({ screen_hint: 'signup' })
              }}
            />
          </p>
        </div>
      </section>{' '}
    </div>
  )
}

export default Home
