import React, { Component, useState } from "react";
import $ from "jquery";
import Axios from "axios";
import styles from "./styles/newsitem.module.css";
import MusicStyle from "./styles/musicitem.module.css";
import PlaylistContainer from "./PlaylistContainer";
import { connect } from "react-redux";
import ACTIONS from "../redux/actions/site";
import NEWSACTIONS from "../redux/actions/news";
import colors from "../data/colors.json";
import ShareModal from "./shareModal";
import apiRequest from "../api/clientRequest";
import ShareMethods from "./ShareMethods";
import FavoriteFeedModal from "./FavoriteFeedModal";
import { subscribeBtn, subscribedBtn } from "./styles/podcastInfo.module.css";
import InfiniteScroll from "react-infinite-scroll-component";

var mapStateToProps = (state) => {
  return {
    playlists: state.news.playlists,
    theme: state.site.theme,
    savedItems: state.site.savedItems,
    feedlyUser: state.news.feedlyUser,
    tags: state.news.tags,
    feed: state.news.feed,
    importantFeed: state.news.importantFeed,
    subscribedItems: state.site.subscribedItems,
  };
};

var mapDispatchToProps = (dispatch) => ({
  setSavedItems: (items) => dispatch(ACTIONS.setSavedItems(items)),
  setFeed: (item) => dispatch(NEWSACTIONS.setFeed(item)),
  setImportantFeed: (item) => dispatch(NEWSACTIONS.setImportantFeed(item)),
  setSubscribedItems: (items) => dispatch(ACTIONS.setSubscribedItems(items)),
});
class NewsItem extends Component {
  state = {
    expanded: false,
    loadingImage: false,
    hoverImg: false,
    shareVisibile: false,
    selectedArticle: null,
    savedArticle: { id: false },
    showPlayHover: false,
    showShareHover: false,
    mode: null,
    likeHover: false,
    page: 1,
    feed: [],
  };

  componentWillMount() {
    try {
      if (this.props.articles[0].image.contains("https"))
        Axios.get(this.props.articles[0].image)
          .then((res) => {
            this.setState({ loadingImage: true });
          })
          .catch((err) => {
            this.setState({ loadingImage: false });
          });
    } catch (err) {
      this.setState({ loadingImage: false });
    }
  }

  unExpand() {
    $(".instantSearch").toggleClass("d-none");

    this.setState({
      expanded: false,
    });
  }

  setModalVisibility(cond) {
    this.setState({
      shareVisibile: cond,
    });
  }

  setSelectArticle(article, mode) {
    this.setState({
      selectedArticle: article,
      mode,
      shareVisibile: true,
    });
  }

  saveItem = (item) => {
    item.type = "news";
    if (this.props.savedItems.find((ite) => ite.track_id == item.id)) {
      item.clicked = false;
      this.props.setSavedItems(
        this.props.savedItems.filter((ite) => ite.track_id !== item.id)
      );
      apiRequest({ url: "/news/save", method: "POST", data: item });
    } else {
      item.clicked = true;
      apiRequest({ url: "/news/save", method: "POST", data: item });
      this.props.setSavedItems([
        { track_id: item.id, type: "news", ...item },
        ...this.props.savedItems.map((ite) => ite),
      ]);
    }
  };

  expand() {
    let importantOnly = "";
    if (this.props.filter !== "All") importantOnly = "/important";

    apiRequest({
      url: `/feedly/articles/${this.props.data.category}/${importantOnly}`,
      method: "POST",
      data: { feedlyUser: this.props.feedlyUser },
    }).then((res) => {
      this.setState({
        feed: res.data.articles,
      });
    });

    $(".instantSearch").toggleClass("d-none");
    this.setState({ expanded: true });
  }

  playAllArticles(articles) {
    articles.map((article) => this.props.playArticle(article));
  }

  subscribeTo = (item, isSubscribed) => {
    item.type = "news";
    item["item_id"] = item.id;
    if (isSubscribed) {
      item.clicked = false;
      this.props.setSubscribedItems(
        this.props.subscribedItems.filter((ite) => ite.item_id !== item.id)
      );
      apiRequest({
        url: "/news/subscribe",
        method: "POST",
        data: item,
      });
    } else {
      item.clicked = true;
      apiRequest({
        url: "/news/subscribe",
        method: "POST",
        data: item,
      });
      this.props.setSubscribedItems([
        ...this.props.subscribedItems.map((ite) => ite),
        item,
      ]);
    }
  };

  fetchMoreData = () => {
    apiRequest({
      url: "/feedly/lazyload",
      data: {
        page: this.state.page,
        count: 20,
        type: this.props.data.category,
        feedlyUser: this.props.feedlyUser,
      },
      method: "POST",
    }).then((res) => {
      console.log(res.data);
      let temparticles = [...this.state.feed, ...res.data.articles];
      temparticles = temparticles.sort((a, b) => {
        return b.published - a.published;
      });
      console.log(temparticles);
      this.setState((prev) => ({
        page: prev.page + 1,
        feed: temparticles,
      }));
    });
  };

  render() {
    let { category, articles } = this.props.data;
    articles = articles.sort((a, b) => b.published - a.published);
    const article = articles[0];
    var color = "";
    const { showPlayHover, showShareHover, likeHover } = this.state;

    if (this.props.theme == "color-theme" && !article.image)
      color = colors[Math.floor(Math.random() * colors.length)];

    const isSubscribed = this.props.subscribedItems.find((item) => {
      return item.item_id == category;
    });

    const { feed } = this.state;

    return (
      <div
        className={`currency-box newsItem  ${
          this.state.expanded ? "expand" : ""
        }`}
      >
        <ShareModal
          visible={this.state.shareVisibile}
          setVisiblity={this.setModalVisibility.bind(this)}
          selectedItem={this.state.selectedArticle}
          type="news"
          setSelectedArticle={this.setSelectArticle.bind(this)}
          mode={this.state.mode}
        />

        <div className="currency-pair">
          <div
            className={`background-image ${isSubscribed ? "subscribed" : ""} ${
              this.props.theme == "color-theme" && this.state.expanded
                ? "white-back"
                : ""
            }`}
            style={{
              background: `${
                this.props.theme == "color-theme" && this.state.expanded
                  ? "white"
                  : `url(${article.image}) ${color}`
              }`,
            }}
          ></div>

          <div
            className={styles.playOver}
            onMouseLeave={() =>
              this.setState({ showPlayHover: false, showShareHover: false })
            }
            style={{ display: showPlayHover == 1 ? "flex" : "none" }}
          >
            <div>
              Play Now{" "}
              <img
                src={"/images/playNow.png"}
                className={`${styles.playIcon} `}
                onClick={() => this.props.playArticle(article, "playNow")}
                alt=""
              />
            </div>
            <div>
              Play Next{" "}
              <img
                src={"/images/playNext.png"}
                className={`${styles.playIcon} `}
                onClick={() => this.props.playNext(article)}
                alt=""
              />
            </div>
            <div>
              {article["leoSummary"] ? "Play Leo Summary" : "Play Summary"}
              <img
                src={"/images/playIcon.png"}
                className={`${styles.playIcon} `}
                onClick={() =>
                  this.props.playArticle(
                    { ...article, summary: true },
                    "playNow"
                  )
                }
                alt=""
              />
            </div>
            <div>
              Play In-Queue{" "}
              <img
                src={"/images/playIcon.png"}
                className={`${styles.playIcon} `}
                onClick={() => this.props.playArticle(article)}
                alt=""
              />
            </div>
          </div>

          <ShareMethods
            id={1}
            showHover={showShareHover}
            setShowHover={() => this.setState({ showShareHover: false })}
            setSelected={(mode) => this.setSelectArticle(article, mode)}
          />
          <FavoriteFeedModal
            id={1}
            visible={likeHover}
            setHover={() => this.setState({ likeHover: false })}
            saveToCollection={() => this.saveItem(article)}
            selectedArticle={article}
            savedItems={this.props.savedItems}
            type="news"
          />

          <div id={`${category}content`} className="cat-content">
            <img
              src="/images/block-menu.png"
              className={`${styles.expandIcon} ${
                this.state.expanded ? "d-none" : "d-inline-block"
              }`}
              alt=""
              onClick={
                () => this.expand()
                // this.setState((prev) => ({ expanded: !prev.expanded }))
              }
            />
            <span
              className={`name mt-1  ${
                this.state.expanded ? "d-none" : "d-inline-block"
              }`}
            >
              #{category}
            </span>
            <img
              src={"/images/playIcon.png"}
              onClick={() => this.props.playArticle(article)}
              className={`${styles.playIcon} ${
                this.state.expanded ? "d-none" : ""
              }`}
              onMouseEnter={() => this.setState({ showPlayHover: 1 })}
              alt=""
            />

            <div
              className={`article-container   ${
                this.state.expanded ? "d-none" : ""
              }`}
            >
              <span className="article-name"> {article.title}</span>
              <div className={styles.actionContainer}>
                <span className="article-source">
                  {article.source ? article.source.substr(0, 15) : ""}
                </span>
                <div className="d-flex ml-auto align-items-end">
                  <img
                    src="/images/cluster.png"
                    className={styles.actionIcon}
                    alt=""
                    onMouseEnter={() => this.setState({ showShareHover: 1 })}
                  />
                  {this.props.savedItems.find(
                    (item) => item.track_id == article.id
                  ) ? (
                    <img
                      src="/images/HeartFill.png"
                      className={`${styles.actionIcon} ${styles.likedIcon}`}
                      onClick={() => this.saveItem(article)}
                      onMouseEnter={() => this.setState({ likeHover: 1 })}
                      alt=""
                    />
                  ) : (
                    <img
                      src="/images/Heart.png"
                      className={`${styles.actionIcon} `}
                      onMouseEnter={() => this.setState({ likeHover: 1 })}
                      onClick={() => this.saveItem(article)}
                      alt=""
                    />
                  )}
                </div>
              </div>
            </div>

            {this.state.expanded ? (
              <div
                className={`row m-0 expanded-container ${
                  this.state.expanded ? "" : "d-none"
                }`}
              >
                <div
                  className={styles.backArrowContainer}
                  onClick={this.unExpand.bind(this)}
                >
                  <img src="/images/backTo.svg" alt="" />
                  <img src="/images/back-left.png" alt="" />
                </div>
                <div
                  style={{
                    position: "fixed",
                    left: "31px",
                    top: "30%",
                    zIndex: "999",
                  }}
                >
                  <img
                    src={"/images/playIcon.png"}
                    className={`${styles.playIcon} `}
                    onClick={() => this.playAllArticles(articles)}
                    style={{
                      width: "80px",
                      height: "80px",
                      marginBottom: "15px",
                    }}
                    alt=""
                  />
                  <p style={{ textAlign: "center" }}>Play All </p>
                </div>
                <PlaylistContainer
                  playlists={this.props.playlists.filter(
                    (item) => item.category == category
                  )}
                  heading={category.toUpperCase()}
                />
                {isSubscribed ? (
                  <button
                    className={`${subscribeBtn} ${subscribedBtn} ${styles.subBtn}`}
                    onClick={() =>
                      this.subscribeTo({ id: category }, isSubscribed)
                    }
                  >
                    Subscribed
                  </button>
                ) : (
                  <button
                    className={` ${subscribeBtn} ${styles.subBtn}`}
                    onClick={() =>
                      this.subscribeTo({ id: category }, isSubscribed)
                    }
                  >
                    Subscribe
                  </button>
                )}
                <InfiniteScroll
                  dataLength={feed.length} //This is important field to render the next data
                  next={() => {
                    this.fetchMoreData();
                  }}
                  hasMore={true}
                  scrollableTarget={`${category}content`}
                >
                  <div
                    className="row mt-3 mx-0"
                    style={{ paddingBottom: "10em" }}
                  >
                    {feed.map((article) => (
                      <div
                        className="d-flex"
                        // onMouseLeave={() => this.setState({ hoverImg: false })}
                        // onMouseOver={() => this.setState({ hoverImg: article.image })}
                      >
                        <div
                          className={styles.playOver}
                          onMouseLeave={() =>
                            this.setState({ showPlayHover: false })
                          }
                          style={{
                            display:
                              showPlayHover == article.id ? "flex" : "none",
                          }}
                        >
                          <div>
                            Play Now{" "}
                            <img
                              src={"/images/playNow.png"}
                              className={`${styles.playIcon} `}
                              onClick={() =>
                                this.props.playArticle(article, "playNow")
                              }
                              alt=""
                            />
                          </div>
                          <div>
                            Play Next{" "}
                            <img
                              src={"/images/playNext.png"}
                              className={`${styles.playIcon} `}
                              onClick={() => this.props.playNext(article)}
                              alt=""
                            />
                          </div>
                          <div>
                            {article["leoSummary"]
                              ? "Play Leo Summary"
                              : "Play Summary"}
                            <img
                              src={"/images/playIcon.png"}
                              className={`${styles.playIcon} `}
                              onClick={() =>
                                this.props.playArticle(
                                  {
                                    ...article,
                                    summary: true,
                                  },
                                  "playNow"
                                )
                              }
                              alt=""
                            />
                          </div>
                          <div>
                            Play In-Queue{" "}
                            <img
                              src={"/images/playIcon.png"}
                              className={`${styles.playIcon} `}
                              onClick={() => this.props.playArticle(article)}
                              alt=""
                            />
                          </div>
                        </div>

                        <ShareMethods
                          id={article.id}
                          showHover={showShareHover}
                          setShowHover={() =>
                            this.setState({ showShareHover: false })
                          }
                          setSelected={(mode) =>
                            this.setSelectArticle(article, mode)
                          }
                        />
                        <FavoriteFeedModal
                          id={article.id}
                          visible={likeHover}
                          setHover={() => this.setState({ likeHover: false })}
                          saveToCollection={() => this.saveItem(article)}
                          selectedArticle={article}
                          savedItems={this.props.savedItems}
                          type="news"
                        />

                        <img
                          src={"/images/playIcon.png"}
                          onClick={() => this.props.playArticle(article)}
                          className={styles.playIcon}
                          onMouseEnter={() =>
                            this.setState({ showPlayHover: article.id })
                          }
                          alt=""
                        />
                        <div className="article-container ">
                          <div className="d-flex justify-content-between">
                            <span
                              className={`article-name ${MusicStyle.articleName}`}
                            >
                              {article.title}
                            </span>
                            <img
                              className={MusicStyle.trackImage}
                              src={article.image}
                              alt=""
                            />
                          </div>

                          <div className={styles.actionContainer}>
                            {" "}
                            <span className="article-source">
                              {article.source
                                ? article.source.substr(0, 15)
                                : ""}
                            </span>
                            <div className="d-flex ml-auto align-items-end">
                              <img
                                src="/images/cluster.png"
                                className={styles.actionIcon}
                                alt=""
                                onMouseEnter={() =>
                                  this.setState({ showShareHover: article.id })
                                }
                              />
                              {this.props.savedItems.find(
                                (item) => item.track_id == article.id
                              ) ? (
                                <img
                                  src="/images/HeartFill.png"
                                  className={`${styles.actionIcon} ${styles.likedIcon}`}
                                  onClick={() => this.saveItem(article)}
                                  onMouseEnter={() =>
                                    this.setState({ likeHover: article.id })
                                  }
                                  alt=""
                                />
                              ) : (
                                <img
                                  src="/images/Heart.png"
                                  className={`${styles.actionIcon} `}
                                  onClick={() => this.saveItem(article)}
                                  onMouseEnter={() =>
                                    this.setState({ likeHover: article.id })
                                  }
                                  alt=""
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </InfiniteScroll>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsItem);
