import React, { Component } from "react";
import message from "antd/lib/message";
import styles from "../styles/onboarding.module.css";

var newstags = require("../../data/newstag.json");

export default class step2 extends Component {
  state = {
    selectedTags: [],
    searchQuery: "",
    loggedIn: false
  };
  constructor(props) {
    super(props);
    const authEndpoint = "https://cloud.feedly.com/v3/auth/auth";
    // Replace with your app's client ID, redirect URI and desired scopes
    const clientId = "audioone";
    const redirectUri = "https://feedlyauth.herokuapp.com/feedlyCallback";
    const scope = "https://cloud.feedly.com/subscriptions";

    var url = `${authEndpoint}?client_id=${clientId}&redirect_uri=${encodeURIComponent(
      redirectUri
    )}&scope=${scope}&response_type=code`;

    this.feedlyUrl = url;
    window.addEventListener("message", this.receiveMessage.bind(this));
  }

  receiveMessage(e) {
    if (e.data.type == "feedlyAuth") {
      window.localStorage.setItem("feedly_token", e.data.accessToken);
      window.localStorage.setItem("feedly_refresh", e.data.refreshToken);
      window.localStorage.setItem("feedly_id", e.data.userId);
      this.setState({
        loggedIn: true
      });
    }
  }

  feedlyLogIn(e) {
    e.preventDefault();
    var spotifyLoginWindow = window.open(
      this.feedlyUrl,
      "Login with Feedly",
      "width=550,height=600"
    );
  }

  componentWillMount() {
    newstags = this.shuffleTags(newstags);
    this.articleTags = {
      children: newstags
    };
  }

  handleSubmit(e) {
    e.preventDefault();

    this.props.submitHandle({ tags: this.state.selectedTags });
  }

  tagClicked(item) {
    if (this.state.selectedTags.length == 5) {
      message.warning("Sorry you can only select upto five tags");
      return false;
    } else if (!this.state.selectedTags.find(ite => ite == item)) {
      this.setState(prev => ({
        selectedTags: [...prev.selectedTags, item]
      }));
      return true;
    } else {
      this.setState(prev => ({
        selectedTags: prev.selectedTags.filter(ite => ite !== item)
      }));
      return false;
    }
  }

  shuffleTags(a) {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
  }

  componentDidMount() {
    const self = this;
    var diameter = 1100;

    var bubble = window.d3
      .pack(this.articleTags)
      .size([diameter, diameter])
      .padding(function(d) {
        return d.height > 1 ? 5 / 0.35 : 20;
      });
    var svg = window.d3
      .select("#chart")
      .append("svg")
      .attr("width", diameter)
      .attr("height", diameter)
      .attr("class", "bubble")
      .style("transform", "rotate(-90deg)");

    var nodes = window.d3.hierarchy(this.articleTags).sum(function(d) {
      return Math.floor(Math.random() * 400) + 200;
    });
    var node = svg
      .selectAll(".node")
      .data(bubble(nodes).descendants())
      .enter()
      .filter(function(d) {
        return !d.children;
      })
      .append("g")
      .attr("class", function(d){
        if(self.props.data.tags.includes(d.data.name))
        return "node active"
        else
        return "node"
      })
      .attr("transform", function(d) {
        return "translate(" + d.x + "," + d.y + ") ";
      })
      .on("mouseover", function(d, i) {
        window.d3.select(this).style("cursor", "pointer");
      })
      .on("click", function(d) {
        if (self.tagClicked(d.data.name)) {
          window.d3.select(this).classed("active", true);
        } else window.d3.select(this).classed("active", false);
      });

    node.append("title").text(function(d) {
      return d.name + ": " + d.Count;
    });

    node
      .append("circle")
      .attr("r", function(d) {
        return d.r;
      })
      .style("fill", "#1C1C1C");

    node
      .append("text")
      .attr("dy", ".2em")
      .style("text-anchor", "middle")
      .style("font-size", "14px")
      .style("transform", "rotate(90deg)")
      .text(function(d) {
        return d.data.name.substring(0, d.r / 3);
      })
      .attr("font-family", "sans-serif")
      .attr("fill", "white");

    node
      .append("svg:foreignObject")
      .attr("height", "30px")
      .attr("width", "30px")

      .attr("margin-top", "10px")
      .html('<i class="fas fa-check"></i>');
  }
  render() {
    const { bindSubmitForm, submitHandle } = this.props;
    let tags = newstags.filter(
      ite => !this.state.selectedTags.includes(ite.name)
    );
    if (this.state.searchQuery) {
      const query = this.state.searchQuery;
      const rege = `${query.toLowerCase()}.*`;

      const re = new RegExp(rege);
      tags = tags.filter(ite => ite.name.toLowerCase().match(re));
    }

    return (
      <div className={styles.stepContainer}>
        <div className="row">
          <div className={`col-md-3 ${styles.leftSide}`}>
            <h1 className={styles.heading}>News</h1>
            <img src="/images/newsImages.png" className={styles.stepImage} />
            <h2 className={styles.secondHeading}>
              Get the Latest News Stories
            </h2>
            <p className={styles.paraContent}>
              No time to read, then just listen to the news. <b>Audio One</b>{" "}
              delivers the latest news stories covering a wide spectrum
              interests. News that you can trust from sources that you read,
              listen or watch on a daily basis.
            </p>
            <p className={styles.paraContent}>
              To get started, you can <b>select</b> up to <b>5</b> topics on
              your free Audio One account. If you want more, there are many
              options, including bringing your own personalized news form
              services like <b>Feedly, Facebook</b> or <b>Twitter</b>. You can
              add your <b>Feedly</b> account right now.
            </p>
            <button
              className={styles.logBtn}
              onClick={this.feedlyLogIn.bind(this)}
            >
              {" "}
              <img src="/images/feedlyWhite.png" />{" "}
              {this.state.loggedIn ? "Logged into Feedly" : "Login To Feedly"}
            </button>
          </div>
          <div className={`col-md-9 ${styles.rightContainer}`}>
            <div id="chart"></div>
          </div>

          <button
            ref={fr => {
              bindSubmitForm(fr);
            }}
            onClick={this.handleSubmit.bind(this)}
            type="submit"
            className="d-none btn"
          >
            click me
          </button>
        </div>
      </div>
    );
  }
}

// import React, { Component } from "react";
// import message from "antd/lib/message";
// var newstags = require("../../data/newstag.json");
// export default class step2 extends Component {
//   state = {
//     selectedTags: [],
//     searchQuery: ""
//   };

//   componentWillMount() {
//     newstags = this.shuffleTags(newstags);
//   }

//   handleSubmit(e) {
//     e.preventDefault();

//     this.props.submitHandle({ tags: this.state.selectedTags });
//   }

//   tagClicked(item) {
//     if (this.state.selectedTags.length == 5) {
//       message.warning("Sorry you can only select upto five tags");
//       return;
//     }
//     this.setState(prev => ({
//       selectedTags: [...prev.selectedTags, item]
//     }));
//   }

//   deSelectTag(item) {
//     this.setState(prev => ({
//       selectedTags: prev.selectedTags.filter(ite => ite !== item)
//     }));
//   }

//   shuffleTags(a) {
//     for (let i = a.length - 1; i > 0; i--) {
//       const j = Math.floor(Math.random() * (i + 1));
//       [a[i], a[j]] = [a[j], a[i]];
//     }
//     return a;
//   }
//   render() {
//     const { bindSubmitForm, submitHandle } = this.props;
//     let tags = newstags.filter(
//       ite => !this.state.selectedTags.includes(ite.name)
//     );
//     if (this.state.searchQuery) {
//       const query = this.state.searchQuery;
//       const rege = `${query.toLowerCase()}.*`;

//       const re = new RegExp(rege);
//       tags = tags.filter(ite => ite.name.toLowerCase().match(re));
//     }

//     return (
//       <div className="page-content">
//         <div className="container contact-container pt-1">
//           <header className="contact-head">
//             <div className="secondary-heading">
//               <h5 className="secondary-heading-item">
//                 Select News that you really want to hear.
//               </h5>
//             </div>
//           </header>
//           <form className="contact-form">
//             <input
//               className="contact-input"
//               type="text"
//               name="search"
//               placeholder="Search.."
//               value={this.state.searchQuery}
//               onChange={e => this.setState({ searchQuery: e.target.value })}
//             />
//             <button className="button-contact-input btn">
//               <i className="fas fa-search"></i>
//             </button>
//           </form>
//         </div>
//         <div className="contact-tags">
//           <div className="tag-box">
//             {this.state.selectedTags.map(item => (
//               <span
//                 onClick={this.deSelectTag.bind(this, item)}
//                 className="tag-item red-hover tag-selected"
//               >
//                 #{item}
//               </span>
//             ))}
//             {tags.map(item => (
//               <span
//                 onClick={this.tagClicked.bind(this, item.name)}
//                 className="tag-item blue-hover"
//               >
//                 #{item.name}
//               </span>
//             ))}
//           </div>
//           <button
//             ref={fr => {
//               bindSubmitForm(fr);
//             }}
//             onClick={this.handleSubmit.bind(this)}
//             type="submit"
//             className="d-none btn"
//           >
//             click me
//           </button>
//         </div>
//       </div>
//     );
//   }
// }
