import React, { Component } from "react";
import styles from "./styles/podcastInfo.module.css";
import { connect } from "react-redux";
import ACTIONS from "../redux/actions/site";
import { selectBtn } from "./styles/sharemodal.module.css";
import { playIcon, actionIcon } from "./styles/newsitem.module.css";
import apiRequest from "../api/clientRequest";
const mapStateToProps = (state) => ({
  subscribedItems: state.site.subscribedItems,
});

const mapDispatchToProps = (dispatch) => ({
  updateRecentPlayed: (items) => dispatch(ACTIONS.updateRecentPlayed(items)),
});

class TeamEmailBanner extends Component {
  render() {
    const data = JSON.parse(this.props.data.media);
    const { title, description, image, source, id, published } = data;

    return (
      <div className={`${styles.container} d-flex pinfoContainer`}>
        <img className={styles.image} src={image} alt="" />

        <div
          className={`${styles.contentContainer} ${styles.contentContainerMax} pr-5`}
        >
          <h3 className="text-white">{title}</h3>
          <span className="text-white">{source}</span>
        </div>
        <div>
          <a
            class={`btn ml-2 mt-3 pt-3 ${selectBtn}`}
            style={{ width: "145px", float: "right" }}
            target="_blank"
            href={`/content/${this.props.data._id}`}
          >
            View
          </a>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamEmailBanner);
