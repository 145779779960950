import React, { Component } from "react";
import ACTIONS from "../redux/actions/site";
import { connect } from "react-redux";
import axios from "axios";
import styles from "./Navbar/dashNavbar.module.css";
import env from "../api/environment";
import player from "../data/player";

var mapStateToProps = (state) => {
  return {
    musicList: state.site.musicList,
  };
};

var mapDispatchToProps = (dispatch) => ({
  setMusicList: (item) => dispatch(ACTIONS.setMusicList(item)),
  spotifyFetchList: (item) => dispatch(ACTIONS.spotifyFetchList(item)),
});
class SpotifyLogin extends Component {
  state = {
    spotifyToken: window.localStorage.getItem("spotify_token"),
    musicPlaylist: this.props.musicList,
  };

  constructor(props) {
    super(props);

    this.Player = player();
    const authEndpoint = "https://accounts.spotify.com/authorize";
    // Replace with your app's client ID, redirect URI and desired scopes
    const clientId = "47827d1d96cc4167aaa863d61f791d2c";
    const redirectUri =
      "https://spotify-authrization.herokuapp.com/auth/callback";
    const scopes = [
      "user-top-read",
      "user-read-currently-playing",
      "user-read-playback-state",
      "playlist-read-private",
      "playlist-read-collaborative",
      "streaming",
      "user-read-email",
      "user-read-private",
      "user-read-recently-played",
      "user-modify-playback-state",
    ];

    var url = `${authEndpoint}?client_id=${clientId}&redirect_uri=${encodeURIComponent(
      redirectUri
    )}&scope=${scopes.join("%20")}&response_type=code&show_dialog=true`;

    this.spotifyUrl = url;

    this.receiver = document.getElementById("receiver").contentWindow;
    window.addEventListener("message", this.receiveMessage.bind(this));
  }
  async componentDidMount() {
    var timer = 55 * 60000;
    const that = this;
    await that.getRefreshedToken();

    if (window.localStorage.getItem("spotify_token")) {
      this.Player.sendSpotifyToken();
      setInterval(async () => {
        await that.getRefreshedToken();
        this.Player.sendSpotifyToken();

        const accesstoken = window.localStorage.getItem("spotify_token");
        that.setState({
          spotifyToken: accesstoken,
        });
        const obj = {
          type: "spotify_token_renew",
          token: accesstoken,
        };
        that.receiver.postMessage(obj, env.PLAYER_URL);
      }, timer);
    }

    if (this.props.musicList.length == 0)
      this.props.spotifyFetchList({
        fetch: true,
        token: window.localStorage.getItem("spotify_token"),
      });
  }
  receiveMessage(e) {
    if (e.data.type == "spotifyAuth")
      this.spotifyToken({
        acToken: e.data.accessToken,
        refToken: e.data.refreshToken,
      });
  }

  spotifyToken(tokens) {
    window.localStorage.setItem("spotify_token", tokens.acToken);
    window.localStorage.setItem("spotify_refresh", tokens.refToken);
    this.Player.sendSpotifyToken();

    var d = new Date();
    d.setMinutes(d.getMinutes() + 60);
    window.localStorage.setItem("token_time", d);
    var that = this;

    this.setState({
      spotifyToken: tokens.acToken,
    });
    this.props.spotifyFetchList({ fetch: true, token: tokens.acToken });
  }

  async getRefreshedToken() {
    var refreshed_token = await axios
      .post("https://spotify-authrization.herokuapp.com/refresh_token", {
        refresh_token: window.localStorage.getItem("spotify_refresh"),
      })
      .then((res) => res.data);
    var d = new Date();
    d.setMinutes(d.getMinutes() + 60);
    this.setState({
      spotifyToken: refreshed_token.access_token,
    });
    window.localStorage.setItem("spotify_token", refreshed_token.access_token);
    window.localStorage.setItem("token_time", d);
  }

  spotifyLogIn(e) {
    e.preventDefault();
    var spotifyLoginWindow = window.open(
      this.spotifyUrl,
      "Login with Spotify",
      "width=550,height=600"
    );
  }

  spotifyLogout() {
    this.props.setMusicList([]);
    window.localStorage.removeItem("spotify_token");
    window.localStorage.removeItem("spotify_refresh");
    this.setState({
      spotifyToken: null,
    });
  }

  render() {
    return (
      <>
        {this.state.spotifyToken ? (
          <img
            src="/images/spotLogo.png"
            alt=""
            onClick={this.spotifyLogout.bind(this)}
          />
        ) : (
          <img
            className={styles.spotLogo}
            src="/images/spotLogo.png"
            alt=""
            onClick={this.spotifyLogIn.bind(this)}
          />
        )}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SpotifyLogin);
