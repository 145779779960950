import React, { Component } from "react";
import styles from "../styles/shorts.module.css";
import playstyles from "../styles/playlist.module.css";
import ACTIONS from "../../redux/actions/site";
import TEAMACTIONS from "../../redux/actions/team";
import { connect } from "react-redux";
import { Modal, Button, message } from "antd";
import modalstyles from "../styles/sharemodal.module.css";
import mainstyle from "../styles/addTeam.module.css";
import apiRequest from "../../api/clientRequest";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import CampaignModal from "./CampaignModal";
var mapStateToProps = (state) => {
  return {
    savedItems: state.site.savedItems,
    theme: state.site.theme,
    recentUsers: state.site.recentUsers,
    teams: state.team.teams,
    selectedTeam: state.team.selectedTeam,
    user: state.auth.user,
    teamtemplate: state.team.teamtemplates,
  };
};
var mapDispatchToProps = (dispatch) => ({
  setSavedItems: (items) => dispatch(ACTIONS.setSavedItems(items)),
  setTeam: (item) => dispatch(TEAMACTIONS.setTeams(item)),
  setSelectedTeam: (item) => dispatch(TEAMACTIONS.setSelectedTeam(item)),
  setMeter: (items) => dispatch(ACTIONS.setMeter(items)),
  setMeterProgress: (items) => dispatch(ACTIONS.setMeterProgress(items)),
  setMeterTitle: (items) => dispatch(ACTIONS.setMeterTitle(items)),
});

class AddGroup extends Component {
  state = {
    modalVisible: false,
    emails: [],
    teamName: "",
    teamDescription: "",
    teamLogo: null,
  };

  handleOk = async () => {
    this.resetState();
  };

  createTeam = () => {
    const config = {
      headers: {
        "Content-Type": `multipart/form-data;`,
      },
    };
    this.props.setMeterTitle("Creating Group");
    this.props.setMeter(true);
    this.props.setMeterProgress("25");

    const formData = new FormData();
    formData.append("name", this.state.teamName);
    formData.append("description", this.state.teamDescription);
    formData.append("emails", this.state.emails);
    formData.append("avatar", this.state.teamLogo);
    formData.append("team_id", this.props.selectedTeam.id);
    apiRequest({
      url: "/teams/group",
      method: "POST",
      data: formData,
      config,
    }).then((res) => {
      this.props.setMeterTitle("Added Group");
      this.props.setMeterProgress("100");

      setTimeout(() => {
        this.props.setMeter(false);
      }, 1000);

      if (res.data.status == 200) {
        this.resetState();
        let allTeams = this.props.teams;
        const foundIndex = this.props.teams.findIndex(
          (item) => item.id == this.props.selectedTeam.id
        );

        allTeams[foundIndex].groups.push(res.data.group);
        this.props.setSelectedTeam(allTeams[foundIndex]);
        this.props.setTeam([...allTeams]);
      } else {
        message.error(res.data.msg);
        this.props.setMeter(false);
      }
    });
  };

  handleLogoChange(files) {
    if (files.length > 0) this.setState({ teamLogo: files[0] });
  }

  handleCsvUpload(files) {
    if (files.length > 0) {
      var that = this;
      var reader = new FileReader();
      reader.onload = function () {
        console.log(reader.result);
        const emails = that.processCSvData(reader.result);
        that.setState((prev) => ({
          emails: [...prev.emails, ...emails],
        }));
      };
      // start reading the file. When it is done, calls the onload event defined above.
      reader.readAsBinaryString(files[0]);
    }
  }

  processCSvData(allText) {
    console.log(allText);
    allText = allText.replace(/,/g, "");
    console.log(allText);
    var allTextLines = allText.split(/\r\n|\n/);
    return allTextLines.slice(1, allTextLines.length - 2);
    // alert(lines);
  }

  resetState() {
    this.setState({
      teamName: "",
      teamDescription: "",
      emails: "",
      teamLogo: null,
      modalVisible: false,
    });
  }

  handleGroupAdd() {
    const foundItem = this.props.selectedTeam;
    const foundUser = foundItem.users.find(
      (user) => user.email == this.props.user.email
    );
    if (foundUser && foundUser.role !== "Member") {
      this.setState({ modalVisible: true });
    } else {
      message.error("You do not have permission to perform this operation");
    }
  }

  render() {
    const { dataSource } = this.state;
    return (
      <div>
        <div
          className="d-flex align-items-center"
          style={{ cursor: "pointer", position: "absolute", bottom: "60px" }}
          onClick={this.handleGroupAdd.bind(this)}
        >
          <img
            src="/images/plusNewIcon.png"
            style={{
              width: "45px",
              marginRight: "10px",
            }}
          />
          <h2 style={{ fontSize: "1.8em", margin: "0", opacity: "0.55" }}>
            Add Campaign
          </h2>
        </div>

        <CampaignModal
          templates={this.props.teamtemplate}
          visible={this.state.modalVisible}
          selectedTeam={this.props.selectedTeam}
          teams={this.props.teams}
          setVisibility={(val) => {
            this.setState({ modalVisible: false });
          }}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddGroup);
