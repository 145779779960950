import React, { Component } from "react";
import homestyle from "./styles/home.module.css";
import CollectionItem from "./CollectionItem";
import styles from "./styles/Queuelist.module.css";
import { connect } from "react-redux";
import player from "../data/player";
import apiRequest from "../api/clientRequest";
import ACTIONS from "../redux/actions/site";
import ShareModal from "./shareModal";

var Player = player();
var mapStateToProps = (state) => {
  return {
    savedItems: state.site.savedItems,
    podcasts: state.news.podcasts,
    shorts: state.site.shorts,
    musicList: state.site.musicList,
    listenLater: state.news.listenLater,
  };
};
var mapDispatchToProps = (dispatch) => ({
  setSavedItems: (items) => dispatch(ACTIONS.setSavedItems(items)),
});
class CollectionContainer extends Component {
  state = {
    filter: "All",
    podcasts: [],
    allItems: [],
    shorts: [],
    news: [],
    music: [],
    selectedArticle: { type: null },
    shareVisibile: false,
    mode: "",
  };

  playTrack(item, type, playType = false) {
    if (type == "music") Player.playSpotifyTrack(item);
    else if (type == "podcast")
      Player.playPodcast(
        {
          episode: item,
          title: item.podcastTitle,
        },
        playType
      );
    else if (type == "news") Player.playAudio(item, playType);
    else if (type == "shorts") Player.playShorts(item, playType);
  }

  playNext(item, type) {
    if (type == "music") Player.playNext(item, type);
    else if (type == "podcast")
      Player.playNext(
        {
          episode: item,
          title: item.podcastTitle,
        },
        type
      );
    else if (type == "news") Player.playNext(item, type);
    else if (type == "shorts") Player.playNext(item, type);
  }

  removeLiked(item) {
    item.clicked = false;
    this.props.setSavedItems(
      this.props.savedItems.filter((ite) => ite.track_id !== item.id)
    );
    apiRequest({ url: "/news/save", method: "POST", data: item });
  }

  setSelectedArticle(item, type, mode) {
    var selArt = item;
    selArt.type = type;
    this.setState({
      selectedArticle: selArt,
      shareVisibile: true,
      mode,
    });
  }

  renderItems() {
    let items = this.props.listenLater.filter(
      (item) => item.type !== "shortscategory"
    );
    if (this.state.filter == "Podcast")
      items = items.filter((item) => item.type == "podcast");
    else if (this.state.filter == "Music")
      items = items.filter((item) => item.type == "music");
    else if (this.state.filter == "News")
      items = items.filter((item) => item.type == "news");
    else if (this.state.filter == "Shorts")
      items = items.filter((item) => item.type == "shorts");
    else if (this.state.filter == "Videos")
      items = items.filter((item) => item.type == "youtube");

    return items.map((item) => (
      <CollectionItem
        type={item.type}
        data={item}
        onPlay={() => this.playTrack(item, item.type)}
        onPlayNowTrack={() => this.playTrack(item, item.type, "playNow")}
        onPlaySummary={() =>
          this.playTrack({ ...item, summary: true }, item.type, "playNow")
        }
        onPlayNext={() => {
          this.playNext({ ...item, summary: true }, item.type, "playNow");
        }}
        removeLiked={() => this.removeLiked(item)}
        setSelected={(mode) => this.setSelectedArticle(item, item.type, mode)}
      />
    ));
  }

  render() {
    const { filter } = this.state;
    return (
      <div className="col-md-12 mb-5">
        <ShareModal
          visible={this.state.shareVisibile}
          setVisiblity={(cond) =>
            this.setState({
              shareVisibile: cond,
            })
          }
          mode={this.state.mode}
          selectedItem={this.state.selectedArticle}
          type={this.state.selectedArticle.type}
          setSelectedArticle={(article) =>
            this.setState({
              selectedArticle: article,
            })
          }
        />

        <h5
          className={`pl-3 w-100 mb-4 ${homestyle.yellowColow} d-flex`}
          style={{ textTransform: "capitalize" }}
        >
          <img
            src="/images/Heart.png"
            style={{ width: "30px", height: "30px", marginRight: ".5em" }}
          />
          Listen Later{" "}
          <div className="ml-4">
            <button
              onClick={() => this.setState({ filter: "All" })}
              className={`btn ${styles.filter_btn} ${
                filter == "All" ? styles.filter_selected : ""
              }`}
            >
              All
            </button>
            <button
              onClick={() => this.setState({ filter: "News" })}
              className={`btn ${styles.filter_btn} ${
                filter == "News" ? styles.filter_selected : ""
              }`}
            >
              News
            </button>
            <button
              onClick={() => this.setState({ filter: "Music" })}
              className={`btn ${styles.filter_btn} ${
                filter == "Music" ? styles.filter_selected : ""
              }`}
            >
              Music
            </button>
            <button
              onClick={() => this.setState({ filter: "Podcast" })}
              className={`btn ${styles.filter_btn} ${
                filter == "Podcast" ? styles.filter_selected : ""
              }`}
            >
              Podcast
            </button>
            <button
              onClick={() => this.setState({ filter: "Shorts" })}
              className={`btn ${styles.filter_btn} ${
                filter == "Shorts" ? styles.filter_selected : ""
              }`}
            >
              Shorts
            </button>
            <button
              onClick={() => this.setState({ filter: "Videos" })}
              className={`btn ${styles.filter_btn} ${
                filter == "Videos" ? styles.filter_selected : ""
              }`}
            >
              Videos
            </button>
          </div>
        </h5>
        <div className="d-flex flex-wrap">{this.renderItems()}</div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CollectionContainer);
