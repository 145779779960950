import React, { Component } from "react";
import styles from "./styles/sidebar.module.css";
import SITEACTIONS from "../redux/actions/site";
import NEWSACTIONS from "../redux/actions/news";
import { connect } from "react-redux";
import Item from "antd/lib/list/Item";
import player from "../data/player.js";
import moment from "moment";
var Player = player();

var mapStateToProps = (state) => {
  return {
    radioMode: state.news.radioMode,
    subscribedItems: state.site.subscribedItems,
    podcasts: state.news.podcasts,
    musicList: state.site.musicList,
    recentlyPlayed: state.site.recentlyPlayed,
    shorts: state.site.shorts,
    savedItems: state.site.savedItems,
  };
};

var mapDispatchToProps = (dispatch) => ({
  setTab: (item) => dispatch(SITEACTIONS.setTab(item)),
  setRadioMode: (item) => dispatch(NEWSACTIONS.setRadioMode(item)),
});

class DashboardSidebar extends Component {
  switchRadioMode() {
    if (!this.props.radioMode) {
      this.props.setRadioMode(true);
    }
  }

  render() {
    return (
      <div className={`${styles.container} sidebarContainer`}>
        <div
          className={styles.sectionContainer}
          onClick={() => {
            this.switchRadioMode();
          }}
        >
          <img
            src="/images/Radio.png"
            alt=""
            className={`${styles.image} ${styles.image1}`}
          />
          <p>Radio</p>
        </div>
        <div className={styles.sectionContainer}>
          <img
            src="/images/moreIcon.png"
            alt=""
            style={{ width: "25px", filter: "brightness(0.7)" }}
            className={`${styles.image} `}
          />
          <p>More</p>
        </div>
        <div
          className={styles.sectionContainer}
          onClick={() => this.props.setTab(1)}
        >
          <img
            src="/images/list.png"
            alt=""
            className={`${styles.image} ${styles.image2}`}
          />
          <p>News</p>
        </div>
        <div
          className={styles.sectionContainer}
          onClick={() => this.props.setTab(2)}
        >
          <img
            src="/images/mic.png"
            alt=""
            className={`${styles.image} ${styles.image4}`}
          />
          <p>Podcast</p>
        </div>
        <div
          className={styles.sectionContainer}
          onClick={() => this.props.setTab(3)}
        >
          <img
            src="/images/Browse.png"
            alt=""
            className={`${styles.image} ${styles.image3}`}
          />
          <p>Music</p>
        </div>

        <div className={styles.sectionContainer}>
          <img
            src="/images/HeartFill.png"
            alt=""
            className={`${styles.image} ${styles.image5}`}
          />
          <p>Liked</p>
        </div>
        <div className={styles.sectionContainer}>
          <img
            src="/images/cluster.png"
            alt=""
            className={`${styles.image} ${styles.image6}`}
          />
          <p>Shared</p>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardSidebar);
