import React, { Component } from "react";
import { Modal, Button, message, Input } from "antd";
import { connect } from "react-redux";
import styles from "./styles/sharemodal.module.css";
import apiRequest from "../api/clientRequest";
import ACTIONS from "../redux/actions/site";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import moment from "moment";
import CampaignSearch from "./CampaignSearch";
import TEAMACTIONS from "../redux/actions/team";
import "react-multi-email/style.css";
import CampaignItem from "./TeamCampaign/CampaignItem";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import axios from "axios";

const mapStateToProps = (state) => ({
  theme: state.site.theme,
  teams: state.team.teams,
  feedlyUser: state.news.feedlyUser,
});
var mapDispatchToProps = (dispatch) => ({
  setMeter: (items) => dispatch(ACTIONS.setMeter(items)),
  setMeterProgress: (items) => dispatch(ACTIONS.setMeterProgress(items)),
  setMeterTitle: (items) => dispatch(ACTIONS.setMeterTitle(items)),
  setTeam: (item) => dispatch(TEAMACTIONS.setTeams(item)),
  setSelectedTeam: (item) => dispatch(TEAMACTIONS.setSelectedTeam(item)),
  setCampaignItems: (item) => dispatch(TEAMACTIONS.setCampaignItems(item)),
});

class AddCampaignItem extends Component {
  state = {
    emails: [],
    selectedTemplate: "personal",
    selectedCampaign: false,
    items: [this.props.loadedArticle],
    previewLink: false,
  };

  shareByEmail() {
    this.props.setMeterTitle("Sending Email");
    this.props.setMeter(true);
    this.props.setMeterProgress("50");
    const { description, link } = this.props.loadedArticle;
    const campaign = this.state.selectedCampaign;
    var related = [];
    let { items } = this.state;

    if (campaign) {
      var { selected_template, emails, team_id, group_ids } = campaign;
      if (group_ids.length > 0) {
        let groups = this.props.teams.map((item) => item.groups);
        groups = groups.flat();
        let foundGroup = groups.find((item) => group_ids.includes(item.id));
        emails = foundGroup.emails;
      }
      related = items.slice(1);
      this.onSaveCampaign();
    } else {
      var selected_template = this.state.selectedTemplate;
      var emails = this.state.emails;
      var team_id = null;
    }

    let firstItem = items[0];

    apiRequest({
      url: "/news/share/article/email",
      method: "POST",
      data: {
        previewLink: this.state.previewLink,
        related: related,
        loadedLink: firstItem.link,
        shareEmails: emails,
        type: firstItem.type,
        selectedTemplate: selected_template,
        teamid: team_id,
        date: moment().format("X"),
        feedlyUser: this.props.feedlyUser,
        campaignImage: this.state.selectedCampaign.defaultImage,
        groups: [],
        isTag: this.props.isTag,
        ...firstItem,
        description:
          firstItem["description"] !== undefined ? firstItem.description : "",
      },
    }).then((res) => {
      if (res.data.success) {
        if (this.state.previewLink) {
          window.open(res.data.link);
        } else {
          this.props.setMeterTitle("Email sent successfully");
          this.props.setMeterProgress("100");
          if (this.state.selectedTemplate !== "personal") {
            const allteam = [...this.props.teams];
            const foundIndex = allteam.findIndex(
              (item) => item.id == this.state.teamid
            );
            let newEmail = res.data.email;
            newEmail["events"] = [{ event: "sent" }];
            allteam[foundIndex].emails.push(newEmail);
            this.props.setTeam([...allteam]);
          }
          if (!this.state.previewLink) {
            this.props.setVisibility(false);
          }
        }
        setTimeout(() => {
          this.props.setMeter(false);
        }, 2000);
      } else {
        this.props.setMeter(false);
        message.error(res.data.error);
      }

      this.setState({ previewLink: false });
    });
  }

  onSaveCampaign = () => {
    if (this.state.selectedCampaign) {
      const { _id } = this.state.selectedCampaign;
      let { items } = this.state;
      apiRequest({
        url: "/teams/campaign",
        method: "PUT",
        data: {
          _id,
          items,
        },
      }).then((res) => {
        if (res.data.status == 200) {
          this.props.setCampaignItems({
            campId: _id,
            items,
          });
        } else {
          message.error(res.data.error);
        }
      });
    }
    if (!this.state.previewLink) {
      this.props.setVisibility(false);
    }
  };

  removeItem = (data) => {
    if (this.state.selectedCampaign) {
      const { _id } = this.state.selectedCampaign;
      let { items } = this.state;
      let tempitems = [...items];
      tempitems = tempitems.filter((item) => item.id !== data.id);

      this.setState({ items: tempitems });

      apiRequest({
        url: "/teams/campaign",
        method: "PUT",
        data: {
          _id,
          items: tempitems,
        },
      }).then((res) => {
        if (res.data.status == 200) {
          this.props.setCampaignItems({
            campId: _id,
            items: tempitems,
          });
        } else {
          message.error(res.data.error);
        }
      });
    }
  };

  renderItems() {
    let items = this.state.items;

    const SortableItem = SortableElement(({ value, key }) => {
      return (
        <CampaignItem
          // notShare={true}
          data={value}
          type={value.type}
          onRemove={() => this.removeItem(value)}
          key={key}
        />
      );
    });
    const SortableList = SortableContainer(({ items }) => {
      return (
        <div className="d-flex flex-wrap" style={{ overflow: "auto" }}>
          {items.map((value, index) => (
            <SortableItem
              key={`item-${index}`}
              index={index}
              value={value}
              sortIndex={index}
            />
          ))}
        </div>
      );
    });

    return (
      <SortableList
        distance={50}
        items={items}
        onSortEnd={this.onSortEnd.bind(this)}
        // lockToContainerEdges={true}
        axis="xy"
        disableAutoscroll={true}
      />
    );
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ items }) => ({
      items: arrayMove(items, oldIndex, newIndex),
    }));
  };

  render() {
    let campaigns = this.props.teams.map((item) => item.campaigns);
    campaigns = campaigns.flat();
    const loadedArticle = this.props.loadedArticle;
    const { selectedCampaign } = this.state;
    return (
      <Modal
        visible={true}
        title="Share this Article"
        onOk={this.handleOk}
        footer={[
          <Button key="back" onClick={this.handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={this.handleOk}>
            Share
          </Button>,
        ]}
        className={`${styles.shareEmail} shareEmail share-modal ${this.props.theme}`}
        onCancel={this.handleCancel}
      >
        <div className="p-3">
          <div className="d-flex justify-content-between">
            <h2>Add to Email Campaign</h2>
            <i
              class={`${styles.crossIcon} far fa-times-circle`}
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.props.setVisibility(false);
              }}
            ></i>
          </div>

          <div className="row mt-5">
            <div className="col-md-12 px-0">
              <div className="row align-items-center">
                <div className="col-md-5">
                  <h5 className="mb-4" style={{ fontSize: "1.3em" }}>
                    Select an Email Campaign
                  </h5>
                  <CampaignSearch
                    campaigns={campaigns}
                    onSelect={(campaign) =>
                      this.setState((prev) => ({
                        selectedCampaign: campaign,
                        items: [loadedArticle, ...campaign.items],
                      }))
                    }
                  />
                </div>
                <span
                  style={{ fontSize: "1.2em" }}
                  className="d-block text-center mt-5 mx-3 col-md-1"
                >
                  OR
                </span>
                <div className="col-md-5">
                  <h5 className="mb-4 pb-2" style={{ fontSize: "1.3em" }}>
                    Add Recipients and Send
                  </h5>
                  <ReactMultiEmail
                    placeholder="Enter email"
                    emails={this.state.emails}
                    onChange={(_emails) => {
                      this.setState({ emails: _emails });
                    }}
                    validateEmail={(email) => {
                      return isEmail(email); // return boolean
                    }}
                    getLabel={(email, index, removeEmail) => {
                      return (
                        <div data-tag key={index}>
                          {email}
                          <span
                            data-tag-handle
                            onClick={() => removeEmail(index)}
                          >
                            ×
                          </span>
                        </div>
                      );
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-12 mt-5 px-0">
              <h5 className="mb-4" style={{ fontSize: "1.3em" }}>
                Selected Campaign Items
              </h5>

              {this.renderItems()}
            </div>
          </div>
          <div className=" d-flex justify-content-end">
            <button
              class={`btn  mt-3 mb-4 mr-4 ${styles.selectBtn} ${styles.selectBtnActive}`}
              style={{ width: "145px" }}
              onClick={() => {
                this.setState({ previewLink: true }, () => {
                  this.shareByEmail();
                });
              }}
              disabled={!selectedCampaign}
            >
              Preview Link
            </button>
            <button
              class={`btn  mt-3 mb-4 ${styles.selectBtn} ${styles.selectBtnActive}`}
              style={{ width: "145px" }}
              onClick={this.shareByEmail.bind(this)}
              disabled={!selectedCampaign}
            >
              SEND
            </button>
            <button
              class={`btn ml-4 mt-3 mb-4 ${styles.selectBtn} ${styles.selectBtnActive}`}
              style={{ width: "145px", backgroundColor: "green" }}
              onClick={this.onSaveCampaign}
              disabled={!selectedCampaign}
            >
              SAVE
            </button>
          </div>
          <img
            style={{ width: "100px", float: "right" }}
            className="ml-auto"
            src="/images/AudioOneLogo@2x.png"
            alt=""
          />
        </div>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddCampaignItem);
