import React, { Component, useState } from "react";
import $ from "jquery";
import Axios from "axios";
import styles from "./styles/newsitem.module.css";
import MusicStyle from "./styles/musicitem.module.css";
import PlaylistContainer from "./PlaylistContainer";
import { connect } from "react-redux";
import ACTIONS from "../redux/actions/site";
import NEWSACTIONS from "../redux/actions/news";
import colors from "../data/colors.json";
import ShareModal from "./shareModal";
import apiRequest from "../api/clientRequest";
import ShareMethods from "./ShareMethods";
import moment from "moment";
import FavoriteFeedModal from "./FavoriteFeedModal";
var mapStateToProps = (state) => {
  return {
    theme: state.site.theme,
    savedItems: state.site.savedItems,
    youtubeUser: state.news.youtubeUser,
    videos: state.news.youtubeVideos,
  };
};

var mapDispatchToProps = (dispatch) => ({
  setSavedItems: (items) => dispatch(ACTIONS.setSavedItems(items)),
  setFeed: (item) => dispatch(NEWSACTIONS.setFeed(item)),
  setImportantFeed: (item) => dispatch(NEWSACTIONS.setImportantFeed(item)),
});
class NewsItem extends Component {
  state = {
    shareVisibile: false,
    selectedArticle: null,
    showPlayHover: false,
    showShareHover: false,
    mode: null,
    likeHover: false,
  };

  setModalVisibility(cond) {
    this.setState({
      shareVisibile: cond,
    });
  }

  setSelectArticle(article, mode) {
    this.setState({
      selectedArticle: {
        ...article,
        related: this.props.videos
          .splice(0, 5)
          .filter((item) => item.id !== article.id),
      },
      mode,
      shareVisibile: true,
    });
  }

  saveItem = (item) => {
    item.type = "news";
    if (this.props.savedItems.find((ite) => ite.track_id == item.id)) {
      item.clicked = false;
      this.props.setSavedItems(
        this.props.savedItems.filter((ite) => ite.track_id !== item.id)
      );
      apiRequest({ url: "/news/save", method: "POST", data: item });
    } else {
      item.clicked = true;
      apiRequest({ url: "/news/save", method: "POST", data: item });
      this.props.setSavedItems([
        { track_id: item.id, type: "news", ...item },
        ...this.props.savedItems.map((ite) => ite),
      ]);
    }
  };

  render() {
    const { title, image, publishedAt, source, id } = this.props.data;
    var color = "";
    const { showPlayHover, showShareHover, likeHover } = this.state;

    if (this.props.theme == "color-theme" && !image)
      color = colors[Math.floor(Math.random() * colors.length)];
    return (
      <div className={`currency-box  ${this.state.expanded ? "expand" : ""}`}>
        <ShareModal
          visible={this.state.shareVisibile}
          setVisiblity={this.setModalVisibility.bind(this)}
          selectedItem={this.state.selectedArticle}
          type="youtube"
          setSelectedArticle={this.setSelectArticle.bind(this)}
          mode={this.state.mode}
        />
        <div className="currency-pair">
          <div
            className={`background-image `}
            style={{
              background: `url(${image}) ${color}`,
            }}
          ></div>

          <div
            className={styles.playOver}
            onMouseLeave={() =>
              this.setState({ showPlayHover: false, showShareHover: false })
            }
            style={{ display: showPlayHover == 1 ? "flex" : "none" }}
          >
            <div>
              Play Now{" "}
              <img
                src={"/images/playNow.png"}
                className={`${styles.playIcon} `}
                onClick={() => this.props.playVideo("playNow")}
                alt=""
              />
            </div>
            <div>
              Play Next{" "}
              <img
                src={"/images/playNext.png"}
                className={`${styles.playIcon} `}
                onClick={() => this.props.playVideo("playNext")}
                alt=""
              />
            </div>

            <div>
              Play In-Queue{" "}
              <img
                src={"/images/playIcon.png"}
                className={`${styles.playIcon} `}
                onClick={() => this.props.playVideo()}
                alt=""
              />
            </div>
          </div>
          <ShareMethods
            id={1}
            showHover={showShareHover}
            setShowHover={() => this.setState({ showShareHover: false })}
            setSelected={(mode) => this.setSelectArticle(this.props.data, mode)}
          />
          <FavoriteFeedModal
            id={1}
            visible={likeHover}
            setHover={() => this.setState({ likeHover: false })}
            saveToCollection={() => this.saveItem(this.props.data)}
            selectedArticle={this.props.data}
            savedItems={this.props.savedItems}
            type="video"
          />
          <div className="cat-content">
            <span
              className={`name mt-1  ${
                this.state.expanded ? "d-none" : "d-inline-block"
              }`}
            >
              {moment(publishedAt).fromNow()}
            </span>
            <img
              src={"/images/playIcon.png"}
              className={`${styles.playIcon} ${
                this.state.expanded ? "d-none" : ""
              }`}
              onMouseEnter={() => this.setState({ showPlayHover: 1 })}
              alt=""
            />

            <div
              className={`article-container   ${
                this.state.expanded ? "d-none" : ""
              }`}
            >
              <span className="article-name"> {title}</span>
              <div className={styles.actionContainer}>
                <span className="article-source">{source}</span>
                <div className="d-flex ml-auto align-items-end">
                  <img
                    src="/images/cluster.png"
                    className={styles.actionIcon}
                    alt=""
                    onMouseEnter={() => this.setState({ showShareHover: 1 })}
                  />
                  {this.props.savedItems.find(
                    (item) => item.track_id == this.props.data.id
                  ) ? (
                    <img
                      src="/images/HeartFill.png"
                      className={`${styles.actionIcon} ${styles.likedIcon}`}
                      onClick={() => this.saveItem(this.props.data)}
                      onMouseEnter={() => this.setState({ likeHover: 1 })}
                      alt=""
                    />
                  ) : (
                    <img
                      src="/images/Heart.png"
                      className={`${styles.actionIcon} `}
                      onMouseEnter={() => this.setState({ likeHover: 1 })}
                      onClick={() => this.saveItem(this.props.data)}
                      alt=""
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsItem);
