import React from "react";
import sliderstyles from "./styles/sliderItemHover.module.css";
import newsstyles from "./styles/newsitem.module.css";
import apiRequest from "../api/clientRequest";
import NEWSACTIONS from "../redux/actions/news";
import { Popover, Button } from "antd";
import { connect } from "react-redux";
import _ from "lodash";
import { AutoComplete } from "antd";
import styles from "./styles/favoriteBoard.module.css";

var mapStateToProps = (state) => {
  return {
    boards: state.news.boards,
    feedlyUser: state.news.feedlyUser,
    listenLater: state.news.listenLater,
    savedItems: state.site.savedItems,
  };
};
var mapDispatchToProps = (dispatch) => ({
  setListenLater: (item) => dispatch(NEWSACTIONS.setListenLater(item)),
});

function SliderItemLikeHover(props) {
  const [boards, setBoards] = React.useState([]);
  const [savedBoards, setSavedBoards] = React.useState([]);

  React.useEffect(() => {
    setBoards(props.boards);
  }, [props.boards]);

  const [searchQuery, setSearchQuery] = React.useState("");

  const renderBoard = (board) => (
    <div className={`${styles.boardItem}`}>
      <div>
        <i class={`far fa-star ${styles.favIcon}`}></i>

        <span className={styles.boardTitle}>{board.label}</span>
      </div>
      <button
        className={`${styles.saveBtn} ${styles.greenOutlineBtn} ${styles.actionBtn}`}
        onClick={() => {
          saveToFeedlyBoard(props.selectedArticle, board);
          setSavedBoards([board, ...savedBoards]);
        }}
      >
        <i class="fas fa-plus"></i>
        <i class={`fas fa-check `}></i>
        <span>Save</span>
      </button>
    </div>
  );

  const renderSavedBoard = (board) => (
    <div className={`${styles.boardItem} ${styles.saved}`}>
      <div>
        <i class={`far fa-star ${styles.favIcon}`}></i>

        <span className={styles.boardTitle}>{board.label}</span>
      </div>
      <button
        className={`${styles.saveBtn} ${styles.redOutLineBtn} ${styles.actionBtn}`}
        onClick={() =>
          setSavedBoards(
            savedBoards.filter((item) => item.label !== board.label)
          )
        }
      >
        <i class="fas fa-trash"></i>
        <span>Remove</span>
      </button>
    </div>
  );

  const saveToFeedlyBoard = (article, board) => {
    apiRequest({
      method: "POST",
      url: `/news/board`,
      data: {
        feedlyUser: props.feedlyUser,
        board,
        articleId: article.article_id,
      },
    });
  };

  let personalBoards = searchQuery
    ? boards
        .filter(
          (item) =>
            item.label &&
            item.label.toLowerCase().includes(searchQuery.toLowerCase())
        )
        .map((item) => renderBoard(item))
    : boards.map((item) => renderBoard(item));

  const content = (
    <div>
      <div className="row mx-0">
        <div className="col-md-12">
          <h1 className={styles.heading}>
            <img src="/images/feedlyWhite.png" alt="" /> Feedly Boards
          </h1>
        </div>
        <div className="col-md-6">
          <div className="">
            <input
              type="text"
              className={styles.boardSearch}
              placeholder="Find Board"
              value={searchQuery}
              onKeyDown={(e) => e.stopPropagation()}
              onChange={(e) => {
                e.preventDefault();
                setSearchQuery(e.target.value);
              }}
            />
          </div>

          <h3 className={styles.sectionHeading}>PERSONAL BOARDS</h3>
          <div className={styles.horizontalLine}></div>
          <div className={styles.boardList}>{personalBoards}</div>
          <div className={styles.horizontalLine}></div>
          <div>
            <button
              className={`${styles.saveBtn} ${styles.greenOutlineBtn} ${styles.createBtn}`}
            >
              <i class="fas fa-plus"></i>
              <span>CREATE BOARD</span>
            </button>
          </div>
        </div>
        <div className="col-md-6">
          <h3 className={`${styles.sectionHeading} mt-2`}>SELECTED BOARDS</h3>
          <div className={styles.horizontalLine}></div>
          <div className={styles.boardList} style={{ height: "310px" }}>
            {savedBoards.map((item) => renderSavedBoard(item))}
          </div>
        </div>
      </div>
    </div>
  );

  const readLater = () => {
    let track = props.selectedArticle;

    if (props.type.toLowerCase() == "news")
      apiRequest({
        method: "POST",
        url: `/news/read-later`,
        data: {
          ...track,
          item_id: track.id,
          feedlyUser: props.feedlyUser,
          articleId: props.selectedArticle.article_id,
          type: "news",
        },
      });
    else {
      apiRequest({
        method: "POST",
        url: `/news/read-later`,
        data: {
          ...track,
          item_id: track.id,
          type: props.type.toLowerCase(),
        },
      });
    }

    if (props.listenLater.find((ite) => ite.track_id == track.id)) {
      props.setListenLater(
        props.listenLater.filter((ite) => ite.track_id !== track.id)
      );
    } else {
      props.setListenLater([
        { ...track, track_id: track.id, type: props.type.toLowerCase() },
        ...props.listenLater,
      ]);
    }
  };

  return (
    <div
      onMouseLeave={() => {
        props.setLikeHover(false);
      }}
      style={{ display: props.visible ? "block" : "none" }}
      className={`${props.insideSearch ? sliderstyles.smallContainer : ""}  ${
        sliderstyles.container
      }`}
    >
      <div className="row ml-4">
        <div
          className={`col-md-3 ${props.insideSearch ? "p-0" : ""}`}
          style={{ backgroundColor: "transparent" }}
        >
          <div className="col-md-4 d-flex my-3">
            Favorites
            {props.savedItems.find(
              (item) => item.track_id === props.selectedArticle.id
            ) ? (
              <img
                src="/images/HeartFill.png"
                alt=""
                className="ml-4"
                style={{ width: "20px", height: "20px" }}
                onClick={() => props.saveToCollection()}
              />
            ) : (
              <img
                src="/images/Heart.png"
                alt=""
                className="ml-4"
                style={{ width: "20px", height: "20px" }}
                onClick={() => props.saveToCollection()}
              />
            )}
          </div>
          <div className="col-md-4 d-flex">
            Collections
            <img
              src="/images/folderIcon.png"
              style={{ width: "25px", height: "20px", marginLeft: "12px" }}
              onClick={() => props.saveToCollection()}
              alt=""
            />
          </div>
        </div>
        <div
          className={`col-md-4  ${props.insideSearch ? "mt-0" : "mt-3"}`}
          style={{ backgroundColor: "transparent" }}
        >
          <div className="col-md-12 d-flex my-3">
            Listen Later
            <img
              src={"/images/listenLaterIcon.png"}
              className="ml-4"
              alt=""
              onClick={() => readLater()}
              style={{ width: "20px", height: "20px" }}
            />
          </div>
          {props.type.toLowerCase() === "news" ? (
            <Popover
              overlayClassName={styles.boardModal}
              content={content}
              placement="right"
              //   mouseLeaveDelay={1}
              //   onVisibleChange={(visible) => {
              //     setModalVisible(visible);
              //   }}
            >
              <div className="col-md-12 d-flex">
                Feedly Boards
                <img
                  src="/images/feedlyWhite.png"
                  style={{ width: "25px", height: "20px", marginLeft: "12px" }}
                  alt=""
                />
              </div>
            </Popover>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SliderItemLikeHover);
