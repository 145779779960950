import React from "react";
import styles from "./styles/playlist.module.css";
import { play } from "./styles/Queuelist.module.css";


export default function PlaylistItem(props) {
  const { category, articles } = props.data;
  return (
    <div
      className={`${styles.playlistItem} playlistItem`}
      style={{ backgroundColor: `${props.color}` }}
    >
      <img
        src="/images/playIcon.png"
        className={play}
        alt=""
        onClick={() => props.playPlaylist(props.data)}
      />
      <div className={styles.itemInfo}>
        <p className={styles.playlistName}>#{category}</p>
        <div className={styles.articleSideInfo}>
          <p className={styles.noArticle}>{articles.length + 1}</p>
          <p className={styles.article}>articles</p>
        </div>
      </div>
    </div>
  );
}
