import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import NewsBoard from "../../components/Boards/NewsBoard";
import MusicBoard from "../../components/Boards/MusicBoard";
import PodcastBoard from "../../components/Boards/PodcastBoard";
import TeamsBoard from "../../components/Boards/TeamsBoard";
import RightSidebar from "../../components/Sidebar/RightSidebar";
import ACTIONS from "../../redux/actions/site";
import SearchPage from "./SearchPage";
import { instantSearch } from "../../components/styles/search.module.css";
import HomeBoard from "../../components/HomeBoard";
import TeamSidebar from "../../components/Sidebar/TeamSidebar";
import EmailSidebar from "../../components/Sidebar/EmailSidebar";
import VideoBoard from "../../components/Boards/VideoBoard";
var mapStateToProps = (state) => {
  return {
    tab: state.site.tab,
    SearchToggled: state.search.SearchToggled,
    searchType: state.search.searchType,
    searchVal: state.search.searchVal,
    instantSearch: state.search.instantSearch,
    emailExpanded: state.team.emailExpanded,
  };
};

var mapDispatchToProps = (dispatch) => ({
  setTab: (item) => dispatch(ACTIONS.setTab(item)),
});
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return value === index ? children : "";
}

class IndexPage extends Component {
  constructor(props) {
    super(props);
    this.handleChangeIndex = this.handleChangeIndex.bind(this);
  }

  handleChangeIndex(event, newValue) {
    this.props.setTab(newValue);
  }

  render() {
    return (
      <div className="page-content h-100">
        <div className="row main-page h-100">
          <div
            className="w-100 "
            style={{
              height: "87%",
              display: `${
                this.props.SearchToggled & !this.props.instantSearch
                  ? "none"
                  : "flex"
              }`,
            }}
          >
            {/* {this.props.tab == 0 ? (
              <HomeBoard style="flex" />
            ) : this.props.tab == 1 ? (
              <NewsBoard style="flex" />
            ) : this.props.tab == 2 ? (
              <PodcastBoard style="flex" />
            ) : this.props.tab == 3 ? (
              <VideoBoard style="flex" />
            ) : this.props.tab == 4 ? (
              <MusicBoard style="flex" />
            ) : this.props.tab == 5 ? (
              <TeamsBoard style="flex" />
            ) : (
              ""
            )} */}
            <HomeBoard style={this.props.tab == 0 ? "flex" : "none"} />
            <NewsBoard style={this.props.tab == 1 ? "flex" : "none"} />
            <PodcastBoard style={this.props.tab == 2 ? "flex" : "none"} />
            <VideoBoard style={this.props.tab == 3 ? "flex" : "none"} />
            <MusicBoard style={this.props.tab == 4 ? "flex" : "none"} />
            <TeamsBoard style={this.props.tab == 5 ? "flex" : "none"} />
            {this.props.tab !== 5 ? (
              <RightSidebar />
            ) : this.props.emailExpanded ? (
              <EmailSidebar />
            ) : (
              <TeamSidebar />
            )}
          </div>

          <div
            className={`w-100  ml-3 mt-3 flex-column ${
              this.props.instantSearch ? `${instantSearch} instantSearch` : ""
            }`}
            style={{
              height: "100%",
              display: `${this.props.SearchToggled ? "flex" : "none"}`,
            }}
          >
            <SearchPage tab={this.props.tab} />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(IndexPage);
