import React, { Component } from "react";
import styles from "./styles/playlist.module.css";
import shortstyle from "./styles/shorts.module.css";
import PlaylistItem from "./PlaylistItem";
import { connect } from "react-redux";
import player from "../data/player";
import colors from "../data/colors.json";

const mapStateToProps = (state) => ({
  theme: state.site.theme,
  instantSearch: state.site.instantSearch,
});

class PlaylistContainer extends Component {
  player = player();

  playArticle(article) {
    this.player.playAudio(article);
  }

  async playPlaylist(item) {
    var addTracks = async () => {
      const promises = item.articles.map(async (article) =>
        this.player.playShorts(article)
      );
      return Promise.all(promises);
    };

    await addTracks().then((res) => console.log(res));
  }
  render() {
    return (
      <div
        className={`${styles.container} playlistContainer`}
        style={{ opacity: this.props.instantSearch ? "0" : "1" }}
      >
        <div className={styles.headingContainer}>
          <h1 className={`${styles.mainHeading} ${styles.explodedHeading}`}>
            {this.props.heading}
          </h1>
          <h2 className={styles.secondHeading}>Playlists</h2>
        </div>
        <div className={shortstyle.playlistText}>
          <p>
            Listen to daily business news updates. Playlist is updated every
            frequently throughout the day. Click to add to your personal radio
            session.
          </p>
        </div>
        <div className={styles.itemContainer}>
          <PlaylistItem
            data={this.props.data}
            playPlaylist={this.playPlaylist.bind(this)}
          />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, null)(PlaylistContainer);
