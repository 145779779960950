import React, { Component } from "react";
import styles from "../styles/nowPlaying.module.css";
import { imagePicker, imagePickContainer } from "../styles/addTeam.module.css";
import { playlistItem } from "../styles/playlist.module.css";
import { connect } from "react-redux";
import ACTIONS from "../../redux/actions/site";
import TEAMACTIONS from "../../redux/actions/team";
import MemberItem from "../MemberItem";
import AddCampaign from "./AddCampaign";
// import EditGroup from "./EditGroup";
import { message } from "antd";
import apiRequest from "../../api/clientRequest";
import CampaignEdit from "./CampaignEdit";
import ScrollContainer from "react-indiana-drag-scroll";

var mapStateToProps = (state) => {
  return {
    savedItems: state.site.savedItems,
    selectedTeam: state.team.selectedTeam,
    teams: state.team.teams,
    user: state.auth.user,
  };
};

var mapDispatchToProps = (dispatch) => ({
  setSavedItems: (items) => dispatch(ACTIONS.setSavedItems(items)),
  setGroupSelected: (items) => dispatch(TEAMACTIONS.setGroupSelected(items)),
  setTeam: (item) => dispatch(TEAMACTIONS.setTeams(item)),
  setSelectedTeam: (item) => dispatch(TEAMACTIONS.setSelectedTeam(item)),
});

class CampaignList extends Component {
  state = {
    editGroup: false,
    backgroundImage: null,
    editVisible: "",
    selectedCampaign: null,
  };

  setGroupSelected = (id) => {
    const foundItem = this.props.selectedTeam;

    const foundUser = foundItem.users.find(
      (user) => user.email == this.props.user.email
    );
    if (foundUser && foundUser.role === "Member") {
      message.error("You do not have permission to perform this operation");
      return;
    }

    const foundGroup = this.props.selectedTeam.groups.find(
      (item) => item.id == id
    );
    this.props.setGroupSelected(foundGroup);
    this.setState({ editGroup: true });
  };

  deleteCampaign = (id) => {
    const foundItem = this.props.selectedTeam;

    const foundUser = foundItem.users.find(
      (user) => user.email == this.props.user.email
    );
    if (foundUser && foundUser.role === "Member") {
      message.error("You do not have permission to perform this operation");
      return;
    }

    apiRequest({
      url: `/teams/campaign/${id}`,
      method: "DELETE",
    }).then((res) => {
      if (res.data.status == 200) {
        let allTeams = this.props.teams;
        const foundIndex = this.props.teams.findIndex(
          (item) => item.id == this.props.selectedTeam.id
        );

        allTeams[foundIndex].campaigns = allTeams[foundIndex].campaigns.filter(
          (item) => item._id !== id
        );
        console.log(allTeams[foundIndex]);
        this.props.setSelectedTeam(allTeams[foundIndex]);
        this.props.setTeam([...allTeams]);
      } else message.error(res.data);
    });
  };

  setCampaignSelected = (id) => {
    console.log(id);
    this.setState({
      selectedCampaign: this.props.selectedTeam.campaigns.find(
        (item) => item._id == id
      ),
      editVisible: true,
    });
  };

  // handleBackgroundImage(files) {
  //   if (files.length > 0) {
  //     message.info("Adding Default Image");
  //     const formData = new FormData();
  //     formData.append("teamid", this.props.selectedTeam.id);
  //     formData.append("image", files[0]);

  //     apiRequest({
  //       url: `/teams/group/addImage`,
  //       method: "POST",
  //       data: formData,
  //     }).then((res) => {
  //       if (res.data.status == 200) {
  //         message.success("Default Image added");
  //         let allTeams = this.props.teams;
  //         const foundIndex = this.props.teams.findIndex(
  //           (item) => item.id == this.props.selectedTeam.id
  //         );
  //         allTeams[foundIndex]["defaultImage"] = res.data.filename;
  //         this.props.setSelectedTeam(allTeams[foundIndex]);
  //         this.props.setTeam([...allTeams]);
  //       } else message.error(res.data);
  //     });
  //   }
  // }

  render() {
    return (
      <div className={`${styles.content} ${styles.podcastContent}`}>
        <>
          <div className="" style={{ width: "90%" }}>
            <h1
              className={`${styles.title} mt-5 text-right ${styles.podcastHead}`}
              style={{ flex: "none", width: "100%" }}
            >
              <img
                src="/images/emailIcon.png"
                className={styles.typeImage}
                style={{
                  width: "20px",
                  marginRight: "0.7em",
                  marginBottom: "0.25em",
                }}
              />
              Campaigns
            </h1>

            <div className={styles.titleContainer} style={{ marginTop: "6em" }}>
              <h1 className={styles.title} style={{ fontSize: "2.6em" }}>
                {this.props.selectedTeam ? this.props.selectedTeam.name : ""}
              </h1>
              <img
                src={
                  this.props.selectedTeam ? this.props.selectedTeam.logo : ""
                }
                style={{ width: "90px", height: "50px" }}
                alt=""
              />
              <div
                className="d-flex align-items-center"
                style={{ marginBottom: "3em" }}
              ></div>
            </div>
            <div style={{ marginTop: "6em" }}>
              <h1 className={styles.title} style={{ fontSize: "1.6em" }}>
                Email Campaigns
              </h1>
              <ScrollContainer>
                <div className={styles.listContainer}>
                  {this.props.selectedTeam
                    ? this.props.selectedTeam.campaigns.map((item) => (
                        <MemberItem
                          onClick={this.setCampaignSelected}
                          onDelete={this.deleteCampaign}
                          data={{
                            fullname: item.name,
                            id: item._id,
                          }}
                        />
                      ))
                    : ""}
                </div>
              </ScrollContainer>
            </div>

            {this.props.selectedTeam ? (
              <>
                <CampaignEdit
                  selectedCampaign={this.state.selectedCampaign}
                  visible={this.state.editVisible}
                  setVisibility={() =>
                    this.setState({
                      editVisible: false,
                      selectedCampaign: null,
                    })
                  }
                />
                <AddCampaign />
              </>
            ) : (
              ""
            )}
          </div>
        </>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CampaignList);
