import React, { Component } from "react";
import styles from "./styles/newsitem.module.css";
import MusicStyle from "./styles/musicitem.module.css";
import podStyle from "./styles/podcastitem.module.css";
import apiRequest from "../api/clientRequest";
import ShareModal from "./shareModal";
import FavoriteFeedModal from "./FavoriteFeedModal";

export default class TrackItem extends Component {
  state = {
    savedArticle: { id: false },
    shareVisibile: false,
    selectedArticle: null,
    showPlayHover: false,
    likeHover: false,
  };

  render() {
    const track = this.props.data;
    const { showPlayHover, likeHover } = this.state;

    return (
      <div className={`d-flex ${podStyle.podExtendItem}`}>
        <ShareModal
          visible={this.state.shareVisibile}
          setVisiblity={(cond) =>
            this.setState({
              shareVisibile: cond,
            })
          }
          selectedItem={this.state.selectedArticle}
          type="music"
          setSelectedArticle={(article) =>
            this.setState({
              selectedArticle: article,
            })
          }
        />
        <FavoriteFeedModal
          id={track.id}
          visible={likeHover}
          setHover={() => this.setState({ likeHover: false })}
          saveToCollection={() => this.props.saveItem(track)}
          selectedArticle={track}
          savedItems={this.props.savedItems}
          type="music"
        />
        <div
          className={styles.playOver}
          onMouseLeave={() => this.setState({ showPlayHover: false })}
          style={{
            display: showPlayHover == track.id ? "flex" : "none",
          }}
        >
          <div>
            Play In-Queue{" "}
            <img
              src={"/images/playIcon.png"}
              className={`${styles.playIcon} `}
              onClick={() =>
                this.props.playSpotifyTrack(this.props.data, "spotifyplay")
              }
              alt=""
            />
          </div>

          <div>
            Play Next{" "}
            <img
              src={"/images/playNext.png"}
              className={`${styles.playIcon} `}
              onClick={() =>
                this.props.playSpotifyTrack(this.props.data, "playNext")
              }
              alt=""
            />
          </div>

          <div>
            Play Now{" "}
            <img
              src={"/images/playNow.png"}
              className={`${styles.playIcon} `}
              onClick={() =>
                this.props.playSpotifyTrack(this.props.data, "playNow")
              }
              alt=""
            />
          </div>
        </div>

        <img
          src={"/images/playIcon.png"}
          className={styles.playIcon}
          alt=""
          onMouseEnter={() => this.setState({ showPlayHover: track.id })}
          // onClick={() => this.props.playSpotifyTrack(this.props.data)}
        />
        <div className={`article-container`}>
          <div className="d-flex justify-content-between">
            <span className={`article-name ${MusicStyle.articleName}`}>
              {track.title.split(":")[0]}
            </span>
            <img className={MusicStyle.trackImage} src={track.image} alt="" />
          </div>
          <div className={MusicStyle.trackDetailContainer}>
            <div>
              <span>Album</span>
              <p>{track.album}</p>
            </div>
            <div className="d-flex">
              <div>
                <span>Artist</span>
                <p>{track.title.split(":")[1]}</p>
              </div>
            </div>
          </div>
          <div className={styles.actionContainer}>
            {" "}
            <div className="d-flex ml-auto align-items-end">
              <img
                src="/images/cluster.png"
                className={styles.actionIcon}
                alt=""
                onClick={() => {
                  this.setState({
                    shareVisibile: true,
                    selectedArticle: track,
                  });
                }}
              />
              {this.props.savedItems.find(
                (item) => item.track_id == track.id
              ) ? (
                <img
                  src="/images/HeartFill.png"
                  className={`${styles.actionIcon} ${styles.likedIcon}`}
                  onClick={() => this.props.saveItem(track)}
                  alt=""
                  onMouseEnter={() => this.setState({ likeHover: track.id })}
                />
              ) : (
                <img
                  src="/images/Heart.png"
                  className={`${styles.actionIcon} `}
                  onClick={() => this.props.saveItem(track)}
                  alt=""
                  onMouseEnter={() => this.setState({ likeHover: track.id })}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
