import React, { Component } from "react";
import SessionItem from "./SessionItem";
import shortstyle from "./styles/shorts.module.css";
import { expandIcon, actionIcon } from "./styles/newsitem.module.css";
import { subscribeBtn } from "./styles/podcastInfo.module.css";
import NEWSACTIONS from "../redux/actions/news";
import { connect } from "react-redux";
import musicPlayer from "../data/player";
import moment from "moment";

var mapDispatchToProps = (dispatch) => ({
  setRadioMode: (item) => dispatch(NEWSACTIONS.setRadioMode(item)),
});

var mapStateToProps = (state) => {
  return {
    wheather: state.news.wheather,
    feedlyUser: state.news.feedlyUser,
    radioMode: state.news.radioMode,
    subscribedItems: state.site.subscribedItems,
    podcasts: state.news.podcasts,
    musicList: state.site.musicList,
    recentlyPlayed: state.site.recentlyPlayed,
    shorts: state.site.shorts,
    savedItems: state.site.savedItems,
    playlist: state.site.queue.items,
    playlists: state.news.playlists,
    importantFeed: state.news.importantFeed,
  };
};

class SessionContainer extends Component {
  state = { sessions: ["Driving Mode"], session: null, round: 1 };

  receiveMessage(e) {
    const { round } = this.state;

    if (e.data.type == "playQueue") {
      if (this.state.session && e.data.items) {
        if (e.data.items.length == 1) {
          const newTrack = e.data.currentPlayed;
          if (newTrack.type == "spotify") {
            if (round == 1) {
              this.addShorts();
              this.setState({ round: 2 });
            } else {
              this.addNews();
              this.setState({ round: 1 });
            }
          } else if (newTrack.type == "shorts") {
            this.addMusic(2);
          } else if (newTrack.type == "normal") {
            this.addMusic();
          }
        }
      }
    }
  }

  addShorts() {
    const shortSaved = this.props.savedItems.filter(
      (item) => item.type == "shortscategory"
    );

    var shorts = [];
    let shortCount = 0;

    if (shortSaved.length > 0) {
      const savedCats = shortSaved.map((item) => item.track_id);
      const allItems = this.props.shorts.filter((item) =>
        savedCats.includes(item.category)
      );
      shorts = allItems.map((item) => item.shorts);
    }

    if (shorts.length == 0) {
      shorts = this.props.shorts.map((item) => {
        return item.shorts;
      });
    }

    shorts = shorts.filter(
      (v, i, a) => a.findIndex((t) => t.id === v.id) === i
    );

    shorts = [].concat.apply([], shorts);
    shorts = shorts.sort((a, b) => b.published - a.published);
    if (shorts.length > 0) {
      shorts.filter((item) => {
        if (shortCount >= 3) return;
        const curDate = moment();
        const podDate = moment(item.published);
        if (podDate.diff(curDate, "days") <= 7) {
          const testItem = this.props.recentlyPlayed.find(
            (itee) => itee.id == item.id
          );
          var condition = true;
          if (testItem) {
            if (testItem.completed) {
              condition = false;
            } else condition = true;
          } else condition = true;

          if (condition) {
            if (!this.props.playlist.find((tr) => tr.id == item.id)) {
              shortCount += 1;
              this.MusicPlayer.playShorts(item);
            }
          }
        }
      });
    }
  }

  addMusic(count = 3) {
    if (this.props.musicList.length > 0) {
      let tempArr = [...this.props.musicList];

      for (let i = tempArr.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [tempArr[i], tempArr[j]] = [tempArr[j], tempArr[i]];
      }

      let initialArr = tempArr[Math.floor(Math.random() * tempArr.length)];
      var tracksArr = [];
      while (tracksArr.length < count) {
        var rand =
          initialArr.tracks[
            Math.floor(Math.random() * initialArr.tracks.length)
          ];
        if (
          !tracksArr.find((tr) => tr.id == rand.id) &&
          !this.props.playlist.find((tr) => tr.id == rand.id)
        )
          tracksArr.push(rand);
      }

      this.MusicPlayer.playSpotifyList(
        { ...initialArr, tracks: tracksArr },
        window.localStorage.getItem("spotify_token")
      );
    }
  }

  addPodcast(count = 1) {
    let podCount = 0;
    let countLimit = count;
    var radioPodcasts = [];

    if (
      this.props.subscribedItems.filter((item) => item.type == "podcast")
        .length > 0
    ) {
      const subPodcasts = this.props.subscribedItems
        .filter((item) => item.type == "podcast")
        .sort((a, b) => b.published - a.published);

      subPodcasts.filter((item) => {
        if (podCount >= countLimit) return;
        const curDate = moment();
        const podDate = moment(item.published);
        if (podDate.diff(curDate, "days") <= 7) {
          const testItem = this.props.recentlyPlayed.find(
            (itee) => itee.id == item.episode[0].id
          );

          var condition = true;
          if (testItem) {
            if (testItem.completed) {
              condition = false;
            } else condition = true;
          } else condition = true;

          if (condition) {
            if (
              !this.props.playlist.find((tr) => tr.id == item.episode[0].id)
            ) {
              podCount += 1;
              radioPodcasts.push({
                episode: item.episode[0],
                title: item.title,
              });
            }
          }
        }
      });
    }
    var currentPodcasts = [];

    if (this.props.podcasts.length > 0) {
      if (podCount < countLimit) {
        let podcasts = this.props.podcasts.map((item) => item.podcasts);
        podcasts = [].concat.apply([], podcasts);
        podcasts = podcasts.sort((a, b) => b.published - a.published);

        podcasts.map((podcast) => {
          if (podCount >= countLimit) return;
          const curDate = moment();
          const podDate = moment(podcast.published);
          if (podDate.diff(curDate, "days") <= 7) {
            const testItem = this.props.recentlyPlayed.find(
              (itee) => itee.id == podcast.episode[0].id
            );

            var condition = true;
            if (testItem) {
              if (testItem.completed) {
                condition = false;
              } else condition = true;
            } else condition = true;

            if (
              !this.props.playlist.find((tr) => tr.id == podcast.episode[0].id)
            ) {
              podCount += 1;
              currentPodcasts.push({
                episode: podcast.episode[0],
                title: podcast.title,
              });
            }
          }
        });
      }
    }

    radioPodcasts.push(...currentPodcasts);
    radioPodcasts.map((podcast) => {
      this.MusicPlayer.playPodcast(podcast);
    });
  }

  addNews() {
    if (this.props.playlists.length > 0) {
      let allTracks = [];
      allTracks = this.props.playlists.map((item) => {
        return item.articles;
      });

      allTracks = [].concat.apply([], allTracks);

      allTracks = allTracks.sort((a, b) => b.published - a.published);
      allTracks = allTracks.filter(
        (v, i, a) => a.findIndex((t) => t.id === v.id) === i
      );
      allTracks.unshift(...this.props.importantFeed);
      allTracks = allTracks.filter((item) => {
        const fItem = this.props.recentlyPlayed.find(
          (itee) => itee.id == item.id
        );

        if (fItem) {
          if (fItem.completed) {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      });

      let selTracks = [];
      for (var i = 0; selTracks.length <= 3; i++) {
        const selectedItem = allTracks[i];
        if (selectedItem)
          if (!this.props.playlist.find((tr) => tr.id == selectedItem.id)) {
            selTracks.push(selectedItem);
          }
      }
      var that = this;
      var addTracks = async () => {
        const promises = selTracks.map(async (article) =>
          that.MusicPlayer.playAudio(article, true)
        );
        return Promise.all(promises);
      };

      addTracks().then((res) => console.log(res));
    }
  }

  componentDidMount() {
    window.addEventListener("beforeunload", this.onUnload);
    window.addEventListener("message", this.receiveMessage.bind(this));
  }

  playSession(session) {
    const { temperature, humidity, precip, visibility } = this.props.wheather;
    const time = moment().format("HH:mm A");
    const announcer = `You're listening to AudioOne Radio Drive Mode.  The current time is ${time}.  The temperature is ${temperature} with a humidity of ${humidity}, visibility ${visibility} and ${precip} chance of precipitation.`;
    this.MusicPlayer.toggleStation("playNow", "session", announcer);
    setTimeout(() => {
      this.setState({ session }, () => {
        this.addMusic();
      });
    }, 1000);
  }
  MusicPlayer = musicPlayer();

  render() {
    return (
      <div className="col-md-12 mb-5">
        <h5
          className={`pl-3 w-100 mb-4 `}
          style={{ textTransform: "capitalize" }}
        >
          <img
            src="/images/Radio.png"
            style={{ width: "30px", marginRight: ".5em" }}
          />
          Sessions{" "}
          <img
            src="/images/PlusIcon.png"
            style={{ width: "20px", marginLeft: ".5em" }}
          />
        </h5>
        <div className="d-flex overflow-x">
          {this.state.sessions.map((item) => (
            <SessionItem
              session={item}
              duration={15}
              onPlay={this.playSession.bind(this)}
            />
          ))}
          <div
            className={`${shortstyle.firstItem} ml-3 mb-3`}
            style={{
              backgroundColor: `${
                this.props.theme == "color-theme" ? "#182c42" : "#141313"
              }`,
            }}
          >
            <p className="mb-4">
              <b>Audio One Sessions</b> are preset and user defined playlists of
              music, news and podcasts. Some are contextual based on time,
              location, and activities so that you get the best audio experience
              possible. Customize each session how you want.
            </p>
            <div className="d-flex">
              <button className={subscribeBtn} style={{ fontSize: ".8em" }}>
                Learn More
              </button>
              <div className="d-flex ml-auto align-items-end">
                <img src="/images/cluster.png" className={actionIcon} alt="" />
                <img
                  src="/images/Heart.png"
                  className={`${actionIcon} `}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SessionContainer);
