import React, { Component } from "react";
import styles from "./styles/playlist.module.css";
import PlaylistItem from "./PlaylistItem";
import { connect } from "react-redux";
import player from "../data/player";
import colors from "../data/colors.json";

const mapStateToProps = (state) => ({
  theme: state.site.theme,
  instantSearch: state.site.instantSearch,
});

class PlaylistContainer extends Component {
  player = player();

  playArticle(article) {
    this.player.playAudio(article);
  }

  async playPlaylist(item) {
    var addTracks = async () => {
      const promises = item.articles.map(async (article) =>
        this.player.playAudio(article, true)
      );
      return Promise.all(promises);
    };

    await addTracks().then((res) => console.log(res));
  }

  componentDidUpdate() {
    const element = this.element;
    // Things involving accessing DOM properties on element
    // In the case of what this question actually asks:
    const hasOverflowingChildren =
      element.offsetHeight < element.scrollHeight ||
      element.offsetWidth < element.scrollWidth;
  }

  render() {
    return (
      <div
        className={`${styles.container} playlistContainer`}
        style={{ display: this.props.instantSearch ? "none" : "flex" }}
      >
        <div className={styles.headingContainer}>
          <h2 className={styles.secondHeading}>Top News Article Playlists</h2>
        </div>
        <div
          className={styles.itemContainer}
          ref={(el) => {
            this.element = el;
          }}
        >
          {this.props.playlists.map((data) => {
            var color = false;
            if (this.props.theme == "color-theme")
              color = colors[Math.floor(Math.random() * colors.length)];

            if (data.articles.length > 0)
              return (
                <PlaylistItem
                  color={color}
                  data={data}
                  playArticle={this.playArticle.bind(this)}
                  playPlaylist={this.playPlaylist.bind(this)}
                />
              );
          })}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, null)(PlaylistContainer);
