import React, { Component } from "react";
import styles from "../styles/rightSidebar.module.css";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Tooltip from "@material-ui/core/Tooltip";
import { connect } from "react-redux";
import ACTIONS from "../../redux/actions/team";
import TeamMember from "../TeamMember";
import TeamGroup from "../TeamGroup/GroupList";
import TeamCampaign from "../TeamCampaign/CampaignList";

const mapStateToProps = (state) => ({
  tab: state.team.tab,
  theme: state.site.theme,
});

const mapDispatchToProps = (dispatch) => ({
  setTab: (item) => dispatch(ACTIONS.setTeamTab(item)),
});

function CloneProps(props) {
  const { children, ...other } = props;
  return children(other);
}

class TeamSidebar extends Component {
  handleTabChange(event, newValue) {
    this.props.setTab(newValue);
  }

  render() {
    const tabs = [
      <Tab label="Team" value={0} disableRipple={true} />,
      <Tab label="Groups" value={1} disableRipple={true} />,
      <Tab label="Campaigns" value={2} disableRipple={true} />,
    ];

    return (
      <div
        className={`col-md-3 p-0 `}
        style={{
          backgroundImage:
            "linear-gradient(180deg, rgba(0, 0, 0, 0.84) 0%, rgba(0, 0, 0, 0.58) 48%, rgba(0, 0, 0, 0.69) 54%, rgba(0, 0, 0, 0.84) 100%)",
        }}
      >
        <div
          className={`histogram team-sidebar px-3 pb-3 pt-0 ${styles.histogram}`}
          style={{ overflow: "hidden" }}
        >
          <div className={`histogram-logo ${styles.sidebarContainer}`}>
            <Tabs
              value={this.props.tab}
              onChange={this.handleTabChange.bind(this)}
              TabIndicatorProps={{ style: { display: "none" } }}
              variant={"standard"}
              tabItemContainerStyle={{
                position: "fixed",
                bottom: "0",
              }}
            >
              {tabs.find((item, index) => index == this.props.tab)}
              {tabs.filter((item, index) => index != this.props.tab)}
              {/* <CloneProps>
                {(tabProps) => (
                  <Tooltip title="Coming Soon">
                    <div>
                      <Tab {...tabProps} disabled label={<span>Chat</span>} />
                    </div>
                  </Tooltip>
                )}
              </CloneProps>
              <CloneProps>
                {(tabProps) => (
                  <Tooltip title="Coming Soon">
                    <div>
                      <Tab {...tabProps} disabled label={<span>Detail</span>} />
                    </div>
                  </Tooltip>
                )}
              </CloneProps> */}
            </Tabs>
          </div>
          <div className={styles.tabContainer}>
            {this.props.tab == 0 ? <TeamMember /> : ""}
            {this.props.tab == 1 ? <TeamGroup /> : ""}
            {this.props.tab == 2 ? <TeamCampaign /> : ""}
          </div>
        </div>
      </div>
    );
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(TeamSidebar);
