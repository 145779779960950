import React, { Component, useState } from "react";
import $ from "jquery";
import Axios from "axios";
import styles from "./styles/newsitem.module.css";
import MusicStyle from "./styles/musicitem.module.css";
import PlaylistContainer from "./PlaylistContainer";
import { connect } from "react-redux";
import ACTIONS from "../redux/actions/site";
import NEWSACTIONS from "../redux/actions/news";
import colors from "../data/colors.json";
import ShareModal from "./shareModal";
import apiRequest from "../api/clientRequest";
import ShareMethods from "./ShareMethods";
import FavoriteFeedModal from "./FavoriteFeedModal";
import { subscribeBtn, subscribedBtn } from "./styles/podcastInfo.module.css";

var mapStateToProps = (state) => {
  return {
    playlists: state.news.playlists,
    theme: state.site.theme,
    savedItems: state.site.savedItems,
    feedlyUser: state.news.feedlyUser,
    tags: state.news.tags,
    feed: state.news.feed,
    importantFeed: state.news.importantFeed,
    boards: state.news.boards,
    subscribedItems: state.site.subscribedItems,
  };
};

var mapDispatchToProps = (dispatch) => ({
  setSavedItems: (items) => dispatch(ACTIONS.setSavedItems(items)),
  setFeed: (item) => dispatch(NEWSACTIONS.setFeed(item)),
  setImportantFeed: (item) => dispatch(NEWSACTIONS.setImportantFeed(item)),
  setBoards: (item) => dispatch(NEWSACTIONS.setBoards(item)),
  setSubscribedItems: (items) => dispatch(ACTIONS.setSubscribedItems(items)),
});
class TagsItem extends Component {
  state = {
    expanded: false,
    loadingImage: false,
    hoverImg: false,
    shareVisibile: false,
    selectedArticle: null,
    savedArticle: { id: false },
    showPlayHover: false,
    showShareHover: false,
    mode: null,
    likeHover: false,
  };

  componentWillMount() {
    try {
      if (this.props.articles[0].image.contains("https"))
        Axios.get(this.props.articles[0].image)
          .then((res) => {
            this.setState({ loadingImage: true });
          })
          .catch((err) => {
            this.setState({ loadingImage: false });
          });
    } catch (err) {
      this.setState({ loadingImage: false });
    }
  }

  unExpand() {
    $(".instantSearch").toggleClass("d-none");

    this.setState({
      expanded: false,
    });
  }

  setModalVisibility(cond) {
    this.setState({
      shareVisibile: cond,
    });
  }

  setSelectArticle(article, mode) {
    this.setState({
      selectedArticle: article,
      mode,
      shareVisibile: true,
    });
  }

  saveItem = (item) => {
    item.type = "news";
    if (this.props.savedItems.find((ite) => ite.track_id == item.id)) {
      item.clicked = false;
      this.props.setSavedItems(
        this.props.savedItems.filter((ite) => ite.track_id !== item.id)
      );
      apiRequest({ url: "/news/save", method: "POST", data: item });
    } else {
      item.clicked = true;
      apiRequest({ url: "/news/save", method: "POST", data: item });
      this.props.setSavedItems([
        { track_id: item.id, type: "news", ...item },
        ...this.props.savedItems.map((ite) => ite),
      ]);
    }
  };

  expand() {
    apiRequest({
      url: `/news/board-get`,
      method: "POST",
      data: { feedlyUser: this.props.feedlyUser, tag: this.props.data },
    }).then((res) => {
      let allBoards = this.props.boards;
      const foundIndex = allBoards.findIndex(
        (item) => item.id == this.props.data.id
      );
      allBoards[foundIndex]["articles"] = res.data;
      this.props.setBoards(allBoards);
      $(".instantSearch").toggleClass("d-none");
      this.setState({ expanded: true });
    });
    $(".instantSearch").toggleClass("d-none");
    this.setState({ expanded: true });
  }

  subscribeTo = (item, isSubscribed) => {
    item.type = "news";
    item["item_id"] = item.id;
    if (isSubscribed) {
      item.clicked = false;
      this.props.setSubscribedItems(
        this.props.subscribedItems.filter((ite) => ite.item_id !== item.id)
      );
      apiRequest({
        url: "/news/subscribe",
        method: "POST",
        data: item,
        type: "news",
      });
    } else {
      item.clicked = true;
      apiRequest({
        url: "/news/subscribe",
        method: "POST",
        data: item,
        type: "news",
      });
      this.props.setSubscribedItems([
        ...this.props.subscribedItems.map((ite) => ite),
        item,
      ]);
    }
  };

  playAllArticles(articles) {
    articles.map((article) => this.props.playArticle(article));
  }

  render() {
    let { label, articles, cover, id, description } = this.props.data;

    const isSubscribed = this.props.subscribedItems.find((item) => {
      return item.item_id == id;
    });
    var color = "";
    const { showPlayHover, showShareHover, likeHover } = this.state;

    return (
      <div className={`currency-box  ${this.state.expanded ? "expand" : ""}`}>
        <ShareModal
          visible={this.state.shareVisibile}
          setVisiblity={this.setModalVisibility.bind(this)}
          selectedItem={this.state.selectedArticle}
          type="news"
          setSelectedArticle={this.setSelectArticle.bind(this)}
          mode={this.state.mode}
          isTag={id}
        />
        <div className="currency-pair">
          <div
            className={`background-image ${isSubscribed ? "subscribed" : ""} ${
              cover ? "" : "tags"
            } ${
              this.props.theme == "color-theme" && this.state.expanded
                ? "white-back"
                : ""
            }`}
            style={{
              background: `${
                this.props.theme == "color-theme" && this.state.expanded
                  ? "white"
                  : `url(${cover}) ${color}`
              }`,
            }}
          ></div>

          <div className="cat-content">
            <img
              src="/images/block-menu.png"
              className={`${styles.expandIcon} ${
                this.state.expanded ? "d-none" : "d-inline-block"
              }`}
              alt=""
              onClick={() => this.expand()}
            />
            <span
              className={`name mt-1  ${
                this.state.expanded ? "d-none" : "d-inline-block"
              }`}
            >
              {label}
            </span>

            <div
              className={`article-container   ${
                this.state.expanded ? "d-none" : ""
              }`}
            >
              <span className="article-name"> {description}</span>

              <div className={styles.actionContainer}>
                <span className="article-source">
                  {isSubscribed ? (
                    <button
                      className={`${subscribeBtn} ${subscribedBtn}`}
                      style={{ marginTop: "2em" }}
                      onClick={() =>
                        this.subscribeTo(this.props.data, isSubscribed)
                      }
                    >
                      Subscribed
                    </button>
                  ) : (
                    <button
                      className={` ${subscribeBtn}`}
                      onClick={() =>
                        this.subscribeTo(this.props.data, isSubscribed)
                      }
                      style={{ marginTop: "2em" }}
                    >
                      Subscribe
                    </button>
                  )}
                </span>
              </div>
            </div>

            {this.state.expanded ? (
              <div
                className={`row m-0 expanded-container ${
                  this.state.expanded ? "" : "d-none"
                }`}
              >
                <div
                  className={styles.backArrowContainer}
                  onClick={this.unExpand.bind(this)}
                >
                  <img src="/images/backTo.svg" alt="" />
                  <img src="/images/back-left.png" alt="" />
                </div>
                <div
                  style={{
                    position: "fixed",
                    left: "31px",
                    top: "30%",
                    zIndex: "999",
                  }}
                >
                  <img
                    src={"/images/playIcon.png"}
                    className={`${styles.playIcon} `}
                    onClick={() => this.playAllArticles(articles)}
                    style={{
                      width: "80px",
                      height: "80px",
                      marginBottom: "15px",
                    }}
                    alt=""
                  />
                  <p style={{ textAlign: "center" }}>Play All </p>
                </div>
                <PlaylistContainer
                  playlists={[]}
                  heading={label.toUpperCase()}
                />
                {articles &&
                  articles.map((article) => (
                    <div
                      className="d-flex"
                      // onMouseLeave={() => this.setState({ hoverImg: false })}
                      // onMouseOver={() => this.setState({ hoverImg: article.image })}
                    >
                      <div
                        className={styles.playOver}
                        onMouseLeave={() =>
                          this.setState({ showPlayHover: false })
                        }
                        style={{
                          display:
                            showPlayHover == article.id ? "flex" : "none",
                        }}
                      >
                        <div>
                          Play Now{" "}
                          <img
                            src={"/images/playNow.png"}
                            className={`${styles.playIcon} `}
                            onClick={() =>
                              this.props.playArticle(article, "playNow")
                            }
                            alt=""
                          />
                        </div>
                        <div>
                          Play Next{" "}
                          <img
                            src={"/images/playNext.png"}
                            className={`${styles.playIcon} `}
                            onClick={() => this.props.playNext(article)}
                            alt=""
                          />
                        </div>
                        <div>
                          {article["leoSummary"]
                            ? "Play Leo Summary"
                            : "Play Summary"}
                          <img
                            src={"/images/playIcon.png"}
                            className={`${styles.playIcon} `}
                            onClick={() =>
                              this.props.playArticle(
                                {
                                  ...article,
                                  summary: true,
                                },
                                "playNow"
                              )
                            }
                            alt=""
                          />
                        </div>
                        <div>
                          Play In-Queue{" "}
                          <img
                            src={"/images/playIcon.png"}
                            className={`${styles.playIcon} `}
                            onClick={() => this.props.playArticle(article)}
                            alt=""
                          />
                        </div>
                      </div>

                      <ShareMethods
                        id={article.id}
                        showHover={showShareHover}
                        setShowHover={() =>
                          this.setState({ showShareHover: false })
                        }
                        setSelected={(mode) =>
                          this.setSelectArticle(article, mode)
                        }
                      />
                      <FavoriteFeedModal
                        id={article.id}
                        visible={likeHover}
                        setHover={() => this.setState({ likeHover: false })}
                        saveToCollection={() => this.saveItem(article)}
                        selectedArticle={article}
                        savedItems={this.props.savedItems}
                        type="news"
                      />

                      <img
                        src={"/images/playIcon.png"}
                        onClick={() => this.props.playArticle(article)}
                        className={styles.playIcon}
                        onMouseEnter={() =>
                          this.setState({ showPlayHover: article.id })
                        }
                        alt=""
                      />
                      <div className="article-container ">
                        <div className="d-flex justify-content-between">
                          <span
                            className={`article-name ${MusicStyle.articleName}`}
                          >
                            {article.title}
                          </span>
                          <img
                            className={MusicStyle.trackImage}
                            src={article.image}
                            alt=""
                          />
                        </div>

                        <div className={styles.actionContainer}>
                          {" "}
                          <span className="article-source">
                            {article.source ? article.source.substr(0, 15) : ""}
                          </span>
                          <div className="d-flex ml-auto align-items-end">
                            <img
                              src="/images/cluster.png"
                              className={styles.actionIcon}
                              alt=""
                              onMouseEnter={() =>
                                this.setState({ showShareHover: article.id })
                              }
                            />
                            {this.props.savedItems.find(
                              (item) => item.track_id == article.id
                            ) ? (
                              <img
                                src="/images/HeartFill.png"
                                className={`${styles.actionIcon} ${styles.likedIcon}`}
                                onClick={() => this.saveItem(article)}
                                onMouseEnter={() =>
                                  this.setState({ likeHover: article.id })
                                }
                                alt=""
                              />
                            ) : (
                              <img
                                src="/images/Heart.png"
                                className={`${styles.actionIcon} `}
                                onClick={() => this.saveItem(article)}
                                onMouseEnter={() =>
                                  this.setState({ likeHover: article.id })
                                }
                                alt=""
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TagsItem);
