import React, { Component } from "react";
import QueueList from "../QueueList";
import styles from "../styles/rightSidebar.module.css";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { connect } from "react-redux";
import ACTIONS from "../../redux/actions/site";
import NowPlaying from "../NowPlaying";
import RecentPlayed from "../RecentPlayed";
const mapStateToProps = (state) => ({
  tab: state.site.queueTab,
  theme: state.site.theme,
});

const mapDispatchToProps = (dispatch) => ({
  setTab: (item) => dispatch(ACTIONS.setQueueTab(item)),
});

class RightSidebar extends Component {
  handleTabChange(event, newValue) {
    this.props.setTab(newValue);
  }

  render() {
    const tabs = [
      // <Tab label="Now Playing" value={0} disableRipple={true} />,
      <Tab label="Audio Queue" value={1} disableRipple={true} />,
      <Tab label="Recently Played" value={2} disableRipple={true} />,
    ];

    return (
      <div
        className={`col-md-3 p-0 `}
        style={{
          backgroundColor:
            this.props.tab == 1 ? "transparent" : "rgba(58, 62, 70, 0.2)",
        }}
      >
        <div
          className={`histogram px-3 pb-3 pt-0 ${styles.histogram}`}
          style={{ overflow: this.props.tab == 0 ? "hidden" : "" }}
        >
          <div
            className={`histogram-logo ${styles.sidebarContainer} ${
              this.props.theme !== "black-theme" &&
              (this.props.tab == 1 || this.props.tab == 2)
                ? styles.tabColor
                : ""
            }`}
          >
            <Tabs
              value={this.props.tab}
              onChange={this.handleTabChange.bind(this)}
              TabIndicatorProps={{ style: { display: "none" } }}
              variant={"standard"}
              tabItemContainerStyle={{
                position: "fixed",
                bottom: "0",
              }}
            >
              {tabs.filter((item, index) => index != this.props.tab)}
              {tabs.find((item, index) => index == this.props.tab)}
            </Tabs>
          </div>
          <div className={styles.tabContainer}>
            {/* {this.props.tab == 0 ? <NowPlaying /> : ""} */}
            {this.props.tab == 1 ? <QueueList /> : ""}
            {this.props.tab == 2 ? <RecentPlayed /> : ""}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RightSidebar);
