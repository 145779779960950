import React from "react";
import contstyles from "./styles/contentstyle.module.css";
import contentstyle from "./contentsection/contentpage.module.css";
import { sourceImg } from "./styles/nowPlaying.module.css";
import newui from "./styles/newui.module.css";
import { connect } from "react-redux";
import SliderItemLikeHover from "./SliderItemLikeHover";
import SliderItemShareHover from "./SliderItemShareHover";
import apiRequest from "../api/clientRequest";
import ACTIONS from "../redux/actions/site";
import ShareModal from "./shareModal";

var mapStateToProps = (state) => {
  return {
    savedItems: state.site.savedItems,
  };
};

var mapDispatchToProps = (dispatch) => ({
  setSavedItems: (items) => dispatch(ACTIONS.setSavedItems(items)),
});

function MainSliderItem(props) {
  const [likeHover, setLikeHover] = React.useState(false);
  const [shareHover, setShareHover] = React.useState(false);
  const [mode, setMode] = React.useState(false);
  const [shareVisible, setShareVisible] = React.useState(false);

  let item = props.data;

  const saveItem = (item) => {
    if (props.savedItems.find((ite) => ite.track_id == item.id)) {
      item.clicked = false;
      props.setSavedItems(
        props.savedItems.filter((ite) => ite.track_id !== item.id)
      );
      apiRequest({ url: "/news/save", method: "POST", data: item });
    } else {
      item.clicked = true;
      apiRequest({ url: "/news/save", method: "POST", data: item });
      props.setSavedItems([
        { track_id: item.id, type: "news", ...item },
        ...props.savedItems.map((ite) => ite),
      ]);
    }
  };

  const setSelectArticle = (mode) => {
    setMode(mode);
    setShareVisible(true);
    props.setAutoPlay(false);
  };
  return (
    <div className="d-flex pl-5">
      <img
        src={"/images/playIcon.png"}
        alt=""
        style={{
          width: "50px",
          height: "50px",
          cursor: "pointer",
          marginTop: "40px",
        }}
        onClick={() => props.onPlay(item)}
      />
      <div
        className={`ml-4 mt-0 ${contstyles.titleContainer} titleContainer`}
        style={{
          flexDirection: "column",
          display: "flex",
          position: "relative",
        }}
      >
        <ShareModal
          visible={true}
          setVisiblity={() => {
            setShareVisible(false);
            props.setAutoPlay(true);
          }}
          selectedItem={item}
          type={item.type}
          setSelectedArticle={setSelectArticle}
          mode={mode}
        />
        <SliderItemLikeHover
          visible={likeHover}
          setLikeHover={setLikeHover}
          saveToCollection={() => saveItem(item)}
          selectedArticle={item}
          type={item.type}
        />

        <SliderItemShareHover
          visible={shareHover}
          setShowHover={setShareHover}
          setSelected={(mode) => setSelectArticle(mode)}
        />

        <h5 className="w-100 d-flex" style={{ textTransform: "capitalize" }}>
          {item.type == "podcast" ? (
            <>
              <img src="/images/Radio.png" className={contentstyle.actionBtn} />
              {item.subtype}
            </>
          ) : item.type == "youtube" ? (
            <>
              <img
                src="/images/youtubeWhiteIcon.png"
                className={contentstyle.actionBtn}
              />
              YouTube Video
            </>
          ) : item.type == "shorts" ? (
            <>
              <img
                src="/images/spotWhite.png"
                className={contentstyle.actionBtn}
              />
              Shorts
            </>
          ) : item.type == "music" ? (
            <>
              <img
                src="/images/spotWhite.png"
                className={contentstyle.actionBtn}
              />
              Music Playlists
            </>
          ) : (
            <>
              <img
                src="/images/feedlyWhite.png"
                className={contentstyle.actionBtn}
              />
              {item.subtype}
            </>
          )}
        </h5>
        <h2 className={`${newui.itemTitle}  ${contstyles.artTitle}`}>
          <span>{item.title}</span>
          <h4
            className="mt-2 d-flex"
            style={{
              fontSize: "18px",
              fontWeight: "normal",
            }}
          >
            {item.sourceImg ? (
              <img src={item.sourceImg} className={`${sourceImg}`} />
            ) : (
              ""
            )}
            {item.source}

            <div className={"ml-auto d-flex"} style={{ zIndex: 999 }}>
              <img
                src="/images/moreIcon.png"
                alt=""
                className={contentstyle.actionBtn}
              />
              <img
                src="/images/cluster.png"
                alt=""
                onMouseEnter={() => setShareHover(true)}
                className={contentstyle.actionBtn}
              />
              {props.savedItems.find((ite) => ite.track_id == props.data.id) ? (
                <img
                  src="/images/HeartFill.png"
                  className={contentstyle.actionBtn}
                  onClick={() => props.saveItem(props.data)}
                  onMouseEnter={() => setLikeHover(true)}
                  alt=""
                />
              ) : (
                <img
                  src="/images/Heart.png"
                  className={contentstyle.actionBtn}
                  onClick={() => props.saveItem(props.data)}
                  onMouseEnter={() => setLikeHover(true)}
                  alt=""
                />
              )}
            </div>
          </h4>
        </h2>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(MainSliderItem);
